const userEn = {
  // Setting
  publicProfile: "Public Profile",
  accountSetting: "Account Setting",
  notifications: "Notifications",
  security: "Security",
  paymentMethods: "Payment Methods",
  connectYourDiscordAccount: "Connect Your Discord Account",
  connectYourTwitterAccount: "Connect Your Twitter Account",
  clubName: "Club Name",
  uploadAnImage: "Upload an image",
  updateProfile: "Update Profile",
  update: "Update",
  accountSettings: "Account Settings",
  change: "Change",
  add: "Add",
  submit: "Submit",
  changePassword: "Change Password",
  currentPassword: "Current Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  newPasswordConfirm: "New Password Confirmation",
  addEmail: "Add Email",
  addMobile: "Add Mobile",
  status: "Status",
  overview: "Overview",
  cards: "Cards",
  achievements: "Achievements",
  squads: "Squads",
  myAuctions: "My Auctions",
  offersReceived: "Offers received",
  offersSent: "Offers sent",
  transactions: "Transactions",
  delete: "Delete",
  edit: "Edit",
  editAuction: "Edit Auction",
  volexBalance: "Volex balance",
  notificationRecords: "Notification Records",
  markAllAsRead: "Mark all as read",
  noNotification: "No notification found!",
  depositHistory: "Deposit History",
  withdrawHistory: "Withdraw History",
  paymentHistory: "Payment History",
  submittedOffers: "Submitted offers",
  auctionDetail: "Auction detail",
  seeAuctionDetail: "View",
  FINISHED: "Finished",
  CANCELED: "Canceled",
  REGISTERED: "Registered",
  WON: "Won",
  NOTWON: "Not won",
  action: "Action",
  deletedOfferSuccessfully: "You offer deleted successfully.",
  failedDeletingOffer: "",
  cropButton: "Crop",
  cancelButton: "Cancel",
};

export default userEn;
