import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import bgImg from "assets/img/dashboard-bg.jpg";
import defaultProfileImg from "assets/img/default-profile.png";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { t } from "locales";

const DashboardHeader = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        pb: 3,
        borderBottom: (theme) =>
          theme.palette.mode === "dark"
            ? "1px solid #2a2e34"
            : "1px solid #eee",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "140px",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            width: "100%",
            height: "100%",
            img: {
              width: "120px",
              height: "120px",
              borderRadius: "8px",
              transform: "translateY(20px)",
              objectFit: "cover",
            },
          }}
        >
          <img src={user?.avatar || defaultProfileImg} alt="" />
        </Box>
      </Box>
      <Box
        className="container"
        sx={{
          pt: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {user.name}
            </Typography>
            <Typography variant="body1">
              {t("since")}{" "}
              {format(new Date(user?.createdAt), "yyyy-MM-dd")?.replaceAll?.(
                "-",
                "/"
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardHeader;
