const howToPlayEn = {
  howToPlayLeagueHeader: "Join your first league",
  howToPlayPlayerHeader: "Follow your first player",

  next: "Next",
  leagueInfoTitle: "What are leagues?",
  leagueInfoDesc:
    "There are multiple competitions in , each with their own entry criteria. Here are three examples:",
  leagueInfoItem1Title: "All Start",
  leagueInfoItem2Title: "Under 50",
  leagueInfoItem3Title: "Champion",
  leagueInfoItem1Type: "Global",
  leagueInfoItem2Type: "Global",
  leagueInfoItem3Type: "Champion",
  leagueInfoItem1Desc: "Create a dream team of crypto players around the world.",
  leagueInfoItem2Desc: "Build a package of cryptos under the ranks of 50.",
  leagueInfoItem3Desc: "Play with the top crypto players around the world.",

  leagueWinningTitle: "Winning a league",
  leagueWinningDesc:
    "Managers who compete in a tournament are ranked in a leaderboard on their team’s performance.",
  leagueWinningHint:
    "Points are earned through the real-life performances of the players on your team. Goals, assists, clean sheets, and more all contribute to your points total.",
  leagueWinningBtn: "What do I win?",

  leagueRewardsTitle: "Earning rewards",
  leagueRewardsDesc:
    "Top performing Managers win rewards, often powerful cards. You can view potential rewards for each tournament in the Prize Pool.",
  leagueRewardsBtn: "Next",
  viewLess: "View less",
  viewMore: "View more",

  cardDetailTitle: "Player Card details",
  cardDetailDesc:
    "When you select a card, you will find valuable information such as a player’s average score, any bonus and experience points.",
  cardDetailHint:
    "When cards are played in tournaments, they gain experience points (XP), and level up. These give bonus points and allow you to climb up the rankings.",
  cardDetailItem1Title: "Average score",
  cardDetailItem2Title: "Bonus",
  cardDetailItem1Desc:
    "Based on the 5 last games, the average can go from 0 to 100",
  cardDetailItem2Desc:
    "Boosts the performance of the player, and gives additional points.",
  cardDetailItem3Desc: "Eligible in the under 50 rank",
  cardDetailBtn: "Start Playing",

  cardTypeTitle: "Player Card scarcities",
  cardTypeDesc:
    "Player Cards in Xbit Play are scarce, there is a limited amount of them in circulation. They come in 4 differents scarcities.",
  cardTypeHintTitle: "New cards can be created for your projects",
  cardTypeBodyTitle: "Each player is licensed by a club or a league.",
  cardTypeBodyDesc:
    "And generated on a Blockchain. There’s provable scarcity. Cards cannot be duplicated.",

  wizardStartPlayTitle: "Let's get started",
  wizardStartPlayDesc:
    "As a new manager, you can compete in the Casual League. Register your team to complete this task!",

  done: "Done",
  revealAll: "Reveal all",
  openPackTitle: "Open your pack",
  openPackDesc: "Let's introduce you to your squad",
  openPackBtn: "Open pack",
};

export default howToPlayEn;
