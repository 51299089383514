import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { t } from "locales";
import { formatDistanceToNowStrict } from "date-fns";
import { useSelector } from "react-redux";
import SoccerCardDetail from "routes/CryptoWars/SingleCard/SingleCard";
import CryptoWarsCardDetail from "routes/CryptoWars/SingleCard/SingleCard";
import PutForSale from "../../PutForSale";
import WithdrawCard from "../../WithdrawCard";

const SingleCard = ({ power, createdAt, card, status, id, cardId }) => {
  const {
    image,
    FC_player: playerData,
    isCommon,
    bonus,
    assetId,
    name,
  } = card || {};
  const isCryptoWars = !!assetId;

  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const modal = useSelector((state) => state.app.modal);

  const openCardDetail = () => {
    const DetailCompoent = isCryptoWars
      ? CryptoWarsCardDetail
      : SoccerCardDetail;

    modal.show(<DetailCompoent onClose={modal.hide} cardId={cardId} />);
  };

  const openSellModal = () => {
    modal.show(
      <PutForSale
        auctionType={isCryptoWars ? "PREDICTION" : "CARD"}
        onClose={() => modal.hide()}
        id={id}
      />
    );
  };
  const openWithdrawModal = () => {
    modal.show(
      <WithdrawCard
        auctionType={isCryptoWars ? "PREDICTION" : "CARD"}
        onClose={() => modal.hide()}
        id={id}
      />
    );
  };

  if (!card) return null;

  return !smallDevice ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row", sm: "column" },
        gap: 1,
        p: 2,
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        onClick={openCardDetail}
        sx={{
          width: { xs: "85px", sm: "100%" },
          flexGrow: { xs: 0, sm: 1 },
          transition: "transform 0.1s ease-out",
          cursor: "pointer",
          img: {
            height: { xs: "85px", sm: "150px", borderRadius: "12px" },
            width: { xs: "85px", sm: "auto", borderRadius: "12px" },
          },

          "&:hover": {
            transform: "scale(1.01)",

            "& + .favorite-big-device": {
              transform: "scale(1)",
            },
          },
        }}
      >
        <img src={getImageUrl(image)} alt="" width="200" />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column" },
          flexGrow: { xs: 1, sm: 0 },
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          {power && (
            <Box
              sx={{
                width: "30px",
                height: "24px",
                display: "inline-flex",
                alignItems: "center",
                flexShrink: "0",
                borderRadius: "36px",
                justifyContent: "center",
                backgroundColor: (theme) =>
                  getPowerBadgeColor(power, theme?.palette?.mode),
              }}
            >
              <Typography variant="caption">{power}</Typography>
            </Box>
          )}
          {!isNaN(bonus) && (
            <Typography
              variant="caption"
              sx={{
                display: "inline-flex",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#eee" : "#414244",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "#414244" : "#fff",
                padding: "1px 8px",
                borderRadius: "8px",
                height: "24px",
              }}
            >
              {bonus}%
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {createdAt && (
            <Typography variant="caption">
              {formatDistanceToNowStrict(new Date(createdAt), {
                addSuffix: true,
              })}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Button
          onClick={() => openSellModal(id)}
          disabled={status !== "FREE" || isCommon}
          color="primary"
          variant="outlined"
          sx={(theme) => ({
            // backgroundImage: theme.palette.gradient.purpule,
            color:
              theme.palette.mode === "light" ? "primary.main" : "primary.light",
            padding: "7px 20px",
            transition: "background-color 250ms",
            width: "100%",

            "&:hover": {
              backgroundColor: "primary.main",
              color: "common.white",
            },
          })}
        >
          {t("sell")}
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => openWithdrawModal(id)}
          disabled={status !== "FREE" || isCommon}
          color="primary"
          variant="outlined"
          sx={(theme) => ({
            // backgroundImage: theme.palette.gradient.purpule,
            color:
              theme.palette.mode === "light" ? "primary.main" : "primary.light",
            padding: "7px 20px",
            transition: "background-color 250ms",
            width: "100%",

            "&:hover": {
              backgroundColor: "primary.main",
              color: "common.white",
            },
          })}
        >
          {t("withdraw")}
        </Button>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row", sm: "column" },
        gap: 1,
        p: 2,
        height: "100%",
      }}
    >
      <Box
        onClick={openCardDetail}
        sx={{
          width: { xs: "85px", sm: "100%" },
          flexGrow: { xs: 0, sm: 1 },
          cursor: "pointer",

          img: {
            width: { xs: "85px", sm: "100%", borderRadius: "8px" },
          },
        }}
      >
        <img src={getImageUrl(image)} alt="" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column" },
          flexGrow: { xs: 1, sm: 0 },
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              gap: 1,
              alignItems: "center",
            }}
          >
            {!!power && (
              <Box
                sx={{
                  width: "30px",
                  height: "24px",
                  display: "inline-flex",
                  alignItems: "center",
                  flexShrink: "0",
                  borderRadius: "36px",
                  justifyContent: "center",
                  backgroundColor: (theme) =>
                    getPowerBadgeColor(power, theme?.palette?.mode),
                }}
              >
                <Typography variant="caption">{power}</Typography>
              </Box>
            )}
            {!isNaN(bonus) && (
              <Typography
                variant="caption"
                sx={{
                  display: "inline-flex",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#eee" : "#414244",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "#414244" : "#fff",
                  padding: "1px 8px",
                  borderRadius: "3px",
                  height: "24px",
                }}
              >
                {bonus}%
              </Typography>
            )}
          </Box>
          {createdAt && (
            <Typography variant="caption">
              {formatDistanceToNowStrict(new Date(createdAt), {
                addSuffix: true,
              })}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="body2">
            {playerData?.playerName || name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => openSellModal(id)}
            disabled={status !== "FREE" || isCommon}
            color="primary"
            variant="outlined"
            sx={(theme) => ({
              // backgroundImage: theme.palette.gradient.purpule,
              color: theme.palette.common.white,
              padding: "7px 20px",
              transition: "background-color 250ms",
              width: "fit-content",
              marginLeft: "auto",

              "&:hover": {
                backgroundColor: "primary.main",
              },
            })}
          >
            {t("sell")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleCard;
