import { Close } from "@mui/icons-material";
import { ButtonBase, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { inputAutoFillStyles } from "assets/styles/styles";
import { t } from "locales";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateUserData } from "redux/auth/asyncActions";
import { useDispatch } from "react-redux";
import { addEmailValidation } from "validations/authValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorAlert from "components/ErrorAlert";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import OtpInput from "react-otp-input";
import axios from "library/http";
import { getHumanError } from "library/translateServerErrors";
import { replaceJSX } from "library/helper";

const OtpInputCustom = styled(OtpInput)(({ theme }) => ({
  input: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid gray",
    fontSize: "18px",
    width: "3rem !important",
    textAlign: "center",
    padding: "0.5rem",
    margin: "0 .5rem",
    borderRadius: "8px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(162 111 255 / 25%)",
    },
  },
}));

const EmailModal = ({ closeModal = null }) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(addEmailValidation),
  });

  const submitHandler = async (data) => {
    try {
      if (!token) {
        const response = await axios.patch("user/signup", {
          email: data?.email,
        });

        setToken(response.data?.data?.token);
      } else {
        await axios.post("user/verify", {
          code: data?.code,
          token,
        });

        await dispatch(updateUserData());

        closeModal?.();
      }
    } catch (error) {
      const errorMessage = getHumanError(error);
      setError(errorMessage);
    }
  };

  let renderedFields;
  if (!token) {
    renderedFields = (
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            error={errors?.email}
            helperText={t(errors?.email?.message)}
            fullWidth
            name="email"
            label={t("email")}
            type="email"
            id="email"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
            sx={inputAutoFillStyles}
          />
        )}
      />
    );
  } else {
    renderedFields = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Controller
          control={control}
          name="code"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Box sx={{ marginBottom: 3 }}>
              <OtpInputCustom
                value={value}
                // onChange={handleChange}
                onChange={onChange}
                onBlur={onBlur}
                numInputs={4}
                separator={<span>-</span>}
                hasErrored={!!errors.code}
              />
            </Box>
          )}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: "20px",
        minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "650px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {!token ? t("addEmail") : t("verify")}
        </Typography>

        <ButtonBase
          onClick={closeModal}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      {error && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={error} />
        </Box>
      )}

      {token && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <Typography
            variant="body1"
            sx={{ width: "100%", color: "gray", textAlign: "center" }}
          >
            {replaceJSX(
              t("verifyDesc"),
              "#",
              <Typography component="span" variant="body1">
                {getValues("email")}
              </Typography>
            )}
          </Typography>
        </Box>
      )}

      <Box
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {renderedFields}
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          loading={isSubmitting}
          disabled={!_.isEmpty(errors, true)}
          loaderHeight="40"
          sx={{ fontSize: "1.1rem", marginTop: 2 }}
          withSpinner
        >
          {!token ? t("submit") : t("verify")}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default EmailModal;
