const marketFa = {
  auctionTitle: "مزایده کارت های جدید",
  auctionDesc:
    "در مزایده های بازیکنان مورد علاقه خود با مدیران دیگر رقابت کنید",
  deleteAllFilters: "حذف تمامی فیلتر ها",
  defender: "مدافع",
  goalkeeper: "دروازبان",
  forward: "مهاجم",
  midfielder: "هافبک",
  saleType: "نوع فروش",
  cards: "کارت ها",
  bundles: "بسته",
  limited: "محدود",
  rare: "کمیاب",
  superRare: "بسیار کمیاب",
  unique: "منحصر به فرد",
  bid: "پیشنهاد",
  marketLandingSubHeading: "ساختن تیم رویایی",
  marketLandingHeading: "بازار NFT",
  viewAllCards: "تماشای همه ی‌ کارتها",
  viewAllBundles: "دیدن ‎همه ی بسته ها",
  makeAnOffer: "پیشنهاد دادن",
  buy: "خرید",
  level: "سطح",
  xp: "XP",
  type: "نوع",
  club: "باشگاه",
  serialNumber: "شماره سریال",
  season: "فصل",
  cardAttributes: "ویژگی های کارت",
  connectToVolexWallet: "اتصال به کیف پول ایکس بیت",
  connectYourWallet: "اتصال به کیف پول شما",
  connect: "اتصال",
  verifyYourWallet: "تأیید کیف پولتان",
  swapVlxToPlay: "انتقال VLX  به کیف پول",
  free: "رایگان",
  inGame: "داخل بازی",
  inAuction: "داخل حراج",
  price: "قیمت",
  position: "موقعیت",
  basePrice: "پایه قیمت",
  immediatePrice: "قیمت مقطوع",
  bookingPrice: "قیمت رزرو",
  sell: "فروش",
  withdraw: "برداشت",
  walletAddress: "آدرس والت",
  startTime: "تاریخ شروع",
  endTime: "تاریخ پایان",
  walletBalance: "تعادل کیف پول",
  enterYourOffer: "پیشنهاد خود را وارد کنید",
  bids: "مزایده میدهد",
  swap: "مبادله",
  balance: "تعادل",
  from: "از جانب",
  buyImmediately: "فورا خرید کنید",
  min: "حداقل",
  max: "حداکثر",
  // maxError: "تعادل ناکافی",
  maxError: "لطفا مقداری کمتر از حداکثر وارد کنید",
  minError: "لطفاً مقداری بالاتر از حداقل وارد کنید",
  noCardFound: "هیچ کارای پیدا نشد!",
  newCards: "کارتهای جدید",
  newBundles: "بسته های جدید",
  newListed: "لیست های جدید",
  olderListed: "لیست قدیمی تر",
  endingSoon: "به زودی تمام میشود",
  endingLater: "پایان یافتن",
  showFilters: "مشاهده فیلترها",
};

export default marketFa;
