import { Button, ButtonBase, Tooltip, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { t } from "locales";
import { useSelector } from "react-redux";
import InviteModal from "./InviteModal";
import Clipboard from "react-clipboard.js";
import { ContentCopy, QrCode } from "@mui/icons-material";
import { useState } from "react";

const ClipboardCustom = styled(Clipboard)({
  display: "flex",
  cursor: "pointer",
});

const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const modal = useSelector((state) => state.app.modal);
  const [copied, setCopied] = useState(false);

  const referralCode = user?.referralCode;
  const referralLink = `${
    window?.location?.host || "xbitplay.com//"
  }/auth/register?ref=${referralCode}`;

  const onCopy = () => {
    setCopied(true);
  };

  const openInviteModal = () => {
    modal.show(<InviteModal code={referralCode} onClose={modal.hide} />);
  };

  return (
    <Box className="container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          color: "white",
          py: 5,
          maxWidth: { md: "600px" },
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {t("inviteFirstHeader")}
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {t("inviteSecondHeader")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontSize: "18px" }}>
            {t("inviteDesc")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "8px",
            bgcolor: "white",
            p: { xs: "15px 20px", md: "5px 20px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: "5px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "#444", fontFamily: "en" }}
            >
              {referralLink}
            </Typography>
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
              <ClipboardCustom
                sx={{ color: "#222" }}
                component="span"
                data-clipboard-text={referralLink}
                onSuccess={onCopy}
              >
                <Tooltip title={copied ? "Copied" : "Copy to clipboard"} arrow>
                  <ContentCopy sx={{ fontSize: 20 }} />
                </Tooltip>
              </ClipboardCustom>
              <ButtonBase
                onClick={openInviteModal}
                sx={{
                  p: "8px 10px",
                  bgcolor: "primary.main",
                  color: "#fff",
                  borderRadius: "5px",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <QrCode sx={{ fontSize: 25 }} />
              </ButtonBase>
            </Box>
          </Box>
          <Button
            onClick={openInviteModal}
            variant="contained"
            fullWidth
            sx={{ py: "10px", display: { md: "none" } }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("inviteFriends")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
