import axios from "library/http";
import { t } from "locales";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { truncateToDecimals } from "library/helper";
import { SkeletonLoader } from "components/Skeleton";
import { Box } from "@mui/system";
import { getHumanError } from "library/translateServerErrors";
import ErrorAlert from "components/ErrorAlert";
import { Button, ButtonBase, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
// import SellConfirm from "./SellConfirm";

const DetailModal = ({
  pyamentName,
  onClose,
  gatewayIcon,
  renderedMethods,
  price,
  rate,
  coinPrecision,
  paymentTermId,
  amount,
  selectedAssets,
  type,
}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const gatewayRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");

  const firstRow = amount; // sepnd amount - recieved amount + fiat
  const secondRow = _.divide(amount, price);
  const thirdRow = (Math.abs(_.subtract(rate, 1)) * amount).toPrecision(12); // transaction fee + fiat

  useEffect(() => {
    if (token) {
      gatewayRef.current?.submit();
    }
  }, [token]);

  const sumbitHandler = async () => {
    setLoading(true);

    try {
      if (type === "BUY") {
        const response = await axios.post("asset/buy", {
          amount,
          paymentTermId,
        });

        if (response.status === 200) {
          const data = response?.data?.data;

          if (data?.payment_url) {
            window.location.replace(data.payment_url);
          } else {
            const data = response?.data?.data;
            setToken(data.token);
          }
        }
      } else if (type === "SELL") {
        // modal.show(<SellConfirm paymentTermId={paymentTermId} />, "md");
        onClose();
      }
    } catch (error) {
      setLoading(false);

      const humanError = getHumanError(error);
      setError(humanError);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          p: "20px",
          width: { xs: "95vw", sm: "60vw", lg: "40vw", xl: "500px" },
          maxWidth: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {type === "BUY" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                img: {
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                },
              }}
            >
              <img alt="" src={gatewayIcon} />
              <Typography variant="h6">{t(pyamentName)}</Typography>
            </Box>
          ) : (
            <Typography variant="h6">{t("moreDetail")}</Typography>
          )}
          {onClose && (
            <ButtonBase
              onClick={onClose}
              sx={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#eee" : "#414244",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
                borderRadius: "3px",
              }}
            >
              <Close fontSize="small" />
            </ButtonBase>
          )}
        </Box>
        {error && <ErrorAlert text={error} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            "& > div": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          {/* {type === "SELL" && (
            <Typography>{t("sellFiatTip")}</Typography>
          )} */}
          <Box>
            <Typography variant="body1" className="title">
              {type === "BUY" ? t("spendAmount") : t("receivedAmount")}
            </Typography>
            <Typography variant="body1" className="value">
              {truncateToDecimals(firstRow, coinPrecision)}{" "}
              {selectedAssets?.fiat?.label}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className="title">
              {type === "BUY" ? t("arrivalAmount") : t("deductedAmount")}
            </Typography>
            <Typography variant="body1" className="value">
              {truncateToDecimals(secondRow, coinPrecision)}{" "}
              {selectedAssets?.coin?.label}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className="title">
              {t("transactionFee")}
            </Typography>
            <Typography variant="body1" className="value">
              {parseFloat(thirdRow)} {selectedAssets?.fiat?.label}
            </Typography>
          </Box>
          {type === "BUY" && (
            <Box>
              <Typography variant="body1" className="title">
                {t("paymentMethods")}
              </Typography>
              {renderedMethods}
            </Box>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading ? (
            <SkeletonLoader isLight={isLight} width="100%" height="40" />
          ) : (
            <Button
              onClick={sumbitHandler}
              color="primary"
              variant="outlined"
              sx={(theme) => ({
                // backgroundImage: theme.palette.gradient.purpule,
                color: theme.palette.common.white,
                padding: "7px 20px",
                transition: "background-color 250ms",
                width: "100%",

                "&:hover": {
                  backgroundColor: "primary.main",
                },
              })}
            >
              {t("confirm")}
            </Button>
          )}
        </Box>
      </Box>
      <form
        ref={gatewayRef}
        action="https://pna.shaparak.ir/_ipgw_/payment/"
        method="post"
      >
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="language" value="fa" />
      </form>
    </>
  );
};

export default DetailModal;
