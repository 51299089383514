import * as yup from "yup";

export const addTicketValidation = yup.object({
  title: yup
    .string()
    .required("titleRequired")
    .test("descriptionValidaton", "titleRequired", (value) => {
      return value.trim() !== "";
    }),
  description: yup.string().required("descriptionRequired"),
  // .test("descriptionValidaton", "descriptionToShortMin50", (value) => {
  //   return value.trim().length >= 50;
  // }),
});

export const addReplyValidation = yup.object({
  text: yup.string().required("textRequired"),
  // .test("descriptionValidaton", "descriptionToShortMin50", (value) => {
  //   return value.trim().length >= 50;
  // }),
});
