import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";
import { termsAndConditionContent } from "./termsAndCondition";

const TermsAndCondition = () => {
  const lang = useSelector((state) => state.setting.lang);

  const headerContent = termsAndConditionContent.header;
  const bodyContent =
    termsAndConditionContent.body[lang] || termsAndConditionContent.body.en;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", my: 5 }}
      className="container"
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          {t(headerContent.title)}
        </Typography>
        {/* <Typography variant="body1">{t(headerContent.desc)}</Typography> */}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {bodyContent?.map?.(({ title, items }) => (
          <Box
            sx={{
              bgcolor: (theme) => `card.${theme.palette.mode}`,
              p: "20px 10px",
              borderRadius: "5px",
              li: {
                mb: 1,
                listStyleType: "square",
              },
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              {t(title)}
            </Typography>
            <Box sx={{ pl: 1 }}>
              {items?.map?.((item) =>
                typeof item === "string" ? (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {t(item)
                      .split("\n")
                      .map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                ) : (
                  <>
                    {item.title && (
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        {t(item.title)
                          .split("\n")
                          .map(function (item, idx) {
                            return (
                              <span key={idx}>
                                {item}
                                <br />
                              </span>
                            );
                          })}
                      </Typography>
                    )}
                    {!!item?.list ? (
                      <ul>
                        {item?.list?.map?.((item) => (
                          <li>
                            <Typography variant="body2">
                              {t(item)
                                .split("\n")
                                .map(function (item, idx) {
                                  return (
                                    <span key={idx}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      item.items?.map?.((item) =>
                        typeof item === "string" ? (
                          <Typography variant="body2" sx={{ mb: 2 }}>
                            {t(item)
                              .split("\n")
                              .map(function (item, idx) {
                                return (
                                  <span key={idx}>
                                    {item}
                                    <br />
                                  </span>
                                );
                              })}
                          </Typography>
                        ) : (
                          <>
                            {item.title && (
                              <Typography variant="body1">
                                {t(item.title)}
                              </Typography>
                            )}
                            {!!item?.list && (
                              <ul>
                                {item?.list?.map?.((item) => (
                                  <li>
                                    <Typography variant="body2">
                                      {t(item)
                                        .split("\n")
                                        .map(function (item, idx) {
                                          return (
                                            <span key={idx}>
                                              {item}
                                              <br />
                                            </span>
                                          );
                                        })}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </>
                        )
                      )
                    )}
                  </>
                )
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
