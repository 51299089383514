import React from "react";

const BundleIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5_2)">
        <path
          d="M16 8.00034C16 7.59846 15.7647 7.23721 15.4025 7.08034L12.9122 5.99971L15.4016 4.9194C15.7647 4.76221 16.0003 4.40096 16.0003 3.9994C16.0003 3.59783 15.765 3.23658 15.4028 3.08002L8.58313 0.121582C8.21313 -0.0399804 7.78813 -0.0406054 7.41813 0.120957L0.59875 3.07971C0.235312 3.2369 0 3.59815 0 3.99971C0 4.40127 0.235313 4.76252 0.5975 4.91909L3.08812 5.99971L0.598437 7.08002C0.235312 7.2369 0 7.59846 0 8.00034C0 8.40159 0.235313 8.76315 0.5975 8.91971L3.08781 10L0.59875 11.08C0.235312 11.2372 0 11.5985 0 12.0003C0 12.4016 0.235313 12.7632 0.5975 12.9197L7.41875 15.8788C7.60199 15.9587 7.79976 16 7.99969 16C8.19781 15.9994 8.39625 15.9594 8.58062 15.8794L15.4009 12.9203C15.7641 12.7632 15.9997 12.4019 15.9997 12.0003C15.9997 11.5985 15.7644 11.2372 15.4022 11.0803L12.9122 10L15.4012 8.92003C15.7647 8.76284 16 8.40159 16 8.00034ZM7.98344 1.49658L7.98438 1.49721H7.9825L7.98344 1.49658ZM8 1.50377L13.755 4.00033L8.01656 6.50315L2.24719 3.99971L8 1.50377ZM13.7559 12.0013L8.01656 14.5035L2.24719 12L4.9725 10.8175L7.41906 11.8788C7.6023 11.9587 7.80007 12 8 12C8.19813 11.9994 8.39656 11.9594 8.58094 11.8794L11.0275 10.8178L13.7559 12.0013V12.0013ZM8.01656 10.5032L2.24719 7.99971L4.97281 6.81721L7.41906 7.87846C7.6023 7.95842 7.80007 7.9997 8 7.99971C8.19813 7.99909 8.39656 7.95909 8.58094 7.87909L11.0275 6.81752L13.7559 8.00128L8.01656 10.5032Z"
          fill="#BD10E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_2">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BundleIcon;
