import { AccountBalanceWalletRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";

import { useSelector } from "react-redux";
import FinanceModal from "./FinanceModal";

const NavbarNotification = () => {
  const modal = useSelector((state) => state.app.modal);

  const openFinanceModal = () => {
    modal.show(<FinanceModal onClose={modal.hide} />);
  };

  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={openFinanceModal}
        size="large"
        sx={{
          color: "gray",
        }}
      >
        <AccountBalanceWalletRounded fontSize="small" sx={{ color: "gray" }} />
      </IconButton>
    </Box>
  );
};

export default NavbarNotification;
