import SingleDeck from "./SingleDeck";
import FadeIn from "react-fade-in";

const LargeField = ({ cards, showCard, showedCards }) => {
  return cards.map((item, i) => (
    <FadeIn key={item.coin || i} transitionDuration={500} delay={300 * (i + 1)}>
      <SingleDeck showCard={showCard} item={item} showedCards={showedCards} />
    </FadeIn>
  ));
};

export default LargeField;
