import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl } from "library/helper";
import { t } from "locales";

const Prizes = ({ list, coin, coinIcon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "10px 20px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        {t("prizes")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          "& > *:not(:last-child)": {
            borderBottom: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "border.dark" : "border.light",
          },
        }}
      >
        {list.map(({ id, number, cardTier, cardType, tier, amount }) => (
          <Box
            key={id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 20px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {tier}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    bgcolor: cardType.color,
                  }}
                ></Box>
                <Typography variant="body1">
                  {number}x {cardTier?.title} {cardType?.title}
                </Typography>
              </Box>
              {amount && amount > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    img: {
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <Typography variant="body1">
                    {amount} {coin}
                  </Typography>
                  {coinIcon && <img src={getImageUrl(coinIcon)} alt="" />}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Prizes;
