import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { formatDistanceToNowStrict } from "date-fns";
// import { CRYPTOWARS_END_TIME, CRYPTOWARS_START_TIME } from "library/constants";
import formatDate from "library/formatDate";
import { t } from "locales";
import { Link } from "react-router-dom";

const BUTTON_TEXT = {
  LIVE: "viewLiveResults",
  OPEN: "compete",
  COMPLETED: "viewResults",
};

const Single = ({ id, title, status, startAt, endAt, series }) => {
  // const startTime = CRYPTOWARS_START_TIME[series];
  // const endTime = CRYPTOWARS_END_TIME[series];
  const startTime = series + ":00";
  const endTime = series + ":00";

  var isLess;

  if (new Date(startAt + " " + startTime) > new Date()) {
    isLess = true;
  } else {
    isLess = false;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#1a1c24" : "#fff",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: "20px 20px", sm: "20px 30px" },
          gap: 6,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography
            variant="caption"
            sx={{
              p: "4px 12px",
              borderRadius: "8px",
              background:
                status === "LIVE"
                  ? "linear-gradient( -41deg, rgb(125,27,246) 0%, rgb(160,24,238) 99%)"
                  : status === "COMPLETED"
                  ? "#333"
                  : "green",
              fontWeight: "bold",
              color: "common.white",
            }}
          >
            {t(status)}
          </Typography>
          <Typography variant="body1">{title}</Typography>
          {isLess && status === "OPEN" && (
            <Typography
              variant="body2"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main",
              }}
            >
              {formatDistanceToNowStrict(new Date(endAt + " " + endTime + "Z"))}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: { xs: "270px", sm: "300px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "500", fontSize: { xs: "22px", sm: "32px" } }}
            >
              {formatDate(startAt, { day: "numeric", month: "short" })}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              {/* {format(new Date(startAt), "h:mm a")} */}
              {startTime}
            </Typography>
          </Box>
          <Typography variant="body1">{t("to")}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "500", fontSize: { xs: "22px", sm: "32px" } }}
            >
              {formatDate(endAt, { day: "numeric", month: "short" })}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              {/* {format(new Date(endAt), "h:mm a")} */}
              {endTime}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ButtonBase
        as={Link}
        to={`/crypto-wars/play/${id}/competitions`}
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTop: "3px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "#121212" : "#eee",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark" ? "primary.light" : "primary.main",
          }}
        >
          {t(BUTTON_TEXT[status] || "compete")}
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default Single;
