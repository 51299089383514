import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BodyRow from "./BodyRow";
import HeaderRow from "./HeaderRow";
import axios from "library/http";
import _ from "lodash";

const Withdraw = () => {
  const [asset, setAsset] = useState({});
  const [active, setActive] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletWithdraw, setWalletWithdraw] = useState([]);
  const [walletWithdrawConfig, setWalletWithdrawConfig] = useState([]);
  const [wallet, setWallet] = useState();
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  function resetData() {
    setActive(null);
    setWallet(null);
    setConfirm(null);
  }

  // Handle changing dropdown
  const onSelect = (value) => {
    resetData();
    navigate(`/withdraw/${value.name}-${value.coin}`);
  };

  // Set default protocol - when config changed
  useEffect(() => {
    if (walletWithdrawConfig.length > 0) setActive(walletWithdrawConfig[0]);
  }, [walletWithdrawConfig]);

  // Chack for coin param and validate coin
  useEffect(() => {
    resetData();

    if (params.coin && walletWithdraw.length > 0) {
      const [name, coin] = params.coin.split("-");
      const assetData = { coin, name };
      const isValid = _.find(walletWithdraw, assetData);

      if (isValid) {
        setAsset(assetData);
      } else {
        setAsset(walletWithdraw[0]);
      }
    }
  }, [params, walletWithdraw]);

  // Getting all listed coin - just for first time
  useEffect(() => {
    axios
      .get("asset")
      .then((res) => {
        const withdrawList = res?.data?.data.map((item) => ({
          coin: item.coin,
          name: item.name,
          value: item.coin,
          label: `${item.coin} ( ${item.name} )`,
        }));

        setWalletWithdraw(withdrawList);

        if (!params?.coin) {
          setAsset(withdrawList?.[0]);
        }
      })
      .catch((error) => {});
  }, []);

  // Set wallet based on active protocol - when protocol changed
  useEffect(() => {
    if (active?.wallet) {
      setWallet(active.wallet);
    }
  }, [active]);

  // Fetching config of selected asset(coin or fiat) - when asset changed
  useEffect(() => {
    setActive(null);
    setLoading(true);

    if (!asset?.coin) return;

    axios
      // .get(`asset/config?type=withdraw&coin=${asset.coin}`)
      // Fix wrong API url
      .get(`wallet`)
      .then((response) => {
        const data = response?.data?.data;

        // setWalletWithdrawConfig(data);

        if (data?.length > 0) {
          // const wallet = data.find((w) => w?.id === active?.id)?.wallet;
          const wallet = data[0]

          setWallet(wallet);
        }
        setLoading(false);
      })
      .catch((error) => {
        setWalletWithdrawConfig([]);
        setActive("no protocol");
        setLoading(false);

        setDisable(true);
      });

    axios
      .get(`asset/config?type=withdraw&coin=${asset.coin}`)
      .then((response) => {
        const data = response?.data?.data;
        setWalletWithdrawConfig(data);
      })
      .catch((error) => {
        setWalletWithdrawConfig([]);
        setActive("no protocol");
        setLoading(false);

        setDisable(true);
      });
  }, [asset]);

  // Handle network-protocol
  const changeNetwork = (type) => {
    setActive(type);
    setConfirm(null);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, my: 4 }}
      className="container"
    >
      <HeaderRow
        wallet={wallet}
        walletWithdraw={walletWithdraw}
        asset={asset}
        loading={loading}
        onSelect={onSelect}
      />
      <BodyRow
        wallet={wallet}
        active={active}
        asset={asset}
        loading={loading}
        walletWithdrawConfig={walletWithdrawConfig}
        changeNetwork={changeNetwork}
        disable={disable}
        confirm={confirm}
        setLoading={setLoading}
        setWallet={setWallet}
      />
    </Box>
  );
};

export default Withdraw;
