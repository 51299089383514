import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";

const Index = () => {
  return (
    <Box sx={{ padding: "4rem 0" }}>
      <Box className="container" sx={{ textAlign: "center" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {t("auctionTitle")}
        </Typography>
        <Typography variant="body1">{t("auctionDesc")}</Typography>
      </Box>
    </Box>
  );
};

export default Index;
