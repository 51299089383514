import * as yup from "yup";

export const contactFormValidation = yup.object({
  email: yup
    .string()
    .required("contactFormEmailRequired")
    .email("emailNotValid"),
  department: yup.string().required("contactFormDepartmentRequired"),
  subject: yup.string().required("contactFormSubjectRequired"),
  description: yup.string().required("contactFormDescriptionRequired"),
});
