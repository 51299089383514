export const statusOptions = [
  { title: "free", value: "FREE" },
  { title: "inGame", value: "INGAME" },
  { title: "inAuction", value: "INAUCTION" },
];

// export const positionOptions = [
//   { title: "defender", value: "Defenders" },
//   { title: "goalkeeper", value: "Goalkeepers" },
//   { title: "forward", value: "Forwards" },
//   { title: "midfielder", value: "Midfielders" },
// ];

export const saleTypeOptions = [
  { title: "cards", value: "cards" },
  { title: "bundles", value: "bundles" },
];

export const levelConfig = { min: 0, max: 20, step: 1, title: "level" };
export const priceConfig = {
  min: 1,
  max: 10000,
  step: 0.1,
  title: "price",
};
