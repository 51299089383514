import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { SkeletonLoader } from "components/Skeleton";
import { getImageUrl } from "library/helper";
import { Typography } from "@mui/material";
import { useGetTradingCards } from "_hooks/CryptoWars/landing";

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  // slidesToShow: 6,
  slidesToScroll: 1,
  // responsive: [
  //   {
  //     breakpoint: 1200,
  //     settings: { slidesToShow: 4 },
  //   },
  //   {
  //     breakpoint: 900,
  //     settings: { slidesToShow: 4 },
  //   },
  //   {
  //     breakpoint: 500,
  //     settings: { slidesToShow: 2 },
  //   },
  // ],
  rows: 2,
  slidesPerRow: 3,
  responsive: [
    {
      breakpoint: 900,
      settings: { slidesPerRow: 2, rows: 3 },
    },
  ],
};

const CardsSlider = ({ sliderRef }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const { data = [], isLoading: loading } = useGetTradingCards();

  return (
    // <Box sx={data?.length < 5 && { ".slick-track": { m: 0 } }}>
    <Box sx={{ ".slick-track": { m: 0 } }}>
      <Slider ref={sliderRef} {...settings}>
        {loading
          ? Array(5)
              .fill({})
              .map((_, i) => (
                <Box key={i} sx={{ px: "10px" }}>
                  <SkeletonLoader
                    isLight={isLight}
                    width="100%"
                    height="270"
                    borderRadius="10"
                  />
                </Box>
              ))
          : data.map(({ image, fakeImg, serialNumber, initialNumber, id }) => {
              const cardSerialTag =
                !isNaN(+serialNumber) && serialNumber !== null
                  ? `${serialNumber}/${initialNumber}`
                  : "Common";

              return (
                <Box
                  key={id}
                  sx={{
                    px: "10px",
                    margin: "0",
                    width: { md: "100%", sm: "50%" },
                    position: "relative",
                    mt: 2,
                    mb: 3,
                  }}
                >
                  <Box
                    component={Link}
                    to="/crypto-wars/market"
                    className="slider-item"
                    sx={{
                      padding: "0",
                      margin: "0",
                      height: "auto",
                      width: "100%",
                      position: "relative",
                      borderRadius: "12px",
                      textDecoration: "none",
                      color: "common.white",
                      filter: "brightness(1.1)",
                      img: {
                        display: "inline-block",
                        borderRadius: "12px",
                        transition: "box-shadow 0.1s ease-out",
                        objectFit: "cover",
                        alignSelf: "flex-start",
                        width: "100%",
                      },

                      transition: "transform 0.1s ease-out",
                      "&:hover": {
                        transform: "scale(1.02)",

                        img: {
                          boxShadow: (theme) =>
                            theme.palette.mode === "dark"
                              ? "2px 10px 32px -10px rgb(0 0 0 / 90%)"
                              : "2px 10px 32px -10px rgba(0,0,0,0.1)",
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        top: "5px",
                        left: "8px",
                        color: cardSerialTag === "Common" ? "#333" : "#eaeaea",
                        fontWeight: cardSerialTag === "Common" ? "500" : "400",
                      }}
                    >
                      {cardSerialTag}
                    </Typography>
                    <img src={image ? getImageUrl(image) : fakeImg} alt="" />
                  </Box>
                </Box>
              );
            })}
      </Slider>
    </Box>
  );
};

export default CardsSlider;
