import Header from "routes/Community/components/Header";
import { Grid } from "@mui/material";
import SideBar from "./SideBar";
import TimeLine from "./TimeLine";

const FeedHome = ({ active }) => {
  return (
    <>
      <Header active={active} />
      <Grid container>
        <Grid item xs={12} md={8} sx={{ p: 2 }}>
          <TimeLine />
        </Grid>
        <Grid item xs={12} md={4}>
          <SideBar />
        </Grid>
      </Grid>
    </>
  );
};

export default FeedHome;
