const inviteFa = {
  inviteFirstHeader: "دوستانتان را به بازی ایکس بیت دعوت کنید.",
  inviteSecondHeader: "شما و دوستانتان کارت رایگان برنده می شوید.",
  inviteDesc:
    "کسی دوستدار فوتبال می شناسید ؟با جمع آوری ۵ کارت توسط دوست دعوتیتان هم شما هم دوستتان برنده یک کارت رایگان می شوید.",
  inviteModalDesc: "دوستت را دعوت کن و کارت رایگان ببر.",
  inviteModalCodeTitle: "کد دعوت",
  user: "نام کاربری",
  cardNumbers: "اعداد کارت ها",
  COMPELETED: "کامل شده",
  COMPLETED: "تکمیل شده",
  EXPIRED: "منقضی شده",
  IN_PROGRESS: "در حال انجام",
  noInvitedFriend: "یافت نشد!",
  inviteSidebarHeader: "این چگونه کار می کند؟",
  levelOnePrizeTitle:
    "اگر دوست شما کارت جمع آوری کند، شما هم برنده کارت می شوید.",
  milestoneInviteTitle: "نقشه راه",
  milestoneInviteCondition: "سی امین دوست",
  completedReferrals: "دعوتی های تکمیل شده",
  friendsInvited: "دوستان دعوت شده",
  inviteHistoryTitle: "دعوتنامه های فرستاده شده",
  inviteFriendsNavLink: "دعوت دوستان",
};

export default inviteFa;
