import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuction } from "_hooks/Socket/auction";
import CardsRowList from "./List";

const CardsRow = ({
  list = [],
  title,
  refetchCallback,
  buttonText,
  loading,
  type,
  alwaysShow = false,
}) => {
  const [cards, setCards] = useAuction(refetchCallback, list);

  useEffect(() => {
    if (!loading) setCards(list || []);
  }, [list, setCards, loading]);

  if (!cards?.length && !loading && !alwaysShow) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: "20px",
        borderRadius: "8px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="h6">{t(title)}</Typography>
        <ButtonBase
          as={Link}
          to={`/crypto-wars/market/auction?saleType=cards${
            type ? `&type=${type}` : ""
          }&sort=name&order=ASC
          `}
          sx={{
            p: "2px 12px",
            borderRadius: "8px",
            bgcolor: "primary.light",
            color: "common.white",
          }}
        >
          <Typography variant="body2">
            {buttonText ? buttonText : t("viewAllCards")}
          </Typography>
        </ButtonBase>
        <ButtonBase
          as={Link}
          to={`/crypto-wars/market/deposit`}
          sx={{
            p: "2px 12px",
            borderRadius: "8px",
            bgcolor: "#035c07",
            color: "common.white",
          }}
        >
          <Typography variant="body2">{t("deposit")}</Typography>
        </ButtonBase>
      </Box>
      <Box>
        <CardsRowList list={cards} loading={loading} />

        {!loading && !cards?.length && (
          <Typography variant="body1" sx={{ textAlign: "center", my: 4 }}>
            {t("noCardFound")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CardsRow;
