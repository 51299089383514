import { Typography, CircularProgress, Grid, Divider } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect } from "react";
import axios from "library/http";
import { useParams } from "react-router";
import Header from "routes/Community/components/Header";
import User from "routes/Community/components/User";
import SideBar from "routes/Community/Feed/SideBar";
import { Capitalize } from "library/helper";

const FollowingAndFollowersList = ({ active }) => {
  const [hasMore, setHasMore] = useState();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const { userID, type } = useParams();
  const limit = 10;

  const fetchMoreData = async () => {
    try {
      const result = await axios.get(`/gravity/profiles/${userID}/${type}`, {
        params: {
          page: page,
          limit: limit,
        },
      });
      setPage(page + 1);
      setItems([...items, ...result.data.data.data]);
      setLoading(false);

      // Check if there is more data
      setHasMore(result.data.data.total > limit * page);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (type !== "followers" && type !== "followings") return false;

    fetchMoreData();
  }, []);

  if (type !== "followers" && type !== "followings") return false;

  return (
    <>
      <Header active={active} />
      <Grid container>
        <Grid item xs={12} md={8} sx={{ px: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "500" }}>
            {Capitalize(type)} List
          </Typography>
          <Divider sx={{ my: 1 }} />
          {loading ? (
            <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
          ) : (
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <CircularProgress
                  sx={{ display: "block", m: "0 auto", my: 3 }}
                />
              }
            >
              {items.map((item, index) => (
                <>
                  <User key={index} user={item} />
                  {items.length - 1 !== index && <Divider />}
                </>
              ))}
            </InfiniteScroll>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <SideBar />
        </Grid>
      </Grid>
    </>
  );
};

export default FollowingAndFollowersList;
