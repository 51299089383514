import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import commonCard from "assets/img/cards/silver.jpg";
import limitedCard from "assets/img/cards/bronze.jpg";
import rareCard from "assets/img/cards/gold.jpg";
import uniqueCard from "assets/img/cards/black.jpg";
import superRareCard from "assets/img/cards/rosegold.jpg";
import { t } from "locales";

const types = [
  {
    id: "0",
    image: commonCard,
    title: "Common",
    count: "Unlimited per player",
  },
  {
    id: "1",
    image: limitedCard,
    title: "Limited",
    count: "1000 per player",
  },
  {
    id: "2",
    image: rareCard,
    title: "Rare",
    count: "100 per player",
  },
  {
    id: "3",
    image: superRareCard,
    title: "Super rare",
    count: "10 per player",
  },
  {
    id: "4",
    image: uniqueCard,
    title: "Unique",
    count: "1 per player",
  },
];

const CardsType = ({ goNextStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: {
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          {t("cardTypeTitle")}
        </Typography>
        <Typography variant="body1">{t("cardTypeDesc")}</Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90vw", md: "600px", lg: "800px" },
          mx: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            pb: "10px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              p: "20px",
              borderTop: "1px solid",
              borderColor: (theme) =>
                theme.palette.mode === "dark" ? "border.dark" : "border.light",
            }}
          >
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              {types.map(({ title, count, id, image }) => (
                <Grid key={id} item xs={6} sm={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        {title}
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: ".8" }}>
                        {count}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        img: {
                          width: "100%",
                          height: "auto",
                          filter: "brightness(1.1)",
                        },
                      }}
                    >
                      <img src={image} alt="" />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            sx={{
              p: { xs: "15px 20px", lg: "0" },
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                left: { xs: "0", lg: "calc(80% + 20px)" },
                mb: "10px",
                top: { xs: "0", lg: "0" },
                width: { xs: "100%", lg: "220px" },
                margin: { xs: "", lg: "0" },
                position: { xs: "relative", lg: "absolute" },
                zIndex: "1500",
                borderRadius: "8px",
                opacity: ".85",
                border: "1px solid",
                borderColor: "primary.dark",
                bgcolor: "rgb(37 22 55)",
                p: "8px 10px",
                color: "#fff",
              }}
            >
              {t("cardTypeHintTitle")}
              {/* <br />
              <b>2020-21</b> 100 Rare Messi cards
              <br />
              <b>2021-22</b> 100 Rare Messi cards */}
            </Typography>
            <Box
              sx={{
                display: { xs: "none", lg: "initial" },
                right: "145px",
                top: "15px",
                width: "17px",
                height: "17px",
                zIndex: "1500",
                position: "absolute",
                flexGrow: "0",
                flexShrink: "0",
                borderRadius: "50%",
                backgroundColor: "primary.dark",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: "20px",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              {t("cardTypeBodyTitle")}
            </Typography>
            <Typography variant="body2" sx={{ opacity: ".75" }}>
              {t("cardTypeBodyDesc")}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            onClick={goNextStep}
            sx={(theme) => ({
              backgroundImage: theme.palette.gradient.purpule,
              color: theme.palette.common.white,
              width: "fit-content",
              padding: "10px 30px",
              fontSize: "1rem",
              mx: "auto",
            })}
          >
            {t("next")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CardsType;
