import { Close } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "jalali-moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { readNotifications } from "redux/auth/authSlice";
import { useReadSingleNotification } from "_hooks/User/queries";

const NotificationModal = ({ onClose, item }) => {
  const dispatch = useDispatch();
  const { mutate: readNotificationRequest } = useReadSingleNotification();

  useEffect(() => {
    if (item && !item?.status) {
      dispatch(readNotifications(item?.id));

      readNotificationRequest(item?.id);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem 2rem",
        gap: 2,
        width: { xs: "95vw", sm: "60vw", lg: "40vw", xl: "500px" },
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Notification Detail</Typography>
        <ButtonBase
          onClick={onClose}
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: (theme) =>
              theme.palette.mode === "dark" ? "#eee" : "#414244",
            border: "1px solid",
            borderColor: (theme) => `border.${theme.palette.mode}`,
            borderRadius: "3px",
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>
      <Box>
        <Typography variant="body1">{item?.title}</Typography>
        <Typography variant="caption">
          {moment(item?.createdAt).locale("en").format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          {item?.description}
        </Typography>
      </Box>
      <Box>
        <ButtonBase
          onClick={onClose}
          sx={{
            outline: "none",
            border: "none",
            padding: "0.6rem 1rem",
            borderRadius: "0.3rem",
            background: "linear-gradient(to right, #d2140c, #ab0f09)",
            color: "#fff",
            width: "100%",
          }}
        >
          <Typography variant="body1">I understand</Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default NotificationModal;
