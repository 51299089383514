import { useRef, useState, useEffect } from "react";
import SingleDeck from "./SingleDeck";
import Slider from "react-slick";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Box } from "@mui/system";

const SmallFields = ({
  team,
  teamPercentages,
  openCardList,
  changeCardType,
  changeCardPercent,
  changeActiveCard,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [smallFieldTeam, setSmallFieldTeam] = useState(team);
  const sliderRef = useRef();

  const nextHandler = () => {
    sliderRef?.current?.slickNext?.();
  };

  const prevHandler = () => {
    sliderRef?.current?.slickPrev?.();
  };

  useEffect(() => {
    setSmallFieldTeam(team);
  }, [team]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    draggable: true,
    swipe: false,
    arrows: false,
    afterChange: (cur) => setCurrentSlide(cur),
  };


  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",

        "& > .container": { height: "100%" },

        ".slider-btn": {
          position: "absolute",
          cursor: "pointer",
          bgcolor: "rgba(0,0,0,0.5)",
          width: "37px",
          height: "37px",
          borderRadius: "50%",
          transition: "all .5s",
          "&:hover": {
            bgcolor: "rgba(0,0,0,0.7)",
          },
        },
        ".next, .prev": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
        ".prev": {
          left: "47%",
        },
      }}
    >
      {currentSlide !== 0 && (
        <Box
          onClick={prevHandler}
          className="slider-btn"
          sx={[
            { top: "50%", zIndex: "10", color: "#fff" },
            (theme) =>
              theme.direction === "ltr" ? { left: "10px" } : { left: "10px" },
          ]}
        >
          <ArrowBackIosRounded className="prev" fontSize="60" />
        </Box>
      )}
      {currentSlide !== team.length - 1 && (
        <Box
          onClick={nextHandler}
          className="slider-btn"
          sx={[
            { top: "50%", zIndex: "10", color: "#fff" },
            (theme) =>
              theme.direction === "ltr" ? { right: "10px" } : { right: "10px" },
          ]}
        >
          <ArrowForwardIosRounded className="next" fontSize="60" />
        </Box>
      )}
      <Slider ref={sliderRef} className="container" {...settings}>
        {smallFieldTeam.map((card, index) => (
          <SingleDeck
            key={index} // using index - DONOT use card.id here (It will cause problem)
            card={card}
            openCardListModal={openCardList}
            changeCardPercent={changeCardPercent}
            changeCardType={changeCardType}
            changeActiveCard={changeActiveCard}
            percent={teamPercentages?.[index]}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default SmallFields;
