import AddToHomescreen from "react-add-to-homescreen";
import AppRoutes from "./Routing/Routes";
import i18next from "./locales";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar/Navbar";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import MainSidebar from "components/MainSidebar";
import { Box } from "@mui/system";
import { setModal } from "redux/app/appSlice";
import AppModal from "components/Modal";
import jmoment from "jalali-moment";
import MainWindow from "./library/MainWindow";
import {
  getWizardData,
  refreshUserToken,
  updateUserData,
  updateWallets,
} from "redux/auth/asyncActions";
import Toast from "components/Toast";
import UserSocket from "library/UserSocket";
import { setRefresh } from "redux/setting/settingSlice";
import { RTLS } from "library/constants";
import ScrollToTop from "library/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GOOGLE_CAPTCHA_SECRET } from "config/Config";
import { useLocation } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  const modalRef = useRef();
  const { lang, theme } = useSelector((state) => state.setting);
  const { refreshToken, accessTokenExpire, accessToken, user } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const isMain = MainWindow();
  const isAuth = !!user;

  const appTheme = useMemo(
    () =>
      createTheme({
        direction: RTLS.includes(lang) ? "rtl" : "ltr",
        palette: {
          mode: theme,

          primary: {
            light: "#b81711",
            main: "rgb(171,15,9)",
            dark: "rgb(179,17,12)",
            contrastText: "#fff",
          },
          secondary: {
            main: "rgb(220,72,66)",
          },
          paper: {
            main: "#2a2e34",
            dark: "#2a2e34",
            // light: "#f8f9fa",
            light: "#F1F0F5",
          },
          background: {
            main: "#2a2e34",
            dark: "#2a2e34",
            // light: "#f8f9fa",
            light: "#F1F0F5",
          },
          gradient: {
            pink: "linear-gradient(-25deg, rgb(246,98,27) 0%, rgb(208,49,209) 100%)",
            purpule:
              "linear-gradient( -41deg, rgb(171,15,9) 0%, rgb(210,20,12) 99%)",
          },
          solid: {
            blue: "rgb(6, 97, 220)",
            grayLight: "#6E737C",
            common: "#6E737C",
            // #2F2D2D
            limited: "#E9BB0D",
            rare: "#D31408",
            superRare: "#067BC9",
            unique: "#000",
            discord: "rgb(114, 137, 218)",
            twitter: "rgb(0, 172, 237)",
          },
          card: {
            dark: "#1a1c24",
            light: "#f3f7fa",
          },
          border: {
            dark: "#2a2e34",
            light: "#eee",
          },
        },
        typography: {
          fontFamily: RTLS.includes(lang) ? "fa" : "en",
          button: {
            textTransform: "none",
          },
        },
      }),
    [theme, lang]
  );

  // init modal
  useEffect(() => {
    dispatch(setModal(modalRef.current));
  }, [dispatch]);

  // config language
  useEffect(() => {
    i18next.changeLanguage(lang);
    dispatch(setRefresh(Math.random()));
    document.body.dir = RTLS.includes(lang) ? "rtl" : "ltr";
  }, [lang]);

  // Update user data & wallets
  useEffect(() => {
    if (!isAuth) return;

    dispatch(updateUserData());
    dispatch(updateWallets());
  }, [accessToken]);

  // MALI - refresh token
  useEffect(() => {
    let timeOut;

    if (!isMain && timeOut) clearTimeout(timeOut);

    if (refreshToken && isMain) {
      let current = jmoment().valueOf();

      let remainingTime = accessTokenExpire - current - 120000;

      timeOut = setTimeout(() => {
        dispatch(refreshUserToken(refreshToken));
      }, remainingTime);
    }

    return () => {
      if (timeOut) clearTimeout(timeOut);
    };
  }, [refreshToken, isMain, accessTokenExpire]);

  useEffect(() => {
    if (!isAuth) return;

    dispatch(getWizardData());
  }, [isAuth, dispatch]);

  const { pathname } = useLocation();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_SECRET}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen />

        <ThemeProvider theme={appTheme}>
          <AddToHomescreen />

          {!!user && <UserSocket />}
          <ScrollToTop />
          <AppModal ref={modalRef} />
          <Toast />

          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {pathname == "/" ? (
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  paddingTop: { xs: "60px", sm: "64px" },
                  maxWidth: "100%",
                  overflowX: "hidden",
                }}
              >
                <AppRoutes />
              </Box>
            ) : (
              <>
                <Navbar />
                <MainSidebar />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    paddingTop: { xs: "60px", sm: "64px" },
                    maxWidth: "100%",
                    overflowX: "hidden",
                  }}
                >
                  {/* <Toolbar /> */}
                  <AppRoutes />
                </Box>
              </>
            )}
            {/* <Navbar />

            <MainSidebar /> */}
          </Box>
        </ThemeProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
