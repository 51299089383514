import { TaskAltOutlined } from "@mui/icons-material";
import {
  Avatar,
  Container,
  CssBaseline,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { replaceJSX } from "library/helper";
import { t } from "locales";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifValidation } from "validations/authValidation";
import { updateUserData, updateWallets, verify } from "redux/auth/asyncActions";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import VerifyTimer from "components/VerifyTimer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthLayout from "components/AuthLayout";
import { goBackFromVerify } from "redux/auth/authSlice";

const getBackPath = (verifyType) => {
  let path = "/auth/login";
  if (verifyType === "REGISTER") {
    path = "/auth/register";
  }

  return path;
};

const OtpInputCustom = styled(OtpInput)(({ theme }) => ({
  direction: "ltr",

  input: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid gray",
    fontSize: "18px",
    width: "3rem !important",
    textAlign: "center",
    padding: "0.5rem",
    margin: "0 .5rem",
    borderRadius: "8px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(162 111 255 / 25%)",
    },
  },
}));

let intervalId;

const Index = () => {
  const { verifyToken, verifyType, resendEmail, resendMobile } = useSelector(
    (state) => state.auth
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  let attempts = 1;
  const navigate = useNavigate();
  function getRetryTimeout(attemptNumber) {
    const retryTimeouts = {
      1: 40,
      2: 80,
      3: 100,
      4: 120,
      5: 140,
    };

    const maxTimeout = 600;
    const defaultTimeout =
      maxTimeout - Object.values(retryTimeouts).reduce((a, b) => a + b);

    return retryTimeouts[attemptNumber] || defaultTimeout;
  }
  const [timer, setTimer] = useState(getRetryTimeout(attempts));
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(verifValidation),
    defaultValues: { code: "" },
  });

  // CHECK FOR VERIFY TOKEN
  useEffect(() => {
    if (!verifyToken) {
      navigate("/auth/login");
    }
  }, []);

  // HANDLE TIMER
  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      clearInterval(intervalId);
    }

    if (!timer) return;

    intervalId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const resetTimer = () => {
    attempts += 1;
    setTimer(getRetryTimeout(attempts));
    reset({ code: "" });
  };

  const submitHandler = async (data) => {
    const gToken = await executeRecaptcha();
    await dispatch(
      verify({
        token: verifyToken,
        code: data?.code,
        gRecaptchaResponse: gToken,
      })
    );

    if (verifyType === "FORGOT_PASSWORD") {
      navigate("/auth/reset-password");
    } else {
      await dispatch(updateUserData());
      await dispatch(updateWallets());
    }
  };

  const backHandler = () => {
    dispatch(goBackFromVerify());

    navigate(getBackPath(verifyType));
  };

  return (
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: 1,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <TaskAltOutlined />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ direction: "ltr", span: { display: "inline-block" } }}
            >
              <span>{t(verifyType)}</span> <span>{t("verification")}</span>
            </Typography>
            <Typography variant="body1" sx={{ color: "gray" }}>
              {replaceJSX(
                t("verifyDesc"),
                "#",
                <Typography component="span" variant="body1">
                  {resendEmail ? resendEmail : resendMobile}
                </Typography>
              )}
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            noValidate
          >
            <Box sx={{ marginBottom: 2 }}>
              <Controller
                control={control}
                name="code"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Box sx={{ "& > div": { justifyContent: "center" } }}>
                    <OtpInputCustom
                      value={value}
                      // onChange={handleChange}
                      onChange={onChange}
                      onBlur={onBlur}
                      numInputs={4}
                      separator={<span>-</span>}
                      hasErrored={!!errors.code}
                    />
                  </Box>
                )}
              />
              {errors?.code && (
                <FormHelperText
                  sx={{ color: "#f44336", textAlign: "center", mt: 2 }}
                >
                  {t(errors?.code?.message)}
                </FormHelperText>
              )}
            </Box>
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isSubmitting}
              disabled={!_.isEmpty(errors, true)}
              loaderHeight="40"
              extraSx={{ marginBottom: 2, width: "100%" }}
            >
              {t("verify")}
            </CustomButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                onClick={backHandler}
                variant="body2"
                sx={{
                  color: "primary.light",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("back")}
              </Typography>
              <VerifyTimer
                timeLeft={timer}
                resetTimer={resetTimer}
                // setError={setError}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default Index;
