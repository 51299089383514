import { Box } from "@mui/system";
import { openToast } from "components/Toast";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  ButtonGroup,
  ButtonBase,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import Qrcode from "components/Qrcode";
import { t } from "locales";
import { replaceJSX } from "library/helper";
import { BASE_URL } from "config/Config";

const Instruction = () => {
  const { accessToken } = useSelector((state) => state.auth);
  // const [step, setStep] = useState(1);
  const [done, setDone] = useState(false);
  var walletAddress;
  var contractAddress;
  const isLight = useSelector((state) => state.setting.theme === "light");

  function connect_metamask() {
    // Step 1: if Metamask installed show instruction and button
    const metamask_installed = window.ethereum;
    if (metamask_installed) {
      return (
        <Typography variant="body2">
          <Typography id="instruction">
            1. Please connect your wallet by clicking the button below.
          </Typography>
          <br />
          <ButtonBase
            onClick={() => requestMetaMaskConnection()}
            id="btn-metamask-connect"
            sx={{
              p: "2px 12px",
              borderRadius: "8px",
              bgcolor: "#035c07",
              color: "common.white",
              mt: "5px",
            }}
          >
            <Typography variant="body2">Connect</Typography>
          </ButtonBase>
          <Typography
            variant="body2"
            id="connection-result"
            sx={{
              p: "5px",
              m: "5px 0",
              borderRadius: "3px",
              width: "fit-content",
            }}
          ></Typography>
          <Typography
            variant="p"
            id="note"
            sx={{
              m: "8px 0",
              width: "fit-content",
              p: "5px",
              borderRadius: "3px",
              whiteSpace: "pre-wrap",
              display: "block",
            }}
          ></Typography>
        </Typography>
      );
    } else {
      return (
        <span style={{ color: "red" }}>
          You have to install MetaMask to continue depositing cards
          <br />
          <a href="https://metamask.io/" target="_blank">
            Download MetaMask from official website
          </a>
        </span>
      );
    }
  }

  function requestMetaMaskConnection() {
    // Step 1 part 2: User clicked connect button, send request to metamask to connect the website
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res) => {
        openToast(
          "success",
          "Wallet has been connected, follow the instructions",
          "top-left"
        );
        const connectBTN = document.getElementById("btn-metamask-connect");
        connectBTN.remove();
        // setStep(2);
        get_token();
      })
      .catch((err) => {
        var error_message;
        switch (err.code) {
          case -32002:
            error_message =
              "Request is already sent, please click on Metamask extension icon to connect your wallet";
            break;

          case 4100:
            error_message = "Request is refused by you. Please try again.";
            break;

          default:
            error_message = err.message;
        }
        openToast("error", error_message, "top-left");
      });
  }

  function get_token() {
    // Step 3, get token from API
    var url = `${BASE_URL}user/card-crypto/token`;
    var message;

    fetch(url, {
      method: "GET",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status == "success") {
          var token = json.data.token;
          message =
            "2. Now please sign given token in Metamask extension to continue depositing cards. Token ID:" +
            token;
          sign_token(token);
        } else {
          // Throw exception
          throw "API Error";
        }
      })
      .catch((error) => {
        message =
          "Problem while receiving token, please try later or contact the administrator";
        console.error(error);
      })
      .finally(() => {
        const instruction = document.getElementById("instruction");
        instruction.innerHTML = message;
      });
  }

  function sign_token(token) {
    window.ethereum
      .request({
        method: "personal_sign",
        params: [token, window.ethereum.selectedAddress],
      })
      .then((res) => {
        // User signed the token, check it with API
        check_sign(res);
      })
      .catch((err) => {
        var error_message;
        switch (err.code) {
          case -32002:
            error_message =
              "Request is already sent, please click on Metamask extension icon to connect your wallet";
            break;

          case 4100:
            error_message = "Request is refused by you. Please try again.";
            break;

          default:
            error_message = err.message;
        }
        openToast("error", error_message, "top-left");
      });
  }

  function check_sign(signature) {
    // Get token from API using fetch request
    var url =
      `${BASE_URL}user/card-crypto/address?signature=` +
      signature;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.status == "success") {
          walletAddress = json.data.address;
          contractAddress = json.data.contractAddress;
          setDone({ list: [walletAddress, contractAddress] });
        } else {
          // Throw exception
          console.error("Problem while connecting to API");
          throw "API Error";
        }
      })
      .catch((error) => {
        const message =
          "Problem while signing token, please try later or contact the administrator";
        openToast("error", message, "top-left");
      });
  }

  function show_result() {
    return (
      <Grid
        container
        sx={{ justifyContent: "space-between", gap: { xs: 2, lg: 0 } }}
      >
        <Grid
          item
          xs={12}
          // lg={6.95}
          sx={{
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            p: "20px",
            borderRadius: "12px",
          }}
          className="col-md-7 col-sm-12 mt-3"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" sx={{ fontWeight: "300" }}>
                Network:
              </Typography>
              <ButtonGroup variant="contained" sx={{ boxShadow: "none" }}>
                <Button>Polygon</Button>
              </ButtonGroup>
            </Box>
            <Alert variant="outlined" severity="success">
              {t("successDeposit")}
            </Alert>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: "300" }}
            >
              {t("walletAddress")}:
            </Typography>
            <Qrcode text={done.list[0]} />
            <Typography
              variant="body1"
              component="span"
              sx={{ fontWeight: "300" }}
            >
              {t("contractAddress")}:
            </Typography>
            <Qrcode text={done.list[1]} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return <Box>{done ? show_result() : connect_metamask()}</Box>;
};

export default Instruction;
