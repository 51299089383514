const authEn = {
  verifyDesc:
    "Please enter the 4-digit verification code that was sent to (#). The code is valid for 1 minute.",
  email: "Email",
  mobile: "Mobile",
  password: "Password",
  passwordConfirmation: "Password confirmation",
  name: "Name",
  login: "Login",
  signin: "Login",
  signIn: "Login",
  signup: "Sign up",
  signUp: "Sign up",
  LOGIN: "Login",
  SIGNUP: "Sign Up",
  REGISTER: "Register",
  signupAgreement: "I have read and agree to the Terms of Service.",
  verify: "Verify",
  verification: "Verification",
  alreadyHaveAnAccount: "Already have an account ?",
  forgotPassword: "Forgot password?",
  freeRegistration: "Free registration",
  back: "Back",
  next: "Next",
  FORGOT_PASSWORD: "Forgot Password",
  resetPassword: "Reset Password",
  resend: "Resend",
  mobileNotValid: "Mobile isn't valid!",
  referredCodeLabel: "Referral Id (Optional)",
};

export default authEn;
