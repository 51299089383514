import { Box } from "@mui/system";
import Header from "./Header";
import CardsList from "./CardsList";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { t } from "locales";
import { useMemo, useState, useEffect } from "react";
import { useGetCryptoTeamCards } from "_hooks/Investment/competitions";
import axios from "library/http";

const Index = ({
  onAdd,
  onRemove,
  team,
  asModal = false,
  closeModal,
  leagueId,
  onClose,
}) => {
  const [keyword, setKeyword] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  // const { data, isLoading } = useGetCryptoTeamCards({
  //   keyword: keyword,
  // });


  useEffect(async () => {
    try {
      const result = await axios.get("public/cryptos", {
        params: { keyword: keyword },
      });
      if (result.data?.status == "success") {
        setData(result);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [loading]);

  const cards = useMemo(() => data?.data?.data?.data, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: asModal ? "360px" : "360px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#1d1f23" : "#eee",
        height: "100%",
        boxShadow: "0px 2px 26.73px 0.27px rgba(0, 0, 0, 0.36)",
        zIndex: 1,
      }}
    >
      {asModal && (
        <Box sx={{ display: "flex", padding: "5px 10px" }} onClick={onClose}>
          <IconButton
            onClick={closeModal}
            sx={{
              marginLeft: "auto",
              border: (theme) =>
                theme.palette.mode === "dark"
                  ? "2px solid #2a2e34"
                  : "2px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
      <Header
        cardsCount={cards?.length ?? 0}
        setKeyword={setKeyword}
        setLoading={setLoading}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: 1.5,
          }}
        >
          <CircularProgress />
          <Typography
            variant="body2"
            sx={{ color: "primary.main", fontWeight: "500" }}
          >
            {t("loading")}...
          </Typography>
        </Box>
      ) : (
        <CardsList
          cards={cards}
          onAdd={onAdd}
          onRemove={onRemove}
          team={team}
        />
      )}
    </Box>
  );
};

export default Index;
