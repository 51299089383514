export const privaceyPolicyContent = {
  HEADER: { TITLE: "Privacy policy", DESC: "headerDesc" },
  BODY: {
    en: [
      {
        TITLE: "title1",
        ITEMS: [
          "text1-1",
          "text1-2",
          "text1-3",
          "text1-4",
          "text1-5",
          "text1-6",
        ],
      },
      {
        TITLE: "title2",
        ITEMS: ["text2-1", "text2-2", "text2-3", "text2-4"],
      },
      {
        TITLE: "title3",
        ITEMS: [
          "text3-1",
          "text3-2",
          "text3-3",
          "text3-4",
          "text3-5",
          "text3-6",
          "text3-7",
          "text3-8",
          "text3-9",
          "text3-10",
          "text3-11",
        ],
      },
      {
        TITLE: "title4",
        ITEMS: ["text4-1", "text4-2", "text4-3"],
      },
      {
        TITLE: "title5",
        ITEMS: [
          "text5-1",
          "text5-2",
          "text5-3",
          "text5-4",
          "text5-5",
          "text5-6",
          "text5-7",
        ],
      },
      {
        TITLE: "title6",
        ITEMS: ["text6-1", "text6-2", "text6-3", "text6-4", "text6-5"],
      },
      {
        TITLE: "title7",
        ITEMS: ["text7-1", "text7-2", "text7-3", "text7-4", "text7-5"],
      },
      {
        TITLE: "title8",
        ITEMS: [
          "text8-1",
          "text8-2",
          "text8-3",
          "text8-4",
          "text8-5",
          "text8-6",
          "text8-7",
        ],
      },
      // {
      //   TITLE: "title9",
      //   ITEMS: ["text9-1", "text9-2", "text9-3", "text9-4", "text9-5"],
      // },
      // {
      //   TITLE: "title10",
      //   ITEMS: ["text10-1", "text10-2"],
      // },
      // {
      //   TITLE: "title11",
      //   ITEMS: ["text11-1", "text11-2"],
      // },
    ],
    // fa: [
    //   {
    //     TITLE: "title1",
    //     ITEMS: [
    //       "text1-1",
    //       "text1-2",
    //       "text1-3",
    //       "text1-4",
    //       "text1-5",
    //       "text1-7",
    //       "text1-8",
    //       "text1-9",
    //       "text1-10",
    //       "text1-11",
    //       "text1-12",
    //       "text1-13",
    //       "text1-14",
    //       "text1-15",
    //       "text1-16",
    //       "text1-17",
    //       "text1-18",
    //     ],
    //   },
    //   {
    //     TITLE: "title2",
    //     ITEMS: ["text2-1", "text2-2"],
    //   },
    //   {
    //     TITLE: "title3",
    //     ITEMS: ["text3-1", "text3-2"],
    //   },
    // ],
  },
};
