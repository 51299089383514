import { Route, Routes } from "react-router";
import { PrivateRoute } from "./CustomRoutes";
import CryptosMain from "routes/Cryptos/List";
import CryptosDetails from "routes/Cryptos/Details";

export const cryptosRoutes = [
  {
    path: "/cryptos",
    component: CryptosMain,
    exact: true,
  },
  {
    path: "/cryptos/:coinname",
    component: CryptosDetails,
    exact: true,
  }
];

export const CryptosRoutes = () => (
  <Routes>
    {cryptosRoutes.map(({ exact, path, component: Component }, i) => {
      return (
        <Route key={i} exact={exact} path={path} element={<Component />} />
      );
    })}
  </Routes>
);
