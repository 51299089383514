import { MailOutline, PersonOutline } from "@mui/icons-material";
import { Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DataTable from "components/DataTable";
import { SkeletonLoader } from "components/Skeleton";
import { calcTotalPages, getImageUrl } from "library/helper";
import axios from "library/http";
import { t } from "locales";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import defaultProfileImg from "assets/img/default-profile.png";
import Dropdown from "components/Dropdown";

const PERPAGE = 10;
const LOADING_LIST = Array(5).fill({});
const STATUS = [
  { title: "all", value: undefined },
  { title: "COMPELETED", value: "COMPLETED" },
  { title: "EXPIRED", value: "EXPIRED" },
  { title: "IN_PROGRESS", value: "IN_PROGRESS" },
];

const History = ({ referralData = {} }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(STATUS[0]);

  useEffect(() => {
    setLoading(true);

    axios
      .get("user/referral/history", {
        params: {
          page,
          limit: PERPAGE,
          status: selectedStatus?.value || undefined,
        },
      })
      .then((res) => {
        setHistory(res.data?.data?.data);
        setTotal(calcTotalPages(res.data?.data?.total, PERPAGE));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [page, selectedStatus?.value]);

  const onPageChange = (_, value) => setPage(value);

  return (
    <Box
      sx={{
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        p: "20px",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{t("inviteHistoryTitle")}</Typography>
          <Dropdown
            options={STATUS ?? []}
            value={selectedStatus}
            onChange={(newValue) => setSelectedStatus(newValue)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            flexGrow: 1,
            gap: 2,
            "& > div": { display: "flex", alignItems: "center" },
          }}
        >
          <Box>
            <PersonOutline sx={{ fontSize: "20px", mx: "5px" }} />
            <Typography variant="body2">
              {referralData?.completedReferrals || 0} {t("completedReferrals")}
            </Typography>
          </Box>
          <Box>
            <MailOutline sx={{ fontSize: "20px", mx: "5px" }} />
            <Typography variant="body2">
              {referralData?.friendsInvited || 0} {t("friendsInvited")}
            </Typography>
          </Box>
          <Box></Box>
        </Box>

        <Box>
          <DataTable
            columns={[
              {
                title: t("user"),
                field: "userReferred",
                render: ({ userReferred }) => {
                  return loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        img: {
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        },
                      }}
                    >
                      <img
                        src={
                          getImageUrl(userReferred?.avatar) || defaultProfileImg
                        }
                        alt=""
                      />
                      <Typography variant="body1">
                        {userReferred?.name ?? "-"}
                      </Typography>
                    </Box>
                  );
                },
              },
              {
                title: t("cardNumbers"),
                field: "cardNumbers",
                render: ({ cardNumbers }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">
                      {cardNumbers}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ opacity: ".75" }}
                      >
                        /5
                      </Typography>
                    </Typography>
                  ),
              },
              {
                title: t("status"),
                field: "status",
                render: ({ status }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">{t(status) ?? "-"}</Typography>
                  ),
              },
            ]}
            data={loading ? LOADING_LIST : history}
            rowClass="py-2"
            headClass="table-head"
          />
          {!loading && history.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 5,
              }}
            >
              <Typography variant="body1">{t("noInvitedFriend")}</Typography>
            </Box>
          ) : (
            total > 1 && (
              <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
                <Pagination
                  sx={{ width: "fit-content" }}
                  count={total}
                  page={page}
                  onChange={onPageChange}
                />
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default History;
