const config = {
  BASE_URL: mandatory("REACT_APP_BASE_URL_PLAY"),
  IMAGE_URL: mandatory("REACT_APP_IMAGE_URL_PLAY"),
  SOCKET_URL: mandatory("REACT_APP_SOCKET_URL_PLAY"),
  SITE_URL: mandatory("REACT_APP_SITE_URL_PLAY"),
  CAPTCHA_SITE_KEY: mandatory("REACT_APP_CAPTCHA_SITE_KEY"),
  CAPTCHA_SECRET: mandatory("REACT_APP_CAPTCHA_SECRET_KEY"),
  GOOGLE_CAPTCHA_SECRET: mandatory("REACT_APP_GOOGLE_CAPTCHA_SECRET_KEY"),
};

function mandatory(key) {
  const value = process.env[key];
  if (!value) {
    throw new Error(`missing mandatory env value: ${key}`);
  }
  return value;
}

const {
  BASE_URL,
  IMAGE_URL,
  SOCKET_URL,
  SITE_URL,
  CAPTCHA_SITE_KEY,
  CAPTCHA_SECRET,
  GOOGLE_CAPTCHA_SECRET,
} = config;
export {
  BASE_URL,
  IMAGE_URL,
  SOCKET_URL,
  SITE_URL,
  CAPTCHA_SITE_KEY,
  CAPTCHA_SECRET,
  GOOGLE_CAPTCHA_SECRET,
};
