import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { SkeletonLoader } from "components/Skeleton";
import { useSelector } from "react-redux";
import axios from "library/http";
import { formatPrice } from "library/helper";
import LinkIcon from "@mui/icons-material/Link";
import StarsIcon from "@mui/icons-material/Stars";
import { MarketsList } from "components/MarketsList";
import TelegramIcon from "@mui/icons-material/Telegram";
import DiscordIcon from "assets/icons/Discord";

const ExchangesDetails = ({}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [loading, setLoading] = useState(true);
  const { exchangeName } = useParams();
  const [details, setDetails] = useState([]);

  const [markets, setMarkets] = useState([]);
  const [hasMore, setHasMore] = useState();
  const [page, setPage] = useState(1);

  const [marketsUnverified, setMarketsUnverified] = useState([]);
  const [hasMoreUnverified, setHasMoreUnverified] = useState();
  const [pageUnverified, setPageUnverified] = useState(1);

  const [readMore, setReadMore] = useState(false);
  const [exchangeId, setExchangeId] = useState();
  const limit = 10;

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  let hostname;
  if (details.chat && isValidUrl(details.chat))
    hostname = new URL(details.chat).hostname;

  const list = async () => {
    try {
      let result = await axios.get("public/exchanges", {
        params: { keyword: exchangeName },
      });
      if (result.data?.status == "success" && result.data.data.total > 0) {
        let data = result.data.data.data[0];

        setDetails(data);

        setExchangeId(data.id);

        // Get markets
        marketsList(data);
        // Get unverified markets
        marketsList(data, false);

        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const marketsList = async (data, verified = null) => {
    try {
      let params = {
        exchangeId: exchangeId || data.id,
        page: page,
        limit: limit,
      };

      if (verified !== null) params.verified = verified;

      let result = await axios.get("public/markets", {
        params: params,
      });
      if (result.data?.status == "success" && result.data.data.total > 0) {
        if (verified === false) {
          setMarketsUnverified([
            ...marketsUnverified,
            ...result.data.data.data,
          ]);
          setPageUnverified(pageUnverified + 1);
          // Check if there is more data
          setHasMoreUnverified(result.data.data.total > limit * pageUnverified);
        } else {
          setMarkets([...markets, ...result.data.data.data]);
          setPage(page + 1);
          // Check if there is more data
          setHasMore(result.data.data.total > limit * page);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const marketsListUnverified = (data) => {
    marketsList(data, false);
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <Box sx={{ m: 2 }}>
      {loading ? (
        <SkeletonLoader
          isLight={isLight}
          width="100%"
          height="40"
          children="5"
        />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item md={8} sm={12}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: (theme) =>
                    theme.palette.background[theme.palette.mode],
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 5,
                }}
              >
                <Box sx={{ display: "flex", "& img": { mr: 2 } }}>
                  <img
                    src={details.icon[0].location}
                    alt={details.name + " icon"}
                    width="50"
                  />
                  <Typography component="h1" variant="h3">
                    {details.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography color="textSecondary">Volume (24h)</Typography>
                  <Typography variant="h6">
                    {formatPrice(details.volume24h)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
              sx={{
                display: "flex",
                alignContent: "flex-start",
                flexWrap: "wrap",
                "& span": { m: 1 },
                "& span, & a": {
                  display: "flex",
                  alignItems: "center",
                  minWidth: "30%",
                },
                "& svg": { mr: 1 },
              }}
            >
              <Box component="span">
                <Link
                  href={
                    details.website.startsWith("https://")
                      ? details.website
                      : "https://" + details.website
                  }
                  target="_blank"
                  underline="none"
                  rel="noreferrer"
                >
                  <LinkIcon color="disabled" />
                  {details.website}
                </Link>
              </Box>
              {details.chat && isValidUrl(details.chat) && (
                <Box component="span">
                  <Link
                    href={details.chat}
                    target="_blank"
                    underline="none"
                    rel="noreferrer"
                  >
                    {hostname.includes("t.me") ? (
                      <TelegramIcon color="disabled" />
                    ) : hostname.includes("discord") ? (
                      <DiscordIcon color="disabled" />
                    ) : (
                      <LinkIcon color="disabled" />
                    )}
                    Chat
                  </Link>
                </Box>
              )}
              <Box component="span">
                <StarsIcon color="disabled" />
                Score: {details.score}
              </Box>
            </Grid>
          </Grid>
          {loading ? (
            <SkeletonLoader isLight={isLight} width="100%" height="140" />
          ) : (
            <Box
              sx={{
                mt: 2,
                p: 2,
                backgroundColor: (theme) =>
                  theme.palette.background[theme.palette.mode],
              }}
            >
              <Typography variant="h6">About {details.name}</Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {readMore
                  ? details.description.split("\\n").map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })
                  : details.description.slice(0, 200) + " ..."}{" "}
                <Box
                  component="span"
                  onClick={() => setReadMore(!readMore)}
                  sx={{
                    color: "primary.main",
                    textDecoration: "underline",
                    // display: "block",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                >
                  {readMore ? "Read less" : "Read more"}
                </Box>
              </Typography>
            </Box>
          )}
          <Box>
            {loading ? (
              Array(10)
                .fill(1)
                .map((_, i) => (
                  <SkeletonLoader
                    isLight={isLight}
                    width="100%"
                    height="72"
                    children="5"
                    key={i}
                  />
                ))
            ) : (
              <>
                <Typography variant="h6" sx={{ mt: 5 }}>
                  Markets
                </Typography>
                <MarketsList
                  markets={markets}
                  fetchMoreData={marketsList}
                  hasMore={hasMore}
                />
                {marketsUnverified.length > 0 && (
                  <>
                    <Typography variant="h6" sx={{ mt: 5 }}>
                      Unverified Markets
                    </Typography>
                    <MarketsList
                      markets={marketsUnverified}
                      fetchMoreData={marketsListUnverified}
                      hasMore={hasMoreUnverified}
                      verified={false}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ExchangesDetails;
