import { ButtonBase, InputBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { stringQueriesToObj } from "library/helper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SkeletonLoader } from "components/Skeleton";

const SearchBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get("name") || "");

  useEffect(() => {
    if (!!searchValue) {
      const queries = { ...stringQueriesToObj(searchParams.toString() ?? "") };
      setSearchParams({ ...queries, name: searchValue });
    } else {
      const queries = {
        ...stringQueriesToObj(searchParams.toString() ?? "", "name"),
      };
      setSearchParams({ ...queries });
    }
  }, [searchValue]);

  const onChange = (value) => {
    value !== searchValue && setSearchValue(value.target.value.trim());
  };

  return (
    <Box>
      <InputBase
        placeholder="Search…"
        onChange={onChange}
        autoComplete="off"
        autoFocus={true}
        value={searchValue}
        sx={{
          width: "100%",
          transition: "all 0.3s ease-in-out",
          borderRadius: "4px",
          border: "1px solid #ccc",
          padding: "8px",
          fontSize: "14px",
          "&.Mui-focused": {
            outline: "none",
            border: "2px solid rgb(179,17,12)",
          },
        }}
      />
    </Box>
  );
};

export default SearchBar;
