import { Close } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import PhoneField from "components/PhoneInput";
import { t } from "locales";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updateUserData } from "redux/auth/asyncActions";
import { useDispatch } from "react-redux";
import { addMobileValidation } from "validations/authValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorAlert from "components/ErrorAlert";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import { getHumanError } from "library/translateServerErrors";
import axios from "library/http";
import OtpInput from "react-otp-input";
import { replaceJSX } from "library/helper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const OtpInputCustom = styled(OtpInput)(({ theme }) => ({
  input: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid gray",
    fontSize: "18px",
    width: "3rem !important",
    textAlign: "center",
    padding: "0.5rem",
    margin: "0 .5rem",
    borderRadius: "8px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(162 111 255 / 25%)",
    },
  },
}));

const MobileModal = ({ closeModal = null }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    defaultValues: {
      mobile: "",
    },
    resolver: yupResolver(addMobileValidation),
  });

  const submitHandler = async (data) => {
    try {
      if (!token) {
        const response = await axios.patch("user/signup", {
          mobile: data?.mobile,
        });

        setToken(response.data?.data?.token);
      } else {
        const gToken = await executeRecaptcha();
        await axios.post("user/verify", {
          code: data?.code,
          token,
          gRecaptchaResponse: gToken,
        });

        await dispatch(updateUserData());
        closeModal?.();
      }
    } catch (error) {
      const errorMessage = getHumanError(error);
      setError(errorMessage);
    }
  };

  let renderedFields;
  if (!token) {
    renderedFields = (
      <Controller
        control={control}
        name="mobile"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Box mb={12}>
            <PhoneField
              errors={errors}
              id="mobile"
              label={t("mobile")}
              name="mobile"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              inputStyle={{ width: "100%" }}
            />
          </Box>
        )}
      />
    );
  } else {
    renderedFields = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          "& div:nth-child(1)": {
            justifyContent: "center",
            flexWrap: "wrap",
          },
        }}
      >
        <Controller
          control={control}
          name="code"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Box
              sx={{
                marginBottom: 3,
              }}
            >
              <OtpInputCustom
                value={value}
                // onChange={handleChange}
                onChange={onChange}
                onBlur={onBlur}
                numInputs={4}
                separator={<span>-</span>}
                hasErrored={!!errors.code}
              />
            </Box>
          )}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: "20px",
        minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "650px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{t("addMobile")}</Typography>
        <ButtonBase
          onClick={closeModal}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      {error && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={error} />
        </Box>
      )}

      {token && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <Typography
            variant="body1"
            sx={{ width: "100%", color: "gray", textAlign: "center" }}
          >
            {replaceJSX(
              t("verifyDesc"),
              "#",
              <Typography component="span" variant="body1">
                {getValues("mobile")}
              </Typography>
            )}
          </Typography>
        </Box>
      )}

      <Box
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {renderedFields}
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          loading={isSubmitting}
          disabled={!_.isEmpty(errors, true)}
          loaderHeight="40"
          sx={{ fontSize: "1.1rem", marginTop: 2 }}
          withSpinner
        >
          {t("submit")}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MobileModal;
