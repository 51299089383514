const ticketFa = {
  ticket: "تیکت",
  tickets: "تیکت ها",
  newTicket: "ایجاد تیکت جدید",
  sendTicket: "ایجاد تیکت",
  sendReply: "ارسال پیام",
  enterTitle: "موضوع را وارد کنید",
  enterDescription: "توضیحات را وارد کنید",
  title: "موضوع",
  description: "توضیحات",
  priority: "اولویت",
  department: "دپارتمان",
  HIGHT: "بالا",
  MEDIUM: "متوسط",
  LOW: "کم",
  sendingFile: "ارسال فایل",
  uploadFile: "اپلود فایل",
  titleRequired: "وارد کردن موضوع الزامی میباشد",
  descriptionRequired: "وارد کردن توضیحات الزامی میباشد",
  singleTicketHeader: "تیکت شماره #",
  creationTime: "تاریخ ایجاد",
  replies: "پاسخ ها",
  enterYourMessage: "پیام خود را وارد کنید",
  textRequired: "وارد کردن پیام الزامی میباشد",
  noReply: "بدون پاسخ",
  files: "فایل ها",
  sort: "ترتیب",
  // maxTicketFile: "حداکثر 5MB",
  maxTicketFile: "حداکثر ۵ مگابایت",
};

export default ticketFa;
