import { CssBaseline, Drawer } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Sidebar from "./index";

const MobilePlayersList = ({
  onAdd,
  onRemove,
  open,
  team,
  asModal,
  leagueId,
  onClose,
}) => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        (theme) => ({
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }),
      ]}
    >
      <CssBaseline />
      <Drawer
        variant="temporary"
        open={open}
        onClose={onClose}
        sx={{
          zIndex: 1300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            backgroundImage: "none",
            backgroundColor: (theme) =>
              theme.palette.background[theme.palette.mode],
          },
        }}
        anchor="left"
      >
        <Sidebar
          onClose={onClose}
          onAdd={onAdd}
          onRemove={onRemove}
          team={team}
          asModal
          leagueId={leagueId}
        />
      </Drawer>
    </Box>
  );
};

export default MobilePlayersList;
