import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import TrendDownIcon from "assets/icons/TrendDownIcon";
import TrendUpIcon from "assets/icons/TrendUpIcon";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import React from "react";

const TRENDS_BG = {
  rise: { dark: "#373a34", light: "#b1cd7e" },
  fall: { dark: "#342726", light: "#e99483" },
};
const TRENDS_COLOR = {
  rise: { dark: "#96a878", light: "#373a34" },
  fall: { dark: "#a45747", light: "#342726" },
};

const Index = ({ onAdd = null, onRemove = null, data = {}, team, index }) => {
  const {
    card,
    highPrice24h: high = 0,
    lowPrice24h: low = 0,
    changePercentage: change = 0,
  } = data;
  // const { image } = card;
  const existInTeam = !!team?.find?.((c) => data?.id === c?.id);

  const selectCardHandler = () => {
    if (existInTeam) onRemove(data.id);
    else onAdd({ ...data, type: "" });
  };

  const trend = +change >= 0 ? "rise" : "fall";


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        p: "4px 6px",
        mb: "12px",
      }}
    >
      <Box
        onClick={selectCardHandler}
        sx={{
          width: "160px",
          height: "auto",
          position: "relative",
          cursor: "pointer",
          img: {
            objectFit: "contain",
            width: "100%",
            height: "auto",
            borderRadius: "17px",
          },
        }}
      >
        {existInTeam && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "rgba(0, 0, 0, 0.67)",
              borderRadius: "17px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1" sx={{ color: "#fff" }}>
              {t("delete")}
            </Typography>
          </Box>
        )}
        {/* <img src={getImageUrl(image)} alt="" /> */}
        <img src={data.icon[0].location} alt="" />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "4px",
        }}
      >
        <Box>
          <Typography
            variant="body2"
            sx={{ opacity: "0.8", fontSize: "0.775rem" }}
          >
            {t("24hHigh")}
          </Typography>
          <Typography variant="body2">{high}</Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{ opacity: "0.8", fontSize: "0.775rem" }}
          >
            {t("24hLow")}
          </Typography>
          <Typography variant="body2">{low}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          p: "5px",
          gap: 1,
          bgcolor: (theme) => TRENDS_BG[trend][theme.palette.mode],
          color: (theme) => TRENDS_COLOR[trend][theme.palette.mode],
          svg: {
            width: "20px",
            fill: (theme) => TRENDS_COLOR[trend][theme.palette.mode],
          },
        }}
      >
        <Typography variant="body1">{change}%</Typography>
        {+change > 0 ? <TrendUpIcon /> : +change !== 0 && <TrendDownIcon />}
      </Box>
    </Box>
  );
};

export default Index;
