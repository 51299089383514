import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { t } from "locales";
import { accordionStyle } from "assets/styles/styles";
import { useSearchParams } from "react-router-dom";
import { addComma, stringQueriesToObj } from "library/helper";

const RangeFilter = ({ min = 0, max = 100, step = 1, title = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([
    searchParams?.get(`min-${title}`) || min,
    searchParams?.get(`max-${title}`) || max,
  ]);
  const minValue = searchParams?.get(`min-${title}`);
  const maxValue = searchParams?.get(`max-${title}`);

  // clear selected when user clicked on Delete all filters button
  useEffect(() => {
    if (!minValue || !maxValue) {
      setSelected([minValue || min, maxValue || max]);
    }
  }, [minValue, maxValue, min, max]);

  const changeHandler = (event, newValue) => {
    setSelected(newValue);
  };

  const handleFilter = () => {
    const queries = { ...stringQueriesToObj(searchParams.toString() ?? "") };

    if (!!selected)
      setSearchParams({
        ...queries,
        [`min-${title}`]: selected?.[0],
        [`max-${title}`]: selected?.[1],
      });
  };

  return (
    <Accordion sx={{ ...accordionStyle }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t(title)}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{ display: "flex", flexDirection: "column", padding: "0 5px" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Typography variant="caption">{addComma(selected?.[0])}</Typography>
            <Typography variant="caption">{addComma(selected?.[1])}</Typography>
          </Box>
          <Box sx={{ padding: "0 7px" }}>
            <Slider
              min={min}
              max={max}
              step={step}
              value={selected}
              onChange={changeHandler}
              onChangeCommitted={handleFilter}
              valueLabelDisplay="off"
              size="small"
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RangeFilter;
