import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import bgImg from "assets/img/dashboard-bg.jpg";
import { t } from "locales";


const MainContainer = styled("div")({
  minHeight: "150px",
  backgroundImage: `url(${bgImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  marginBottom: "2rem",
});

const Header = () => {
  return (
    <MainContainer>
      <Box
        className="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "fit-content",
          marginLeft: "2em"
        }}
      >
        <Typography
          variant="subtitle2"
          sx={[
            {
              color: "rgb(232, 93, 33)",
              padding: "2px 8px",
            },
            (theme) =>
              theme.direction === "ltr"
                ? {
                    borderLeft: "2px solid rgb(232, 93, 33)",
                  }
                : {
                    borderRight: "2px solid rgb(232, 93, 33)",
                  },
          ]}
        >
          {t("marketLandingSubHeading")}
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
          {t("listedprojects")}
        </Typography>
      </Box>
    </MainContainer>
  );
};

export default Header;
