import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BodyRow from "./BodyRow";
import HeaderRow from "./HeaderRow";
import axios from "library/http";
import _ from "lodash";
import { t } from "locales";

const Deposit = () => {
  const [asset, setAsset] = useState({});
  const [active, setActive] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [tag, setTag] = useState(null);
  const [walletDeposit, setWalletDeposit] = useState([]);
  const [walletDepositConfig, setWalletDepositConfig] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [wallet, setWallet] = useState();
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  function resetData() {
    setActive(null);
    setError(null);
    setWallet(null);
    setConfirm(null);
    setAddress(null);
    setSuccess(null);
  }

  // Handle changing dropdown
  const onSelect = (value) => {
    resetData();
    navigate(`/deposit/${value.name}-${value.coin}`);
  };

  // Set default protocol - when config changed
  useEffect(() => {
    if (walletDepositConfig.length > 0) setActive(walletDepositConfig[0]);
  }, [walletDepositConfig]);

  // Chack for coin param and validate coin
  useEffect(() => {
    resetData();

    if (params.coin && walletDeposit.length > 0) {
      const [name, coin] = params.coin.split("-");
      const assetData = { coin, name };
      const isValid = _.find(walletDeposit, assetData);

      if (isValid) {
        setAsset(assetData);
      } else {
        setAsset(walletDeposit[5]);
      }
    }
  }, [params, walletDeposit]);

  // Getting all listed coin - just for first time
  useEffect(() => {
    axios
      .get("asset")
      .then((res) => {
        const depositList = res?.data?.data.map((item) => ({
          coin: item.coin,
          name: item.name,
          value: item.coin,
          label: `${item.coin} ( ${item.name} )`,
        }));

        setWalletDeposit(depositList);
        if (!params?.coin) {
          setAsset(depositList?.[0]);
        }
      })
      .catch((error) => {});
  }, []);

  // Set wallet based on active protocol - when protocol changed
  useEffect(() => {
    if (active?.wallet) {
      setWallet(active.wallet);
    }
  }, [active]);

  // Fetching config of selected asset(coin or fiat) - when asset changed
  useEffect(() => {
    setActive(null);
    setLoading(true);

    if (!asset?.coin) return;

    axios
      .get(`asset/config?type=deposit&coin=${asset.coin}`)
      .then((response) => {
        const data = response?.data?.data;

        setWalletDepositConfig(data);

        if (data?.length > 0) {
          const wallet = data.find((w) => w?.id === active?.id)?.wallet;

          setWallet(wallet);
        }
        setLoading(false);
      })
      .catch((error) => {
        setWalletDepositConfig([]);
        setActive("no protocol");
        setLoading(false);

        setDisable(true);
        setError(t("noWithdrawConfigBecauseOfUpdate"));
      });
  }, [asset]);

  // Submiting form for coins -> getting address
  const getAddress = () => {
    setLoading(true);
    setSuccess(null);
    setError(null);
    axios
      .post(`asset/deposit/${active.id}`)
      .then((response) => {
        const { address, tag = "" } = response?.data?.data;
        setAddress(address);
        setTag(tag);
        // refreshWallet();
        setConfirm(false);

        setSuccess("successDeposit");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const { error: errorData } = error?.response?.data ?? {};
        setError(errorData);
      });
  };

  // Handle network-protocol
  const changeNetwork = (type) => {
    setActive(type);

    // reset form
    setAddress(null);
    setConfirm(null);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, my: 4 }}
      className="container"
    >
      <HeaderRow
        wallet={wallet}
        walletDeposit={walletDeposit}
        asset={asset}
        loading={loading}
        onSelect={onSelect}
      />
      <BodyRow
        wallet={wallet}
        active={active}
        asset={asset}
        loading={loading}
        onSelect={onSelect}
        walletDepositConfig={walletDepositConfig}
        changeNetwork={changeNetwork}
        error={error}
        success={success}
        address={address}
        getAddress={getAddress}
        disable={disable}
        tag={tag}
        confirm={confirm}
        setConfirm={setConfirm}
      />
    </Box>
  );
};

export default Deposit;
