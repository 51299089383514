import {
  SwapHorizOutlined,
  DownloadRounded,
  UploadRounded,
} from "@mui/icons-material";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
// import Swap from "components/Swap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const actions = [
  // { title: "swap", icon: SwapHorizOutlined, content: Swap },
  { title: "deposit", icon: DownloadRounded, content: null, link: "/deposit" },
  { title: "withdraw", icon: UploadRounded, content: null, link: "/withdraw" },
];

const Actions = () => {
  const modal = useSelector((state) => state.app.modal);

  const onClose = () => modal.hide();

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem 0",
      }}
    >
      {actions.map(({ title, icon: Icon, content: Content, link }) => (
        <Grid
          key={title}
          item
          xs={6}
          sm={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            as={!!link ? Link : Box}
            to={!!link ? link : ""}
            onClick={() =>
              !!Content && modal?.show(<Content onClose={onClose} />)
            }
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
              p: "10px",
              textDecoration: "none",
            }}
          >
            <Icon fontSize="large" sx={{ color: "primary.light" }} />
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#111",
              }}
            >
              {t(title)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Actions;
