import {
  HomeOutlined,
  ListAltOutlined
} from "@mui/icons-material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";

export const NAV_ITEMS = [
  {
    title: "home",
    icon: HomeOutlined,
    path: "/",
    needAuth: false,
  },

  {
    title: "Games",
    icon: SportsEsportsOutlinedIcon,
    submenu: [
      {
        title: "Crypto Wars",
        icon: MonetizationOnOutlinedIcon,
        path: "/crypto-wars/play",
        needAuth: false,
      },
      {
        title: "Investment",
        icon: CurrencyExchangeIcon,
        path: "/investment/play",
        needAuth: false,
      },
      // {
      //   title: "Tournaments",
      //   icon: SportsEsportsOutlinedIcon,
      //   path: "/crypto-wars/play",
      //   needAuth: false,
      // },
    ],
    // path: "/crypto-wars",
    needAuth: false,
  },

  {
    title: "community",
    icon: TextsmsOutlinedIcon,
    path: "/community",
    needAuth: false,
    activeRegex: /^\/community+/gi,
  },

  {
    title: "Projects",
    icon: ListAltOutlined,
    submenu: [
      {
        title: "Exchanges",
        path: "/exchanges",
        needAuth: false,
        icon: MonetizationOnOutlinedIcon,
      },
      {
        title: "Listed Projects",
        path: "/cryptos",
        needAuth: false,
        icon: ListAltOutlined,
      },
      {
        title: "NFT Marketplace",
        path: "/crypto-wars/market",
        needAuth: false,
        icon: LocalGroceryStoreOutlinedIcon,
      },
    ],
    needAuth: false,
  },

  {
    title: "Help",
    icon: HelpOutlineOutlinedIcon,
    submenu: [
      {
        title: "xBit Coin",
        path: "/xbit-coin",
        needAuth: false,
        icon: MonetizationOnOutlinedIcon,
      },
      {
        title: "Contact",
        path: "/contact",
        needAuth: false,
        icon: PhoneOutlinedIcon,
      },
      {
        title: "Help",
        path: "/help",
        needAuth: false,
        icon: HelpOutlineOutlinedIcon,
      },
    ],
    needAuth: false,
  },
];
