const ticketEn = {
  ticket: "Ticket",
  tickets: "Tickets",
  newTicket: "New Ticket",
  sendTicket: "Send ticket",
  sendReply: "Send reply",
  enterTitle: "Enter title",
  enterDescription: "Enter description",
  title: "Title",
  description: "Description",
  priority: "Priority",
  department: "Department",
  HIGHT: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  sendingFile: "Sending File",
  uploadFile: "Upload File",
  titleRequired: "Title is a required field",
  descriptionRequired: "Description is a required field",
  singleTicketHeader: "Ticket number #",
  creationTime: "Creation time",
  replies: "Replies",
  enterYourMessage: "Enter your message",
  textRequired: "Message is a required field!",
  noReply: "No Reply",
  files: "Files",
  sort: "Sort",
  maxTicketFile: "Maximum 5MB",
  descriptionToShortMin50: "Too short! Please enter at least 50 characters",
  replyText: "Reply text",
  CLOSED: "Closed",
  PENDING: "Pending",
  REPLIED: "Replied",
  CREATED: "Created",
};

export default ticketEn;
