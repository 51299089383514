import {
  Grid,
  Box,
  Typography,
  Stack,
  Link,
  Divider,
  LinearProgress,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { t } from "locales";
import { useState } from "react";
import { formatPrice, percentChange, percentage } from "library/helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LinkIcon from "@mui/icons-material/Link";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from "@mui/icons-material/People";
import CodeIcon from "@mui/icons-material/Code";
import GitHubIcon from "@mui/icons-material/GitHub";
import ChatIcon from "@mui/icons-material/Chat";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const LinkButton = ({ link, label, icon }) => {
  label = label || link;
  return (
    <Link
      color="textPrimary"
      underline="none"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.background[theme.palette.mode],
        p: "10px 18px",
        m: "5px",
        textAlign: "center",
        borderRadius: "2px",
        height: "42px",
        fontWeight: "600",
        lineHeight: "21px",
        fontSize: "16px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg:nth-child(1)": {
          mr: 0.5,
        },
      }}
    >
      {icon && icon}
      {label ? label : link}
      <OpenInNewIcon sx={{ ml: 0.5 }} fontSize="8px" color="disabled" />
    </Link>
  );
};

const LinksButton = ({ links, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let label, icon;

  if (!links) return null;
  if (typeof links === "string") links = [links];
  let objectLength = Object.keys(links).length;
  let url = new URL(links[0]);

  switch (index) {
    case "websites":
      icon = <LinkIcon />;
      label = objectLength > 1 ? "Websites" : url.hostname;
      break;

    case "explorers":
      icon = <SearchIcon />;
      label = objectLength > 1 ? "Explorers" : "Explorer";
      break;

    case "communities":
      icon = <PeopleIcon />;
      label = objectLength > 1 ? "Communities" : "Community";
      break;

    case "chats":
      icon = <ChatIcon />;
      label = objectLength > 1 ? "Chats" : "Chat";
      break;

    case "sourceCode":
      icon = url.hostname == "github.com" ? <GitHubIcon /> : <CodeIcon />;
      label = "Source Code";
      break;

    case "whitepaper":
      icon = <ReceiptLongIcon />;
      label = "Whitepaper";
      break;
  }

  if (objectLength == 1) {
    return <LinkButton link={links[0]} icon={icon} label={label} />;
  } else if (objectLength > 1) {
    return (
      <>
        <Link
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="textPrimary"
          underline="none"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.background[theme.palette.mode],
            p: "10px 18px",
            m: "5px",
            textAlign: "center",
            borderRadius: "2px",
            height: "42px",
            fontWeight: "600",
            lineHeight: "21px",
            fontSize: "16px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon && icon}
          {label}
          <KeyboardArrowDownIcon fontSize="small" color="disabled" />
        </Link>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& li": {
              backgroundColor: (theme) =>
                theme.palette.background[theme.palette.mode],
              p: "10px 18px",
              m: "5px",
              textAlign: "center",
              borderRadius: "2px",
              height: "42px",
              fontWeight: "600",
              lineHeight: "21px",
              fontSize: "16px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          {links.map((link, index) => (
            <MenuItem key={index} onClick={handleClose}>
              {link}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
  return null;
};

const BottomSummary = ({ details }) => {
  const detailsLinks = {
    websites: details?.websites,
    explorers: details?.explorers,
    communities: details?.communities,
    chats: details?.chats,
    sourceCode: details?.sourceCode,
    whitepaper: details?.whitepaper,
  };
  //   const summaryButtons = {
  //     // website: details.websites[0].url,
  //     // explorer: details.explo
  //   };
  const sectionTitleStyle = {
    fontSize: "12px",
    fontWeight: "500",
  };
  const sectionBodyStyle = {
    fontWeight: "800",
  };
  const percentChangeStyle = {
    fontSize: "12px",
    p: 0,
  };

  const circulatingPercentage = percentage(
    details.totalSupply,
    details.maxSupply
  );

  return (
    <>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Stack sx={{ alignItems: "center" }}>
                {Object.keys(detailsLinks).map((_, index) => (
                  <LinksButton links={detailsLinks[_]} key={index} index={_} />
                ))}
              </Stack>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  "& div": {
                    mb: 3,
                  },
                }}
              >
                <Box>
                  <Typography
                    color="textSecondary"
                    sx={{ ...sectionTitleStyle }}
                  >
                    {t("marketCap")}
                  </Typography>
                  <Typography color="textPrimary" sx={{ ...sectionBodyStyle }}>
                    {formatPrice(details.marketCap)}
                  </Typography>
                  {/* {percentChange(1.2, "mainColor", percentChangeStyle)} */}
                </Box>
                <Box>
                  <Typography
                    color="textSecondary"
                    sx={{ ...sectionTitleStyle }}
                  >
                    {t("fullyDilutedMarketCap")}
                  </Typography>
                  <Typography color="textPrimary" sx={{ ...sectionBodyStyle }}>
                    {formatPrice(details.fdmc)}
                  </Typography>
                  {/* {percentChange(1.2, "mainColor", percentChangeStyle)} */}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mr: -1, display: { md: "inherit", sm: "none", xs: "none" } }}
        />
        <Grid item md={3} sm={12} xs={12}>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              "& div": {
                mb: 3,
              },
            }}
          >
            <Box>
              <Typography color="textSecondary" sx={{ ...sectionTitleStyle }}>
                {t("volume")}
              </Typography>
              <Typography color="textPrimary" sx={{ ...sectionBodyStyle }}>
                {formatPrice(details.volume24h)}
              </Typography>
              {/* {percentChange(1.2, "mainColor", percentChangeStyle)} */}
            </Box>
            <Box>
              <Typography color="textSecondary" sx={{ ...sectionTitleStyle }}>
                {t("volumeMarketCap")}
              </Typography>
              <Typography color="textPrimary" sx={{ ...sectionBodyStyle }}>
                {details.marketCap / details.volume24h}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mr: -1, display: { md: "inherit", sm: "none", xs: "none" } }}
        />
        <Grid item md={3} sm={12} xs={12}>
          <Stack
            sx={{
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "90%", p: 3 }}>
              <Typography color="textSecondary" sx={{ ...sectionTitleStyle }}>
                {t("circulatingSupply")}
              </Typography>
              <Box>
                <Typography
                  component="span"
                  color="textPrimary"
                  sx={{ ...sectionBodyStyle }}
                >
                  {formatPrice(details.circulatingSupply, ...[, ,], false)}
                </Typography>
                <Typography
                  component="span"
                  color="textSecondary"
                  sx={{ float: "right" }}
                >
                  {circulatingPercentage}%
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={circulatingPercentage}
              />
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                width: "90%",
                p: "0 20px",
                justifyContent: "space-between",
                alignContent: "space-between",
              }}
            >
              <Typography color="textSecondary" sx={{ fontSize: "12px" }}>
                {t("maxSupply")}
              </Typography>
              <Typography
                color="textPrimary"
                sx={{ fontSize: "12px", fontWeight: "800" }}
              >
                {formatPrice(details.maxSupply, ...[, ,], false)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                width: "90%",
                p: "0 20px",
                justifyContent: "space-between",
                alignContent: "space-between",
              }}
            >
              <Typography color="textSecondary" sx={{ fontSize: "12px" }}>
                {t("totalSupply")}
              </Typography>
              <Typography
                color="textPrimary"
                sx={{ fontSize: "12px", fontWeight: "800" }}
              >
                {formatPrice(details.totalSupply, ...[, ,], false)}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3 }} />
    </>
  );
};

export default BottomSummary;
