import { Box } from "@mui/system";
import Header from "./Header";
import PrizesList from "./PrizesList";

function SoccerPlayPrizeDetail({ returnToAllPrize, data }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Header
        returnToAllPrize={returnToAllPrize}
        title={data?.title}
        participants={data?.participants}
        id={data?.id}
        image={data?.image}
      />
      <PrizesList
        list={data?.prizes}
        coin={data?.asset?.coin}
        coinIcon={data?.asset?.icon}
      />
      {/* <Participation /> */}
    </Box>
  );
}

export default SoccerPlayPrizeDetail;
