import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import Header from "./Header";
import Sidebar from "./Sidebar";
import History from "./History";
import bgImg from "assets/img/dashboard-bg.jpg";
import axios from "library/http";
import { useEffect, useState } from "react";

const Invite = () => {
  const [referralData, setReferralData] = useState({});

  useEffect(() => {
    axios
      .get("user/referral")
      .then((res) => {
        setReferralData(res.data?.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 5 }}>
      <Box
        sx={{
          background: `url(${bgImg}) rgba(0, 0, 0, 0.05)`,
          backgroundBlendMode: "multiply",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />
      </Box>
      <Box className="container">
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} md={7.9} sx={{ mb: { xs: 2, md: 0 } }}>
            <History referralData={referralData} />
          </Grid>
          <Grid item xs={12} md={3.9}>
            <Sidebar prizes={referralData?.prize} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Invite;
