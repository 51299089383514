import React from "react";
import { useLocation } from "react-router-dom";
import { COMMON_SIDEBAR, SOCIAL_LINKS } from "./mainSidebar.config";
import MainSidebarContent from "./MainSidebarContent";

function MainSidebar() {
  const { pathname } = useLocation();
  // const currentItems =
  // SIDEBARS.find((item) => !!pathname.match(item.regex))?.items ||
  // COMMON_SIDEBAR;

  const currentItems = COMMON_SIDEBAR[0]?.items;

  // return pathname == "/sas" ? (
  //   false
  // ) : (
  return <MainSidebarContent {...currentItems} socialLinks={SOCIAL_LINKS} />;
  // );
}

export default MainSidebar;
