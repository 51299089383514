import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login, updateUserData, updateWallets } from "redux/auth/asyncActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginWithEmailValidation } from "validations/authValidation";
import { useEffect } from "react";
import EmailTab from "./EmailTab";
import { setAuthData } from "redux/auth/authSlice";
import { t } from "locales";
import _ from "lodash";
import ErrorAlert from "components/ErrorAlert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthLayout from "components/AuthLayout";

const Login = () => {
  const dispatch = useDispatch();
  const { loginError, status } = useSelector((state) => state.auth);
  const resolver = yupResolver(loginWithEmailValidation);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver,
  });

  const onSubmit = async (data) => {
    try {
      const gToken = await executeRecaptcha();
      const typeName = "email";
      const loginData = {
        [typeName]: data[typeName],
        password: data?.password,
        gRecaptchaResponse: gToken,
      };
      dispatch(setAuthData(loginData));
      await dispatch(login(loginData));

      if (status === "fulfilled-login") await dispatch(updateUserData());
    } catch (error) {
      console.error(error?.response);
    }
  };

  useEffect(() => {
    if (status === "fulfilled-login" && !loginError) {
      dispatch(updateUserData());
      dispatch(updateWallets());
    }
  }, [status]);

  return (
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "3px #720804 solid",
            borderRadius: "10px",
            padding: "30px 10px",
            // background: "#f1f0f5",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("signIn")}
            </Typography>
          </Box>

          <Box sx={{ width: "100%", pt: 3, pr: 3, pl: 3 }}>
            {loginError && <ErrorAlert text={loginError} />}
          </Box>
          <EmailTab
            onSubmit={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            loading={isSubmitting}
            disabled={!_.isEmpty(errors, true)}
          />
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default Login;
