import { Box } from "@mui/system";
import Filters from "./Filters";
import AuctionBody from "./AuctionBody";
import AuctionHeader from "./AuctionHeader";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import {
  useGetAuctionList,
  useGetBundleAuctionList,
} from "_hooks/CryptoWars/market";
import { useAuction } from "_hooks/Socket/auction";

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isCardActive =
    !searchParams.get("saleType") || searchParams.get("saleType") === "cards";
  const [page, setPage] = useState(1);
  const {
    isLoading: loading,
    data,
    refetch,
  } = (isCardActive ? useGetAuctionList : useGetBundleAuctionList)({
    page,
    cardTypeId: searchParams.get("type") || undefined,
    position: searchParams.get("position") || undefined,
    max: searchParams.get("max-price") || undefined,
    min: searchParams.get("min-price") || undefined,
    sort: searchParams.get("sort") || undefined,
    order: searchParams.get("order") || undefined,
    assetName: searchParams.get("name") || undefined,
  });
  const [list, setList] = useAuction(refetch, [], isCardActive);

  useEffect(() => {
    setList(data?.list || []);
  }, [data?.list, setList]);

  const clearAllFilters = () => setSearchParams({});
  const onPageChange = (_, value) => setPage(value);

  return (
    <Box sx={{ pb: 5 }}>
      <AuctionHeader />
      <Box
        className="container"
        sx={{ paddingTop: 3, display: "flex", gap: 3 }}
      >
        <Filters
          onClear={clearAllFilters}
          style={{ display: { xs: "none", md: "flex" } }}
        />
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 3, flexGrow: 1 }}
        >
          <AuctionBody cards={list || []} loading={loading} />
          <Box sx={{ mx: "auto" }}>
            <Pagination
              sx={{ width: "fit-content" }}
              count={data?.total || 1}
              page={page}
              onChange={onPageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
