import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";

const Header = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        {t("topCoins")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          borderBottom: "1px solid",
          borderColor: (theme) => `border.${theme.palette.mode}`,
        }}
      ></Box>
    </Box>
  );
};

export default Header;
