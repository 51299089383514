import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import champion from "assets/img/champion.png";
import stars from "assets/img/stars.png";
import under50 from "assets/img/under50.png";
import { t } from "locales";

const leagues = [
  {
    type: "leagueInfoItem1Type",
    title: "leagueInfoItem1Title",
    image: stars,
    description: "leagueInfoItem1Desc",
  },
  {
    type: "leagueInfoItem2Type",
    title: "leagueInfoItem2Title",
    image: under50,
    description: "leagueInfoItem2Desc",
  },
  {
    type: "leagueInfoItem3Type",
    title: "leagueInfoItem3Title",
    image: champion,
    description: "leagueInfoItem3Desc",
  },
];

const Info = ({ goNextStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: {
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          {t("leagueInfoTitle")}
        </Typography>
        <Typography variant="body1">{t("leagueInfoDesc")}</Typography>
      </Box>
      <Box sx={{ px: "20px", maxWidth: "1000px", mx: "auto" }}>
        <Grid container spacing={3}>
          {leagues?.map?.(({ type, title, image, description }) => (
            <Grid key={title} item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "cetner",
                  justifyContent: "center",
                  gap: 1,
                  padding: "20px",
                  height: "100%",
                  borderRadius: "8px",
                  bgcolor: (theme) => `card.${theme.palette.mode}`,
                  img: {
                    width: "80px",
                    height: "80px",
                    borderRadius: "4px",
                    mx: "auto",
                  },
                  p: {
                    textAlign: "center",
                  },
                }}
              >
                <Box>
                  <Typography variant="body2" sx={{ opacity: ".65" }}>
                    {t(type)}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "500" }}>
                    {t(title)}
                  </Typography>
                </Box>
                <img src={image} alt="" />
                <Typography variant="body2">{t(description)}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        onClick={goNextStep}
        sx={(theme) => ({
          backgroundImage: theme.palette.gradient.purpule,
          color: theme.palette.common.white,
          width: "fit-content",
          padding: "10px 30px",
          fontSize: "1rem",
          mx: "auto",
        })}
      >
        {t("next")}
      </Button>
    </Box>
  );
};

export default Info;
