import { Box, Typography } from "@mui/material";
import axios from "library/http";
import { useEffect, useState } from "react";
import { SkeletonLoader } from "components/Skeleton";
import { MarketsList } from "components/MarketsList";
import { useSelector } from "react-redux";

const MarketsTab = ({ coinID }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [loading, setLoading] = useState(true);
  const [markets, setMarkets] = useState([]);
  const [hasMore, setHasMore] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  const marketsList = async () => {
    try {
      const result = await axios.get("public/markets", {
        params: { cryptoId: coinID, page: page, limit: limit },
      });
      if (result.data?.status == "success") {
        setMarkets([...markets, ...result.data.data.data]);
        setPage(page + 1);

        // Check if there is more data
        setHasMore(result.data.data.total > limit * page);

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    marketsList();
  }, []);

  return (
    <Box>
      {loading ? (
        Array(10)
          .fill(1)
          .map((_, i) => (
            <SkeletonLoader
              isLight={isLight}
              width="100%"
              height="72"
              children="5"
              key={i}
            />
          ))
      ) : (
        <Typography variant="h4">List of Markets</Typography>
      )}

      <Typography variant="h4">Markets</Typography>
      <MarketsList
        markets={markets}
        fetchMoreData={marketsList}
        hasMore={hasMore}
      />
    </Box>
  );
};

export default MarketsTab;
