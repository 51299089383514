import Box from "@mui/material/Box";
import { CircularProgress, Pagination, Typography } from "@mui/material";
import { t } from "locales";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DetailModal from "./DetailModal";
import defaultAvatar from "assets/img/default-profile.png";
import {
  calcTotalPages,
  getImageUrl,
  truncateToDecimals,
} from "library/helper";
import Dropdown from "components/Dropdown";
import rank1 from "assets/img/rank1.jpg";
import rank2 from "assets/img/rank2.jpg";
import rank3 from "assets/img/rank3.jpg";
import { useGetLeaderBoard } from "_hooks/Investment/competitions";

const LIMIT = 10;
const RANKS = [rank1, rank2, rank3];

const List = ({ competitionId, types }) => {
  const modal = useSelector((state) => state.app.modal);
  const [searchParams] = useSearchParams();
  const [selectedLeague, setSelectedLeague] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const activeType = types?.find(
    (item) => item.id === searchParams.get("type")
  );
  const {
    isLoading: loading,
    data: resData,
    refetch,
  } = useGetLeaderBoard({
    id: competitionId,
    leagueId: selectedLeague?.id,
    page,
    limit: LIMIT,
  });
  const openDetailModal = (userId, competitionLeagueId) => {
    modal.show(
      <DetailModal
        onClose={() => modal.hide()}
        competitionLeagueId={competitionLeagueId}
        userId={userId}
      />
    );
  };

  useEffect(() => {
    if (!competitionId || !selectedLeague?.id) return;
    refetch({
      id: competitionId,
      leagueId: selectedLeague?.id,
      page,
      limit: LIMIT,
    });
  }, [selectedLeague, competitionId, page]);
  useEffect(() => {
    setTotal(calcTotalPages(resData?.data?.data?.total, LIMIT));
  }, [resData]);
  useEffect(() => {
    setSelectedLeague(activeType?.leagues?.[0]);
    setPage(1);
  }, [activeType]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box>
      {activeType && (
        <Box sx={{ display: "flex", mb: 3 }}>
          <Dropdown
            options={activeType?.leagues ?? []}
            value={selectedLeague}
            onChange={(newValue) => setSelectedLeague(newValue)}
          />
        </Box>
      )}
      <Box
        sx={{
          border: "1px solid",
          borderColor: (theme) => `border.${theme.palette.mode}`,
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {resData?.data?.data?.data?.map(
          ({ userId, user, balance: score, competitionLeagueId }, index) => {
            const rank = index + 1 + (page - 1) * LIMIT;
            const rankBg = RANKS?.[rank - 1];

            return (
              <Box
                key={userId}
                onClick={() => openDetailModal(userId, competitionLeagueId)}
                sx={[
                  {
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  },
                  !!rankBg
                    ? {
                        backgroundImage: `url(${rankBg})`,
                        backgroundSize: "cover",
                        height: "100px",
                        color: "white",
                        ".rank": {
                          fontSize: "20px",
                        },
                        ".score": {
                          bgcolor: "#0D0C11",
                          position: "relative",
                          "& > p:before": {
                            top: "0",
                            left: "-20px",
                            content: "''",
                            position: "absolute",
                            borderLeft: "solid 20px transparent",
                            borderBottom: "solid 100px #0D0C11",
                          },
                        },
                        img: {
                          width: "60px",
                          height: "60px",
                          borderRadius: "8px",
                        },
                      }
                    : {
                        "&:not(:last-child)": {
                          borderBottom: "1px solid",
                          borderColor: (theme) =>
                            `border.${theme.palette.mode}`,
                        },
                        img: {
                          width: "40px",
                          height: "40px",
                          borderRadius: "8px",
                        },
                      },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    p: "15px 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    className="rank"
                    sx={{ fontWeight: "bold" }}
                  >
                    {rank}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img
                      src={getImageUrl(user?.avatar) ?? defaultAvatar}
                      alt=""
                    />
                    <Typography variant="h6" sx={{ fontWeight: "500" }}>
                      {user?.name || "Unknown"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="score"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      px: "20px",
                    }}
                  >
                    {truncateToDecimals(score, 2) || 0} pts
                  </Typography>
                </Box>
              </Box>
            );
          }
        )}
        {!resData?.data?.data?.data?.length && !loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px",
            }}
          >
            <Typography variant="body1">{t("noData")}</Typography>
          </Box>
        )}
      </Box>
      {total > 1 && !!resData?.data?.data?.data?.length && (
        <Box>
          <Pagination
            sx={{ width: "fit-content", mx: "auto", mt: 3 }}
            count={total}
            page={page}
            onChange={handleChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default List;
