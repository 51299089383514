import { Box, Breadcrumbs, Typography } from "@mui/material";
import { t } from "locales";
import React from "react";


const Breadcrumb = ({ name }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Breadcrumbs aria-label="breadcrumb" separator="»">
        <Typography variant="body1" color="inherit">
          {t("listedprojects")}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {name}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
