import { Box } from "@mui/system";
import { Tab, Tabs } from "@mui/material";
import { t } from "locales";
import { useState, useEffect } from "react";
import DashboardOverview from "./Overview";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import DashboardCards from "./Cards";
import Setting from "./Setting";
import Ticket from "./Ticket";
import SingleTicket from "./Ticket/SingleTicket";

const ROUTES_WITH_TAB = [
  {
    title: "overview",
    id: "overview",
    path: "/dashboard/overview",
    component: DashboardOverview,
    index: 0,
  },
  {
    title: "cards",
    id: "cards",
    path: "/dashboard/cards",
    component: DashboardCards,
    index: 1,
  },
  {
    title: "setting",
    id: "setting",
    path: "/dashboard/setting?tab=profile",
    component: Setting,
    index: 2,
  },
];

const ROUTES_NO_TAB = [
  {
    path: "/ticket/:id",
    component: SingleTicket,
    index: 2,
  },
];

const DashboardBody = () => {
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const index = ROUTES_WITH_TAB?.findIndex((tab) =>
      pathname.includes(tab?.id)
    );
    if (index !== -1) setTab(index);
  }, [pathname]);

  const onTabChange = (_, newValue) => {
    setTab(newValue);
    navigate(ROUTES_WITH_TAB?.[newValue]?.path);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
        }}
      >
        <Tabs value={tab} onChange={onTabChange} className="container">
          {ROUTES_WITH_TAB?.map(({ title, id }) => (
            <Tab key={id} label={t(title)} id={id} aria-controls={title} />
          ))}
        </Tabs>
      </Box>

      <Box className="container">
        <Routes>
          {ROUTES_WITH_TAB.map(({ exact, component: Component, id, path }) => {
            return (
              <Route
                key={id}
                exact={exact}
                path={`/${id}`}
                element={<Component />}
              />
            );
          })}
          {ROUTES_NO_TAB.map(({ component: Component, path }) => {
            return <Route key={path} path={path} element={<Component />} />;
          })}
        </Routes>
      </Box>
    </Box>
  );
};

export default DashboardBody;
