import React, { useState } from "react";
import QRCode from "qrcode.react";
import Clipboard from "react-clipboard.js";
import { Box, styled } from "@mui/system";
import { ContentCopy } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";

const ClipboardCustom = styled(Clipboard)({
  display: "flex",
  cursor: "pointer",
});

function Qrcode({ inofText = null, text, qr = null, extraSx = {} }) {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        aliginItems: "center",
        justifyContent: "center",
        gap: 3,
        ...extraSx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          canvas: {
            borderRadius: "0.5rem",
            border: "5px solid #fff",
          },
        }}
      >
        {text && <QRCode value={qr ?? text} level="Q" size={180} />}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid",
          borderColor: (theme) => theme.palette.mode === "dark" && "#555",
          borderRadius: "10px",
          "& > *": {
            p: "8px 10px",
          },
        }}
      >
        <Typography
          component="span"
          variant="body2"
          sx={{
            flexGrow: 1,
            borderRight: "1px solid",
            borderColor: (theme) => theme.palette.mode === "dark" && "#555",
            bgcolor: (theme) => theme.palette.mode === "dark" && "#555",
          }}
        >
          {text}
        </Typography>
        <ClipboardCustom
          component="span"
          data-clipboard-text={text}
          onSuccess={onCopy}
        >
          <Tooltip title={copied ? "Copied" : "Copy to clipboard"} arrow>
            <ContentCopy sx={{ fontSize: 20 }} />
          </Tooltip>
        </ClipboardCustom>
      </Box>
    </Box>
  );
}

export default Qrcode;
