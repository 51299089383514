import Avatar from "react-avatar-edit";
import { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { openToast } from "components/Toast";
import { t } from "locales";
import { useSelector } from "react-redux";

const AvatarImageCropper = (props) => {
  const { imageSrc, createImage, closeModal, minSize } = props;
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [preview, setPreview] = useState();

  const onClose = () => setPreview(null);
  const onCrop = (preview) => setPreview(preview);

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > minSize) {
      openToast("warning", "Maximum supported file size is 1MB!");
      elem.target.value = "";
    }
  };
  return (
    <Box p={2}>
      <Avatar
        width={320}
        height={295}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        src={imageSrc}
        labelStyle={{ color: isLight ? "#333" : "#eee" }}
      />
      <Stack direction="row" justifyContent="center" mt={2}>
        <Box mr={1} ml={1}>
          <Button onClick={closeModal} color="primary" variant="outlined">
            {t("cancelButton")}
          </Button>
        </Box>
        <Button
          onClick={() => createImage(preview)}
          color="primary"
          variant="contained"
        >
          {t("cropButton")}
        </Button>
      </Stack>
    </Box>
  );
};

export default AvatarImageCropper;
