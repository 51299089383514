import { SocketContext } from "context/socket/socket";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWalletsFromSocket } from "redux/auth/asyncActions";
import { setNotifications } from "redux/auth/authSlice";
import axios from "library/http";

const UserSocket = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();

  // Fetch unread notifications for first time
  useEffect(() => {
    if (!accessToken) return;

    axios
      .get("user/notifications", {
        params: {
          limit: 500,
          type: "private",
          status: false,
        },
      })
      .then((res) => {
        dispatch(setNotifications(res?.data?.data?.data || []));
      })
      .catch((error) => {
        dispatch(setNotifications([]));
      });
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken) return;

    socket.setListener("wallet", (data) => {
      if (!!data) {
        const wallet = JSON.parse(data);

        dispatch(updateWalletsFromSocket(wallet));
      }
    });

    socket.setListener("notification", (data) => {
      if (!!data) {
        const notifications = JSON.parse(data);

        dispatch(setNotifications(notifications));
      }
    });

    return () => {
      socket.removeListener?.("wallet");
      socket.removeListener?.("notification");
    };
  }, [accessToken]);

  return null;
};

export default UserSocket;
