import { Box } from "@mui/system";
import { t } from "locales";
import { Link, useSearchParams } from "react-router-dom";
import { ButtonBase, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { useSelector } from "react-redux";
import MenuModal from "./MenuModal";

const SecondaryTopbar = ({ menu, findActiveCallback }) => {
  const [searchParams] = useSearchParams();
  const modal = useSelector((state) => state.app.modal);
  const { pathname } = useLocation();
  const [active, setActive] = useState(() =>
    menu?.find?.(({ activePath, link }) =>
      findActiveCallback
        ? findActiveCallback(activePath || link)
        : pathname?.includes(activePath || link)
    )
  );

  const openMenuModal = () => {
    modal.show(
      <MenuModal menu={menu} closeModal={modal.hide} active={active} />
    );
  };

  useEffect(() => {
    setActive(
      menu?.find?.(({ activePath, link }) =>
        findActiveCallback
          ? findActiveCallback(activePath || link)
          : pathname?.includes(activePath || link)
      )
    );
  }, [pathname, searchParams]);

  useEffect(() => {
    modal?.hide?.();
  }, [pathname, modal, searchParams]);

  return (
    <Box
      onClick={openMenuModal}
      sx={{
        display: { xs: "flex", sm: "none" },
        gap: 1,
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Box
        as={Link}
        to={active?.link ?? ""}
        className="item"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,

          color: "#eee",
          textDecoration: "none",
          flexGrow: 1,
        }}
      >
        <Box
          sx={[
            {
              width: "32px",
              height: "32px",
              display: "flex",
              padding: "10px",
              alignItems: "center",
              borderRadius: "12px",
              justifyContent: "center",
              bgcolor: ({ palette }) =>
                palette.mode === "dark"
                  ? palette.primary.main
                  : palette.primary.dark,
            },
          ]}
        >
          {active?.icon && <active.icon />}
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: ({ palette }) =>
              palette.mode === "dark" ? "common.white" : "common.black",
          }}
        >
          {t(active?.title)}
        </Typography>
      </Box>
      <ButtonBase
        sx={{
          width: "38px",
          height: "38px",
          borderRadius: "6px",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
        }}
      >
        <ArrowDropDown />
      </ButtonBase>
    </Box>
  );
};

export default SecondaryTopbar;
