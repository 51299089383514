import { useQuery } from "react-query";
import ApiCall from "_clients/apiCall";

export const useCheckCardsForCompetition = ({
  minimumCards = 0,
  status,
  ...params
}) => {
  return useQuery(
    ["check-user-cards", params],
    () =>
      ApiCall("GET", "/user/check-card", null, params, (res) => ({
        allowToCompete: (res.data?.data?.count || 0) >= minimumCards,
        requirement: `${res.data?.data?.count || 0}/${minimumCards}`,
      })),
    { enabled: !!params?.id && !!params.type && status === "OPEN" }
  );
};
