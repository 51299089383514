const DiscordIcon = ({ color = "gray" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.125 2.59844C18.1201 2.0845 17.9128 1.59323 17.548 1.23112C17.1833 0.869017 16.6905 0.665272 16.1766 0.664062L3.81406 0.625C3.30063 0.625102 2.80819 0.828785 2.44471 1.19139C2.08122 1.554 1.87634 2.04595 1.875 2.55937V15.3125C1.875 16.3789 2.74531 17.1875 3.81406 17.1875H14.375L13.8672 15.4688L18.125 19.375V2.59844ZM12.6816 12.8832C12.6816 12.8832 12.341 12.4773 12.0566 12.1285C13.2965 11.7793 13.7695 11.0156 13.7695 11.0156C13.429 11.2415 13.0641 11.4282 12.6816 11.5723C12.2401 11.7556 11.7804 11.8916 11.3102 11.9781C10.5 12.1269 9.66918 12.1238 8.86016 11.9687C8.38428 11.8804 7.91851 11.7443 7.46992 11.5625C7.23261 11.4716 7.00186 11.3645 6.7793 11.2418C6.75078 11.223 6.72266 11.2137 6.69414 11.1945C6.675 11.1852 6.66562 11.1758 6.65508 11.1758C6.48477 11.0813 6.39023 11.0152 6.39023 11.0152C6.39023 11.0152 6.84414 11.7609 8.0457 12.1191C7.76172 12.4777 7.41172 12.893 7.41172 12.893C5.32148 12.827 4.52695 11.4684 4.52695 11.4684C4.52695 8.4582 5.88906 6.01445 5.88906 6.01445C7.25117 5.00469 8.5375 5.0332 8.5375 5.0332L8.63203 5.14648C6.9293 5.62773 6.15391 6.37305 6.15391 6.37305C6.15391 6.37305 6.36172 6.25977 6.71172 6.10859C7.72383 5.66523 8.52813 5.55195 8.86016 5.51445C8.91315 5.50344 8.967 5.49703 9.02109 5.49531C9.65493 5.41307 10.2963 5.40677 10.9316 5.47656C11.9292 5.59115 12.8956 5.89545 13.7789 6.37305C13.7789 6.37305 13.0316 5.66406 11.4234 5.18359L11.5559 5.03281C11.5559 5.03281 12.8516 5.0043 14.2047 6.01562C14.2047 6.01562 15.5668 8.45937 15.5668 11.4695C15.5668 11.4586 14.7719 12.8172 12.6816 12.8832V12.8832Z"
      fill={color}
    />
    <path
      d="M8.2832 8.51562C7.74414 8.51562 7.31836 8.97813 7.31836 9.55352C7.31836 10.1289 7.75352 10.5914 8.2832 10.5914C8.82227 10.5914 9.24805 10.1293 9.24805 9.55352C9.25781 8.97695 8.82227 8.51562 8.2832 8.51562ZM11.7355 8.51562C11.1965 8.51562 10.7707 8.97813 10.7707 9.55352C10.7707 10.1289 11.2059 10.5914 11.7355 10.5914C12.275 10.5914 12.7004 10.1293 12.7004 9.55352C12.7004 8.97773 12.2656 8.51562 11.7355 8.51562Z"
      fill={color}
    />
  </svg>
);

export default DiscordIcon;
