const validationTr = {
  emailRequired: "E-posta zorunlu bir alandır",
  emailNotValid: "E-posta Biçimi Doğru Değil",
  mobileRequired: "Mobil doldurulması zorunlu bir alandır",
  mobileNotValid: "Mobil geçerli değil",
  passwordRequired: "Parola gerekli bir alandır",
  passwordNotValid:
    "Şifre harf, rakam içermeli ve en az 8 karakter uzunluğunda olmalıdır",
  passwordConfirmationRequired: "Parola onayı zorunlu bir alandır",
  passwordConfirmationNotMatch: "Girilen şifre, şifre tekrarından farklı",
  nameRequired: "Ad zorunlu bir alandır",
  termNotChecked: "Şartlar ve Koşullar zorunlu bir alandır",
  codeRequired: "Lütfen Kodu Doğrulayın",
  codeMin: "Kod 4 sayı olmalıdır",
  code_type: "Kod bir sayı olmalıdır",
  codeType: "Kod bir sayı olmalıdır",
  requiredField: "Bu alan zorunludur!",
  insufficientWalletAmount: "Yetersiz miktar!",
  invalidTimeFormat: "Lütfen geçerli bir tarih girin!",
  mustBeNumber: "Bir sayı belirtmelisiniz!",

  minimumTransferFromVolex: "Volex'i transfer etmek için minimum miktar 1 VLX",
};

export default validationTr;
