import {
  AttachMoney,
  DeleteOutlined,
  Person,
  Style,
} from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import bgImage from "assets/img/comp-bnr-small.jpg";
import { openToast } from "components/Toast";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import { Link } from "react-router-dom";
import { useDeleteCompetition } from "_hooks/Investment/competitions";

const ListItem = ({
  image,
  id,
  title,
  asset,
  participants = 0,
  entranceFee,
  minimumCard,
  isExist,
  status,
  competitionId,
}) => {
  const mutationDeleteCompetition = useDeleteCompetition();
  const loading = mutationDeleteCompetition.isLoading;
  const deleteTeamHandler = async () => {
    if (!isExist) return;
    try {
      const result = await mutationDeleteCompetition.mutateAsync(id);
      if (result.status === 200) {
        openToast("success", "Your team deleted successfully.");
      }
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "70px",
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: "20px",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            img: {
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "8px",
            },
          }}
        >
          {image?.[0] && <img src={getImageUrl(image)} alt="" />}
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Style fontSize="small" />
            <Typography variant="caption">{t("minimumCard")}: </Typography>
            <Typography variant="caption">{minimumCard}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <AttachMoney fontSize="small" />
            <Typography variant="caption">{t("entranceFee")}: </Typography>
            <Typography variant="caption">
              {entranceFee} {asset?.coin}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Person fontSize="small" />
            <Typography variant="caption">
              {participants} {t("managersEntered")}
            </Typography>
          </Box>
        </Box>

        {isExist && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {status === "OPEN" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                <ButtonBase
                  disabled={loading}
                  onClick={deleteTeamHandler}
                  sx={{
                    height: "40px",
                    p: "0 10px",
                    borderRadius: "7px",
                    border: `1px solid`,
                    borderColor: (theme) => `border.${theme.palette.mode}`,
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteOutlined />
                  )}
                </ButtonBase>
                {/* <ButtonBase
                    sx={{
                      height: "40px",
                      p: "0 20px",
                      borderRadius: "7px",
                      bgcolor: "primary.main",
                      color: "common.white",
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontWeight: "500" }}
                    >
                      {t("edit")}
                    </Typography>
                  </ButtonBase> */}
              </Box>
            ) : (
              <Typography
                component="span"
                variant="body1"
                sx={{ color: "primary.main" }}
              >
                {t("registered")}
              </Typography>
            )}
          </Box>
        )}

        {!isExist && status === "OPEN" && (
          <Button
            // as={Link}
            // to={`/investment/select-team/${id}-${competitionId}`}
            href={`/investment/select-team/${id}-${competitionId}`}
            color="primary"
            variant="outlined"
            sx={(theme) => ({
              textDecoration: "none",
              textAlign: "center",
              color:
                theme.palette.mode === "light"
                  ? "primary.main"
                  : "primary.light",
              padding: "7px 20px",
              transition: "background-color 250ms",
              width: "100%",
              mt: "auto",

              "&:hover": {
                backgroundColor: "primary.main",
                color: "common.white",
              },
            })}
          >
            {t("enterATeam")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ListItem;
