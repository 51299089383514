import { Route, Routes } from "react-router";
import { PrivateRoute } from "./CustomRoutes";
import InvestmentPlayMain from "routes/Investment/Gameplay/SingleCompetition";
import InvestmentMain from "routes/Investment/Main";
import GameplayInvestment from "routes/Investment/Gameplay/Team/index";
import InvestmentLeagueWizard from "routes/Investment/Wizard/League";
import InvestmentCardWizard from "routes/Investment/Wizard/Player";
import InvestmentHowToPlay from "routes/Investment/HowToPlay";
import CryptoWarPlayCompetitions from "routes/Investment/Gameplay/SingleCompetition/Competitions";
import InvestmentPlayOverview from "routes/Investment/Gameplay/SingleCompetition/Overview";
import InvestmentPlayPrize from "routes/Investment/Gameplay/SingleCompetition/Prize";
import InvestmentLeaderboards from "routes/Investment/Gameplay/SingleCompetition/Leaderboards";
import InvestmentEvents from "routes/Investment/Competitions";

export const investmentPublicRoutes = [
  {
    path: "/investment",
    component: InvestmentMain,
    exact: true,
  },
  {
    path: "/investment/play",
    component: InvestmentEvents,
    exact: true,
    sidebar: true,
    banner: true,
  },
  // {
  //   path: "/investment/how-to-play",
  //   component: InvestmentHowToPlay,
  //   exact: true,
  // },
];

export const investmentPrivateRoutes = [
  {
    path: "/investment/select-team/:leagueId_competitionId",
    component: GameplayInvestment,
    exact: true,
  },
  {
    path: "/investment/wizard/league",
    component: InvestmentLeagueWizard,
    exact: true,
  },
  {
    path: "/investment/wizard/player",
    component: InvestmentCardWizard,
    exact: true,
  },
];

export const investmentPlayRoutes = [
  {
    path: "/investment/play/:competitionId/competitions",
    component: CryptoWarPlayCompetitions,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/investment/play/:competitionId/overview",
    component: InvestmentPlayOverview,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/investment/play/:competitionId/prize",
    component: InvestmentPlayPrize,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/investment/play/:competitionId/leaderboards",
    component: InvestmentLeaderboards,
    exact: true,
    sidebar: true,
    banner: true,
  },
];

export const InvestmentPlayRoutes = () => (
  <Routes>
    {investmentPlayRoutes.map(
      ({ exact, path, component: Component, sidebar, banner }, i) => {
        return (
          <Route
            key={i}
            exact={exact}
            path={path}
            element={
              <InvestmentPlayMain sidebar={sidebar} banner={banner}>
                <Component />
              </InvestmentPlayMain>
            }
          />
        );
      }
    )}
  </Routes>
);

export const InvestmentPublicRoutes = () => (
  <Routes>
    {investmentPublicRoutes.map(({ exact, path, component: Component }, i) => {
      return (
        <Route key={i} exact={exact} path={path} element={<Component />} />
      );
    })}
  </Routes>
);

export const InvestmentPrivateRoutes = () => (
  <Routes>
    {investmentPrivateRoutes.map(({ component: Component, path }, i) => (
      <Route
        key={i}
        path={path}
        element={
          <PrivateRoute>
            <Component />
          </PrivateRoute>
        }
      />
    ))}
  </Routes>
);
