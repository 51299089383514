import { Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import ListItem from "./ListItem";
import { useGetOverView } from "_hooks/CryptoWars/competitions";

const List = ({ competitionId }) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: loading,
  } = useGetOverView(competitionId);

  if (loading) {
    return (
      <Box
        sx={{
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", pb: 2 }}>
      <Box
        sx={{
          my: 2,
          // "& > * + *": {
          //   borderTop: (theme) =>
          //     theme.palette.mode === "dark"
          //       ? "1px solid #2a2e34"
          //       : "1px solid #eee",
          // },
        }}
      >
        {!data?.pages?.[0]?.total && !loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px",
            }}
          >
            <Typography variant="body1">{t("noData")}</Typography>
          </Box>
        )}
        {data?.pages?.map((group) =>
          group?.list?.map?.((item, i) => <ListItem item={item} key={i} />)
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        {isFetchingNextPage ? (
          <CircularProgress size={25} />
        ) : hasNextPage ? (
          <Typography
            onClick={fetchNextPage}
            variant="body2"
            sx={{ cursor: "pointer" }}
          >
            {t("loadMore")}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default List;
