import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { forgotPassword } from "redux/auth/asyncActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordWithEmailValidation } from "validations/authValidation";
import EmailTab from "./EmailTab";
import { useNavigate } from "react-router-dom";
import { setAuthData } from "redux/auth/authSlice";
import { t } from "locales";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthLayout from "components/AuthLayout";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resolver = yupResolver(forgotPasswordWithEmailValidation);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "" },
    resolver,
  });

  const onSubmit = async (data) => {
    try {
      const gToken = await executeRecaptcha();
      const typeName = "email";

      const requestData = {
        [typeName]: data[typeName],
        gRecaptchaResponse: gToken,
      };

      dispatch(setAuthData(requestData));
      await dispatch(forgotPassword(requestData));

      navigate("/auth/verify");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("forgotPassword")}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              marginTop: 2,
            }}
          >
            <EmailTab
              onSubmit={handleSubmit(onSubmit)}
              control={control}
              errors={errors}
              loading={isSubmitting}
            />
          </Box>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPassword;
