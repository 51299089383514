import { Typography, Box, Stack, Button, Link } from "@mui/material";
import { styled } from "@mui/system";
import bgImg from "assets/img/dashboard-bg.jpg";
import { t } from "locales";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useLocation } from "react-router-dom";
import { SITE_URL } from "config/Config";

const MainContainer = styled("div")({
  minHeight: "150px",
  backgroundImage: `url(${bgImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  marginBottom: "1rem",
});

const Header = ({ active }) => {
  let location = useLocation();

  return (
    <MainContainer>
      <Box
        className="container"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          height: "fit-content",
          marginLeft: "2em",
          maxWidth: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "white",
            display: "flex",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <MarkChatUnreadOutlinedIcon sx={{ pr: 1, fontSize: "1.3em" }} />
          {t("community")}
        </Typography>

        <Stack
          direction="row"
          sx={{
            width: "fit-content",
            "& .header-menu-button": {
              border: "1px solid #fff",
              color: "#fff",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              background: "none",
              m: 1,
              px: 2.5,
              py: 0.5,
              borderRadius: "0.1rem",
              transition: "all 0.3s ease 0s",
              cursor: "pointer",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "0.2rem",
                transform: "translateY(-3px)",
                // boxShadow: "0 7px 15px rgb(213 213 213 / 40%)",
              },
            },
            "& .active": {
              color: "#000",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#ffffffb8",
              },
            },
          }}
        >
          <Link
            variant="outlined"
            className={`header-menu-button + ${active == "feed" && "active"}`}
            href={/*SITE_URL +*/ "/community"}
            underline="none"
          >
            <RssFeedIcon sx={{ pr: 1 }} />
            {t("feed")}
          </Link>
          <Link
            variant="outlined"
            className={`header-menu-button + ${
              active == "articles" && "active"
            }`}
            href={/*SITE_URL +*/ "/community/articles"}
            underline="none"
          >
            <ArticleIcon sx={{ pr: 1 }} />
            {t("articles")}
          </Link>
          <Link
            variant="outlined"
            className={`header-menu-button + ${
              active == "profile" && "active"
            }`}
            href={/*SITE_URL +*/ "/community/profile"}
            underline="none"
          >
            <PersonIcon sx={{ pr: 1 }} />
            {t("profile")}
          </Link>
        </Stack>
      </Box>
    </MainContainer>
  );
};

export default Header;
