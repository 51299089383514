const errorsTr = {
  //************************* GAME CENTER ERRORS *************************
  "error-1000": "Girilen cep telefonu numarası yanlış",
  "error-1001": "E-posta veya Şifre hatalı",
  "error-1002": "Mobil veya Şifre hatalı",
  "error-1003": "Girilen cep telefonu numarası sistemde kayıtlı",
  "error-1004": "Girilen e-posta adresi sistemde zaten kayıtlı",
  "error-1005": "Sistemde bilgileri girilmiş kullanıcı yok",
  "error-1006": "Gönderilen token yanlış",
  "error-1007": "Parola güncelleme işlemi başarısız oldu",
  "error-1008": "Gönderilen şifre yanlış",
  "error-1009": "Güncelleme işlemi başarısız oldu",
  "error-1010": "Bu kaynağa erişim yetkiniz yok",
  "error-1011": "Varlık Ağı bulunamadı",
  "error-1012": "Çekilmesine izin verilen minimum değer: ",
  "error-1013": "Kullanıcı Cüzdanı bulunamadı",
  "error-1014": "İstenen miktar kullanıcı bakiyesinden fazla",
  "error-1015": "İşlem kaydedilirken bir hata oluştu",
  "error-1016": "Varlık bulunamadı",
  "error-1017": "Kayıt sırasında bir hata oluştu",
  "error-1018": "Token doğrulanırken bir hata oluştu",
  "error-1019": "Kullanıcı kaydedilirken bir hata oluştu",
  "error-1020": "Blog kaydedilirken bir hata oluştu",
  "error-1021": "Blog bulunamadı",
  "error-1022": "Ödeme tokeni bulunamadı",
  "error-1023": "Ödeme kaydı bulunamadı",
  "error-1024": "Yinelenen ödeme kaydı",
  "error-1025": "Ödeme sayısı bulunamadı",
  "error-1026": "Ödeme başarısız",
  "error-1027": "Onay aşamasında ödeme başarısız oldu",
  "error-1028": "Bildirim bulunamadı",
  "error-1029": "Çift bulunamadı",
  "error-1030": "Ödeme kaydedilirken bir hata oluştu",
  "error-1031": "Ayar kaydedilirken bir hata oluştu",
  "error-1032": "Ayar bulunamadı",
  "error-1033": "Zaten düzenlendi",
  "error-1034": "İşlem bulunamadı",
  "error-1035": "Varlık bulunamadı",
  "error-1036": "Cüzdan bulunamadı",
  "error-1037": "Liste bulunamadı",
  "error-1038": "Oturum açılırken bir hata oluştu",
  "error-1039": "Parola unutma işlemi sırasında bir hata oluştu",
  "error-1040": "Bu içeriğe erişim yetkiniz yok",
  "error-1041": "tutar düşük",
  "error-1042": "düşük cüzdan miktarı",
  "error-1043": "Fiyat hesabı bulunamadı",
  "error-1044": "Kategori bulunamadı.",
  "error-1045": "Ödeme ortağı bulunamadı",
  "error-1046": "tutar aralıkta değil",
  "error-1047": "Ödeme ortağı oluşturulurken bir hata oluştu",
  "error-1048": "Adres oluşturulurken bir hata oluştu",
  "error-1049": "Kaydedilirken bir hata oluştu",
  "error-1050": "Kayıt bulunamadı",
  "error-1051": "Açık artırma kaydedilirken bir hata oluştu",
  "error-1052": "açık artırma bulunamadı",
  "error-1053": "teklif bulunamadı",
  "error-1054":
    "Lütfen bu alanlardan en az birini girin, cep telefonu veya e-posta.",
  "error-1055": "Eklerken bir hata oluştu!",
  "error-1056": "Güncelleme sırasında bir hata oluştu!",
  "error-1057": "Silme sırasında bir hata oluştu!",
  "error-1058": "Öğe bulunamadı!",
  "error-1059": "Öğe düzenlenemez!",
  "error-1060": "Takas başarısız!!",
  "error-1061": "player Id ve entity Id'den biri gerekli!",
  "error-1062": "Açık artırma teklifi bulunamadı!",
  "error-1063": "Rekabet bulunamadı!",
  "error-1064": "Müsabaka ligi bulunamadı!",
  "error-1065": "Ödül bulunamadı!",
  "error-1066": "Ödül havuzu bulunamadı!",
  "error-1067": "Bir veya daha fazla açık artırma teklifi var!",
  "error-1068": "Dil bulunamadı!",
  "error-1069": "Dil oluşturulamadı!",
  "error-1070": "Kartlar en az 5 öğe içermelidir",
  "error-1071": "Kartların konumu yanlış!",
  "error-1072": "Cüzdan düşük!",
  //************************* FC ERRORS *************************
  "error-2001": "Yarışmalar oluşturulurken bir hata oluştu",
  "error-2002": "Yarışmalar bulunamadı",
  "error-2003": "Ülke oluşturulurken bir hata oluştu",
  "error-2004": "Ülke bulunamadı",
  "error-2005": "Eşleşme oluşturulurken bir hata oluştu",
  "error-2006": "Eşleşme bulunamadı",
  "error-2007": "Oynatıcı oluşturulurken bir hata oluştu",
  "error-2008": "Oyuncu bulunamadı",
  "error-2009": "Ekip oluşturulurken bir hata oluştu",
  "error-2010": "Takım bulunamadı",
};

export default errorsTr;
