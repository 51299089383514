import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  addOrdinalSuffix,
  getImageUrl,
  truncateToDecimals,
} from "library/helper";
import { t } from "locales";
import { useSelector } from "react-redux";
import UserTeamDetail from "./UserTeamDetail";
import { useGetUserResault } from "_hooks/Investment/competitions";

const UserResult = ({ competitionId, containerSx = {} }) => {
  const modal = useSelector((state) => state.app.modal);
  const {
    data: userResault,
    isLoading,
    isError,
  } = useGetUserResault(competitionId);

  const openDetailModal = (userId, competitionLeagueId) => {
    modal.show(
      <UserTeamDetail
        onClose={modal.hide}
        competitionLeagueId={competitionLeagueId}
        userId={userId}
      />
    );
  };

  if (userResault?.data?.data?.length < 1 || isLoading || isError) return null;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, ...containerSx }}
    >
      <Typography variant="h6">{t("yourResult")}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {userResault?.data?.data?.map?.((result, i) => (
          <Box
            onClick={() => openDetailModal(result?.userId, result?.leagueid)}
            key={result?.leaueid || i}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "20px",
              borderRadius: "8px",
              cursor: "pointer",
              "&:hover": {
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#22252f" : "#e6eef4",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                img: {
                  width: "50px",
                  height: "50px",
                  borderRadius: "5px",
                  objectFit: "cover",
                },
              }}
            >
              <img src={getImageUrl(result?.leagueimage)} alt="" />
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  {result?.competitiontitle}
                </Typography>
                <Typography variant="body1" component="span">
                  {result?.leauetitle}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "17px", fontWeight: "bold" }}
              >
                {addOrdinalSuffix(result?.rank)}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ fontSize: "17px" }}
              >
                {truncateToDecimals(result?.score, 2)} pts
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserResult;
