import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";

const sections = [
  {
   title: "XbitPlay",
   description: "Xbit Play is an on chain play-to-earn (otherwise known as play-2-earn) game. On chain games are evolved based on the Blockchain technology to combine smart contracts with gaming contests.\nXbit Play offers 5 different types of cards to users. 1 type card is free. This feature has been considered in Xbit Play so that a lot of users would be able to play for free and even win prizes, without even having to purchase a card.\nThis feature will also help a lot of people who are interested in crypto and trading to be able to earn valuable trading experiences in this field. This way, users can create investment packages to persuade other people to purchase them, others can come and hone their trading and persuasion skills as well."
  },
  {
    title: "Prize",
    description: "The prizes that users can win are in three forms. First, the prizes could include Xbit Coins; second, the prizes could be Xbit Play cards with higher value and higher rank; thirdly, your prize can be a combination of both, Xbit Coins and cards."
  },
  {
    title: "Cards",
    description: "Xbit Play cards are designed based on 200 top crypto (coins and tokens) in the world, such as Bitcoin, Ethereum, Counos X, Xbit Coin, Cardano, BNB, Dogecoin, Shiba, etc. \nThere are 5 types of cards in this game:\n\n1. Silver Cards\n• These cards are free and are offered to all users\n• 7 cards are given to users for free\n\n2. Bronze Cards\n• These cards are known as Limited Cards\n• There are 1000 Bronze cards per player\nEach Bronze card is worth 200 Xbit Coins\n\n3. Gold Cards\n• These cards are known as Rare Cards\n• There are 100 Gold cards per player\n• Each Gold card is worth 800 Xbit Coins\n\n4. Rose Gold Cards\n• These cards are known as Super Rare Cards\n• There are 10 Rose Gold cards per player\n• Each Rose Gold card is worth 1800 Xbit Coins\n\n5. Black Cards\n• These cards are known as Unique Cards\n• There is only 1 Black card per player\n• Each Black card is worth 333,333 Xbit Coins\n• Important: Black cards will only be available to project owners – for example the owner of Ethereum can come and register and   prove to be the owner to be able to purchase the corresponding Black card.\n\nIf you are a project owner who would like to have the Xbit Cards based on your coin or token, you can fill out a form and pay a fee, so that we can design a card based on your crypto."
  },
  {
    title: "Unique Cards",
    description: "Each of these characters (Xbit Play cards) are based on actual cryptocurrencies – well-known coins or tokens – and the symbol of those cryptos can be seen on the cards. Each card is completely unique – with standout features and great details pertaining to the corresponding crypto, making the card truly one of a kind.\nIn addition, characters are diverse, both male and female, with greatly distinct characteristics, accessories, and elements."
  },
  {
    title: "Purchasing/Selling Xbit Play Cards",
    description: "There are different ways of purchasing/selling Xbit Play cards.\nCards can be purchased one by one, and also s number of cards can be purchased as part of a bundle. After that, card owners can sell them separately, one by one, or again as part of a bundle.\nAdditionally, cards can be sold in various ways, with a fixed price, as part of a sale, or as part of a bundle. As a user and card owner, you can determine how to sell the cards, how long the sale and auction will take, and all the other variables."
  },
  {
    title: "The Chain of Cards",
    description: "Xbit Play cards are on the chain of Polygon.\nXbit Play cards can be sold on secondary markets, on marketplaces such as OpenSea.\nCards can be sold to other players or to people who are interested in playing the game."
  },
  {
    title: "Investment Packages",
    description: "When you are playing in the website you can offer your own investment packages and even set out the details for them, or even predict what will happen in 6 hours, or 24 hours, or longer. So you will make predictions with regard to what the price will be.\nWhen users open the website and begin their journey to play the game, they will see a chart.\nUsers will be able to specify a number up to 100 percent for each card, depending on the number of cards they have.\nFor example, they can predict whether Ethereum will go higher 30 percent, etc.\nAfter they have specified and submitted the percentages, they will receive a verification code.\n"
  }
]


const GameInfo = ({ goNextStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: {
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          About game
        </Typography>
        <Box
        m={2}
        sx={{
          h5: {
            fontWeight: "bold",
            textAlign: "center",
          },
          p: {
            textAlign: "left",
          }
        }}>
        {sections.map(({title, description}) => (
          <Box>
            <Typography component="h5" sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
            <Box>
            {description.split("\n").map(line => (
              <Typography variant="body1" sx={{mb: 2}}>
                {line}
              </Typography>
            ))}
            </Box>
          </Box>
        ))}
        </Box>
      </Box>
      <Button
        onClick={goNextStep}
        sx={(theme) => ({
          backgroundImage: theme.palette.gradient.purpule,
          color: theme.palette.common.white,
          width: "fit-content",
          padding: "10px 30px",
          fontSize: "1rem",
          mx: "auto",
        })}
      >
        {t("next")}
      </Button>
    </Box>
  );
};

export default GameInfo;
