import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import CardsType from "./CardsType";
import CardDetail from "./CardDetail";
import CardPack from "../CardPack";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { t } from "locales";

const maxStep = 2;

const CryptoWarsCardWizard = () => {
  const wizard = useSelector((state) => state.auth?.wizard);
  const navigate = useNavigate();
  const [step, setStep] = useState(2);

  useEffect(() => {
    const cryptoWizardStep = wizard?.CRYPTO;

    if (cryptoWizardStep >= 3) navigate("/investment/play");

    if (!cryptoWizardStep) navigate("/investment/wizard/league");
    else if (cryptoWizardStep === 1) navigate("/investment/wizard/league");
    else if (cryptoWizardStep === 2) setStep(1);
    else setStep(1);
  }, []);

  const goNextStep = () => {
    setStep((prev) => prev + 1);
    window.scrollTo(0, 0);
  };
  const goPrevStep = () => {
    setStep((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  let body;
  if (step === 1) {
    body = <CardsType goNextStep={goNextStep} />;
  } else if (step === 2) {
    body = <CardDetail goNextStep={goNextStep} />;
  } else if (step === 3) {
    body = <CardPack redirectPath="/investment/play" packId={3} />;
  }

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 64px)",
        minWidth: "100%",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212429" : "#eee",
        pb: 5,
      }}
    >
      {step !== 3 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "15px 20px",
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#000" : "#fff",
          }}
        >
          <Box>
            {step !== 1 && (
              <Box
                onClick={goPrevStep}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  color: "#6E737C",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIos
                  fontSize="small"
                  sx={{
                    transform: (theme) =>
                      theme.direction === "rtl" && "rotate(180deg)",
                  }}
                />
                <Typography variant="body1">{t("back")}</Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {t("howToPlayPlayerHeader")} – {step}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#6E737C",
              }}
            >
              /{maxStep}
            </Typography>
          </Box>
          <Box></Box>
        </Box>
      )}

      <Box
        className="container"
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          minWidth: "100%",
        }}
      >
        {body}
      </Box>
    </Box>
  );
};

export default CryptoWarsCardWizard;
