import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useState } from "react";

const Icon = styled("img")({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
});

const SelectInput = ({
  options = [],
  withIcon = false,
  onChange,
  value,
  label,
  shrink = false,
  containerSx = {},
  selectSx = {},
  labelSx = {},
  itemSx = {},
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleChange = (event) => onChange(event.target.value);

  return (
    <FormControl sx={{ minWidth: 120, ...containerSx }}>
      {label && (
        <InputLabel shrink={shrink} sx={{ labelSx }}>
          {label}
        </InputLabel>
      )}
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        label={label}
        onChange={handleChange}
        sx={{ ...selectSx }}
      >
        {options?.map?.((item) => (
          <MenuItem value={item} key={item.label}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                ...itemSx,
              }}
            >
              {withIcon && item.icon && <Icon src={item.icon} alt="" />}
              <Typography component="span" variant="body1">
                {item.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
