import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getArticles } from "../requests.js";
import { VerticalTabs } from "./RecommendedSlider";

const RecommendedArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    let result = await getArticles();
    if (result.data?.status === "success") {
      setArticles(result.data.data.data);
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
      ) : (
        <VerticalTabs articles={articles} />
      )}
    </>
  );
};

export default RecommendedArticles;
