import { Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useState } from "react";
import { useGetMyAuctions } from "_hooks/User/queries";
import MyAuctionsList from "./MyAuctionsList";

const Index = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading: loading } = useGetMyAuctions(page);

  const onPageChange = (_, value) => setPage(value);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h4">{t("myAuctions")}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}></Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <MyAuctionsList list={data?.list || []} loading={loading} />
        <Box sx={{ mx: "auto" }}>
          <Pagination
            sx={{ width: "fit-content" }}
            count={data?.total || 1}
            page={page}
            onChange={onPageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
