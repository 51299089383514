import { useEffect, useRef } from "react";
import { SOCKET_URL } from "config/Config";
import SocketService from "./socketService";
import { useSelector } from "react-redux";
import { SocketContext } from "./socket";

const privateEvents = ["notification", "wallet"];

const SocketProvider = ({ children }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const socket = useRef(new SocketService(SOCKET_URL, accessToken));

  useEffect(() => {
    if (!!accessToken) {
      socket.current.refreshAccessToken(accessToken);
    } else {
      privateEvents.forEach((event) => {
        socket.current.removeListener(event);
      });
    }
  }, [accessToken]);

  return (
    <SocketContext.Provider value={{ socket: socket.current }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
