import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";
import FollowButton from "routes/Community/components/FollowButton";
import { useEffect, useState } from "react";
import axios from "library/http";

const RecommendedAccounts = () => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const getAccounts = async () => {
      try {
        const result = await axios.get("/gravity/profiles/", {
          params: {
            recommended: true,
          },
        });

        if (result.data?.status === "success") {
          setAccounts(result.data.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAccounts();
  }, []);

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
        }}
      >
        Recommended Accounts
      </Typography>
      <List>
        {loading ? (
          <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
        ) : (
          <>
            {accounts.map((account, index) => (
              <Box key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    px: 1,
                    backgroundColor: (theme) =>
                      theme.palette.background[theme.palette.mode],
                    "& span:nth-of-type(1)": { mr: 0.5 },
                    "& .MuiListItemAvatar-root": { minWidth: "auto" },
                    "& button": {
                      backgroundColor: "primary.contrastText",
                      display: "flex",
                      fontWeight: "bold",
                      alignItems: "center",
                      borderRadius: "0",
                      ml: "auto",
                    },
                  }}
                >
                  <Link
                    href={"/community/users/" + account.userId}
                    underline="none"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "text.primary",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={account.name}
                        src={
                          account.avatar.length > 0
                            ? account.avatar.location
                            : "./broken.jpg"
                        }
                      ></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={account.name}
                      secondary={account.username}
                    />
                  </Link>
                  <FollowButton
                    followed={account.followed}
                    id={account.userId}
                    style={{
                      color: "#000",
                      backgroundColor: "common.white",
                      "& span": {
                        color: "red",
                      },
                    }}
                  />
                </ListItem>
                {index + 1 !== accounts.length && <Divider />}
              </Box>
            ))}
          </>
        )}
      </List>
    </Box>
  );
};

export default RecommendedAccounts;
