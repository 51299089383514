const privacyPolicyTr = {
  privacyPolicy: "Gizlilik politikası",
  headerTitle: "GİZLİLİK POLİTİKASI",
  headerDesc:
    "YASAL BİLDİRİM Voexplay SAS, 2,054,56 € (RCS Créteil No. 844 355 727) sermayesi olan ve kayıtlı ofisi 5, avenue du Général de Gaulle, 94160 Saint-Mandé, Fransa'da bulunan bir Birleşik Krallık şirketidir (société par action simplifiée). (“Şirket”). Yayın direktörü Nicolas Julia'dır. İrtibat : contact@Voexplay.com Web Sitesi ağırlıklı olarak AWS tarafından barındırılmaktadır",

  title1: "1. Önsöz",
  title2: "2. Kapsam",
  title3: "3. Toplanan Veriler ve Amaçlar",
  title4: "4. Veri Alıcıları",
  title5: "5. Saklama Süresi",
  title6: "6. Taşeronluk ve Veri aktarımı",
  title7: "7. Kullanıcının Hakları",
  title8: "8. Çerezler",
  title9: "9. Güvenlik",
  title10: "10. Güvenlik",
  title11: "11. Yargı yetkisi ve uygulanacak hukuk",

  "text1-1":
    'Şirket, volexplay.com web sitesinde (bundan böyle "Web Sitesi" olarak anılacaktır) kayıtlı kullanıcıların (bundan böyle "Kullanıcı" olarak anılacaktır) gelişmiş bir kullanıcı arayüzünden faydalanmalarını sağlar. Volexplay, Kullanıcıların resmi lisanslı dijital kartlarla oynayabileceği küresel bir fantezi futbol oyunudur. ve her hafta ödüller kazandırır.Web sitesi, kayıtlı Kullanıcıları için Koleksiyon Öğelerini ve bunların blok zinciri teknolojisine dayalı temel tokenlerini (yukarıda belirtilen faaliyetler, toplu olarak "Hizmetler") edinmesi, toplaması ve takas etmesi için bir pazar yeri sağlar.',
  "text1-2":
    "Hizmetleri kullanmak için Kullanıcılar Web Sitesinde bir hesap oluşturmalıdır. Her hesap bir cüzdana eklenir.",
  "text1-3":
    "Hizmetler hakkında daha fazla bilgi için Hüküm ve Koşulları ve Oyun Kurallarını okumaya davetlisiniz.",
  "text1-4":
    "Şirket, sizinle olan ilişkilerin anahtarının güven olduğuna kesinlikle inanmaktadır. Bu bağlamda, kişisel verilerinizin ve gizliliğinizin korunması bizim önceliğimizdir.",
  "text1-5":
    "Bu nedenle Şirket, verilerinizin azami özenle toplanıp işlenmesine ve yürürlükteki yasal çerçeveye uyulmasına önem vermektedir.",
  "text1-6":
    "Sizi şeffaf bir şekilde bilgilendirmek için Şirket, Hizmetlerimizi kullandığınız yerlerde kişisel verilerinizin neden ve nasıl işlendiğini ayrıntılı olarak açıklayan aşağıdaki gizlilik politikası uygulamıştır.",

  "text2-1":
    'Bu gizlilik politikası ("Politika"), Hizmetlerin bir ziyaretçisi veya Kullanıcısı olarak, bir hesap dahil olmak üzere herhangi bir terminal, uygulama, cihaz (bundan böyle "Hizmetler" olarak anılacaktır) aracılığıyla Şirket\'in çalışma şekli hakkında sizi bilgilendirmeyi amaçlamaktadır. Denetleyici olarak, Hizmetleri kullanımınız bağlamında sizi doğrudan veya dolaylı olarak tanımlamayı sağlayan bilgileri (“Kişisel Veriler”) işler.',
  "text2-2":
    "Kullanıcı, bu Politikayı kabul etmekle, Kişisel Verilerinin Şirket tarafından bu Politikaya uygun olarak kullanılmasına izin verir.",
  "text2-3":
    "Politika, Web Sitesinde herhangi bir zamanda erişilebilirdir ve önceki herhangi bir sürümden daha üstündür.",
  "text2-4":
    "Politika değişebilir. Güncel sürüm, Web Sitesinde bulunan sürümdür.",

  "text3-1":
    "Şirket, Kişisel Verileri, her biri geçerli bir yasal dayanak tarafından usulüne uygun olarak meşrulaştırılan özel amaçlar için işlemektedir.",
  "text3-2":
    "İlk olarak, bir Kullanıcı olarak, Hizmetlere erişmenizi sağlayan bir hesap oluşturmuş olabilirsiniz. Bu hesabın oluşturulmasından silinmesine kadar yönetilmesi için Şirket, Kişisel Verileri (yani adınız, soyadınız, kullanıcı adınız ve iletişim bilgileriniz) toplayacak ve işleyecektir.",
  "text3-3":
    "Bu işlemenin yasal dayanağı, Şirketin sizin tarafı olduğunuz bir sözleşmeyi akdetmesi gerekliliğidir.",
  "text3-4":
    "İkincisi, Şirket, kullanıcıların Hizmetleriyle nasıl etkileşime girdiğini ve Hizmetlerin kullanımıyla ilgili analitik işlemin gerçekleştirilmesi için Kişisel Veri olarak nitelendirilen Çerezlerden kaynaklanan çeşitli tarama bilgilerini işlemesi gerektiğini anlamaya isteklidir.",
  "text3-5":
    "Bu bağlamda, Şirketin yasal dayanağı, (i) Hizmetlerin Kullanıcılar tarafından nasıl kullanıldığını anlamak ve (ii) çerezin amacına bağlı olarak Hizmetlerin gerekirse iyileştirilmesi veya sizin onayınızdan oluşan meşru menfaatine dayanmaktadır.",
  "text3-6":
    "Üçüncüsü, Şirket, Hizmetlerin kullanımı için Kullanıcının Kişisel Verilerini toplar ve işler. Bu işleme, Şirketin aşağıdaki Kişisel Veri kategorilerini toplamasını ve işlemesini gerektirir:",
  "text3-7": "Açık artırma için: kullanıcı adı;",
  "text3-8":
    "Koleksiyon Öğelerinin satın alınması, aktarılması, geri çekilmesi ve takası için: kullanıcı adı ve blok zinciri genel anahtarları veya ödeme tanımlayıcısı ve banka kartınızın rakamlarının bir kısmı;",
  "text3-9":
    "SO5 oyununa katılım için: kullanıcı adı, blok zinciri açık anahtarları ve ödül kazananlar;",
  "text3-10": "Skor tablosu için: kullanıcı adı.",
  "text3-11":
    "Şirket'in bu amaçla dayandığı yasal dayanak, tarafı olduğunuz bir sözleşmenin ifasıdır.",
  "text3-12":
    "Dördüncüsü, Şirket, Kullanıcıların gönderebileceği soruları ve/veya istekleri yönetmek ve takip etmek için Kullanıcının Kişisel Verilerini toplar ve işler. Bu nedenle, bu işlemin yalnızca bir soru ve/veya talep göndermeniz durumunda gerçekleştirildiğini lütfen unutmayın. Aksi takdirde Kişisel Verileriniz işlenmez.",
  "text3-13":
    'Şirket, yapılan kontrollerin sonuçlarına bağlı olarak, özellikle ek destekleyici belgeler talep ederek güvenlik önlemleri alabilir. Bu önlemler, emrin yürütülmesini askıya alma veya analiz sonucunun emrin güvenliğinin garanti edilmesine izin vermemesi durumunda iptal etme etkisine sahip olacaktır. Bir ödeme aracının hileli kullanımı, siparişle ilgili verilerin Şirketin uyarı dosyasına kaydedilmesiyle sonuçlanacak ve bu, Şirketin gelecekteki siparişler için ek kontroller yapmasına yol açabilecektir. Gözlemlerinizi istediğiniz zaman dolandırıcılık departmanımıza iletebilirsiniz (bkz. Madde 7 "Kullanıcının Hakları").',
  "text3-14":
    "Bu amaçla toplanan Kişisel Veriler, bilgi hesabı ve işlem detaylarıdır.",
  "text3-15":
    "irketin bu amaçla dayandığı yasal dayanak, Şirketin meşru menfaatidir.",
  "text3-16":
    "Beşinci olarak, Şirket, hizmetlerimizin güvenliğini sağlamamıza ve herhangi bir kötü niyetli veya bilgisayar korsanlığı davranışı girişimini veya Hizmetlerimizin kullanım koşullarının herhangi bir ihlalini tespit etmemize, önlememize veya izlememize olanak sağlamak için belirli tarama verilerini toplar.",
  "text3-18":
    "Şirketin bu amaçla dayandığı yasal dayanak, Şirketin meşru menfaatidir.",
  "text3-19":
    "Son olarak Şirket, Kullanıcıların gönderebileceği her türlü soru ve/veya talebi yönetmek ve takip etmek için Kullanıcının Kişisel Verilerini toplar ve işler. Bu nedenle, bu işlemin yalnızca bir soru ve/veya talep göndermeniz durumunda gerçekleştirildiğini lütfen unutmayın. Aksi takdirde Kişisel Verileriniz işlenmez.",
  "text3-20":
    "Bu işleme, Şirketin aşağıdaki Kişisel Veri kategorilerini toplamasını ve işlemesini gerektirir: (i) kimlik verileri (yani kullanıcı adınız ve iletişim bilgileriniz) ve (ii) bir parçası olarak Şirkete gönderdiğiniz mesaj(lar)ın içeriği. Sorunuz/isteğiniz için.",
  "text3-21":
    "Kişisel Verilerinizi bu amaçla işlerken, Şirket, sizinle olan ilişkilerini usulüne uygun olarak yönetmekten oluşan meşru menfaatini esas alır.",
  "text3-22":
    "Her halükarda ve söz konusu işleme amacını göz ardı ederek, Şirketin katı bir veri minimizasyon ilkesine uyacağını ve bu nedenle yalnızca yukarıdaki amaçlar için gerekli olan Kişisel Verileri toplayıp işleyeceğini lütfen unutmayın.",

  "text4-1":
    "Şirket, Kişisel Verilerinizi, bu Politika'da belirtilen amaçların yerine getirilmesinde Şirket'e yardımcı olan üçüncü kişi hizmet sağlayıcıları ve tedarikçileri ile paylaşır.",
  "text4-2":
    "Örneğin, kredi kartı ödemelerini kabul etmek için Şirket, Stripe'ın hizmetlerine güvenir. Bu nedenle, bu sağlayıcı Kişisel Verilerinize, özellikle e-postanıza, ödeme verilerinize ve konumunuza erişebilir.",
  "text4-3":
    "Ayrıca, duruma göre Şirket, Kişisel Verilerinizi yetkili mahkemeler ve Kişisel Verilerinize erişim talep eden diğer resmi ve/veya resmi makamlarla yasal olarak izin verilen ölçüde paylaşır.",
  "text4-4":
    "Her halükarda, Şirket Kişisel Verilerinizi yukarıdaki alıcılara kesinlikle bilmesi gerekenler temelinde ve sadece usulüne uygun olarak tanımlanmış işleme amaçlarını yerine getirmek için gerekli olduğu şekilde iletir.",

  "text5-1":
    "Şirket, Kişisel Verilerinizi, bu Politika'da belirtilen amaçların yerine getirilmesinde Şirket'e yardımcı olan üçüncü kişi hizmet sağlayıcıları ve tedarikçileri ile paylaşır.",
  "text5-2":
    "Öncelikle hesabınızın yönetimi ile ilgili olarak Şirket, Kişisel Verilerinizi hesabınızın kapatıldığı tarihe kadar elinde tutacaktır. Ancak, Şirket'in Kişisel Verilerinizi hesabınızın kapatıldığı tarihten sonra kanıt amacıyla saklaması gerekiyorsa, o zaman geçerli azami saklama süresi, yasal sınırlama sürelerine uygun olacak ve Şirketin verileri kanıt amacıyla saklaması gereken süreyi aşmayacaktır.",
  "text5-3":
    "İkinci olarak, Şirket tarafından veya Şirket adına analiz işlemleri gerçekleştirmek üzere işlenen Kişisel Veriler, bu işlemler tamamlandıktan ve ilgili raporların/çıktıların üretilmesinden sonra Şirket tarafından saklanmayacaktır.",
  "text5-4":
    "Üçüncüsü, Şirket, Hizmetlerin kullanımı için Kullanıcının Kişisel Verilerini toplayıp işlediğinde, Kişisel Veriler son faaliyetten itibaren on beş (15) ay boyunca saklanır. Bir sipariş için ödeme yaptığınızda, ödeme için kullanılan karta ilişkin veriler işlem için gerekli süre boyunca ve daha sonra işlemin kanıtını garanti etmek için ödeme tarihinden itibaren 15 aylık bir süre boyunca saklanır.",
  "text5-5":
    "Dördüncüsü, dolandırıcılığın önlenmesi için işlenen Kişisel Veriler, bir uyarı listesine eklenmelerinden itibaren üç (3) yıl süreyle saklanır.",
  "text5-6":
    "Beşincisi, güvenlikle ilgili olarak işlenen Kişisel Veriler, son faaliyetten itibaren beş (5) yıl süreyle saklanır.",
  "text5-7":
    "Son olarak, sorularınızın ve/veya taleplerinizin yönetimi ile ilgili olarak Şirket, Kişisel Verilerinizi inisiyatifinizde olan son temastan sonra üç (3) yıldan fazla saklamayacaktır.",
  "text5-8":
    "Ancak, Şirket bazı Kişisel Verileri meşru menfaatler ve yasal yükümlülükler nedeniyle acil ve güncel ihtiyaçlarda gerektiğinden daha uzun süre saklayabilir.",
  "text5-9":
    "İlgili saklama süresi sona erdiğinde, Şirket Kişisel Verilerinizi silecek veya artık kimliğinizin tespit edilememesi için geri dönülemez şekilde anonimleştirecektir.",
  "text5-10":
    "Şirket, Kişisel Verilerinizi aktif veritabanında tutmanın gerekli olmadığını düşünürse, arşivleyecek ve erişimin, gerçekten erişmesi gereken sınırlı sayıda kişiyle sınırlandırılmasını sağlayacaktır.",

  "text6-1":
    "Şirket, Web Sitesinin ve Hizmetlerinin kullanımını kolaylaştırmak, sürdürmek, iyileştirmek, analiz etmek ve güvence altına almak için hizmet sağlayıcıları ve diğer üçüncü tarafları kullanabilir. Hizmet sağlayıcılar, Kişisel Verilere yalnızca ve münhasır görevleri yerine getirmek amacıyla erişebilir. Şirket, hizmet sağlayıcıların görevi ifa etmeleri için yeterli teminata sahip olmalarını ve yürürlükteki kanun ve yönetmeliklere uymalarını sağlar.",
  "text6-2":
    "Kişisel Veriler Avrupa Birliği toprakları dışında işlenebilir. Bu durumda Şirket, gerekli tüm önlemleri alacak ve alternatif veya toplu olarak Avrupa Komisyonu'nun varış ülkesine ilişkin bir yeterlilik kararı almasını; Avrupa Komisyonu veya denetleme makamı, alıcıyla imzalanmışsa; alıcının onaylanmış bir davranış kuralına veya sertifika mekanizmasına vb. bağlı kaldığına dair.",
  "text6-3":
    "16 Temmuz 2020 itibariyle, AB veya Birleşik Krallık kaynaklı verileri ABD'ye aktarmak için artık AB-ABD Gizlilik Kalkanı'na güvenmiyoruz",

  "text7-1":
    "Kullanıcı, 2016/679/27 sayılı Tüzüğün 15 ila 22. maddelerinde belirtilen koşullar altında erişim hakkına, düzeltme ve silme hakkına, işlemeyi kısıtlama hakkına, Kişisel Veri taşınabilirliği hakkına sahip olduğu konusunda bilgilendirilir. Nisan 2016.",
  "text7-2":
    "Kullanıcı ayrıca, 6 Ocak 1978 tarih ve 78-17 sayılı kanunun 84 ila 86'ncı maddelerinde belirtilen koşullar altında, vefatından sonra Kişisel Verilerin nasıl yönetileceğini belirleyen talimatları belirleme hakkına sahiptir.",
  "text7-3":
    "Haklarını kullanmak veya Kişisel Verilerin korunmasıyla ilgili herhangi bir soru için Kullanıcı, kimlik kanıtıyla birlikte Volexplay - 59 St. Martin's Lane, Suite 8, Londra, İngiltere, WC2N 4JS adresine posta veya e-posta yoluyla bir talepte bulunacaktır. info@volexplay.com.",
  "text7-4":
    "Şirket, talebin alınmasından itibaren en geç bir ay içinde gecikmeksizin cevap vermeye çalışacaktır. Şirket, karmaşık bir talep olması durumunda bu süreyi üç (3) aya kadar uzatma hakkını saklı tutar.",
  "text7-5":
    'Son olarak, Kullanıcı, bir talepte bulunmak için yetkili denetim makamı olan Commission Nationale Informatique et Libertés\'e ("CNIL") başvurma seçeneğine sahiptir.',
  "text7-6":
    "Şirket, Kişisel Verilerinizi korumayı ve geçerli veri koruma yasal çerçevesine uymayı taahhüt eder.",
  "text7-7":
    "Şirket'in bu amaçla sizden yardım istemesinin nedeni budur. Bu nedenle, Şirket ile paylaştığınız Kişisel Verilerin geçersiz hale gelmesi veya yanlış olması durumunda Şirket'i bilgilendirmeyi taahhüt etmektesiniz.",
  "text7-8":
    "Ayrıca, Şirket'e başka herhangi bir gerçek kişiyi doğrudan veya dolaylı olarak tanımlamanıza olanak sağlayacak bilgileri vermeniz durumunda (örn. e-postanızda), bu bilgileri Şirketle paylaşmadan önce, bu tür diğer gerçek kişilere bu Politikanın verildiğini ve geçerli olduğu ölçüde, verilerinin işlenmesine izin verdiğini beyan ve garanti edersiniz.",

  "text8-1":
    "Web sitemize göz attığınızda, çerezler ile ilgili düzenlemelerin gerektirdiği durumlarda, doğrudan veya onayınızı aldıktan sonra terminalinize çerezler yerleştirilir.",
  "text8-2":
    "Çerezler, ziyaret edilen web sitesinin sunucusu tarafından İnternet kullanıcısının terminal ekipmanına yerleştirilen bilgi parçalarıdır. Bir web sitesi tarafından İnternet kullanıcısının tarayıcısına bilgi göndermek ve bu tarayıcının kaynak web sitesine bilgi göndermesine izin vermek için kullanılırlar ( örneğin, bir oturum tanımlayıcısı veya bir dil seçimi).",
  "text8-3":
    "Yalnızca bir çerezi gönderen, içerdiği bilgileri okuyabilir veya değiştirebilir.",
  "text8-4": "Farklı türde çerezler vardır :",
  "text8-5": "Web Sitesinden ayrılır ayrılmaz kaybolan oturum çerezleri;",
  "text8-6":
    "Kullanım süreleri dolana veya tarayıcınızın işlevlerini kullanarak onları silene kadar terminalinizde kalan kalıcı çerezler.",
  "text8-7":
    "Siteye yaptığınız ziyaretler sırasında terminal ekipmanınıza çerez yüklenebileceği konusunda bilgilendiriliyorsunuz.",

  "text9-1":
    "Şirket, kişisel verilerinizin gizliliğini, güvenliğini ve bütünlüğünü, yetkisiz erişime ve ifşaya, değiştirmeye, değiştirmeye, hasara, kazara kaybolmaya veya kazara veya yasa dışı imhaya ve ayrıca diğer herhangi bir yasa dışı işleme veya yetkisiz kişilerle iletişim kurmaz.",
  "text9-2":
    "Kişisel verilere erişim, bilgileri bilmesi gereken, katı güvenlik ve bilgi koruma kuralları uyguladığımız çalışanlar, ortaklar ve hizmet sağlayıcılarla sınırlıdır.",
  "text9-3":
    "Ancak, Şirket, kişisel verilerinizin kaybolmasına, yok olmasına veya zarar görmesine karşı size garanti veremez. Şirket, kişisel alanınızda bulunan verilerinizin korunmasını sağlayamaz veya gerçekleştiremez.",
  "text9-4":
    "Yedekleme hizmetlerimiz, kişisel alanınızda bulunan verilerinizin yedeğini almaz, bu nedenle ikincil bir yedekleme kaynağı kullanmanız gerekir. Başka bir deyişle, ihmalimiz veya kasıtlı suiistimalimiz olmadıkça, verilerin kaybından dolayı sorumluluk kabul etmiyoruz.",
  "text9-5":
    "Ayrıca, Web Sitesinin kişisel alanınıza giriş yapmanızı gerektiren belirli bölümlerine erişmek için bir şifre seçmeniz gerektiğinde, güvenli bir şifre (güçlü, benzersiz (yani başka bir site için kullanılmamış ve kullanılmayan) seçmek sizin sorumluluğunuzdadır. /veya başvuru vb.) ve gizli tutmak.",

  "text10-1":
    "Şirket, Hizmetler geliştikçe veya yürürlükteki yasaların gerektirdiği şekilde bu Politikayı gerektiği gibi değiştirme hakkını saklı tutar.",
  "text10-2":
    "Kullanıcı, Şirket Hizmetlerini kullanırken, Politika'nın Web Sitemizde yayımlanmasıyla birlikte hükümlerini kabul etmiş sayılır.",

  "text11-1":
    "Politika Birleşik Krallık yasalarına göre yönetilir ve yorumlanır.",
  "text11-2":
    "Yasa tarafından aksi belirtilmedikçe. Politika, Kullanıcıları Birleşik Krallık mahkemelerinin münhasır yargı yetkisine tabi tutacaktır.",
};

export default privacyPolicyTr;
