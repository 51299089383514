import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import PutOfferCryptoWars from "components/PutOfferCryptoWars";
import BigCard from "./BigCard";
import SmallCard from "./SmallCard";
import SingleBundle from "routes/CryptoWars/SingleCard/SingleBundle";
import SingleCardAuction from "routes/CryptoWars/SingleCard/SingleCardAuction";
import { useNavigate } from "react-router";

const SingleCard = (item) => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const lastPrice = item?.max || item?.basePrice;

  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const modal = useSelector((state) => state.app.modal);

  const openCardDetail = () => {
    if (!!item?.bundleCards) {
      modal.show(<SingleBundle onClose={() => modal.hide()} id={item?.id} />);
    } else {
      modal.show(
        <SingleCardAuction onClose={() => modal.hide()} id={item?.id} />
      );
    }
  };

  const openPutOfferModal = (isBundle) => {
    if (!user) navigate("/auth/login");
    else
      modal.show(
        <PutOfferCryptoWars
          onClose={() => modal.hide()}
          id={item?.id}
          isBundle={isBundle}
        />
      );
  };

  return (item?.noResponsive ? true : !smallDevice) ? (
    <BigCard
      {...item}
      lastPrice={lastPrice}
      openCardDetail={openCardDetail}
      openPutOfferModal={openPutOfferModal}
    />
  ) : (
    <SmallCard
      {...item}
      lastPrice={lastPrice}
      openCardDetail={openCardDetail}
      openPutOfferModal={openPutOfferModal}
    />
  );
};

export default SingleCard;
