import { t } from "locales";
import { useSelector } from "react-redux";
import visaIcon from "assets/img/visa.png";
import mastercardIcon from "assets/img/mastercard.png";
import shetabIcon from "assets/img/shetab.png";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DetailModal from "../DetailModal";
import { useNavigate } from "react-router";

const methodsIcon = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  shetab: shetabIcon,
};

const PaymentItem = ({
  amount,
  partner,
  min,
  max,
  rate,
  methods,
  disabled,
  assetId,
  price,
  asset,
  partnerIcon,
  paymentTermId,
  type,
  selectedAssets,
  inputError,
  setInputError,
}) => {
  const user = useSelector((state) => state.auth.user);
  const modal = useSelector((state) => state.app.modal);
  const navigate = useNavigate();

  const openDetailModal = () => {
    if (!user) navigate("/auth/login");

    if (!amount || isNaN(+amount)) {
      setInputError("buyVlxAmountError");

      return;
    }

    if (min && amount < min) {
      setInputError(`Minimum amount is ${min} ${selectedAssets?.fiat?.label}`);
      return;
    } else if (max && amount > max) {
      setInputError(`Maximum amount is ${max} ${selectedAssets?.fiat?.label}`);
      return;
    } else {
      setInputError("");

      modal.show(
        <DetailModal
          pyamentName={partner}
          onClose={() => modal.hide()}
          gatewayIcon={partnerIcon}
          renderedMethods={renderedMethods}
          assetId={assetId}
          price={price}
          rate={rate}
          coinPrecision={asset?.precision ?? 2}
          paymentTermId={paymentTermId}
          type={type}
          selectedAssets={selectedAssets}
          amount={amount}
        />
      );
    }
  };

  const renderedMethods = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mt: 0.5,
        img: {
          width: "25px",
          height: "25px",
          objectFit: "contain",
        },
      }}
    >
      {methods.map((method) => (
        <img
          key={method}
          alt={method}
          src={methodsIcon[method]}
          className="method-img"
        />
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        gap: { xs: 2, sm: 0 },
        p: "20px",
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        borderRadius: "8px",
        ".label": {
          opacity: ".7",
        },
        ".item": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      {type === "BUY" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 1,
            alignItems: "center",
            img: {
              width: "45px",
              height: "45px",
              borderRadius: "50%",
            },
          }}
        >
          <img alt="" src={partnerIcon} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Typography variant="body2" className="label">
              {t("paymentPartner")}
            </Typography>
            <Typography variant="body1">{partner}</Typography>
          </Box>
        </Box>
      )}

      <Box className="item">
        <Typography variant="body2" className="label">
          {t("referencePrice")}
        </Typography>

        <Typography variant="body1">{price}</Typography>
      </Box>

      <Box className="item">
        <Typography variant="body2" className="label">
          {t("singleOrder")}
        </Typography>
        <Typography variant="body1">
          {new Intl.NumberFormat("en").format(min)}~
          {new Intl.NumberFormat("en").format(max)}
        </Typography>
      </Box>

      {type === "BUY" && (
        <Box className="item">
          <Typography variant="body2" className="label">
            {t("paymentMethods")}
          </Typography>

          {renderedMethods}
        </Box>
      )}

      <Box>
        <Button
          onClick={openDetailModal}
          color="primary"
          variant="outlined"
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "primary.main" : "#fff",
            padding: "7px 20px",
            transition: "background-color 250ms",
            width: "fit-content",

            "&:hover": {
              backgroundColor: "primary.main",
              color: "#fff",
            },
          }}
        >
          {t(type)}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentItem;
