import React, { useState } from "react";
import { t } from "locales";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ButtonBase, Typography } from "@mui/material";
import { register, forgotPassword } from "redux/auth/asyncActions";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function VerifyTimer({
  buttonType,
  resetTimer,
  timeLeft,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const { verifyType, resendEmail, resendMobile, resendPassword, resendName } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async () => {
    setLoading(true);
    if (verifyType === "REGISTER") {
      try {
        const gToken = await executeRecaptcha();
        const loginData = {
          [!!resendEmail ? "email" : "mobile"]: resendEmail ?? resendMobile,
          password: resendPassword,
          name: resendName,
          gRecaptchaResponse: gToken,
        };

        await dispatch(register(loginData));
        setLoading(false);
        // reset timer
        resetTimer();
      } catch (error) {
        // props.setError(error);
        setLoading(false);
      }
    } else if (verifyType === "FORGOT_PASSWORD") {
      try {
        const gToken = await executeRecaptcha();
        const typeName = resendEmail ? "email" : "mobile";
        const requestData = {
          [typeName]: typeName === "email" ? resendEmail : resendMobile,
          gRecaptchaResponse: gToken,
        };
        await dispatch(forgotPassword(requestData));
        // reset timer
        resetTimer();
      } catch (error) {
        console.error(error);
      }
    }
  };
  const done = timeLeft === null;

  return (
    <ButtonBase disabled={!done} onClick={onSubmit} loading={loading}>
      <Typography
        variant="body2"
        sx={{ color: !done ? "gray" : "common.white" }}
      >
        {!done ? timeLeft + "s" : t("resend")}
      </Typography>
    </ButtonBase>
  );
}
