import { useEffect, useState } from "react";
import Articles from "routes/Community/components/Articles";
import axios from "library/http";
import { CircularProgress, Typography } from "@mui/material";

const ArticlesTab = ({ id }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const result = await axios.get("/gravity/articles", {
        params: {
          profileId: id,
        },
      });

      if (result.data?.status === "success") {
        setArticles(result.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
      ) : (
        <>
          {articles.length <= 0 ? (
            <Typography variant="h6" sx={{ textAlign: "center", my: 3 }}>
              No articles found
            </Typography>
          ) : (
            <Articles articles={articles} />
          )}
        </>
      )}
    </>
  );
};

export default ArticlesTab;
