import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useSelector } from "react-redux";
import PasswordModal from "./PasswordModal";
import EmailModal from "./EmailModal";
import MobileModal from "./MobileModal";

const UserAccountSetting = () => {
  const user = useSelector((state) => state.auth.user);
  const modal = useSelector((state) => state.app.modal);

  const closeModal = () => modal?.hide();

  const openPasswordModal = () => {
    modal.show(<PasswordModal closeModal={closeModal} />);
  };
  const openEmailModal = () => {
    modal.show(<EmailModal closeModal={closeModal} />);
  };
  const openMobileModal = () => {
    modal.show(<MobileModal closeModal={closeModal} />);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: "20px",
        borderRadius: "8px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "500", marginBottom: 3 }}>
          {t("accountSettings")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          // width: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            ".value": {
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? "primary.light"
                  : "primary.dark",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography variant="body1">{t("email")}</Typography>
              <Typography variant="body2" className="value">
                {user?.email}
              </Typography>
            </Box>
            <Button
              disabled={!!user?.email}
              onClick={openEmailModal}
              variant="contained"
            >
              {t("add")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1">{t("password")}</Typography>
            <Button onClick={openPasswordModal} variant="contained">
              {t("change")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserAccountSetting;
