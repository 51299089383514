import { store } from "app/store";

const localesDate = {
  fa: "fa",
  en: "en-US",
  tr: "tr",
  ar: "ar-sa",
};

export default function formatDate(date, config) {
  const { setting } = store.getState();
  const locale = config?.lang || localesDate[setting?.lang] || "en-US";

  if (!date) return "-";

  return new Date(date).toLocaleDateString(locale, config);
}
