import { Button, ButtonBase, Typography, Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { Close } from "@mui/icons-material";
import { t } from "locales";
import CircularProgress from "@mui/material/CircularProgress";
import { getHumanError } from "library/translateServerErrors";
import defaultProfileImg from "assets/img/default-profile.png";
import LinkIcon from "assets/icons/LinkIcon";
import { formatDistanceToNowStrict } from "date-fns";
import { useSelector } from "react-redux";
import PutOfferCryptoWars from "components/PutOfferCryptoWars";
import { useNavigate } from "react-router";
import { openToast } from "components/Toast";
import { useGetAuction } from "_hooks/CryptoWars/market";
import { MAIN_COIN } from "library/constants";
import {
  useGetAuctionOfferList,
  usePostAuctionOffer,
} from "_hooks/Shared/market";

const cardAttrItemsStyle = {
  height: "140px",
  border: "1px solid",
  borderColor: (theme) => `border.${theme.palette.mode}`,
  borderRadius: "3px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  px: "20px",
};

const SingleCardAuction = ({ onClose = () => {}, id, updateList }) => {
  const modal = useSelector((state) => state.app.modal);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { isLoading: loadingAuction, data } = useGetAuction({
    id,
    onError: () => {
      openToast("error", "auctionNotFound");
      onClose?.();
    },
  });
  const {
    data: offers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAuctionOfferList(id);

  const { isLoading: putOfferLoading, mutate: postAnOffer } =
    usePostAuctionOffer();

  const openPutOfferModal = () => {
    if (!user) {
      onClose?.();
      navigate("/auth/login");
    } else {
      modal.show(<PutOfferCryptoWars onClose={modal.hide} id={id} />);
    }
  };

  const buyImmediately = async () => {
    if (!user) {
      onClose?.();
      navigate("/auth/login");
    } else {
      modal.show(<PutOfferCryptoWars onClose={modal.hide} id={id} />);
    }
    // postAnOffer(
    //   {
    //     auctionId: id,
    //     amount: data?.immediatePrice,
    //   },
    //   {
    //     onSuccess: () => {
    //       openToast("success", "buyCardSuccessful");
    //       updateList?.();
    //       onClose?.();
    //     },
    //     onError: (error) => openToast("error", getHumanError(error)),
    //   }
    // );
  };

  if (loadingAuction || putOfferLoading) {
    return (
      <Box
        sx={{
          minWidth: "50vw",
          minHeight: "50vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 5,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "90vw",
        position: "relative",
        maxWidth: "1400px",
      }}
    >
      <Box
        sx={{ position: "absolute", top: "15px", right: "15px", zIndex: 10 }}
        onClick={onClose}
      >
        <ButtonBase
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: (theme) =>
              theme.palette.mode === "dark" ? "#eee" : "#414244",
            border: "1px solid",
            borderColor: (theme) => `border.${theme.palette.mode}`,
            borderRadius: "3px",
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      <Box
        sx={{
          // height: "460px",
          display: "flex",
          padding: "20px",
          position: "relative",
          transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          alignItems: "center",
          flexShrink: "0",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: "50%",
            maxHeight: "50%",
            img: {
              width: "100%",
              filter: "brightness(1.1)",
              borderRadius: "12px",
            },
          }}
        >
          <img src={getImageUrl(data?.image)} alt="" />
        </Box>
      </Box>
      <Box
        sx={{
          padding: { xs: "50px 15px", sm: "50px 40px 90px 40px" },
          // maxWidth: "1280px",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              {data?.power && (
                <Typography
                  variant="caption"
                  sx={{
                    padding: "1px 8px",
                    display: "inline-flex",
                    alignItems: "center",
                    flexShrink: "0",
                    borderRadius: "4px",
                    justifyContent: "center",
                    backgroundColor: (theme) =>
                      getPowerBadgeColor(data?.power, theme?.palette?.mode),
                    fontSize: "1rem",
                  }}
                >
                  {data?.power}
                </Typography>
              )}
              {!isNaN(data?.bonus) && (
                <Typography
                  variant="caption"
                  sx={{
                    display: "inline-flex",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#eee" : "#414244",
                    border: "1px solid",
                    borderColor: (theme) => `border.${theme.palette.mode}`,
                    padding: "1px 8px",
                    borderRadius: "4px",
                    fontSize: "1rem",
                  }}
                >
                  {data?.bonus}%
                </Typography>
              )}
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "primary.main",
                }}
              >
                {data?.name}
              </Typography>
              <Typography variant="body1">
                {data?.title} –{" "}
                {!isNaN(+data?.serialNumber) && data?.serialNumber !== null
                  ? `${data?.serialNumber}/${data?.initialNumber}`
                  : "Common"}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            p: "20px",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: (theme) => `border.${theme.palette.mode}`,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5" sx={{ fontWeight: "500" }}>
              {(+(data?.max || data?.basePrice))?.toLocaleString?.()}{" "}
              {MAIN_COIN}
            </Typography>
            <Typography variant="body1" sx={{ opacity: ".7" }}>
              {data?.end && formatDistanceToNowStrict(new Date(data?.end))}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 1 },
            }}
          >
            <ButtonBase
              onClick={buyImmediately}
              sx={{
                height: "100%",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
                borderRadius: "8px",
                backgroundColor: (theme) => theme.palette.solid.blue,
                color: (theme) => theme.palette.common.white,
                px: "25px",
                py: "18px",
                transition: "0.3s",
                "&:hover": {
                  boxShadow: "0px 0px 8px 2px #2b7ae5",
                }
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Buy it Now •{" "}
                {(+data?.immediatePrice)?.toLocaleString?.() || 0} {MAIN_COIN}
              </Typography>
            </ButtonBase>
            <Button
              onClick={openPutOfferModal}
              sx={(theme) => ({
                height: "100%",
                border: "rgb(179,17,12) solid 1px",
                backgroundColor: "rgb(179,17,12)",
                color: theme.palette.common.white,
                borderRadius: "8px",
                px: "25px",
                py: "18px",
                transition: "0.3s",
                fontSize: "1rem",

                "&:hover": {
                  color: "#000",
                  opacity: ".9",
                },
              })}
            >
              {t("bid")}
            </Button>
          </Box>
        </Box>

        {offers?.pages?.[0]?.total !== 0 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Typography variant="h5">{t("bids")}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
                borderRadius: "8px",
                "& > * + *": {
                  borderTop: "1px solid",
                  borderColor: (theme) => `border.${theme.palette.mode}`,
                },
              }}
            >
              {offers?.pages.map((group, index) =>
                group?.list?.map?.(({ amount, user, id }) => (
                  <Box
                    key={id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: "10px 20px",
                    }}
                  >
                    <Typography variant="body1">
                      {amount} {MAIN_COIN}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        img: {
                          width: "32px",
                          height: "32px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        },
                      }}
                    >
                      <Typography variant="body1">{user?.name}</Typography>
                      <img
                        src={getImageUrl(user?.avatar) || defaultProfileImg}
                        alt=""
                      />
                    </Box>
                  </Box>
                ))
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              {isFetchingNextPage ? (
                <CircularProgress size={25} />
              ) : hasNextPage ? (
                <Typography
                  onClick={fetchNextPage}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  {t("loadMore")}
                </Typography>
              ) : null}
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">{t("cardAttributes")}</Typography>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box sx={{ ...cardAttrItemsStyle }}>
                <Typography variant="body1">{t("coin")}</Typography>
                <Typography variant="h6">{data?.name}</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box sx={{ ...cardAttrItemsStyle }}>
                <Typography variant="body1">{t("type")}</Typography>

                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      bgcolor: data?.color,
                    }}
                  ></Box>
                  <Typography variant="h6">{data?.title}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box sx={{ ...cardAttrItemsStyle }}>
                <Typography variant="body1">{t("serialNumber")}</Typography>
                <Typography variant="h6">
                  {!isNaN(+data?.serialNumber) &&
                  data?.serialNumber !== null ? (
                    <>
                      {data?.serialNumber}
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{ opacity: 0.6 }}
                      >
                        /{data?.initialNumber}
                      </Typography>
                    </>
                  ) : (
                    "Common"
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {data?.txId !== "Common" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">Blockchain details</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: "20px",
                borderRadius: "8px",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
              }}
            >
              <Typography
                variant="body2"
                sx={{ opacity: ".8", fontWeight: "500" }}
              >
                NFT Token ID:
              </Typography>
              {data?.txId ? (
                <Link
                  target="_blank"
                  href={`https://polygonscan.com/tx/${data?.txId}`}
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontWeight: "600",
                    textDecoration: "none",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "primary.dark"
                        : "primary.light",
                    "& *": {
                      fill: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.primary.dark
                          : theme.palette.primary.light,
                    },
                  }}
                >
                  <span>
                    {data?.txId?.slice(0, 3) +
                      "..." +
                      data?.txId?.slice(
                        data?.txId.length - 3,
                        data?.txId.length
                      )}
                  </span>
                  <LinkIcon />
                </Link>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    opacity: ".8",
                    fontWeight: "500",
                    color: "rgb(171,15,9)",
                  }}
                >
                  Not minted
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SingleCardAuction;
