import { Button, ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { t } from "locales";
import { useSelector } from "react-redux";
import EditAuction from "./EditAuction";
import { format, formatDistanceToNow } from "date-fns";
import { MAIN_COIN } from "library/constants";
import { useDeleteMyAuction } from "_hooks/User/queries";

const SingleCard = ({
  power,
  id,
  image,
  status,
  basePrice,
  start,
  count,
  end,
  max,
  playerName,
  bonus,
  coin,
}) => {
  const modal = useSelector((state) => state.app.modal);
  const { mutate: deleteAuction } = useDeleteMyAuction();

  const openEditModal = () => {
    modal.show(<EditAuction id={id} onClose={() => modal.hide()} />);
  };

  const lastPrice = max || basePrice;
  const disabledActions = +count !== 0 || status !== "ACTIVE";

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        py: 2,
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "85px",

          img: {
            width: "85px",
          },
        }}
      >
        <img src={getImageUrl(image)} alt="" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              gap: 1,
              alignItems: "center",
            }}
          >
            {power && (
              <Box
                sx={{
                  width: "30px",
                  height: "24px",
                  display: "inline-flex",
                  alignItems: "center",
                  flexShrink: "0",
                  borderRadius: "36px",
                  justifyContent: "center",
                  backgroundColor: (theme) =>
                    getPowerBadgeColor(power, theme?.palette?.mode),
                }}
              >
                <Typography variant="caption">{power}</Typography>
              </Box>
            )}
            {!isNaN(bonus) && (
              <Typography
                variant="caption"
                sx={{
                  display: "inline-flex",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#eee" : "#414244",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "#414244" : "#fff",
                  padding: "1px 8px",
                  borderRadius: "3px",
                  height: "24px",
                }}
              >
                {bonus}%
              </Typography>
            )}
          </Box>
          <Typography variant="caption">
            {format(new Date(start), "MM/dd/yyyy")} {t("to")}{" "}
            {format(new Date(end), "MM/dd/yyyy")} ({" "}
            {formatDistanceToNow(new Date(end), { addSuffix: true })} )
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2">{playerName || coin}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {lastPrice} {MAIN_COIN}
            </Typography>
            <Typography variant="caption">
              {count} {t("bids")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <ButtonBase
              onClick={() => deleteAuction(id)}
              disabled={disabledActions}
              sx={{
                height: "100%",
                border: "1px solid",
                borderRadius: "4px",
                pr: "15px",
                pl: "15px",
                "&.Mui-disabled": {
                  opacity: 0.2,
                },
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                {t("delete")}
              </Typography>
            </ButtonBase>
            <Button
              disabled={disabledActions}
              onClick={openEditModal}
              color="primary"
              variant="contained"
              sx={(theme) => ({
                width: "fit-content",
                height: "100%",
                color: theme.palette.common.white,
                borderRadius: "4px",
                pr: "15px",
                pl: "15px",

                "&:hover": {
                  opacity: 0.9,
                },
              })}
            >
              {t("edit")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleCard;
