import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const headCells = [
  {
    id: "league",
    label: "league",
    align: "left",
    justify: "flex-start",
  },
  {
    id: "participants",
    label: "participants",
    align: "center",
    justify: "center",
  },
  {
    id: "cards",
    label: "minimumCard",
    align: "right",
    justify: "flex-end",
  },
];

function ListHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={"normal"}
            sx={{
              borderColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "#2a2e34 !important"
                  : "#eee !important",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: headCell.justify,
                  width: "100%",
                }}
              >
                {headCell?.icon ? (
                  <headCell.icon fontSize="small" />
                ) : (
                  t(headCell.label)
                )}
              </Box>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const List = ({ data, setprizes, loading = false }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("league");
  const [searchParams] = useSearchParams();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  const list = data?.find((item) => item?.id === searchParams.get("type"));

  return (
    <Box
      sx={{
        padding: "10px 20px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <TableContainer>
        <Table size="medium">
          <ListHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={list?.leagues?.length}
          />
          <TableBody>
            {list?.leagues?.map?.((item) => {
              const { image, id, title, minimumCard, prizes, participants } =
                item;

              return (
                <TableRow
                  onClick={() => setprizes(item)}
                  hover
                  tabIndex={-1}
                  key={id}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      borderColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? "#2a2e34 !important"
                          : "#eee !important",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5,
                        fontWeight: "500",
                        img: {
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        },
                      }}
                    >
                      {image?.[0] && <img src={getImageUrl(image)} alt="" />}
                      {title}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? "#2a2e34 !important"
                          : "#eee !important",
                    }}
                  >
                    {participants}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? "#2a2e34 !important"
                          : "#eee !important",
                    }}
                  >
                    {minimumCard}
                    {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {prizes?.map?.((item) => (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Box
                            sx={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              bgcolor: item.color,
                            }}
                          ></Box>
                          <Typography variant="body2"></Typography>
                        </Box>
                      ))}
                    </Box> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default List;
