import React, { useState, useEffect } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import { useSelector } from "react-redux";
import { SkeletonLoader } from "components/Skeleton";
import { Box } from "@mui/system";
import { Typography, Pagination } from "@mui/material";
import SelectInput from "components/SelectInput";
import { useLocation } from "react-router-dom";
import PaymentResult from "./PaymentResult";
import { useGetBuyCryptoHistory } from "_hooks/User/queries";

const STATUS = {
  0: "pending",
  "-1": "canceled",
  1: "successfull",
};
const STATUS_CLASSES = {
  0: "bg-warning", // pending
  "-1": "bg-danger", // canceled
  1: "bg-success", // success
};
const LOADING_LIST = Array(5).fill({
  id: "",
  amount: "",
  currency: "",
  gateway: "",
  status: "",
  traceNumber: "",
});
const TYPES = [
  { label: "Buy", id: "buy" },
  { label: "Sell", id: "sell" },
];

const BuyVlxHistory = () => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [page, setPage] = useState(1);
  const [selectedType, setSelectedType] = useState(TYPES[0]);
  const modal = useSelector((state) => state.app.modal);
  const location = useLocation();
  const { data: requestData, isLoading: loading } = useGetBuyCryptoHistory(
    selectedType.id,
    page
  );

  // Handle payment detail modal
  useEffect(() => {
    if (!modal) return;

    if (location.search && location.search !== "?tab=payment-history") {
      const search = location.search;
      const params = new URLSearchParams(search);
      const data = {
        message: params.get("message"),
        status: params.get("status"),
        refNum: params.get("refNum"),
        amount: params.get("amount"),
      };
      params.delete("message");
      modal.show(<PaymentResult data={data} onClose={modal.hide} />);
    }
  }, [modal]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const selectedTypeChangeHandler = (value) => setSelectedType(value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        p: "20px",
        borderRadius: "12px",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "500" }}>
        {t("paymentHistory")}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography variant="body2">{t("type")}</Typography>
            <SelectInput
              options={TYPES}
              onChange={selectedTypeChangeHandler}
              value={selectedType}
              containerSx={{
                fieldset: { border: "none !important" },
                ".MuiSelect-select": { py: "8px" },
              }}
              selectSx={{ bgcolor: "rgba(0,0,0,.2)", borderRadius: "0" }}
            />
          </Box>
        </Box>
        <DataTable
          data={loading ? LOADING_LIST : requestData?.list}
          columns={[
            {
              title: t("type"),
              field: "type",
              render: ({ type }) =>
                loading ? (
                  <SkeletonLoader width="40" height="15" isLight={isLight} />
                ) : (
                  <Typography variant="body2">
                    {t(type === "DEPOSIT" ? "buy" : "sell")}
                  </Typography>
                ),
            },
            {
              title: t("amount"),
              field: "amount",
              render: ({ amount }) =>
                loading ? (
                  <SkeletonLoader width="40" height="15" isLight={isLight} />
                ) : (
                  <Typography variant="body2">{amount}</Typography>
                ),
            },
            {
              title: t("currency"),
              field: "currency",
              render: ({ currency }) =>
                loading ? (
                  <SkeletonLoader width="40" height="15" isLight={isLight} />
                ) : (
                  <Typography variant="body2">{currency}</Typography>
                ),
            },
            {
              title: t("coin"),
              field: "coin",
              render: ({ asset }) =>
                loading ? (
                  <SkeletonLoader width="40" height="15" isLight={isLight} />
                ) : (
                  <Typography variant="body2">{asset?.coin}</Typography>
                ),
            },
            {
              title: t("gateway"),
              field: "gateway",
              render: ({ gateway }) =>
                loading ? (
                  <SkeletonLoader width="50" height="15" isLight={isLight} />
                ) : (
                  <Typography variant="body2">{t(gateway)}</Typography>
                ),
            },
            {
              title: t("status"),
              field: "status",
              render: ({ status }) =>
                loading ? (
                  <SkeletonLoader
                    width="50"
                    height="25"
                    borderRadius="10"
                    isLight={isLight}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    className={
                      "badge rounded-pill py-2 px-2 " + STATUS_CLASSES[status]
                    }
                  >
                    {typeof status === "number" ? t(STATUS[status]) : t(status)}
                  </Typography>
                ),
            },
            {
              title: t("traceNumber"),
              field: "traceNo",
              render: ({ traceNo }) => {
                return loading ? (
                  <SkeletonLoader width="40" height="15" isLight={isLight} />
                ) : (
                  <Typography variant="body2">{traceNo ?? "-"}</Typography>
                );
              },
            },
          ]}
        />
        {!loading && requestData?.list.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 5,
            }}
          >
            <Typography variant="body1">{t("noDataFound")}</Typography>
          </Box>
        ) : (
          <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
            <Pagination
              sx={{ width: "fit-content" }}
              count={requestData?.total || 1}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BuyVlxHistory;
