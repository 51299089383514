import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonBase,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { t } from "locales";
import { accordionStyle } from "assets/styles/styles";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { stringQueriesToObj } from "library/helper";
import { useGetCardTypeSelectors } from "_hooks/Shared/market";
import { SkeletonLoader } from "components/Skeleton";

const OptionsFilter = ({ options = [], title: mainTitle = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState(
    searchParams?.get(mainTitle) || null
  );
  const searchParamValue = searchParams.get(mainTitle);

  // clear selected when user clicked on Delete all filters button
  useEffect(() => {
    if (!searchParamValue) setSelected(null);
  }, [searchParamValue]);

  const changeHandler = (value) => {
    if (value === selected) setSelected(null);
    else setSelected(value);
  };

  useEffect(() => {
    if (!!selected) {
      const queries = { ...stringQueriesToObj(searchParams.toString() ?? "") };
      setSearchParams({ ...queries, [mainTitle]: selected });
    } else {
      const queries = {
        ...stringQueriesToObj(searchParams.toString() ?? "", mainTitle),
      };
      setSearchParams({ ...queries });
    }
  }, [selected]);

  const {
    data: cardTypes,
    isLoading: cardTypesLoading,
    isError,
  } = useGetCardTypeSelectors();
  const isLight = useSelector((state) => state.setting.theme === "light");
  const searchParamTypeValue = searchParams.get("type");

  const typeChangeHandler = (value) => {
    value === selected ? setSelected(null) : setSelected(value);
    if (value !== selected) {
      const sp = new URLSearchParams(searchParams.toString());
      sp.set('type', value);
      window.location.href = 'auction?' + sp.toString();
    }
  };

  return (
    <Accordion sx={{ ...accordionStyle }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t(mainTitle)}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            paddingBottom: "10px",
          }}
        >
          {options?.map(({ title, value }) =>
            value == "cards" ? (
              <Accordion sx={{ ...accordionStyle }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t(title)}
                </AccordionSummary>
                <AccordionDetails>
                  {!isError &&
                    (cardTypesLoading
                      ? Array(4)
                          .fill({})
                          .map((_, i) => (
                            <SkeletonLoader
                              key={i}
                              isLight={isLight}
                              borderRadius="4"
                              width="110"
                              height="40"
                            />
                          ))
                      : cardTypes.map(
                          ({ status, id, title, color }) =>
                            status === "ACTIVE" && (
                              <ButtonBase
                                key={id}
                                onClick={() => typeChangeHandler(id)}
                                sx={[
                                  {
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "7px 12px",
                                    borderRadius: "7px",
                                    gap: 1.2,
                                    height: "fit-content",
                                  },
                                ]}
                              >
                                <Box
                                  sx={{
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    bgcolor: color,
                                  }}
                                ></Box>
                                <Typography
                                  variant="body1"
                                  sx={{ whiteSpace: "nowrap" }}
                                >
                                  {title}
                                </Typography>
                              </ButtonBase>
                            )
                        ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <ButtonBase
                  key={title}
                  variant="text"
                  // onClick={() => changeHandler(value)}
                  sx={{
                    width: "fit-content",
                    justifyContent: "flex-start",
                    fontSize: "15px",
                    color: value === selected ? "primary.light" : "gray",
                  }}
                >
                  <Typography>{t(title)}</Typography>
                </ButtonBase>
              </>
            )
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default OptionsFilter;
