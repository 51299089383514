import { Tab, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import MarketsTab from "./MarketsTab";
import OverviewTab from "./OverviewTab";

const TabsSection = ({ details }) => {
  const [tab, setTab] = useState("overview");

  const handleChange = (tab, newValue) => {
    setTab(newValue);
  };
  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiTabPanel-root": { px: 0, py: 2 },
      }}
    >
      <TabContext value={tab}>
        <TabList onChange={handleChange}>
          <Tab value="overview" label="Overview" />
          <Tab value="markets" label="Markets" />
          <Tab value="historicalData" label="Historical Data" disabled />
          {/* <Tab value="news" label="News" />
          <Tab value="priceEstimates" label="Price Estimates" />
          <Tab value="moreInfo" label="More Info" /> */}
        </TabList>

        <TabPanel value="overview">
          <OverviewTab details={details} />
        </TabPanel>
        <TabPanel value="markets">
          <MarketsTab coinID={details.id} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default TabsSection;
