const marketTr = {
  auctionTitle: "Yeni Kart Müzayedeleri",
  auctionDesc:
    "En sevdiğiniz oyuncuların müzayedelerinde diğer menajerlerle yarışın",
  deleteAllFilters: "Tüm filtreleri sil",
  defender: "Savunucu",
  goalkeeper: "Kaleci",
  forward: "İleri",
  midfielder: "Orta saha oyuncusu",
  saleType: "Satış Türü",
  cards: "Kartlar",
  bundles: "Paketler",
  limited: "Sınırlı",
  rare: "Nadir",
  superRare: "Süper Nadir",
  unique: "Benzersiz",
  bid: "Teklif",
  marketLandingSubHeading: "Hayalinizdeki Takımı Oluşturun",
  marketLandingHeading: "NFT Pazar yeri",
  viewAllCards: "Tüm Kartları Görüntüle",
  viewAllBundles: "Tüm Paketleri Görüntüle",
  makeAnOffer: "Teklif sunun",
  buy: "Satın al",
  level: "Seviye",
  xp: "XP",
  type: "Tür",
  club: "Kulüp",
  serialNumber: "Seri Numarası",
  season: "Sezon",
  cardAttributes: "Kart özellikleri",
  connectToVolexWallet: "volex cüzdanına bağlanın",
  connectYourWallet: "Cüzdanınızı bağlayın",
  connect: "Bağlan",
  verifyYourWallet: "Cüzdanınızı doğrulayın",
  swapVlxToPlay: "VLX'i cüzdan oynamak için değiştirin",
  free: "Free",
  inGame: "Ücretsiz",
  inAuction: "Oyun İçinde",
  price: "Fiyat",
  position: "Pozisyon",
  basePrice: "Taban Fiyat",
  immediatePrice: "Hemen Fiyat",
  bookingPrice: "Rezervasyon Fiyatı",
  sell: "Sat",
  withdraw: "Withdraw",
  walletAddress: "Wallet Address",
  startTime: "Başlangıç tarihi",
  endTime: "Bitiş tarihi",
  walletBalance: "Cüzdan bakiyesi",
  enterYourOffer: "Teklifinizi girin",
  bids: "Teklifler",
  swap: "Takas",
  balance: "Denge",
  from: "Kimden",
  buyImmediately: "Hemen Satın Al",
  min: "Minimum",
  max: "Maksimum",
  // maxError: "Yetersiz bakiye",
  maxError: "Lütfen maksimumdan küçük bir değer girin",
  minError: "Lütfen minimumun üzerinde bir değer girin",
  noCardFound: "Kart bulunamadı!",
  newCards: "Yeni Kartlar",
  newBundles: "Yeni Paketler",
  newListed: "Yeni Listelenen",
  olderListed: "Daha Eski Listelenen",
  endingSoon: "Yakında Bitiyor",
  endingLater: "Daha Sonra Bitiyor",
  loadMore: "Daha Fazla Yükle",
  bundle: "Paket",
};

export default marketTr;
