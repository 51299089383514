import { Box } from "@mui/material";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { useState } from "react";
import axios from "library/http";
import { openToast } from "components/Toast";

const ArticleLikeButton = ({ article }) => {
  const [likes, setLikes] = useState(article.likeCount);
  const [liked, setLiked] = useState(article.liked);

  const clickHandler = () => {
    if (liked) {
      try {
        axios.delete(`/gravity/likes/article/${article.id}`);
        article.liked = false;
        article.likeCount--;
      } catch (error) {
        console.error(error);
        openToast("error", "Something goes wrong");
      }
    } else {
      try {
        axios.post(`/gravity/likes`, {
          resource: "article",
          resourceId: article.id,
        });
        article.liked = true;
        article.likeCount++;
      } catch (error) {
        console.error(error);
        openToast("error", "Something goes wrong");
      }
    }
    setLiked(article.liked);
    setLikes(article.likeCount);
  };
  return (
    <Box
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        cursor: "pointer",
        color: liked ? "red" : "text.secondary",
      }}
      onClick={clickHandler}
    >
      <FavoriteOutlinedIcon sx={{ mr: "5px", fontSize: "20px" }} />
      {likes}
    </Box>
  );
};

export default ArticleLikeButton;
