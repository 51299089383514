import { Box, Typography } from "@mui/material";
import CommentsSection from "./CommentsSection";

const LowerSection = ({ postID, refresh, resource }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Replies
      </Typography>
      <CommentsSection postID={postID} refresh={refresh} resource={resource} />
    </Box>
  );
};

export default LowerSection;
