import { Route, Routes } from "react-router";
import { PrivateRoute } from "./CustomRoutes";
import CryptoWarsPlayMain from "routes/CryptoWars/Gameplay/SingleCompetition";
import CryptoWarsMain from "routes/CryptoWars/Main";
import GameplayCryptoWars from "routes/CryptoWars/Gameplay/Team/index";
import CryptoWarsLeagueWizard from "routes/CryptoWars/Wizard/League";
import CryptoWarsCardWizard from "routes/CryptoWars/Wizard/Player";
import CryptoWarsHowToPlay from "routes/CryptoWars/HowToPlay";
import CryptoWarPlayCompetitions from "routes/CryptoWars/Gameplay/SingleCompetition/Competitions";
import CryptoWarsPlayOverview from "routes/CryptoWars/Gameplay/SingleCompetition/Overview";
import CryptoWarsPlayPrize from "routes/CryptoWars/Gameplay/SingleCompetition/Prize";
import CryptoWarsLeaderboards from "routes/CryptoWars/Gameplay/SingleCompetition/Leaderboards";
import CryptoWarsAuctionLanding from "routes/CryptoWars/Market/Landing";
import CryptoWarsAuctionDeposit from "routes/CryptoWars/Market/Deposit";
import CryptoWarsAuction from "routes/CryptoWars/Market/Auction";
import CryptoWarsAuctionSingleBundle from "routes/CryptoWars/Market/Auction/CryptoAuctionSingleBundle";
import CryptoWarsEvents from "routes/CryptoWars/Competitions";

export const cryptoWarsPublicRoutes = [
  {
    path: "/crypto-wars",
    component: CryptoWarsMain,
    exact: true,
  },
  {
    path: "/crypto-wars/market",
    component: CryptoWarsAuctionLanding,
    exact: true,
  },
  {
    path: "/crypto-wars/market/auction",
    component: CryptoWarsAuction,
    exact: true,
  },
  {
    path: "/crypto-wars/market/deposit",
    component: CryptoWarsAuctionDeposit,
    exact: true,
  },
  {
    path: "/crypto-wars/market/auction/bundle/:id",
    component: CryptoWarsAuctionSingleBundle,
    exact: true,
  },
  {
    path: "/crypto-wars/play",
    component: CryptoWarsEvents,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/crypto-wars/how-to-play",
    component: CryptoWarsHowToPlay,
    exact: true,
  },
];

export const cryptoWarsPrivateRoutes = [
  {
    path: "/crypto-wars/select-team/:leagueId_competitionId",
    component: GameplayCryptoWars,
    exact: true,
  },
  {
    path: "/crypto-wars/wizard/league",
    component: CryptoWarsLeagueWizard,
    exact: true,
  },
  {
    path: "/crypto-wars/wizard/player",
    component: CryptoWarsCardWizard,
    exact: true,
  },
];

export const cryptoWarsPlayRoutes = [
  {
    path: "/crypto-wars/play/:competitionId/competitions",
    component: CryptoWarPlayCompetitions,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/crypto-wars/play/:competitionId/overview",
    component: CryptoWarsPlayOverview,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/crypto-wars/play/:competitionId/prize",
    component: CryptoWarsPlayPrize,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/crypto-wars/play/:competitionId/leaderboards",
    component: CryptoWarsLeaderboards,
    exact: true,
    sidebar: true,
    banner: true,
  },
];

export const CryptoWarsPlayRoutes = () => (
  <Routes>
    {cryptoWarsPlayRoutes.map(
      ({ exact, path, component: Component, sidebar, banner }, i) => {
        return (
          <Route
            key={i}
            exact={exact}
            path={path}
            element={
              <CryptoWarsPlayMain sidebar={sidebar} banner={banner}>
                <Component />
              </CryptoWarsPlayMain>
            }
          />
        );
      }
    )}
  </Routes>
);

export const CryptoWarsPublicRoutes = () => (
  <Routes>
    {cryptoWarsPublicRoutes.map(({ exact, path, component: Component }, i) => {
      return (
        <Route key={i} exact={exact} path={path} element={<Component />} />
      );
    })}
  </Routes>
);

export const CryptoWarsPrivateRoutes = () => (
  <Routes>
    {cryptoWarsPrivateRoutes.map(({ component: Component, path }, i) => (
      <Route
        key={i}
        path={path}
        element={
          <PrivateRoute>
            <Component />
          </PrivateRoute>
        }
      />
    ))}
  </Routes>
);
