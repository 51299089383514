import { Box } from "@mui/system";
import Actions from "./Actions";
import Balance from "./Balance";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUserData, updateWallets } from "redux/auth/asyncActions";


const DashboardOverview = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateUserData());
    dispatch(updateWallets());
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "& > div": {
          borderRadius: "4px",
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        },
      }}
    >
      <Box sx={{ p: "20px 30px 30px 30px" }}>
        <Balance />
      </Box>
      <Box sx={{ py: "15px" }}>
        <Actions />
      </Box>
    </Box>
  );
  // return <Box>Dashboard Overview - Soon</Box>;
};

export default DashboardOverview;
