import { useDispatch, useSelector } from "react-redux";
import {
  Menu,
  MenuItem,
  Toolbar,
  Box,
  AppBar,
  Button,
  useMediaQuery,
  IconButton,
  Typography,
} from "@mui/material";
import { t } from "../../locales";
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import logoDark from "assets/img/logo-dark.png";
import logoLight from "assets/img/logo-light.png";
import logoDarkSmall from "assets/img/logo-dark-smal.png";
import logoLightSmall from "assets/img/logo-light-smal.png";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleSidebar } from "redux/app/appSlice";
import {
  DarkModeOutlined,
  LightMode,
  LanguageOutlined,
} from "@mui/icons-material";
import { toggleTheme } from "redux/setting/settingSlice";
import LanguageModal from "../LanguageModal";
import { OPPOSIT_THEME } from "library/constants";
import NavbarNotification from "./NavbarNotification";
import NavbarFinance from "./NavbarFinance";
import NavbarUser from "./NavbarUser";
import { NAV_ITEMS } from "./navbar.config";
import NavbarButton from "./NavbarButton";
import { useEffect, useState } from "react";
import axios from "library/http";
import { formatPrice, percentChange } from "library/helper";

const Navbar = () => {
  const isAuth = useSelector((state) => !!state.auth.user);
  const [MarketCap, setMarketCap] = useState(0);
  const [changeMarketCap, setChangeMarketCap] = useState(0);
  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { theme } = useSelector((state) => state.setting);
  const { mobileSidebar, modal } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/public/market-cap")
      .then((res) => {
        setMarketCap(res.data.data.marketCap);
        setChangeMarketCap(res.data.data.percentChange24h);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const toggleThemeHandler = () => {
    dispatch(toggleTheme());
  };

  const openLanguageModal = () => {
    modal?.show(<LanguageModal />);
  };
  return (
    <AppBar
      position="fixed"
      sx={[
        {
          bgcolor: (theme) => theme.palette.background[theme.palette.mode],
          backgroundImage: "none",
        },
        !mobileSidebar && {
          zIndex: (theme) => theme.zIndex.drawer + 1,
        },
      ]}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            // flexGrow: 1,
            a: {
              display: "flex",
            },
            img: {
              height: 40,
              margin: "10px 0",
            },
          }}
        >
          <Link to="/">
            {theme === "dark" ? (
              <img src={smallDevice ? logoDarkSmall : logoDark} alt="" />
            ) : (
              <img src={smallDevice ? logoLightSmall : logoLight} alt="" />
            )}
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 5,
            margin: "0 1rem",
            // width: "100%",
            width: "fit-content",
            flexGrow: 1,
          }}
        >
          {NAV_ITEMS.map((_, index) => (
            <NavbarButton key={index} {...NAV_ITEMS[index]} />
          ))}
        </Box>
        {MarketCap && changeMarketCap && (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#fff" : "#000",
              "& span": {
                fontSize: "10px",
              },
              ml: smallDevice ? 1 : 0,
            }}
          >
            <Typography component="span">
              Market Cap:{" "}
              <Typography
                component="span"
                sx={{ color: "primary.light", fontWeight: "500" }}
              >
                {formatPrice(MarketCap, 0, 0)}
              </Typography>
            </Typography>
            <Typography component="span" sx={{ display: "flex" }}>
              (24h change: {percentChange(changeMarketCap)})
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={toggleThemeHandler}
            size="large"
            sx={{ display: smallDevice ? "none" : "flex" }}
          >
            {theme === "light" ? (
              <DarkModeOutlined fontSize="small" sx={{ color: "gray" }} />
            ) : (
              <LightMode fontSize="small" sx={{ color: "gray" }} />
            )}
          </IconButton>

          <IconButton
            onClick={openLanguageModal}
            size="large"
            sx={{ display: smallDevice ? "none" : "flex" }}
          >
            <LanguageOutlined fontSize="small" sx={{ color: "gray" }} />
          </IconButton>

          {isAuth ? (
            <>
              <NavbarFinance />
              <NavbarNotification />
              <NavbarUser />
            </>
          ) : (
            <Box
              sx={{
                gap: 2,
                margin: "0 1rem",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={[
                  {
                    boxShadow: "none",
                    width: "fit-content",
                    padding: "7px 20px",
                  },
                  (theme) => ({
                    backgroundImage: theme.palette.gradient.purpule,
                    color: theme.palette.common.white,
                  }),
                ]}
                component={Link}
                to="/auth/login"
              >
                {t("login")}/{t("REGISTER")}
              </Button>
            </Box>
          )}
          <IconButton
            onClick={() => dispatch(toggleSidebar())}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <MenuIcon sx={{ color: "gray" }} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
