import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { formatDistanceToNowStrict } from "date-fns";
import { useNavigate } from "react-router";
import formatDate from "library/formatDate";
// import { CRYPTOWARS_END_TIME, CRYPTOWARS_START_TIME } from "library/constants";

const SoccerPlayBanner = ({ data }) => {
  const { title, startAt, endAt, status, previous, next, series } = data || {};
  const startTime = series + ":00";
  const endTime = series + ":00";

  var isLess;

  if (new Date(startAt + " " + startTime) > new Date()) {
    isLess = true;
  } else {
    isLess = false;
  }

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        gap: "10px",
        color: "white",
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        padding: "10px 20px",
        background: "linear-gradient(to right, #d2140c, #ab0f09);",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& > * + *": (theme) =>
              theme.direction === "ltr"
                ? {
                    borderLeft: "1px solid rgba(255,255,255,0.5)",
                    marginLeft: "10px !important",
                    paddingLeft: "10px !important",
                  }
                : {
                    borderRight: "1px solid rgba(255,255,255,0.5)",
                    marginRight: "10px !important",
                    paddingRight: "10px !important",
                  },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {t(status)}
          </Typography>
          {startAt && isLess && status === "OPEN" && (
            <Typography variant="body1">
              {formatDistanceToNowStrict(
                Date.parse(startAt + " " + startTime + "Z")
              )}{" "}
              {t("left")}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TodayOutlined fontSize="small" />
          {startAt && endAt && (
            <Typography
              variant="body2"
              sx={{ fontWeight: "500", color: "#ccc" }}
            >
              {formatDate(startAt, { day: "numeric", month: "short" })},{" "}
              {startTime}
              <Typography
                component="span"
                variant="body2"
                sx={{ textTransform: "lowercase" }}
              >
                {" "}
                {t("to")}{" "}
              </Typography>
              {formatDate(endAt, { day: "numeric", month: "short" })}, {endTime}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          svg: {
            transform: (theme) => theme.direction === "rtl" && "rotate(180deg)",
          },
        }}
      >
        <ButtonBase
          disabled={!previous}
          onClick={() =>
            navigate(`/investment/play/${previous?.id}/competitions`)
          }
          sx={{
            borderRadius: "6px",
            border: "1px solid #fff",
            opacity: !previous ? "0.5" : "1",
          }}
        >
          <ArrowLeftOutlined />
        </ButtonBase>

        <ButtonBase
          disabled={!next}
          onClick={() => navigate(`/investment/play/${next?.id}/competitions`)}
          sx={{
            borderRadius: "6px",
            border: "1px solid #fff",
            opacity: !next ? "0.5" : "1",
          }}
        >
          <ArrowRightOutlined />
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default SoccerPlayBanner;
