const errorsFa = {
  //************************* GAME CENTER ERRORS *************************
  "error-1000": "شماره موبایل وارد شده صحیح نمی باشد!",
  "error-1001": "ایمیل یا رمز عبور صحیح نمی باشد!",
  "error-1002": "شماره موبایل یا رمز عبور صحیح نمی باشد!",
  "error-1003": "شماره موبایل وارد شده قبلا ثبت نام کرده است!",
  "error-1004": "آدرس ایمیل وارد شده قبلا ثبت نام کرده است!",
  "error-1005": "کاربری با جزییات وارد شده یافت نشد",
  "error-1006": "توکن ارسالی صحیح نمی باشد.",
  "error-1007": "عملیات بروزرسانی رمز عبور ناموفق بود.",
  "error-1008": "رمز عبور صحیح نمی باشد",
  "error-1009": "عملیات بروزرسانی ناموفق بود.",
  "error-1010": "شما اجازه دسترسی ندارید.",
  "error-1011": "شبکه دارایی ها یافت نشد.",
  "error-1012": "حداقل مقدار قابل برداشت: ",
  "error-1013": "کیف پول کاربر یافت نشد.",
  "error-1014": "مقدار درخواستی بیشتر از موجودی می باشد.",
  "error-1015": "در انجام تراکنش مشکلی پیش آمده است.",
  "error-1016": "دارایی یافت نشد.",
  "error-1017": "در ثبت نام مشکلی پیش آمده است.",
  "error-1018": "در تایید توکن مشکلی پیش آمده است.",
  "error-1019": "در ثبت نام کاربر مشکلی پیش آمده است.",
  "error-1020": "در ثبت بلاگ مشکلی پیش آمده است.",
  "error-1021": "بلاگ یافت نشد.",
  "error-1022": "توکن پرداختی یافت نشد.",
  "error-1023": "تاریخچه پرداخت یافت نشد.",
  "error-1024": "بازنویسی تاریخچه پرداخت",
  "error-1025": "شماره ارجاع پرداخت یافت نشد.",
  "error-1026": "پرداخت ناموفق بود.",
  "error-1027": "در تایید پضعیت پرداخت مشکلی پیش آمده است.",
  "error-1028": "اعلانی یافت نشد.",
  "error-1029": "همپا یافت نشد.",
  "error-1030": "در ثبت پرداخت مشکلی پیش آمده است.",
  "error-1031": "در ثبت تنظیمان مشکلی پیش آمده است.",
  "error-1032": "تنظیمات یافت نشد.",
  "error-1033": "قبلا ویرایش شده است.",
  "error-1034": "تراکنش یافت نشد.",
  "error-1035": "دارایی یافت نشد",
  "error-1036": "کیف پول یافت نشد",
  "error-1037": "لیست یافت نشد",
  "error-1038": "در ورود مشکلی پیش آمده است.",
  "error-1039": "در بازیابی رمز عبور مشکلی پیش آمده است.",
  "error-1040": "شما اجازه دسترسی ندارد.",
  "error-1041": "مقدار کم است.",
  "error-1042": "موجودی کیف پول کم است.",
  "error-1043": "حساب بانکی یافت نشد.",
  "error-1044": "زیرگروه یافت نشد.",
  "error-1045": "درگاه پرداخت یافت نشد.",
  "error-1046": "مقدار در محدوده تاییدی نیست.",
  "error-1047": "در اتصال با درگاه پرداختی مشکلی پیش آمده است.",
  "error-1048": "در ساخت آدرس مشکلی پیش آمده است.",
  "error-1049": "در ثبت پیروزی مشکلی پیش آمده است",
  "error-1050": "پیروزی  یافت نشد!",
  "error-1051": "در ثبت مزایده مشکلی پیش آمده است!",
  "error-1052": "مزایده یافت نشد!",
  "error-1053": "درخواست یافت نشد!",
  "error-1054": "لطفا حداقل آدرس ایمیل یا شماره موبایلتان را وارد کنید!",
  "error-1055": "در اضافه کردن مشکلی پیش آمده است!",
  "error-1056": "در بروزرسانی مشکلی پیش آمده است!",
  "error-1057": "هنگام حذف مشکلی پیش آمده است!",
  "error-1058": "مورد یافت نشد!",
  "error-1059": "مورد قابل ویرایش نیست!",
  "error-1060": "تبادل ناموفق بود!",
  "error-1061": "شماره بازیکن ویا شماره دارایی را وارد کنید!",
  "error-1062": "در خواست مزایده یافت نشد!",
  "error-1063": "رقابت یافت نشد!",
  "error-1064": "رقابت لیگی وارد نشد!",
  "error-1065": "جایزه یافت نشد!",
  "error-1066": "استخر جایزه یافت نشد!",
  "error-1067": "یک یا چند درخواست مزایده وجود دارد!",
  "error-1068": "زبان یافت نشد!",
  "error-1069": "در ساخت زبان مشکلی پیش آمده است!",
  "error-1070": "کارت ها حداقل باید ۵ مورد باشد!",
  "error-1071": "جایگاه کارت اشتباه است!",
  "error-1072": "موجودی کیف پول کم است !",
  //************************* FC ERRORS *************************
  "error-2001": "در ساخت رقابت مشکلی پیش آمده است!",
  "error-2002": "رقابت ها یافت نشد!",
  "error-2003": "در ساخت کشور مشکلی پیش آمده است!",
  "error-2004": "کشور یافت نشد!",
  "error-2005": "در ساخت مسابقه مشکلی پیش آمده است!",
  "error-2006": "مسابقه یافت نشد!",
  "error-2007": "در ساخت بازیکن مشکلی پیش آمده است!",
  "error-2008": "بازیکن یافت نشد!",
  "error-2009": "در ساخت تیم مشکلی پیش آمده است!",
  "error-2010": "تیم یافت نشد!",
};

export default errorsFa;
