const contactTr = {
  contact: "İletişim",
  finance: "Finans",
  contactHeader: "Bir istek gönder",
  contactEmailLabel: "E-posta adresiniz",
  contactDepartmentLabel: "Departman",
  contactSubjectLabel: "Konu",
  contactDescriptionLabel: "Açıklama",
  contactHelperText:
    "Lütfen isteğinizin ayrıntılarını girin. Destek ekibimizin bir üyesi en kısa sürede yanıt verecektir.",
  contactFormEmailRequired: "E-posta doldurulması zorunlu bir alandır!",
  contactFormDepartmentRequired: "Departman doldurulması zorunlu bir alandır!",
  contactFormSubjectRequired: "Konu zorunlu bir alandır!",
  contactFormDescriptionRequired: "Açıklama doldurulması zorunlu bir alandır!",
};

export default contactTr;
