import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { MAIN_COIN } from "library/constants";
import { truncateToDecimals } from "library/helper";
import { useSelector } from "react-redux";

const Actions = () => {
  const wallets = useSelector((state) => state.auth.wallets);
  const [mainCoinImage, setMainCoinImage] = useState();
  const [xbtCoin, setXbtCoin] = useState();

  const fetchImage = async () => {
    const maincoin = MAIN_COIN ? MAIN_COIN : "USDT";
    const mainCoinImage = await import(
      `assets/icons/crypto/${maincoin.toLowerCase()}.png`
    );
    const xbtCoin = await import(`assets/icons/crypto/xbt.png`);
    setMainCoinImage(mainCoinImage);
    setXbtCoin(xbtCoin);
  };
  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="h6">Account Balance</Typography>
      <Box
        sx={{
          display: "flex",
          img: {
            width: "25px",
            height: "25px",
            borderRadius: "50%",
          },
          "& > div": {
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",

            "& > div": {
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
          },
          "& p": {
            fontWeight: "500",
          },
          "& span": {
            opacity: ".8",
          },
        }}
      >
        <Box>
          {/* <img src={wallets?.XBT?.icon} alt={wallets?.XBT?.coin} /> */}
          <img src={xbtCoin?.default} alt={wallets?.XBT?.coin} />
          <Box>
            <Typography variant="body1">
              {wallets?.XBT?.amount
                ? truncateToDecimals(wallets?.XBT?.amount, 2)
                : 0}{" "}
            </Typography>
            <Typography component="span" variant="body1">
              XBT
            </Typography>
          </Box>
        </Box>
        {/* <Box>
          <img src={mainCoinImage?.default} alt={wallets?.[MAIN_COIN]?.coin} />
          <Box>
            <Typography variant="body1">
              {wallets?.[MAIN_COIN]?.amount || 0}
            </Typography>
            <Typography component="span" variant="body1">
              {MAIN_COIN}
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Actions;
