import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Link, Grid, Divider, CircularProgress } from "@mui/material";
import Header from "routes/Community/components/Header";
import Articles from "routes/Community/components/Articles";
import { useState, useEffect } from "react";
import axios from "library/http";

const AllArticles = ({ active }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      let result = await axios.get("/gravity/articles");
      if (result.data?.status === "success") {
        setArticles(result.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Header active={active} />
      <Grid container>
        <Grid item xs={12} md={12} sx={{ px: 2 }}>
          <Box>
            <Link
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
              href="/community"
              color="text.primary"
              underline="none"
            >
              <ArrowBackIosNewIcon sx={{ fontSize: "12px", m: 2 }} />
              All Articles
            </Link>
            <Divider sx={{ my: 2 }} />
            {loading ? (
              <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
            ) : (
              <Articles articles={articles} />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AllArticles;
