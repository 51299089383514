import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { SkeletonLoader } from "./Skeleton";
import { ScaleLoader } from "react-spinners";

const CustomButton = ({
  loading,
  disabled,
  extraSx = {},
  type = "button",
  fullWidth,
  children,
  variant,
  loaderWidth = "100%",
  loaderHeight = "25",
  withSpinner = false,
  onClick = () => {},
}) => {
  const theme = useSelector((state) => state.setting.theme);

  const isLight = theme === "light";

  if (loading) {
    return withSpinner ? (
      <Button
        type={type}
        fullWidth={fullWidth}
        sx={{ ...extraSx }}
        disabled={loading || disabled}
        variant={variant}
      >
        <ScaleLoader height={20} width={3} color="#555" />
      </Button>
    ) : (
      <SkeletonLoader
        width={loaderWidth}
        height={loaderHeight}
        isLight={isLight}
      />
    );
  }

  return (
    <Button
      onClick={onClick}
      type={type}
      fullWidth={fullWidth}
      sx={{ ...extraSx }}
      disabled={loading || disabled}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
