import { Box, Typography, Link, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { SkeletonLoader } from "components/Skeleton";
import { getImageUrl } from "library/helper";
import { useGetTradingCards } from "_hooks/CryptoWars/landing";
import { useEffect, useState } from "react";

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  // slidesToShow: 6,
  slidesToScroll: 1,
  // responsive: [
  //   {
  //     breakpoint: 1200,
  //     settings: { slidesToShow: 4 },
  //   },
  //   {
  //     breakpoint: 900,
  //     settings: { slidesToShow: 4 },
  //   },
  //   {
  //     breakpoint: 500,
  //     settings: { slidesToShow: 2 },
  //   },
  // ],
  rows: 2,
  slidesPerRow: 3,
};
const Card = () => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [limit, setLimit] = useState(3);

  const { data = [], isLoading: loading } = useGetTradingCards();

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth < 786) {
        setLimit(1);
      } else {
        setLimit(3);
      }
    };

    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {loading
        ? Array(3)
            .fill({})
            .map((_, i) => (
              <Box key={i} sx={{ px: "10px" }}>
                <SkeletonLoader
                  isLight={isLight}
                  width="100%"
                  height="270"
                  borderRadius="10"
                />
              </Box>
            ))
        : data
            .slice(0, limit)
            .map(({ image, fakeImg, serialNumber, initialNumber, id }) => {
              const cardSerialTag =
                !isNaN(+serialNumber) && serialNumber !== null
                  ? `${serialNumber}/${initialNumber}`
                  : "Common";

              return (
                <Box
                  key={id}
                  sx={{
                    px: "10px",
                    margin: "0",
                    width: { md: "32%", sm: "100%" },
                    position: "relative",
                    mt: 2,
                    mb: 3,
                  }}
                >
                  <Box
                    component={Link}
                    to="/crypto-wars/market"
                    className="slider-item"
                    sx={{
                      padding: "0",
                      margin: "0",
                      height: "auto",
                      width: "100%",
                      position: "relative",
                      borderRadius: "12px",
                      textDecoration: "none",
                      color: "common.white",
                      filter: "brightness(1.1)",
                      img: {
                        display: "inline-block",
                        borderRadius: "12px",
                        transition: "box-shadow 0.1s ease-out",
                        objectFit: "cover",
                        alignSelf: "flex-start",
                        width: "100%",
                      },

                      transition: "transform 0.1s ease-out",
                      "&:hover": {
                        transform: "scale(1.02)",

                        img: {
                          boxShadow: (theme) =>
                            theme.palette.mode === "dark"
                              ? "2px 10px 32px -10px rgb(0 0 0 / 90%)"
                              : "2px 10px 32px -10px rgba(0,0,0,0.1)",
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        top: "5px",
                        left: "8px",
                        color: cardSerialTag === "Common" ? "#333" : "#eaeaea",
                        fontWeight: cardSerialTag === "Common" ? "500" : "400",
                      }}
                    >
                      {cardSerialTag}
                    </Typography>
                    <img src={image ? getImageUrl(image) : fakeImg} alt="" />
                  </Box>
                </Box>
              );
            })}
    </Box>
  );
};

const GetStart = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Box sx={{ display: "flex", p: 5 }}>
      <Box
        sx={{
          width: { md: "50%", sm: "100%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 5,
        }}
      >
        <Typography
          sx={{
            fontSize: "34px",
            fontWeight: "700",
            lineHeight: "130%",
            fontFamily: "poppins",
          }}
        >
          Take your first step into safe, secure NFT investing
        </Typography>
        <Button
          // as={Link}
          href={!user ? "/auth/login" : "/crypto-wars/how-to-play"}
          sx={(theme) => ({
            backgroundImage: theme.palette.gradient.purpule,
            color: theme.palette.common.white,
            width: "fit-content",
            padding: "15px 25px",
            textDecoration: "none",
            borderRadius: "5px",
            mt: 3,
          })}
        >
          Get Started
        </Button>
      </Box>
      <Box sx={{ width: { md: "50%", sm: "100%" } }}>
        <Card />
      </Box>
    </Box>
  );
};

export default GetStart;
