const BitTalkIcon = ({ color = "gray" }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="20px"
    height="20px"
  >
    {" "}
    <path
      fill={color}
      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 21 10 L 21 13 L 16 13 L 16 37 L 21 37 L 21 40 L 23 40 L 23 37 L 25 37 L 25 40 L 27 40 L 27 36.990234 C 29.298221 36.925203 34.800781 36.281048 34.800781 30.199219 C 34.800781 25.999219 31.199219 24.600391 29.699219 24.400391 L 29.699219 24.300781 C 30.399219 24.100781 33.900391 23.000391 33.900391 18.900391 C 33.900391 13.785878 29.056334 13.111433 27 13.021484 L 27 10 L 25 10 L 25 13 L 23 13 L 23 10 L 21 10 z M 18.300781 14.900391 L 26.199219 14.900391 C 29.799219 14.900391 31.599609 16.400391 31.599609 19.400391 C 31.599609 20.000391 31.500781 20.5 31.300781 21 C 31.100781 21.5 30.700781 21.900781 30.300781 22.300781 C 29.800781 22.700781 29.299609 22.999219 28.599609 23.199219 C 27.899609 23.399219 27.099219 23.5 26.199219 23.5 L 18.300781 23.5 L 18.300781 14.900391 z M 18.300781 25.5 L 26.199219 25.5 C 28.199219 25.5 29.800391 25.899219 30.900391 26.699219 C 32.000391 27.499219 32.5 28.699219 32.5 30.199219 C 32.5 31.199219 32.3 31.999609 32 32.599609 C 31.6 33.199609 31.199609 33.699609 30.599609 34.099609 C 29.999609 34.499609 29.399609 34.800391 28.599609 34.900391 C 27.899609 35.000391 27.099219 35.099609 26.199219 35.099609 L 18.300781 35.099609 L 18.300781 25.5 z"
    />
  </svg>
);

export default BitTalkIcon;
