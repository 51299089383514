import { Box, Menu, MenuItem, Button, Link } from "@mui/material";
import { OPPOSIT_THEME } from "library/constants";
import { useState, createRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { t } from "../../locales";

const NavbarButton = ({
  path,
  icon: Icon,
  title,
  needAuth,
  activeRegex,
  submenu,
}) => {
  const isAuth = useSelector((state) => !!state.auth.user);
  const { pathname } = useLocation();
  const isActive = !!activeRegex
    ? pathname.match(activeRegex)
    : pathname === path;
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event, submenu) => {
    // if (submenu && Array.isArray(submenu)) event.preventDefault();
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    (needAuth ? isAuth : true) && (
      <Link
        // as={NavLink}
        key={title}
        href={path || "#"}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          textDecoration: "none",

          color: "gray",
          "span, svg": {
            color: (theme) =>
              isActive
                ? `primary.${OPPOSIT_THEME[theme.palette.mode]}`
                : "gray",
          },
        }}
        onClick={(event) => handleClick(event, submenu)}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            position: "relative",
            zIndex: 9999,
          }}
        >
          <Icon fontSize="small" />
          <span>{t(title)}</span>
        </Box>
        {submenu && (
          <Box>
            <Menu
              id="submenu"
              anchorEl={anchorEl}
              open={open}
              hideBackdrop={true}
              onClose={handleClose}
              PaperProps={{
                style: {
                  top: "0 !important",
                },
              }}
              MenuListProps={{
                onMouseLeave: handleClose,
              }}
              sx={{
                position: "inherit",
                zIndex: "unset",
                "& .MuiPaper-root": {
                  position: "fixed",
                  zIndex: "1300",
                },
              }}
            >
              {submenu.map(({ path, icon: Icon, title }) => (
                <MenuItem key={title} to={path}>
                  <Link
                    href={path || ""}
                    sx={{
                      textDecoration: "none",
                      color: (theme) =>
                        isActive
                          ? `primary.${OPPOSIT_THEME[theme.palette.mode]}`
                          : "gray",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon fontSize="small" sx={{ mr: 0.5 }} />
                    {title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Link>
    )
  );
};

export default NavbarButton;
