import {
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Divider,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "library/http";
import Header from "routes/Community/components/Header";
import SquareIcon from "@mui/icons-material/Square";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ArticleLikeButton from "routes/Community/components/ArticleLikeButton";

const ArticlesDetails = ({ active }) => {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const SquareDivider = () => {
    return <SquareIcon sx={{ fontSize: "8px", color: "#D9D9D9" }} />;
  };

  useEffect(() => {
    const posts = async () => {
      try {
        const result = await axios.get("/gravity/articles/" + id);

        if (result.data?.status === "success") {
          setArticle(result.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    posts();
  }, []);

  let content = (content) => {
    return content.split("\n").map((line, index) => (
      <Typography key={index} sx={{ mb: 1 }}>
        {line}
      </Typography>
    ));
  };

  return (
    <>
      <Header active={active} />
      <Box sx={{ m: 2 }}>
        {loading ? (
          <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {article.title}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={article.profile.name}
                    src={
                      article.profile.avatar.length > 0
                        ? article.profile.avatar[0]
                        : "profile.jpg"
                    }
                    sx={{ width: "30px", height: "30px", mr: "5px" }}
                  />
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {article.profile.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  <RemoveRedEyeOutlinedIcon
                    sx={{ mr: "5px", fontSize: "20px" }}
                  />
                  {article.viewCount}
                </Box>

                <SquareDivider />

                <ArticleLikeButton article={article} />
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>{content(article.content)}</Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ArticlesDetails;
