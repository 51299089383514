import { Close } from "@mui/icons-material";
import { ButtonBase, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  getImageUrl,
  getPowerBadgeColor,
  truncateToDecimals,
} from "library/helper";
import React from "react";
import { useGetRankingDetails } from "_hooks/Investment/competitions";

const DetailModal = ({ onClose, competitionLeagueId, userId }) => {
  const { isLoading: loading, data: dataRanking } = useGetRankingDetails({
    id: competitionLeagueId,
    userId: userId,
  });

  if (loading) {
    return (
      <Box
        sx={{
          width: "40vw",
          minWidth: "300px",
          minHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 5,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "90vw",
        maxWidth: "500px",
        p: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "500" }}>
            {dataRanking?.data?.data?.user?.name}
          </Typography>
        </Box>
        <ButtonBase
          onClick={onClose}
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: (theme) =>
              theme.palette.mode === "dark" ? "#eee" : "#414244",
            border: "1px solid",
            borderColor: (theme) => `border.${theme.palette.mode}`,
            borderRadius: "3px",
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            img: { width: "45px", height: "45px", borderRadius: "8px" },
          }}
        >
          <img src={getImageUrl(dataRanking?.data?.data?.image)} alt="" />
          <Typography variant="body2" sx={{ fontWeight: "500" }}>
            {dataRanking?.data?.data?.title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {truncateToDecimals(dataRanking?.data?.data?.teamScore, 2)} pts
          </Typography>
        </Box>
      </Box>
      <Box>
        {dataRanking?.data?.data?.cards?.map((item) => {
          const { isCaptain, totalScore } = item || {};
          const { cardId, card } = item?.assignedCard || {};
          const { image, bonus, power, FC_player } = card || {};
          const { playerName } = FC_player || {};

          return (
            <Box
              key={cardId}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                p: "10px 0",
                height: "100%",
                position: "relative",
              }}
            >
              {isCaptain && (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    fontSize: "calc(30px * 3 / 4)",
                    boxShadow: "-6px 12px 20px rgb(0 0 0 / 30%)",
                    alignItems: "center",
                    fontWeight: "bold",
                    lineHeight: "1",
                    borderRadius: "50%",
                    justifyContent: "center",
                    backgroundColor: "yellow",
                    color: "#000",
                    top: "0px",
                    left: "-10px",
                    zIndex: "10",
                    position: "absolute",
                  }}
                >
                  C
                </Box>
              )}
              <Box
                sx={{
                  width: "65px",
                  img: {
                    width: "65px",
                  },
                }}
              >
                <img src={getImageUrl(image)} alt="" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    gap: 1,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      {power && (
                        <Box
                          sx={{
                            width: "30px",
                            height: "24px",
                            display: "inline-flex",
                            alignItems: "center",
                            flexShrink: "0",
                            borderRadius: "36px",
                            justifyContent: "center",
                            backgroundColor: (theme) =>
                              getPowerBadgeColor(power, theme?.palette?.mode),
                          }}
                        >
                          <Typography variant="caption">{power}</Typography>
                        </Box>
                      )}
                      {!isNaN(bonus) && (
                        <Typography
                          variant="caption"
                          sx={{
                            display: "inline-flex",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#eee"
                                : "#414244",
                            border: "1px solid",
                            borderColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#414244"
                                : "#fff",
                            padding: "1px 8px",
                            borderRadius: "3px",
                            height: "24px",
                          }}
                        >
                          {isCaptain ? +bonus + 20 : bonus}%
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body2">{playerName}</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="body1">
                    {truncateToDecimals(totalScore, 2) || 0} pts
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DetailModal;
