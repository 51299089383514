import axios from "library/http";
import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import Post from "routes/Community/components/Post";

const CommentsSection = ({ postID, refresh, resource }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const response = await axios.get(`gravity/comments`, {
        params: {
          resource: resource,
          resourceId: postID,
        },
      });
      if (response.data.status == "success") {
        setComments(response.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(true);
    }
  }, [refresh]);

  return (
    <Box sx={{ mt: 2 }}>
      {loading ? (
        <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
      ) : comments.length <= 0 ? (
        "No replies yet"
      ) : (
        comments.map((comment, index) => {
          return (
            <Post
              key={index}
              post={comment}
              isPostPage={true}
              isComment={true}
            />
          );
        })
      )}
    </Box>
  );
};

export default CommentsSection;
