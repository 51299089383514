import { Box, Divider } from "@mui/material";
import Article from "routes/Community/components/Article";

const Articles = ({ articles }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {articles.map((_, index) => (
        <Box key={index}>
          <Article article={_} />
          {articles.length - 1 !== index && <Divider sx={{ m: 1 }} />}
        </Box>
      ))}
    </Box>
  );
};

export default Articles;
