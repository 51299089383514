import i18n from "i18next";
import fa from "./fa/fa.js";
import en from "./en/en.js";
import tr from "./tr/tr.js";

i18n.init({
  lng: "en",
  resources: {
    fa: {
      translations: fa,
    },
    en: {
      translations: en,
    },
    tr: {
      translations: tr,
    },
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
export const t = (key, opt) => i18n.t(key, opt);
