import { Box, Link, CircularProgress, Tab, Divider, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "library/http";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Header from "routes/Community/components/Header";
import SideBar from "routes/Community/Feed/SideBar";
import Posts from "routes/Community/components/Posts";
import Articles from "routes/Community/components/Articles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Users from "routes/Community/components/Users";
import TopSearch from "routes/Community/Feed/TimeLine/Search";

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemloading, setItemLoading] = useState(true);
  const [value, setValue] = useState("posts");
  const [hasMore, setHasMore] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;
  const handleChange = (event, newValue) => {
    // Reset values for new tab
    setResult([]);
    setPage(1);
    setItemLoading(true);
    setValue(newValue);
  };

  let query = searchParams.get("q");

  const fetchMoreData = async () => {
    try {
      let query_result = await axios.get(`/gravity/${value}`, {
        params: { keyword: query, page: page, limit: limit },
      });

      if (query_result.data?.status === "success") {
        if (query_result.data.data.data.length > 0) {
          setResult([...result, ...query_result.data.data.data]);
          setPage(page + 1);
          setHasMore(query_result.data.data.total > limit * page);
        } else {
          setResult([]);
        }

        setLoading(false);
        setItemLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, [value]);

  return (
    <Box>
      <Header />
      <Grid container>
        <Grid item xs={12} md={8}>
          <Link
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            href="/community"
            color="text.primary"
            underline="none"
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "12px", m: 2 }} />
            Search Results
          </Link>
          <Divider sx={{ my: 2 }} />

          <Box sx={{ mx: 1 }}>
            <TopSearch defaultValue={query} />
          </Box>

          <Divider sx={{ my: 2 }} />
          {loading ? (
            <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
          ) : (
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} variant="fullWidth">
                    <Tab label="Posts" value="posts" />
                    <Tab label="Articles" value="articles" />
                    <Tab label="People" value="profiles" />
                  </TabList>
                </Box>

                <TabPanel value="posts">
                  {itemloading ? (
                    <CircularProgress
                      sx={{ display: "block", m: "0 auto", my: 3 }}
                    />
                  ) : result.length > 0 ? (
                    <Posts
                      posts={result}
                      hasMore={hasMore}
                      fetchMoreData={fetchMoreData}
                    />
                  ) : (
                    <Box>No Posts Found</Box>
                  )}
                </TabPanel>

                <TabPanel value="articles">
                  {itemloading ? (
                    <CircularProgress
                      sx={{ display: "block", m: "0 auto", my: 3 }}
                    />
                  ) : result.length > 0 ? (
                    <Articles articles={result} />
                  ) : (
                    <Box>No Articles Found</Box>
                  )}
                </TabPanel>

                <TabPanel value="profiles">
                  {itemloading ? (
                    <CircularProgress
                      sx={{ display: "block", m: "0 auto", my: 3 }}
                    />
                  ) : result.length > 0 ? (
                    <Users users={result} />
                  ) : (
                    <Box>No Users Found</Box>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <SideBar />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Search;
