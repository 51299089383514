import SearchIcon from "@mui/icons-material/Search";
import { TextField, Paper, InputAdornment, Button } from "@mui/material";
// import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Search = ({ defaultValue }) => {
  const navigate = useNavigate();


  return (
    <Paper
      sx={{
        width: "100%",
        mt: 2,
        backgroundColor: (theme) =>
          theme.palette.background[theme.palette.mode],
        boxShadow: "none",
        "& div": { p: 0.5 },
      }}
    >
      <form method="get" action="/community/search">
        <TextField
          placeholder="Search for posts or users..."
          autoComplete="off"
          fullWidth={true}
          name="q"
          defaultValue={defaultValue}
          required={true}
          multiline={false}
          // onChange={(e) => setSearch(e.target.value)}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.background[theme.palette.mode],
            "& .MuiOutlinedInput-root fieldset": {
              border: "none",
            },
            "& .MuiOutlinedInput-input": {
              py: 0.5,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="gray" />
              </InputAdornment>
            ),
            endAdornment: (
              <Button position="end" type="submit">
                Search
              </Button>
            ),
          }}
        />
      </form>
    </Paper>
  );
};

export default Search;
