const soccerTr = {
  competitions: "Yarışmalar",
  matches: "Maçlar",
  prize: "Ödül",
  gameWeek: "Oyun Haftası",
  open: "Aç",
  left: "Sol",
  manageMyTeams: "Ekiplerimi Yönet",
  select: "Seç",
  allMatches: "Tüm Maçlar",
  myMatches: "Eşleşmelerim",
  league: "Lig",
  count: "Saymak",
  cards: "Kartlar",
  podium: "Podyum",
  participation: "Katılım",
  to: "Kime",
  compete: "Yarışmak",
  managersEntered: "Yöneticiler girildi",
  minimumCard: "Minimum Kart",
  entranceFee: "Giriş Ücreti",
  participants: "Katılımcılar",
  tier: "Katman",
  registered: "Kayıtlı",
  noCardFound: "Kart bulunamadı!",
  submitTeam: "Ekibi gönder",
  live: "Canlı",
  leaderboards: "Lider tabloları",
  enterATeam: "Bir takım girin",

  captainModalTitle: "Kaptanınızı seçin",
  captainModalDesc:
    "Kaptan olarak seçtiğiniz oyuncu, puanının %20'sini alacaktır. Bu Oyun Haftasında çok iyi performans göstereceğini düşündüğünüz birini seçin!",
  captainModalConfirm: "Tamam!",
};

export default soccerTr;
