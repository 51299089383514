import { t } from "locales";
import NumberFormat from "react-number-format";
import { SkeletonLoader } from "components/Skeleton";
import { replaceComma } from "library/helper";
import { Box } from "@mui/system";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import SelectInput from "components/SelectInput";
import { FormHelperText, InputBase, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const NumberFormatCustom = function NumberFormatCustom(props) {
  return (
    <NumberFormat
      {...props}
      autoComplete="off"
      displayType="input"
      thousandSeparator={true}
      isNumericString
    />
  );
};

const DirectBuyForm = ({
  type,
  loading,
  assets,
  amount,
  error,
  amountChangeHandler,
  fiatChangeHandler,
  coinChangeHandler,
  selectedAssets,
  inputError,
  setInputError,
}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");

  const onAmountChange = (event) => {
    let enteredValue = replaceComma(event.target.value);
    enteredValue = parseInt(enteredValue);

    setInputError(null);
    amountChangeHandler(enteredValue);
  };

  return (
    <Box
      as="form"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-around",
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
        p: "30px 20px",
        borderRadius: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          width: { xs: "100%", sm: "fit-content" },
        }}
      >
        <Typography component="label" variant="body2" htmlFor="amount">
          {type === "BUY" ? t("iWantToSpend") : t("iWantToRecieve")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <InputBase
            type="text"
            value={amount}
            id={"amount"}
            autoComplete="off"
            placeholder={"Enter Amount"}
            onChange={onAmountChange}
            inputComponent={NumberFormatCustom}
            error={!inputError}
            inputProps={{
              thousandSeparator: true,
              isNumericString: true,
              displayType: "input",
            }}
            sx={{
              border: "none",
              px: 1.5,
              flexGrow: { xs: "1", md: "" },
              bgcolor: "rgba(0,0,0,.3)",
            }}
          />
          {loading ? (
            <SkeletonLoader width="200" height="58" isLight={isLight} />
          ) : (
            <SelectInput
              withIcon
              options={assets?.fiat || []}
              onChange={fiatChangeHandler}
              value={selectedAssets?.fiat}
              containerSx={{ fieldset: { border: "none !important" } }}
              selectSx={{ bgcolor: "rgba(0,0,0,.2)", borderRadius: "0" }}
            />
          )}
        </Box>
        <FormHelperText sx={{ color: "#f44336" }}>
          {t(inputError)}
        </FormHelperText>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          label: {
            width: "20px",
            height: "20px",
          },
        }}
      >
        <label></label>
        <ArrowRight />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          flexDirection: "column",
          label: {
            width: "20px",
            height: "20px",
          },
        }}
      >
        <label></label>
        <ArrowDropDown />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          width: {
            xs: "100%",
            sm: "fit-content",
          },
          ".MuiFormControl-root": {
            width: { xs: "100%", sm: "fit-content" },
          },
        }}
      >
        <Typography component="label" variant="body2">
          {type === "BUY" ? t("buy") : t("sell")}
        </Typography>
        {loading ? (
          <SkeletonLoader width="100%" height="58" isLight={isLight} />
        ) : (
          <Box>
            <SelectInput
              withIcon
              options={assets?.coin || []}
              onChange={coinChangeHandler}
              value={selectedAssets?.coin}
              containerSx={{ fieldset: { border: "none !important" } }}
              selectSx={{ bgcolor: "rgba(0,0,0,.2)" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DirectBuyForm;
