import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { hideMobileSidebar } from "redux/app/appSlice";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPath = useRef(pathname);
  const dispatch = useDispatch();

  useEffect(() => {
    // check for changing queries in tabs
    // example -> when "url.com/market?type=card" changed to "url.com/market?type=bundle"
    if (pathname !== prevPath.current) {
      window.scrollTo(0, 0);

      // close mobile sidebar if pathname change
      dispatch(hideMobileSidebar());
    }
    prevPath.current = pathname;
  }, [pathname]);

  return null;
}
