export const gameListStyles = {
  gameButton: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  gameWrapper: {
    borderTopRightRadius: "75px",
    borderTopLeftRadius: "75px",
    borderBottomRightRadius: "45px",
    borderBottomLeftRadius: "45px",
    paddingBottom: 2,
  },
  gameImageContent: {
    maxWidth: "454px",

    borderRadius: "75px",
    overflow: "hidden",
    "& img": {
      width: "100%",
      display: "block",
      objectFit: "cover",
    },
  },
};
