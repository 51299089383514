import { Box } from "@mui/system";
import CardsRow from "./CardsRow";
import BundlesRow from "./BundlesRow";
import {
  useGetAuctionList,
  useGetBundleAuctionList,
  useGetCardsBasedOnTypes,
} from "_hooks/CryptoWars/market";
import { useGetCardTypeSelectors } from "_hooks/Shared/market";

const MarketBody = () => {
  const { data: types } = useGetCardTypeSelectors();
  const cardsBasedOnTypes = useGetCardsBasedOnTypes(types);
  const {
    isLoading: loadingCards,
    data: auctionListData,
    refetch: refetchCard,
  } = useGetAuctionList();
  const {
    isLoading: loadingBundles,
    data: bundleListData,
    refetch: refetchBundle,
  } = useGetBundleAuctionList();

  return (
    <Box
      className="container"
      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
    >
      <CardsRow
        title="newCards"
        list={auctionListData?.list || []}
        refetchCallback={refetchCard}
        loading={loadingCards}
        alwaysShow
      />
      <BundlesRow
        title="newBundles"
        list={bundleListData?.list || []}
        refetchCallback={refetchBundle}
        loading={loadingBundles}
      />
      {cardsBasedOnTypes.map(({ data, isLoading, refetch }) =>
        isLoading
          ? null
          : !!data?.list?.length && (
              <CardsRow
                key={data.type}
                type={data.typeId}
                title={`${data.type} cards`}
                list={data?.list || []}
                refetchCallback={refetch}
                loading={loadingCards}
              />
            )
      )}
    </Box>
  );
};

export default MarketBody;
