import formatDate from "library/formatDate";
import { useSelector } from "react-redux";
import fileIcon from "assets/img/file.png";
import defaultAvatar from "assets/img/default-profile.png";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { Typography } from "@mui/material";
import { getImageUrl } from "library/helper";
import FileModal from "../../FileModal";

const IMAGES = ["png", "jpg", "svg", "gif", "jpeg"];

const SingleReply = ({ text, createdAt, file, userId, user, manager }) => {
  const { lang } = useSelector((state) => state.setting);
  const modal = useSelector((state) => state.app.modal);
  const type = userId ? "user" : "manager";

  const onOpenFile = (file) =>
    modal.show(<FileModal onClose={modal.hide} file={file} />);

  const renderedFiles = file?.map((f, i) => {
    const fileUrl = f?.location;
    const fileExtension = fileUrl.match(/[^\\]*\.(\w+)$/)?.[1];

    if (IMAGES.includes(fileExtension)) {
      return (
        <img
          key={f?.key ?? i}
          src={getImageUrl(null, f?.key) || f?.location}
          alt=""
          onClick={() => onOpenFile(f)}
        />
      );
    }

    return (
      <a key={f?.key ?? i} href={fileUrl}>
        <img key={f?.key ?? i} src={fileIcon} alt="" />
      </a>
    );
  });

  const sender = user ?? manager;

  return (
    <Box sx={{ ...styles.shared.reply, ...styles[type]?.reply }}>
      <Box sx={{ ...styles.shared.header, ...styles[type]?.header }}>
        {sender?.name}
      </Box>
      <Box sx={{ ...styles.shared.body, ...styles[type]?.body }}>
        <img alt="" src={getImageUrl(sender?.avatar) ?? defaultAvatar} />
        <Box sx={styles.shared.content}>
          <Typography
            variant="body1"
            sx={{ ...styles.shared.text, ...styles[type]?.text }}
          >
            {text}
          </Typography>
          <Typography
            variant="body2"
            sx={{ ...styles.shared.time, ...styles[type]?.time }}
          >
            {formatDate(createdAt, lang, { noYear: true })}
          </Typography>
          <Box sx={{ ...styles.shared.files, ...styles[type]?.files }}>
            {renderedFiles}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleReply;
