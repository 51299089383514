const authTr = {
  verifyDesc:
    "Lütfen (#) adresine gönderilen 4 haneli doğrulama kodunu giriniz. Kod 1 dakika geçerlidir.",
  email: "E-Posta",
  mobile: "Mobil",
  password: "Parola",
  passwordConfirmation: "Parola onayı",
  name: "İsim",
  login: "Giriş",
  signin: "Oturum Açma",
  signIn: "Oturum Açma",
  signup: "Kayıt ol",
  signUp: "Kayıt ol",
  LOGIN: "Giriş",
  SIGNUP: "Kayıt ol",
  REGISTER: "Kayıt ol",
  signupAgreement: "Hizmet Şartlarını okudum ve kabul ediyorum.",
  verify: "Doğrula",
  verification: "Doğrulama",
  alreadyHaveAnAccount: "Zaten bir hesabınız var mı?",
  forgotPassword: "Şifremi mi unuttum?",
  freeRegistration: "Ücretsiz kayıt",
  back: "Geri",
  next: "Sonraki",
  FORGOT_PASSWORD: "Şifremi Unuttum",
  resetPassword: "Şifreyi Sıfırla",
  resend: "Yeniden gönder",
  mobileNotValid: "Mobil geçerli değil!",
};

export default authTr;
