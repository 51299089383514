import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Pagination,
  Link,
  CircularProgress,
} from "@mui/material";
// import { formatPrice } from "library/helper";
import axios from "library/http";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "config/Config";
import { formatPrice } from "library/helper";

const ExchangesList = () => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [list, setList] = useState();
  const [pagesize, setPagesize] = useState(1);

  const handleChange = (event, value) => {
    setLoading(true);
    setPage(value);
  };

  useEffect(() => {
    const list = async () => {
      try {
        const result = await axios.get("public/exchanges", {
          params: { page: page },
        });
        if (result.data?.status == "success") {
          setList(result.data.data.data);
          setTotal(
            Math.ceil(result.data.data.total / result.data.data.pageSize)
          );
          setPagesize(result.data.data.pageSize);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    list();
  }, [page]);

  let count = (page - 1) * pagesize;

  return (
    <Box m={2}>
      <Typography variant="h4" component="h1" fontWeight="500" gutterBottom>
        Top Cryptocurrency Spot Exchanges
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Exchanges ranks and scores based on traffic, liquidity, trading volumes,
        and confidence in the legitimacy of trading volumes reported.
      </Typography>
      <TableContainer>
        <Table aria-label="exchanges table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Exchange Score</TableCell>
              <TableCell>Volume (24h)</TableCell>
              <TableCell>Traffic (Monthly)</TableCell>
              <TableCell>Volume Graph (7d)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array(pagesize)
                .fill(1)
                .map((_, i) => (
                  <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress />
                  </Box>
                ))
            ) : (
              <>
                {list?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{++count}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          "& a": {
                            display: "flex",
                            alignItems: "center",
                          },
                          "& img": { mr: 0.5 },
                        }}
                      >
                        <Link
                          href={"/exchanges/" + item.slug}
                          underline="none"
                          color="textPrimary"
                        >
                          <img
                            src={item.icon[0].location}
                            alt={item.name}
                            width="24"
                          />
                          {item.name}
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell>{item.score}</TableCell>
                    <TableCell>{formatPrice(item.volume24h)}</TableCell>
                    <TableCell>
                      {formatPrice(item.traffic, 0, 0, false)}
                    </TableCell>
                    <TableCell>
                      <img
                        src={
                          BASE_URL +
                          "public/sparklines/exchanges/" +
                          item.id +
                          ".svg"
                        }
                        alt={item.name + " 7 days chart"}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ width: "fit-content", mx: "auto", my: "1rem" }}
        count={total}
        page={page}
        color="primary"
        onChange={handleChange}
      />
    </Box>
  );
};

export default ExchangesList;
