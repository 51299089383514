import { useEffect, useState } from "react";
import axios from "library/http";
import { Box, Typography, CircularProgress } from "@mui/material";
import ChartSection from "./ChartSection";

const OverviewTab = ({ details }) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const getPoints = async () => {
      try {
        const chart = await axios.get("public/charts/" + details.id + "/1D");

        if (chart.data?.status == "success") {
          const points = chart.data.data.points;

          // Get first value of each points arrays and store in a new value
          var tmpValues = [];
          for (var point in points) {
            var date = new Date(point * 1000);
            tmpValues.push({ x: date, y: points[point].v[0] });
          }

          setValues(tmpValues);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getPoints();
  }, []);

  return (
    <Box>
      <Typography variant="h4">{details.name} to USD Chart</Typography>

      {loading ? (
        <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
      ) : (
        <ChartSection values={values} />
      )}
    </Box>
  );
};

export default OverviewTab;
