const STATUS_COLOR = {
  CLOSED: "#ff3838",
  PENDING: "#D29D2B",
  REPLIED: "#019267",
  CREATED: "#7882A4",
};

export const styles = {
  status: (status) => ({
    color: "#fff",
    bgcolor: STATUS_COLOR[status],
    width: "fit-content",
    borderRadius: "5px",
    p: "1px 8px",
  }),

  singleTicket: {
    display: "flex",
    flexDirection: "column",

    "& > div": {
      display: "flex",
      flexDirection: "column",
      padding: "1.5rem",
      bgcolor: (theme) => `card.${theme.palette.mode}`,
      borderRadius: "0.75rem",
      mb: "1rem",
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
  },

  headerTitle: {
    "& > span": {
      color: "primary.light",
    },
  },

  back: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    color: "inherit",
    textDecoration: "none",
    mb: 2,
    width: "fit-content",
  },

  ticketDetail: {
    mt: 2,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },

  firstRowDetail: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: { xs: 2, sm: 0 },

    "& > div": {
      display: "flex",
      flexDirection: { xs: "column", md: "row" },
      gap: { xs: 2, md: 0 },
      width: { xs: "100%", md: "50%" },

      "& > div": {
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", md: "50%" },
        pr: 2,
      },
    },
  },
  secondRowDetail: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", sm: "row" },
    gap: { xs: 2, md: 0 },

    "& > div": {
      display: "flex",
      flexDirection: "column",
      width: { xs: "100%", md: "50%" },
      pr: 2,
    },
  },

  ticketDetailItemLabel: {
    opacity: 0.7,
  },

  ticketFiles: {
    display: "flex",
    gap: "0.5rem",
    flexwrap: "wrap",
    mt: "0.5rem",

    img: {
      width: "50px",
      height: "50px",
      cursor: "pointer",
      objectFit: "contain",
    },
  },

  progressWrapper: {
    margin: "2rem 0",

    ".progress": {
      borderradius: "3px !important",
    },
  },

  footer: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "center",
    justifyContent: "space-between",
    mt: "1rem",
  },

  fileInput: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },

  dropzone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
    padding: "0.75rem 3rem",
    bgcolor: "#9443ca23",
    cursor: "pointer",
  },

  dropzoneText: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },

  selectedFiles: {
    display: "flex",
    flexDirection: "column",
    margin: "1rem 0 1.5rem 0",
  },

  singelFile: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",

    "& > svg": {
      transform: "translateX(20px)",
      opacity: "0",
      zIndex: "100",
      transition: "all 300ms ease",
      cursor: "pointer",
      color: "red",
    },

    "&:hover": {
      "& > svg": {
        opacity: "1",
        transform: "translateX(0)",
      },
    },
  },

  singelFileName: {
    zIndex: "200",
  },

  submitBtn: {
    display: "flex",
    alignItems: "flex-end",

    "& > button": {
      outline: "none",
      border: "none",
      p: "10px 24px",
      borderradius: "0.3rem",
      background: "linear-gradient(to right, #5b1af2, #8b01d2)",
      color: "#fff",
      transition: "background-color 250ms",
      width: "fit-content",

      "&:disabled": {
        background: "rgb(47, 50, 65) !important",
      },
    },
  },

  noReply: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    py: 3,
  },

  pagination: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    my: 2,
  },
};
