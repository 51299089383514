import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import defaultProfileImg from "assets/img/default-profile.png";
import { Controller, useForm } from "react-hook-form";
import { inputAutoFillStyles } from "assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BASE_URL } from "config/Config";
import outAxios from "axios";
import { updateUserData } from "redux/auth/asyncActions";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import AvatarImageCropper from "components/AvatarCropper";
import { b64toBlob } from "library/helper";
import { openToast } from "components/Toast";

const MIN_FILE_SIZE = 3000000; // 3MB

const UserProfile = () => {
  const modal = useSelector((state) => state.app.modal);
  const { user, accessToken } = useSelector((state) => state.auth);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState(user?.avatar ?? defaultProfileImg);
  const dispatch = useDispatch();
  const setImageFromCropper = async (image) => {
    const blob = await b64toBlob(image);
    setPreview(image);
    setFile(blob);
    modal.hide();
  };
  const openCropperModal = (image) => {
    modal.show(
      <AvatarImageCropper
        imageSrc={image}
        createImage={(image) => {
          setImageFromCropper(image);
        }}
        closeModal={modal.hide}
        minSize={MIN_FILE_SIZE}
      />
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const selectedFile = acceptedFiles?.[0];

      if (selectedFile?.size <= MIN_FILE_SIZE) {
        openCropperModal(window.URL.createObjectURL(selectedFile));
      } else {
        openToast("warning", "Maximum supported file size is 1MB!");
      }
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { name: user?.name ?? "", clubName: "", status: "" },
  });

  const submitHandler = async (data) => {
    const { name } = data;

    const formData = new FormData();
    formData.append("name", name);
    if (file) formData.append("avatar", file);

    const config = {
      method: "put",
      url: `${BASE_URL}user`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authorization: `Bearer ${accessToken}`,
      },
      data: formData,
    };

    await outAxios(config);

    await dispatch(updateUserData());
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview);
    };
  }, [preview]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: "20px",
        borderRadius: "8px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "500" }}>
          {t("publicProfile")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "fit-content",
        }}
      >
        <Box
          as="form"
          onSubmit={handleSubmit(submitHandler)}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              img: { width: "96px", maxHeight: "200px" },
            }}
          >
            <img src={preview} alt="" />{" "}
            <Box {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Button type="button" fullWidth variant="contained">
                {t("uploadAnImage")}
              </Button>
            </Box>
          </Box>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                error={errors?.name}
                helperText={t(errors?.name?.message)}
                fullWidth
                name="name"
                label={t("name")}
                type="name"
                id="name"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={inputAutoFillStyles}
              />
            )}
          />
          {/* <Controller
            control={control}
            name="clubName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                error={errors?.clubName}
                helperText={t(errors?.clubName?.message)}
                fullWidth
                name="clubName"
                label={t("clubName")}
                type="clubName"
                id="clubName"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={inputAutoFillStyles}
              />
            )}
          /> */}
          {/* <Controller
            control={control}
            name="status"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                error={errors?.status}
                helperText={t(errors?.status?.message)}
                fullWidth
                name="status"
                label={t("status")}
                type="status"
                id="status"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={inputAutoFillStyles}
              />
            )}
          /> */}
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isSubmitting}
            disabled={!_.isEmpty(errors, true)}
            loaderHeight="40"
            withSpinner
          >
            {t("updateProfile")}
          </CustomButton>
        </Box>
        {/* <Box>
          <ButtonBase
            sx={{
              display: "flex",
              gap: 1.5,
              alignItems: "center",
              p: "5px 15px",
              bgcolor: "solid.discord",
              color: "common.white",
              borderRadius: "8px",
            }}
          >
            <DiscordIcon height="22" />
            <Typography variant="body1">
              {t("connectYourDiscordAccount")}
            </Typography>
          </ButtonBase>
        </Box>
        <Box>
          <ButtonBase
            sx={{
              display: "flex",
              gap: 1.5,
              alignItems: "center",
              p: "5px 15px",
              bgcolor: "solid.twitter",
              color: "common.white",
              borderRadius: "8px",
            }}
          >
            <Twitter />
            <Typography variant="body1">
              {t("connectYourTwitterAccount")}
            </Typography>
          </ButtonBase>
        </Box> */}
      </Box>
    </Box>
  );
};

export default UserProfile;
