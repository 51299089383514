import { Typography, Grid, Link, ButtonBase } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { t } from "locales";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import axios from "library/http";
import LinkIcon from "assets/icons/LinkIcon";
import { useParams } from "react-router";
import { Close } from "@mui/icons-material";
import { openToast } from "components/Toast";

const cardAttrItemsStyle = {
  height: "140px",
  border: "1px solid",
  borderColor: (theme) => `border.${theme.palette.mode}`,
  borderRadius: "3px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  px: "20px",
};

const SingleCard = ({ onClose, cardId }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .get(`public/card/${id || cardId}`)
      .then((res) => {
        setData(res.data?.data);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        openToast("error", "auctionNotFound");
        onClose?.();
      });
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 5,
          },
          onClose && {
            minWidth: "50vw",
            minHeight: "50vh",
          },
        ]}
      >
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box
      className={!onClose ? "container" : ""}
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          position: "relative",
        },
        onClose && {
          maxWidth: "1400px",
          width: "90vw",
        },
      ]}
    >
      {!!onClose && (
        <Box
          sx={{ position: "absolute", top: "15px", right: "15px", zIndex: 10 }}
          onClick={onClose}
        >
          <ButtonBase
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        </Box>
      )}
      <Box
        sx={{
          // height: "460px",
          display: "flex",
          padding: "20px",
          position: "relative",
          transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          alignItems: "center",
          flexShrink: "0",
          justifyContent: "center",
          // bgcolor,
        }}
      >
        <Box
          sx={{
            maxWidth: "50%",
            maxHeight: "50%",
            img: {
              width: "100%",
              filter: "brightness(1.1)",
              borderRadius: "12px",
            },
          }}
        >
          <img src={getImageUrl(data?.image)} alt="" />
        </Box>
      </Box>
      <Box
        sx={{
          padding: "50px 40px 90px 40px",
          // maxWidth: "1280px",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              {data?.power && (
                <Typography
                  variant="caption"
                  sx={{
                    padding: "1px 8px",
                    display: "inline-flex",
                    alignItems: "center",
                    flexShrink: "0",
                    borderRadius: "4px",
                    justifyContent: "center",
                    backgroundColor: (theme) =>
                      getPowerBadgeColor(data?.power, theme?.palette?.mode),
                    fontSize: "1rem",
                  }}
                >
                  {data?.power}
                </Typography>
              )}
              {!isNaN(data?.bonus) && (
                <Typography
                  variant="caption"
                  sx={{
                    display: "inline-flex",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#eee" : "#414244",
                    border: "1px solid",
                    borderColor: (theme) => `border.${theme.palette.mode}`,
                    padding: "1px 8px",
                    borderRadius: "4px",
                    fontSize: "1rem",
                  }}
                >
                  {data?.bonus}%
                </Typography>
              )}
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "primary.main",
                }}
              >
                {data?.name}
              </Typography>
              <Typography variant="body1">
                {data?.cardType?.title} –{" "}
                {!isNaN(+data?.tokens?.serialNumber) &&
                data?.tokens?.serialNumber !== null
                  ? `${data?.tokens?.serialNumber}/${data?.initialNumber}`
                  : "Common"}
              </Typography>
            </Box>
          </Box>
          {/* <Box>
            <ButtonBase
              sx={{
                width: "60px",
                height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#eee" : "#414244",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
                borderRadius: "3px",
              }}
            >
              <Favorite fontSize="small" />
            </ButtonBase>
          </Box> */}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">{t("cardAttributes")}</Typography>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box sx={{ ...cardAttrItemsStyle }}>
                <Typography variant="body1">{t("coin")}</Typography>
                <Typography variant="h6">{data?.name}</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box sx={{ ...cardAttrItemsStyle }}>
                <Typography variant="body1">{t("type")}</Typography>

                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      bgcolor: data?.cardType?.color,
                    }}
                  ></Box>
                  <Typography variant="h6">{data?.cardType?.title}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box sx={{ ...cardAttrItemsStyle }}>
                <Typography variant="body1">{t("serialNumber")}</Typography>
                <Typography variant="h6">
                  {!isNaN(+data?.tokens?.serialNumber) &&
                  data?.tokens?.serialNumber !== null ? (
                    <>
                      {data?.tokens?.serialNumber}
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{ opacity: 0.6 }}
                      >
                        /{data?.initialNumber}
                      </Typography>
                    </>
                  ) : (
                    "Common"
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {data?.tokens?.txId !== "Common" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">Blockchain details</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: "20px",
                borderRadius: "8px",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
              }}
            >
              <Typography
                variant="body2"
                sx={{ opacity: ".8", fontWeight: "500" }}
              >
                NFT Token ID
              </Typography>
              <Link
                target="_blank"
                href={`https://bscscan.com/tx/${data?.tokens?.txId}`}
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: "600",
                  textDecoration: "none",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.dark"
                      : "primary.light",
                  "& *": {
                    fill: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  },
                }}
              >
                <span>
                  {data?.tokens?.txId?.slice(0, 3) +
                    "..." +
                    data?.tokens?.txId?.slice(
                      data?.tokens?.txId.length - 3,
                      data?.tokens?.txId.length
                    )}
                </span>
                <LinkIcon />
              </Link>
              <Typography
                variant="body2"
                sx={{ opacity: ".8", fontWeight: "500" }}
              >
                OpenSea Page
              </Typography>
              <Link
                target="_blank"
                href={`https://opensea.io/assets/matic/0xf3694f1a369b47b4653828d978ae96b8bd04fea4/${data?.tokenId}`}
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: "600",
                  textDecoration: "none",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.dark"
                      : "primary.light",
                  "& *": {
                    fill: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  },
                }}
              >
                <span>
                  {data?.tokenId?.slice(0, 3) +
                    "..." +
                    data?.tokenId?.slice(
                      data?.tokenId.length - 3,
                      data?.tokenId.length
                    )}
                </span>
                <LinkIcon />
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SingleCard;
