import { Box, Typography, Avatar, Link } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BottomButtons from "./BottomButtons";
import RepeatIcon from "@mui/icons-material/Repeat";
import MoreMenu from "../MoreMenu";

const Post = ({ post, isPostPage = false, isComment }) => {
  let navigate = useNavigate();

  const postID = post.id;
  const repostID = post.postId || null;
  const profileId = post.profile.userId;
  const main_name = post.profile.name;

  // const isQuote = repostID && content == " " ? true : false;
  if (
    repostID
    //&& !isQuote
  ) {
    post = post.post;
  }

  const renderLine = (line) => {
    const words = line.split(" ");
    const renderedWords = words.map((word, index) => {
      let urlRegex = /(https?:\/\/)?(www\.)?([^\s]+\.[^\s]+)/g;
      let link = word.match(urlRegex);

      if (link) {
        let url = link[0];
        let text = url;
        if (!url.startsWith("http://") && !url.startsWith("https://"))
          url = "http://" + url;

        if (url.length > 30) {
          text = url.substring(0, 30) + "...";
        }

        return (
          <Fragment key={index}>
            <Link href={url} target="_blank" rel="noreferrer">
              {word.replace(urlRegex, text)}
            </Link>{" "}
          </Fragment>
        );
      } else if (word.startsWith("#") || word.startsWith("$")) {
        return (
          <Fragment key={index}>
            <Link href={"/community/search?q=" + encodeURIComponent(word)}>
              {word}
            </Link>{" "}
          </Fragment>
        );
      }
      //  else if (word.startsWith("@")) {
      //   return (
      //     <>
      //       <Link key={index} href={"/community/users/" + word.substring(1)}>
      //         {word}
      //       </Link>{" "}
      //     </>
      //   );
      // }

      return word + " ";
    });

    return <Typography sx={{ mb: 1 }}>{renderedWords}</Typography>;
  };

  let content = post.content
    .split("\n")
    .map((line, index) => <Fragment key={index}>{renderLine(line)}</Fragment>);

  const name = post.profile.name;
  const username = post.profile.slug;
  const date = new Date(post.createdAt);
  const avatar =
    post.profile.avatar.length > 0
      ? post.profile.avatar[0]?.location
      : "./broken.jpg";
  const authorId = post.profile.userId;
  const media = post.image?.length > 0 ? post.image[0].location : null;

  const position =
    post.bullish == true ? "bullish" : post.bullish == false ? "bearish" : null;

  const openPost = () => {
    if (isPostPage) {
      navigate("/community/comments/" + postID);
    } else {
      navigate("/community/posts/" + postID);
    }
  };
  const openProfile = (reposted = false) => {
    const targetId = reposted ? profileId : authorId;
    navigate("/community/users/" + targetId);
  };

  return (
    <Box sx={{ py: 3 }}>
      {repostID && (
        <Link
          sx={{
            color: "gray",
            fontSize: "12px",
            mb: 1,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          href={"/community/users/" + profileId}
        >
          <RepeatIcon fontSize="small" />
          {main_name} reposted
        </Link>
      )}
      <Link href={"/community/users/" + authorId}>
        <Avatar
          alt={name}
          src={avatar}
          sx={{
            m: 1,
            bgcolor: "secondary.main",
            float: "left",
            cursor: "pointer",
          }}
          onClick={() => openProfile()}
        />
      </Link>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", "& span": { px: 0.5 } }}
        >
          <Link
            variant="h6"
            sx={{
              fontWeight: "600",
              textDecoration: "none",
              color: "text.primary",
              "&:hover": { textDecoration: "underline" },
            }}
            href={"/community/users/" + authorId}
          >
            {name}
          </Link>
          <Box
            sx={{
              display: "flex",
              "& p, & span": {
                m: 1,
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
                m: 0,
              },
              "& .position": {
                backgroundColor:
                  position == "bullish" ? "success.main" : "error.main",
                color: "white",
                borderRadius: "5px",
                pr: 1.5,
              },
            }}
          >
            <Typography variant="body2" sx={{ color: "gray" }}>
              {username && (
                <>
                  <Typography variant="body2" component="span">
                    &#x2022;
                  </Typography>
                  <Typography variant="body2" component="span">
                    @{username}
                  </Typography>
                </>
              )}
              <Typography variant="body2" component="span">
                &#x2022;
              </Typography>
              <Typography variant="body2" component="span">
                {date.toDateString()}
              </Typography>
              <Typography variant="body2" component="span" className="position">
                {position == "bullish" ? (
                  <>
                    <ArrowDropUpIcon />
                    Bullish
                  </>
                ) : position == "bearish" ? (
                  <>
                    <ArrowDropDownIcon />
                    Bearish
                  </>
                ) : (
                  <></>
                )}
              </Typography>
            </Typography>
          </Box>
          <Box component="span" sx={{ ml: "auto", mr: "5px" }}>
            <MoreMenu
              id={postID}
              resource={isPostPage ? "comment" : "post"}
              profileId={profileId}
            />
          </Box>
        </Box>
        <Box
          onClick={openPost}
          sx={{ cursor: !isPostPage ? "pointer" : "auto" }}
        >
          {content}
        </Box>
        {media && (
          <Box
            sx={{
              // maxHeight: "300px",
              display: "flex",
              "& img": {
                borderRadius: "10px",
                border: "1px solid gray",
                maxWidth: "100%",
                maxHeight: "350px",
                margin: "0 auto",
              },
            }}
          >
            <Link href={media} target="_blank" sx={{ m: "0 auto" }}>
              <img src={media} />
            </Link>
          </Box>
        )}
        <BottomButtons
          post={post}
          openPost={openPost}
          isPostPage={isPostPage}
          resource={isComment ? "comment" : "post"}
        />
      </Box>
    </Box>
  );
};

export default Post;
