import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material/";
import { formatPrice, percentChange } from "library/helper";

const SideSummary = ({ details }) => {
  return (
    <Box>
      <Typography variant="h6">{details.name} Price Statistics</Typography>
      <TableContainer
        sx={{
          backgroundColor: (theme) =>
            theme.palette.background[theme.palette.mode],
          "& td": {
            fontSize: "10px",
            p: 1,
          },
          "& caption": {
            captionSide: "top !important",
          },
        }}
      >
        <Table aria-label="Statistics table">
          <caption>{details.name} Price Today</caption>
          <TableBody>
            <TableRow>
              <TableCell>{details.name} Price</TableCell>
              <TableCell>{formatPrice(details.price)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Price Change (1h)</TableCell>
              <TableCell>{percentChange(details.percentChange1h)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Price Change (24h)</TableCell>
              <TableCell>{percentChange(details.percentChange24h)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Price Change (7d)</TableCell>
              <TableCell>{percentChange(details.percentChange7d)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                24h Low <br /> 24h High
              </TableCell>
              <TableCell>
                {formatPrice(details.low24h)}
                <br />
                {formatPrice(details.high24h)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Trading Volume</TableCell>
              <TableCell>{Number(details.volume24h).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Volume/Market Cap</TableCell>
              <TableCell>
                {Number(details.volume24h / details.marketCap).toFixed(5)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Market Rank</TableCell>
              <TableCell>#{details.rank || 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table aria-label="Marketcap table">
          <caption>{details.name} Market Cap</caption>
          <TableBody>
            <TableRow>
              <TableCell>Market Cap</TableCell>
              <TableCell>{formatPrice(details.marketCap)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Fully Diluted Market Cap</TableCell>
              <TableCell>{formatPrice(details.fdmc)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SideSummary;
