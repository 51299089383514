import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "redux/auth/asyncActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidation } from "validations/authValidation";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { t } from "locales";
import { inputAutoFillStyles, rtlTextFieldStyle } from "assets/styles/styles";
import { useEffect } from "react";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthLayout from "components/AuthLayout";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState({ new: false, con: false });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleClickShowPassword = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPasswordToken = useSelector(
    (state) => state.auth.forgotPasswordToken
  );

  // CHECK FOR VERIFY TOKEN
  useEffect(() => {
    if (!forgotPasswordToken) {
      navigate("/auth/forgot-password");
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "", mobile: "", password: "" },
    resolver: yupResolver(resetPasswordValidation),
  });

  const onSubmit = async (data) => {
    try {
      const gToken = await executeRecaptcha();
      const resetData = {
        password: data?.password,
        token: forgotPasswordToken,
        gRecaptchaResponse: gToken,
      };

      await dispatch(resetPassword(resetData));

      navigate("/auth/login");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("resetPassword")}
            </Typography>
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  type={showPassword?.new ? "text" : "password"}
                  error={errors?.password}
                  helperText={t(errors?.password?.message)}
                  margin="normal"
                  fullWidth
                  id="password"
                  label={t("password")}
                  name="password"
                  autoFocus
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={[
                    { ...inputAutoFillStyles },
                    (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ mr: "5px" }}>
                        <IconButton
                          onClick={() => handleClickShowPassword("new")}
                          edge="end"
                        >
                          {showPassword?.new ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  type={showPassword?.con ? "text" : "password"}
                  error={errors?.passwordConfirmation}
                  helperText={t(errors?.passwordConfirmation?.message)}
                  margin="normal"
                  fullWidth
                  name="passwordConfirmation"
                  label={t("passwordConfirmation")}
                  id="passwordConfirmation"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={[
                    { ...inputAutoFillStyles },
                    (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ mr: "5px" }}>
                        <IconButton
                          onClick={() => handleClickShowPassword("con")}
                          edge="end"
                        >
                          {showPassword?.con ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              {t("submit")}
            </Button>
          </Box>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default ResetPassword;
