import { useState } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import { useSelector } from "react-redux";
import formatDate from "library/formatDate";
import {
  Button,
  CircularProgress,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { openToast } from "components/Toast";
import { getHumanError } from "library/translateServerErrors";
import {
  useDeleteSubmittedOffer,
  useGetSubmittedOffers,
} from "_hooks/User/queries";
import SoccerSingleCardAuction from "routes/CryptoWars/SingleCard/SingleCardAuction";
import SoccerSingleCard from "routes/CryptoWars/SingleCard/SingleCard";
import SoccerSingleBundle from "routes/CryptoWars/SingleCard/SingleBundle";
import CryptoWarsSingleCardAuction from "routes/CryptoWars/SingleCard/SingleCardAuction";
import CryptoWarsSingleCard from "routes/CryptoWars/SingleCard/SingleCard";
import CryptoWarsSingleBundle from "routes/CryptoWars/SingleCard/SingleBundle";
import { MAIN_COIN } from "library/constants";

const LOADING_LIST = Array(10).fill({});

const SubmittedOffers = () => {
  const modal = useSelector((state) => state.app.modal);
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [page, setPage] = useState(1);
  const [actionsLoading, setActionLoading] = useState(null);
  const { data, isLoading: loading } = useGetSubmittedOffers(page);
  const { mutate: deleteOffer } = useDeleteSubmittedOffer();

  const onPageChange = (_, value) => setPage(value);

  const openAuctionModal = (auction) => {
    if (!auction) return;

    const { auctionType, status, bundleCardId, assignedCardId, id } = auction;

    if (status === "ACTIVE") {
      if (!!bundleCardId) {
        const Component =
          auctionType === "PREDICTION"
            ? CryptoWarsSingleBundle
            : SoccerSingleBundle;
        modal.show(<Component id={id} />);
      } else {
        const Component =
          auctionType === "PREDICTION"
            ? CryptoWarsSingleCardAuction
            : SoccerSingleCardAuction;
        modal.show(<Component id={id} />);
      }
    } else {
      const Component =
        auctionType === "PREDICTION" ? CryptoWarsSingleCard : SoccerSingleCard;
      modal.show(<Component cardId={assignedCardId} />);
    }
  };

  const deleteHandler = (id) => {
    if (isNaN(+id)) return;

    setActionLoading(id);

    deleteOffer(id, {
      onSuccess: () => {
        openToast("success", "deletedOfferSuccessfully");
      },
      onError: (error) => {
        openToast("error", getHumanError(error));
      },
      onSettled: () => setActionLoading(null),
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          p: "20px",
          borderRadius: "12px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("withdrawRecords")}
        </Typography>
        <Box>
          <DataTable
            columns={[
              {
                title: t("amount"),
                field: "amount",
                render: ({ amount }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">{`${
                      amount || 0
                    } ${MAIN_COIN}`}</Typography>
                  ),
              },
              {
                title: t("status"),
                field: "status",
                render: ({ status }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">{t(status)}</Typography>
                  ),
              },
              {
                title: t("auctionDetail"),
                field: "auction",
                render: ({ auction }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : !auction && auction?.status === "FINISHED" ? (
                    <Typography variant="body2">
                      {t(auction?.status || "FINISHED")}
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() => openAuctionModal(auction)}
                      variant="body2"
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        fontWeight: "bold",
                      }}
                    >
                      {t("seeAuctionDetail")}
                    </Typography>
                  ),
              },
              {
                title: t("time"),
                field: "createdAt",
                render: ({ createdAt }) => {
                  return loading ? (
                    <SkeletonLoader width="50" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">
                      {formatDate(createdAt, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        lang: "en",
                      })}
                    </Typography>
                  );
                },
              },
              {
                title: t("action"),
                field: "action",
                render: ({ status, id }) => {
                  return loading ? (
                    <SkeletonLoader width="50" height="20" isLight={isLight} />
                  ) : actionsLoading === id ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "36px",
                      }}
                    >
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <Button
                      variant="text"
                      disabled={status !== "REGISTERED"}
                      onClick={() => deleteHandler(id)}
                    >
                      {t("delete")}
                    </Button>
                  );
                },
              },
            ]}
            data={loading ? LOADING_LIST : data?.list}
            rowClass="py-2"
            headClass="table-head"
          />
          {!loading && data?.list?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 5,
              }}
            >
              <Typography variant="body1">{t("noDataFound")}</Typography>
            </Box>
          ) : (
            <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
              <Pagination
                sx={{ width: "fit-content" }}
                count={data?.total}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SubmittedOffers;
