import { replaceJSX } from "library/helper";
import { t } from "locales";

const translateServerErrors = (
  error = null,
  code = null,
  customize = false
) => {
  if (code) {
    return `error-${code}${customize ? "-custom" : ""}`;
  } else if (error) {
    const errorCode = error?.data?.lang?.code;
    if (!errorCode) return error?.data?.message || error?.data?.error;

    // if (+errorCode === 1119 || +errorCode === 1120) {
    //   const errorDetails = error?.fields;
    //   let errorMessage = "";

    //   errorMessage += replaceJSX(
    //     t(`error-${errorCode}-custom`),
    //     "#start",
    //     errorDetails?.startDate
    //   );
    //   errorMessage += errorDetails?.description;

    //   return {
    //     doNotTranslate: true,
    //     errorCode: [errorMessage, <br />, t("ban-support")],
    //   };
    // }

    return `error-${errorCode}${customize ? "-custom" : ""}`;
  }
};

export default translateServerErrors;

export const getHumanError = (error) => {
  // if (error?.message) {
  //   return error?.message;
  // } else 
  if (error?.response) {
    const errorMessage = translateServerErrors(error?.response);
    return errorMessage;
  } else {
    return "somethingWentWrong";
  }
};
