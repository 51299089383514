import { Button, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { Box } from "@mui/system";
import { t } from "locales";
import { MAIN_COIN } from "library/constants";

const BigCard = ({
  power,
  image,
  bonus,
  count,
  end,
  name,
  lastPrice,
  openCardDetail,
  openPutOfferModal,
  bundleCards,
  initialNumber,
  serialNumber,
}) => {
  const cardSerialTag =
    !isNaN(+serialNumber) && serialNumber !== null
      ? `${serialNumber}/${initialNumber}`
      : "Common";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 2,
        height: "100%",
        position: "relative",
      }}
    >
      {!bundleCards ? (
        <Box
          onClick={openCardDetail}
          sx={{
            position: "relative",
            width: "100%",
            flexGrow: 1,
            filter: "brightness(1.1)",
            cursor: "pointer",
            img: {
              width: "100%",
              transition: "box-shadow 0.1s ease-out",
              borderRadius: "12px",
            },

            transition: "transform 0.1s ease-out",
            "&:hover": {
              transform: "scale(1.02)",

              "& + .favorite-big-device": {
                transform: "scale(1)",
              },

              img: {
                boxShadow: (theme) =>
                  theme.palette.mode === "dark"
                    ? "2px 10px 32px 0px rgb(0 0 0 / 90%)"
                    : "2px 10px 32px 0px rgba(0,0,0,0.1)",
              },
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              top: "5px",
              left: "5px",
              color: cardSerialTag === "Common" ? "#333" : "#eaeaea",
              fontWeight: cardSerialTag === "Common" ? "500" : "400",
            }}
          >
            {cardSerialTag}
          </Typography>
          <img src={getImageUrl(image)} alt="" />
        </Box>
      ) : (
        <>
          <Box
            onClick={openCardDetail}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              gap: "5px",
              width: "100%",
              flexGrow: 1,
              cursor: "pointer",
              position: "relative",
              img: {
                width: "100%",
                transition: "box-shadow 0.1s ease-out",
                borderRadius: "8px",
              },
            }}
          >
            {bundleCards?.slice(0, 4)?.map(({ card }, i) => (
              <img key={i} src={getImageUrl(card?.image)} alt="" />
            ))}
            {bundleCards?.length > 4 && (
              <Box
                sx={{
                  position: "absolute",
                  left: "5px",
                  bottom: "8px",
                  display: "flex",
                  borderRadius: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "card.dark",
                  color: "white",
                  p: "1px 8px",
                  // backgroundColor: (theme) => `card.${theme.palette.mode}`,
                  // color: (theme) => `primary.${theme.palette.mode}`,
                }}
              >
                <Typography variant="body1">
                  +{bundleCards?.length - 4}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {/* <IconButton
        className="favorite-big-device"
        size="large"
        sx={{
          position: "absolute",
          top: "9px",
          right: "10px",
          width: "40px",
          border: "1px solid #F1F0F5",
          height: "40px",
          background: "white",
          borderRadius: "50%",
          transform: " scale(0)",
          transition: "transform 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "&:hover": {
            transform: "scale(1)",
            border: "none",
            // border: "1px solid #F1F0F5",
            // background: "white",
          },
        }}
      >
        {favorite ? (
          <Favorite fontSize="small" color="primary" />
        ) : (
          <FavoriteBorder fontSize="small" color="primary" />
        )}
      </IconButton> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column" },
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          {power && (
            <Box
              sx={{
                width: "30px",
                height: "24px",
                display: "inline-flex",
                alignItems: "center",
                flexShrink: "0",
                borderRadius: "36px",
                justifyContent: "center",
                backgroundColor: (theme) =>
                  getPowerBadgeColor(power, theme?.palette?.mode),
              }}
            >
              <Typography variant="caption">{power}</Typography>
            </Box>
          )}
          {!isNaN(bonus) && (
            <Typography
              variant="caption"
              sx={{
                display: "inline-flex",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#eee" : "#414244",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "#414244" : "#fff",
                padding: "1px 8px",
                borderRadius: "8px",
                height: "24px",
              }}
            >
              {name}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {lastPrice} {MAIN_COIN}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="caption">
            {formatDistanceToNowStrict(new Date(end))}
          </Typography>
          <Typography variant="caption">
            {count} {t("bids")}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button
          // onClick={openConnectWalletModal}
          onClick={() => openPutOfferModal(!!bundleCards)}
          color="primary"
          variant="outlined"
          sx={(theme) => ({
            // backgroundImage: theme.palette.gradient.purpule,
            color:
              theme.palette.mode === "light" ? "primary.main" : "primary.light",
            padding: "7px 20px",
            transition: "background-color 250ms",
            width: "100%",

            "&:hover": {
              backgroundColor: "primary.main",
              color: "common.white",
            },
          })}
        >
          {t("bid")}
        </Button>
      </Box>
    </Box>
  );
};

export default BigCard;
