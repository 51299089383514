import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "redux/auth/authSlice";
import settingReducer from "redux/setting/settingSlice";
import appReducer from "redux/app/appSlice";

const persistedAuthReducer = persistReducer(
  {
    key: "site.auth",
    storage,
    whitelist: [
      "user",
      "accessToken",
      "refreshToken",
      "accessTokenExpire",
      "refreshTokenExpire",
      "wizard",
    ],
  },
  authReducer
);

const persistedSettingReducer = persistReducer(
  {
    key: "site.setting",
    storage,
    whitelist: ["theme", "lang", "currency"],
  },
  settingReducer
);

export const store = configureStore({
  reducer: {
    setting: persistedSettingReducer,
    auth: persistedAuthReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
