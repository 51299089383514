import { Box } from "@mui/system";
import React from "react";
import { useParams } from "react-router";
import SingleBundle from "routes/CryptoWars/SingleCard/SingleBundle";

const CryptoWarsAuctionSingleBundle = () => {
  const { id } = useParams();

  return (
    <Box className="container">
      <SingleBundle id={id} asPage={true} />
    </Box>
  );
};

export default CryptoWarsAuctionSingleBundle;
