import { Box } from "@mui/system";
import { useGetCompettion } from "_hooks/Investment/competitions";
import Header from "./Header";
import List from "./List";

const CryptoWarPlayCompetitions = ({ initLoading = false, competitionId }) => {
  const {
    data,
    isLoading: loading,
    isFetching,
  } = useGetCompettion(competitionId);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Header data={data?.types} status={data?.status} />
      <List
        data={data?.types}
        status={data?.status}
        loading={initLoading || loading || isFetching}
        competitionId={competitionId}
      />
    </Box>
  );
};

export default CryptoWarPlayCompetitions;
