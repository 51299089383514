import { Menu, MenuItem, Link, Box, Typography } from "@mui/material";
import RepeatIcon from "@mui/icons-material/Repeat";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import axios from "library/http";
import { formatNumber } from "library/helper";
import { openToast } from "components/Toast";
import { SITE_URL } from "config/Config";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BottomButtons = ({ post, isPostPage, resource = "post" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.auth);
  let navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [likes, setLikes] = useState(post.likeCount);
  const [liked, setLiked] = useState(post.liked);
  const [reposts, setReposts] = useState(post.repostCount);
  const [reposted, setReposted] = useState(post.reposted);
  const commentsCount = post.commentCount;

  const postID = post.id;

  const repost = async () => {
    try {
      await axios.post("gravity/posts", { content: " ", postId: postID });
      openToast("success", "Reposted");
      setReposted(true);
    } catch (error) {
      if (error.response.status === 401) {
        return openToast(
          "error",
          "You are not authorized to repost, please login"
        );
      }
      console.error(error);
      openToast("error", "Something goes wrong, please try again");
    }
  };

  const pleaseLogin = () => {
    openToast("error", "Please login to like or repost");
  };

  return (
    <Box
      sx={{
        color: "gray",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mx: 3,
        pt: 3,
        "& span, & a": {
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          cursor: "pointer",
        },
        "& svg": {
          mr: 0.5,
        },
      }}
    >
      <Link
        href={
          isPostPage &&
          resource != "post" &&
          `/community/${resource}s/${postID}`
        }
        sx={{
          color: "gray",
          textDecoration: "none",
        }}
      >
        <CommentOutlinedIcon />
        {commentsCount}
      </Link>
      <Typography
        variant="body2"
        component="span"
        onClick={() => {
          user ? repost() : pleaseLogin();
        }}
      >
        {reposted ? (
          <RepeatIcon sx={{ color: "rgb(0, 186, 124)" }} />
        ) : (
          <RepeatIcon />
        )}
        Repost
      </Typography>
      <Typography
        variant="body2"
        component="span"
        onClick={() => {
          if (!user) return pleaseLogin();

          if (liked) {
            try {
              axios.delete(`/gravity/likes/${resource}/${postID}`);
              post.liked = false;
              post.likeCount--;
            } catch (error) {
              console.error(error);
              openToast("error", "Something goes wrong");
            }
          } else {
            try {
              axios.post(`/gravity/likes`, {
                resource: resource,
                resourceId: postID,
              });
              post.liked = true;
              post.likeCount++;
            } catch (error) {
              console.error(error);
              openToast("error", "Something goes wrong");
            }
          }
          setLiked(post.liked);
          setLikes(post.likeCount);
        }}
      >
        {liked ? (
          <FavoriteIcon sx={{ color: "red" }} />
        ) : (
          <FavoriteBorderIcon />
        )}
        {formatNumber(likes)}
      </Typography>
      <Typography variant="body2" component="span">
        <Link
          id="share-button"
          aria-controls={open ? "share-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="gray"
          underline="none"
        >
          <ShareIcon />
          Share
        </Link>
        <Menu
          id="share-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "share-button",
          }}
          sx={{
            "& li": {
              backgroundColor: (theme) =>
                theme.palette.background[theme.palette.mode],
              p: "10px 18px",
              m: "5px",
              textAlign: "center",
              borderRadius: "2px",
              height: "42px",
              fontWeight: "600",
              lineHeight: "21px",
              fontSize: "16px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              openToast("success", "Link copied to clipboard");
              navigator.clipboard.writeText(
                SITE_URL + "community/" + resource + "s/" + postID
              );
            }}
          >
            Copy Link
          </MenuItem>
        </Menu>
      </Typography>
    </Box>
  );
};

export default BottomButtons;
