import { Box, Typography, Avatar, Link } from "@mui/material";
import { formatNumber } from "library/helper";
import FollowButton from "routes/Community/components/FollowButton";
import { useSelector } from "react-redux";
import { SITE_URL } from "config/Config";

const TopSummary = ({ userDetails, setRefresh }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt={userDetails.name}
          src={
            userDetails.avatar?.length > 0
              ? userDetails.avatar[0]?.location
              : "./images/not-set.jpg"
          }
          sx={{ width: 75, height: 75, mr: 2, bgcolor: "secondary.main" }}
        />
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {userDetails.name}
          </Typography>
          {userDetails.slug && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              @{userDetails.slug}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "50%",
          "& a": {
            color: "text.primary",
            "& span": { fontWeight: "bold", mr: "2px" },
          },
          "& button": {
            borderRadius: "0",
          },
        }}
      >
        <Link
          href={"/community/users/" + userDetails.userId + "/followers"}
          underline="none"
          color="primary"
        >
          <Typography component="span">
            {formatNumber(userDetails.followerCount)}
          </Typography>{" "}
          Followers
        </Link>
        <Link
          href={"/community/users/" + userDetails.userId + "/followings"}
          underline="none"
          color="primary"
        >
          <Typography component="span">
            {formatNumber(userDetails.followingCount)}
          </Typography>{" "}
          Followings
        </Link>
        {user?.id != userDetails.userId && (
          <FollowButton
            id={userDetails.userId}
            followed={userDetails.followed}
            setRefresh={setRefresh}
          />
        )}
      </Box>
    </Box>
  );
};

export default TopSummary;
