const contactFa = {
  contact: "تماس",
  finance: "مالی",
  contactHeader: "ثبت یک درخواست",
  contactEmailLabel: "آدرس ایمیل شما",
  contactDepartmentLabel: "دپارتمان",
  contactSubjectLabel: "موضوع",
  contactDescriptionLabel: "توضیح",
  contactHelperText:
    "لطفا جزییات درخواست خود را بنویسید به زودی از تیم پشتیبانی با شما تماس خواهند گرفت.",
  contactFormEmailRequired: "آدرس ایمیل را وارد کنید!",
  contactFormDepartmentRequired: "قسمت دپارتمان را پر کنید!",
  contactFormSubjectRequired: "قسمت موضوع را پر کنید!",
  contactFormDescriptionRequired: "قسمت توضیح را پر کنید!",
  contactRequestSubmitted: "درخواست شما با موفقیت ارسال گردید",
};

export default contactFa;
