import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { ButtonBase, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { inputAutoFillStyles } from "assets/styles/styles";
import { t } from "locales";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "components/CustomButton";
import ErrorAlert from "components/ErrorAlert";
import _ from "lodash";
import { putForSaleValidation } from "validations/marketValidation";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MAIN_COIN } from "library/constants";
import { usePutCardForSale } from "_hooks/User/queries";
import { getHumanError } from "library/translateServerErrors";

const today = new Date();

const PutForSale = ({ onClose, id, auctionType }) => {
  const { mutate: putForSale, isError, error } = usePutCardForSale();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      basePrice: "",
      immediatePrice: "",
      bookingPrice: "",
      start: today,
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7),
    },
    resolver: yupResolver(putForSaleValidation),
  });

  const onSubmit = (data) => {
    putForSale(
      {
        ...data,
        auctionType,
        assignedCardId: id,
      },
      { onSuccess: onClose }
    );
  };

  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: { xs: "95vw", sm: "70vw", lg: "40vw", xl: "400px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("sell")}
        </Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      {isError && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={getHumanError(error)} />
        </Box>
      )}

      <Box
        onSubmit={handleSubmit(onSubmit)}
        as="form"
        autocomplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Controller
          control={control}
          name="basePrice"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.basePrice}
              helperText={t(errors?.basePrice?.message)}
              margin="normal"
              fullWidth
              name="basePrice"
              label={`${t("basePrice")} (${MAIN_COIN})`}
              type="text"
              id="basePrice"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={{ ...inputAutoFillStyles, mb: 3 }}
            />
          )}
        />
        <Controller
          control={control}
          name="immediatePrice"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.immediatePrice}
              helperText={t(errors?.immediatePrice?.message)}
              margin="normal"
              fullWidth
              name="immediatePrice"
              label={`${t("immediatePrice")} (${MAIN_COIN})`}
              type="text"
              id="immediatePrice"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={{ ...inputAutoFillStyles, mb: 3 }}
            />
          )}
        />
        <Controller
          control={control}
          name="bookingPrice"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.bookingPrice}
              helperText={t(errors?.bookingPrice?.message)}
              margin="normal"
              fullWidth
              name="bookingPrice"
              label={`${t("bookingPrice")} (${MAIN_COIN})`}
              type="bookingPrice"
              id="bookingPrice"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={{ ...inputAutoFillStyles, mb: 3 }}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            name="start"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                label={t("start")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.start}
                    helperText={t(errors?.start?.message)}
                    margin="normal"
                    fullWidth
                    name="start"
                    label={t("startTime")}
                    id="start"
                    sx={{ ...inputAutoFillStyles, mb: 3 }}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="end"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                label={t("end")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.end}
                    helperText={t(errors?.end?.message)}
                    margin="normal"
                    fullWidth
                    name="end"
                    label={t("endTime")}
                    id="end"
                    sx={{ ...inputAutoFillStyles, mb: 3 }}
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
        <CustomButton
          type="submit"
          fullWidth
          variant="outlined"
          loading={isSubmitting}
          disabled={!_.isEmpty(errors, true)}
          loaderHeight="40"
          extraSx={{ p: "15px 12px", width: "100%", mt: 2 }}
        >
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {t("submit")}
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PutForSale;
