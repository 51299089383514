import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import {
  ButtonBase,
  CircularProgress,
  FormHelperText,
  InputBase,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { inputAutoFillStyles, numberInputStyle } from "assets/styles/styles";
import { t } from "locales";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "./CustomButton";
import ErrorAlert from "./ErrorAlert";
import _ from "lodash";
import { putOfferValidation } from "validations/marketValidation";
import { useSelector } from "react-redux";
import ConnectWallet from "./ConnectWallet";
import Swap from "components/Swap";
import { openToast } from "./Toast";
import { truncateToDecimals } from "library/helper";
import { useGetAuction, useGetBundle } from "_hooks/CryptoWars/market";
import { useGetWallet } from "_hooks/User/queries";
import { MAIN_COIN } from "library/constants";
import { useQueryClient } from "react-query";
import { usePostAuctionOffer } from "_hooks/Shared/market";
import { useState } from "react";

const changeStep = 0.01;

const onSuccessGetAuction = (data, setValue) => {
  const defaultAmount =
    data?.max && !isNaN(+data?.max)
      ? +data?.max + +data?.max * 0.05
      : data?.basePrice || "";
  setValue("amount", truncateToDecimals(defaultAmount, 2));
};

const PutOfferCryptoWars = ({ onClose, id, isBundle = false }) => {
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();
  const modal = useSelector((state) => state.app.modal);
  // Get query based on isBundle
  const { isLoading: loadingAuction, data } = (
    isBundle ? useGetBundle : useGetAuction
  )({ id, onSuccess: (res) => onSuccessGetAuction(res, setValue) });
  const { isLoading: loadingWallet, data: wallet } = useGetWallet();
  const {
    mutate: putOffer,
    isLoading: putOfferLoading,
    error,
    isError,
  } = usePostAuctionOffer();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { amount: null },
    resolver: yupResolver(putOfferValidation),
  });

  const openConnectWalletModal = () =>
    modal.show(<ConnectWallet onClose={modal.hide} />);
  const openSwapModal = () => modal.show(<Swap onClose={modal.hide} />);

  function verifyOffer(data) {
    const amount = typeof data === "object" ? data.amount : data;
    setStep(amount);
  }

  function buyHandler(price) {
    const immediatePrice = data?.immediatePrice;

    if (immediatePrice && price >= immediatePrice) {
      buyImmediately();
    } else {
      putOfferHandler(price);
    }
  }

  const putOfferHandler = (data) => {
    // const { amount } = data;
    const amount = data;
    putOffer(
      { auctionId: id, amount },
      {
        onSuccess: () => {
          openToast("success", "Your offer sent successfully.");
          onClose?.();
        },
      }
    );
  };

  const buyImmediately = () => {
    const amount = data?.immediatePrice;
    putOffer(
      { auctionId: id, amount },
      {
        onSuccess: () => {
          openToast("success", "buyCardSuccessful");
          queryClient.invalidateQueries(
            isBundle ? "single-cryptowars-bundle" : "single-cryptowars-auction"
          );
          onClose?.();
        },
      }
    );
  };

  const loading = loadingAuction || loadingWallet || putOfferLoading;

  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: { xs: "95vw", sm: "60vw", lg: "40vw", xl: "500px" },
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("bid")}
        </Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      {isError && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={error} />
        </Box>
      )}
      <Box id="putOfferCryptoWars" sx={{ width: "100%" }}>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {step == 0 ? (
              <Box
                onSubmit={handleSubmit(verifyOffer)}
                as="form"
                autocomplete="off"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography variant="h6">
                      {wallet?.asset?.coin || MAIN_COIN}
                    </Typography>
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputBase
                          autoFocus
                          step={changeStep}
                          name="amount"
                          label={t("amount")}
                          id="amount"
                          type="number"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          placeholder={t("enterYourOffer")}
                          sx={{
                            ...inputAutoFillStyles,
                            flexGrow: 1,
                            input: {
                              ...numberInputStyle,
                              fontSize: "1.5rem",
                              "::placeholder": {
                                fontSize: "1rem",
                                opacity: 0.5,
                                margin: "auto",
                              },
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "#1d1f2b"
                                  : "#fdf6ff",
                              borderRadius: "4px",
                              paddingLeft: "10px",
                            },
                            label: {
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {errors?.amount && (
                    <FormHelperText sx={{ color: "#f44336" }}>
                      {t(errors?.amount?.message)}
                    </FormHelperText>
                  )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1">{t("walletBalance")}</Typography>

                  <Typography variant="body1">{wallet?.amount}</Typography>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                >
                  <CustomButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!_.isEmpty(errors, true)}
                    loaderHeight="40"
                    extraSx={{ p: "15px 12px", width: "100%" }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      {t("bid")}
                    </Typography>
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      verifyOffer(+data?.immediatePrice || 0);
                    }}
                    type="button"
                    fullWidth
                    variant="outlined"
                    loading={isSubmitting}
                    // disabled={!_.isEmpty(errors, true)}
                    loaderHeight="40"
                    extraSx={{ p: "15px 12px", width: "100%" }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      {t("buyImmediately")} •{" "}
                      {(+data?.immediatePrice)?.toLocaleString?.() || 0}{" "}
                      {MAIN_COIN}
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            ) : (
              <Box>
                {(data && data.hasOwnProperty('image')) && (
                  <Box
                    sx={{
                      "& img": {
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <img src={data.image[0].location} alt={data?.name} />
                  </Box>
                )}
                <Typography variant="h6">
                  Are you sure to spend {step?.toLocaleString?.()} {MAIN_COIN} to buy {data?.name}?
                </Typography>
                <Box>
                  <CustomButton
                    onClick={() => {
                      buyHandler(step);
                    }}
                    type="button"
                    fullWidth
                    variant="contained"
                    loading={isSubmitting}
                    // disabled={!_.isEmpty(errors, true)}
                    loaderHeight="40"
                    extraSx={{ p: "15px 12px", m: "5px", width: "100%" }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      Confirm
                    </Typography>
                  </CustomButton>
                  <CustomButton
                    // onClick={onClose}
                    onClick={() => {
                      setStep(0);
                    }}
                    type="button"
                    fullWidth
                    variant="outlined"
                    loading={isSubmitting}
                    disabled={!_.isEmpty(errors, true)}
                    loaderHeight="40"
                    extraSx={{ p: "15px 12px", m: "5px", width: "100%" }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      Cancel
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PutOfferCryptoWars;
