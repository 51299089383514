import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import packImage from "assets/img/pack-image.png";
import LargeField from "./LargeField";
import SmallField from "./SmallField";
import { useNavigate } from "react-router";
import { t } from "locales";
import { useGetCryptoWarsPack } from "_hooks/CryptoWars/wizard";
import { openToast } from "components/Toast";

const CardPack = ({
  redirectPath = "/crypto-wars/wizard/league",
  packId = 1,
  nextHandler,
}) => {
  const showBigField = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [showCards, setShowCards] = useState(true);
  const [showedCards, setShowedCards] = useState([]);
  const navigate = useNavigate();
  const { isLoading: loading, data, isError } = useGetCryptoWarsPack(packId);
  const cards = data?.data?.data || [];

  useEffect(() => {
    if (isError) {
      navigate("/crypto-wars");
      openToast("warning", "No Card added");
    }
  }, [isError]);

  const showCard = (cardId) => {
    if (showedCards?.includes?.(cardId)) return;

    setShowedCards((prev) => [...prev, cardId]);
  };

  const revealAllCards = () => {
    const allIds = cards?.map((item) => item.coin);

    setShowedCards(allIds);
  };

  const clickDoneHandler = () => {
    if (loading) return;

    if (!nextHandler) {
      navigate(redirectPath);
    } else {
      nextHandler();
    }
  };

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 64px)",
        minWidth: "100%",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212429" : "#eee",
        pb: 5,
      }}
    >
      <Box
        className="container"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "calc(100vh - 64px)",
          minWidth: "100%",
        }}
      >
        <Box
          className="container"
          sx={{
            ".slick-initialized": { overflow: "hidden" },
          }}
        >
          {showCards ? (
            <>
              {loading || isError ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                  }}
                >
                  <CircularProgress size={60} />
                  <Typography variant="body1">Loading Pack</Typography>
                </Box>
              ) : showBigField ? (
                <Box
                  sx={(theme) => ({
                    py: 2,
                    display: "grid",
                    gap: 2,
                    [theme.breakpoints.between("sm", "lg")]: {
                      gridTemplateColumns: "repeat(3, 1fr)",
                    },
                    [theme.breakpoints.up("lg")]: {
                      gridTemplateColumns: `repeat(${cards?.length}, 1fr)`,
                      gridTemplateRows: "1fr",
                    },
                  })}
                >
                  <LargeField
                    showCard={showCard}
                    showedCards={showedCards}
                    cards={cards}
                  />
                </Box>
              ) : (
                <SmallField
                  showCard={showCard}
                  showedCards={showedCards}
                  cards={cards}
                />
              )}
              {showedCards?.length === cards?.length ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button
                    disabled={loading}
                    onClick={clickDoneHandler}
                    sx={(theme) => ({
                      backgroundImage: theme.palette.gradient.purpule,
                      color: theme.palette.common.white,
                      width: "fit-content",
                      padding: "10px 30px",
                      fontSize: "1rem",
                      mx: "auto",
                      textDecoration: "none",
                    })}
                  >
                    {t("done")}
                  </Button>
                </Box>
              ) : (
                !loading && (
                  <Typography
                    onClick={revealAllCards}
                    variant="body1"
                    sx={{ textAlign: "center", mt: 3, cursor: "pointer" }}
                  >
                    {t("revealAll")}
                  </Typography>
                )
              )}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                img: {
                  width: "174px",
                },
              }}
            >
              <img src={packImage} alt="" />
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ fontWeigh: "500" }}>
                  {t("openPackTitle")}
                </Typography>
                <Typography variant="body2">{t("openPackDesc")}</Typography>
              </Box>
              <Button
                onClick={() => setShowCards(true)}
                sx={(theme) => ({
                  backgroundImage: theme.palette.gradient.purpule,
                  color: theme.palette.common.white,
                  width: "fit-content",
                  padding: "10px 30px",
                  fontSize: "1rem",
                })}
              >
                {t("openPackBtn")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardPack;
