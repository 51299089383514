import { IMAGE_URL } from "config/Config";
import { Box } from "@mui/material";

export function replaceJSX(str, find, replace) {
  let parts = str.split(find);
  let result = [];
  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i]);
    result.push(replace);
  }
  result.pop();
  return result;
}

export const getPowerBadgeColor = (power, themeMode) => {
  if (power === "DNP")
    return themeMode === "dark" ? "rgb(102, 102, 102)" : "#eee";
  else if (power < 45) return "#d4c916";
  else if (power < 75) return "#24c3a2";
  else return "#24c358";
};

export const stringQueriesToObj = (stringQueries = "", removeQuery) => {
  const queriesArray = stringQueries?.split("&");

  const queries = {};

  queriesArray?.forEach((q) => {
    const [key, value] = q?.split("=");
    if (!!key && !!value && key !== removeQuery) queries[key] = value;
  });

  return queries;
};

export function calcTotalPages(total, pageSize) {
  const value = Math.ceil(Number(total) / Number(pageSize));

  return isNaN(value) ? 1 : value;
}

export const replaceComma = (value) => {
  if (!value) return 0;

  if (!isNaN(+value)) return value;

  const number = +value?.replaceAll?.(",", "");

  return !isNaN(number) ? number : 0;
};

export const truncateToDecimals = (num, dec = 2) => {
  if (!num || isNaN(+num)) return;

  const stringNum = String(num);
  const [, after] = stringNum?.split?.(".");

  if (!after || after?.length <= dec) {
    return +num;
  }

  const calcDec = Math.pow(10, dec);
  return Math.trunc(+num * calcDec) / calcDec;
};

export const getImageUrl = (image, key) => {
  if (!image?.[0]?.key && !key) return null;

  return IMAGE_URL + (image?.[0]?.key || key);
};

export const convertScientific = (value) => {
  if (Math.abs(value) < 1.0) {
    const e = parseInt(value.toString().split("e-")[1]);
    if (e) {
      value *= Math.pow(10, e - 1);
      value = "0." + new Array(e).join("0") + value.toString().substring(2);
    }
  } else {
    let e = parseInt(value.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      value /= Math.pow(10, e);
      value += new Array(e + 1).join("0");
    }
  }

  // return precision ? +value : value;
  return value;
};

export const fixNumber = (value, digits = 3) => {
  if (!value || isNaN(+value)) return 0;

  if (digits && digits <= 100 && digits >= 0) {
    return convertScientific(parseFloat((+value)?.toFixed?.(digits)));
  }

  return parseFloat((+value)?.toFixed?.(3));
};

export const addComma = (num, dec) => {
  if (!num || isNaN(+num)) return 0;

  let number = convertScientific(num);

  if (dec) {
    number = convertScientific(truncateToDecimals(num, dec));
  }

  const [before, after] = String(number)?.split?.(".");
  let output = before?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (after) {
    output += "." + after;
  }
  return output;
  // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const addOrdinalSuffix = (n) => {
  // return n + ["st", "nd", "rd"][((((+n + 90) % 100) - 10) % 10) - 1] || "th";
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
export const b64toBlob = async (base64) => {
  const res = await fetch(base64);
  return res.blob();
};

export const formatPrice = (
  price,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  isPrice = true
) => {
  isPrice = isPrice ? "$" : "";
  if (isNaN(price)) return isPrice + 0;
  if (price < 1) {
    return isPrice + price;
  }
  return (
    isPrice +
    Number(price).toLocaleString("en-US", {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    })
  );
};
export const percentChange = (
  percent,
  styleType = "mainColor",
  additionalCSS = ""
) => {
  const mainColor = percent > 0 ? "#5CC589" : "#D8494A";
  const symbol = percent > 0 ? "\\25b2" : "\\25bc";
  var color;
  var bgcolor;
  if (styleType === "mainColor") {
    color = mainColor;
    bgcolor = "none";
  } else {
    color = "#fff";
    bgcolor = mainColor;
  }
  return (
    <Box
      component="span"
      sx={{
        color: color,
        backgroundColor: bgcolor,
        // p: "0.5em",
        borderRadius: "2px",
        // ml: "0.5em",
        display: "flex",
        alignItems: "center",
        fontSize: "10px",
        "&:before": {
          content: `"${symbol}"`,
          fontSize: "0.8em",
          marginRight: "0.5em",
        },
        ...additionalCSS,
      }}
    >
      {Number(percent).toFixed(2) + "%"}
    </Box>
  );
};

export const percentage = (currentValue, totalValue, fraction = 0) => {
  if (isNaN(currentValue) || isNaN(totalValue) || totalValue === 0) return 0;
  return Number(((100 * currentValue) / totalValue).toFixed(fraction));
};

export const formatNumber = (value) => {
  return isNaN(value)
    ? false
    : value < 1000
    ? value
    : Number(value / 1000).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "K";
};

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
