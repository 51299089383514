import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "config/Config";
import { getImageUrl } from "library/helper";
import customAxios from "library/http";
import translateServerErrors from "library/translateServerErrors";

export const register = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const {
        email = undefined,
        mobile = undefined,
        name,
        password,
        referredCode,
        gRecaptchaResponse,
      } = data;

      const response = await axios.post(`${BASE_URL}user/signup`, {
        email,
        mobile,
        name,
        password,
        referredCode,
        gRecaptchaResponse,
      });

      return { ...response.data?.data, verifyType: "REGISTER" };
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const {
        email = undefined,
        mobile = undefined,
        password,
        gRecaptchaResponse,
      } = data;

      const response = await axios.post(`${BASE_URL}user/login`, {
        email,
        mobile,
        password,
        gRecaptchaResponse,
      });

      // return { ...response.data?.data, verifyType: "LOGIN" };
      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();

      if (auth.logoutError) return;

      const response = await customAxios.get("user/logout");

      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const verify = createAsyncThunk(
  "user/verify",
  async (data, { rejectWithValue }) => {
    try {
      const { token, code, gRecaptchaResponse } = data;

      const response = await axios.post(`${BASE_URL}user/verify`, {
        token,
        code,
        gRecaptchaResponse,
      });

      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const updateUserData = createAsyncThunk(
  "user/update-user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await customAxios.get("user");

      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const refreshUserToken = createAsyncThunk(
  "user/refresh-user-token",
  async (refreshToken, { rejectWithValue }) => {
    try {
      const response = await customAxios.post("user/refresh-token", {
        refreshToken: "Bearer " + refreshToken,
      });

      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgot-password",
  async (data, { rejectWithValue }) => {
    try {
      const {
        email = undefined,
        mobile = undefined,
        gRecaptchaResponse,
      } = data;

      const response = await axios.post(`${BASE_URL}user/password`, {
        email,
        mobile,
        gRecaptchaResponse,
      });

      return { ...response.data?.data, verifyType: "FORGOT_PASSWORD" };
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/reset-password",
  async (data, { rejectWithValue }) => {
    try {
      const { token, password, gRecaptchaResponse } = data;

      const response = await axios.patch(`${BASE_URL}user/password`, {
        token,
        password,
        gRecaptchaResponse,
      });

      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/change-password",
  async (data, { rejectWithValue }) => {
    try {
      const { newPassword, oldPassword } = data;

      const response = await customAxios.put("user/password", {
        oldPassword,
        newPassword,
      });

      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const updateWallets = createAsyncThunk(
  "user/update-wallets",
  async (data, { rejectWithValue }) => {
    try {
      const walletResponse = await customAxios.get("wallet");

      let wallets = {};
      walletResponse?.data?.data?.forEach((w, i) => {
        const data = {
          coin: w?.asset?.coin,
          name: w?.asset?.name,
          icon: getImageUrl(w?.asset?.icon),
          precision: w?.asset?.precision,
          assetId: w?.assetId,
          amount: w?.amount,
        };

        wallets[data.coin] = data;
      });

      return wallets;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const updateWalletsFromSocket = createAsyncThunk(
  "user/update-wallets-socket",
  async (data, { rejectWithValue, getState }) => {
    try {
      const newWallets = Object.values(getState()?.auth?.wallets);

      data?.forEach((item) => {
        const index = newWallets?.findIndex?.(
          (w) => w.assetId === item.assetId
        );

        if (index !== -1) newWallets[index] = { ...newWallets[index], ...item };
      });

      const finalWallets = {};
      newWallets?.forEach?.((item) => {
        finalWallets[item.coin] = item;
      });

      return finalWallets;
    } catch (error) {
      console.error(error);
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);

export const getWizardData = createAsyncThunk(
  "auth/get-wizard-data",
  async (data, { rejectWithValue }) => {
    try {
      const response = await customAxios.get(`${BASE_URL}user/wizard-list`);

      const formattedWizardObj = {};
      response.data?.data?.forEach(
        (w) => (formattedWizardObj[w.type] = w?.step)
      );

      return formattedWizardObj;
    } catch (error) {
      if (error?.response) {
        const errorMessage = translateServerErrors(error?.response);

        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue("somethingWentWrong");
      }
    }
  }
);
