const termsAndConditionTr = {
  headerTitle: "HÜKÜM VE KOŞULLAR",
  termsAndConditions: "Şartlar ve koşullar",
  headerDesc:
    "VOLEXPLAY çevrimiçi hizmeti, Algovision Limited'de (İngiltere Londra) kayıtlı Volex Vision Limited şirketi tarafından sağlanır: 59 St. Martin's Lane, Suite 8, Londra, İngiltere, WC2N 4JS üzerinde 13339570 kayıt kodu. VOLEXPLAY esas olarak Amazon tarafından barındırılmaktadır. Web Servisleri. İletişim : info@volexplay.com",

  article1: "Madde 1 – Tanımlar",
  article2: "Madde 2 – Kapsam",
  article3: "Madde 3 – Hesap",
  article4: "Madde 4 – Hizmetler",
  article5: "Madde 5 – Pazar Yeri",
  article6: "Madde 6 – Kullanıcıların Sorumluluğu",
  article7: "Madde 7 – Volexplay'in Sorumluluğu",
  article8: "Madde 8 – Fesih",
  article9: "Madde 9 – Volexplay'in içeriği",
  article10: "Madde 10 – Gizlilik Politikası ve Kişisel Veriler",
  article11: "Madde 11 – Köprü",
  article12: "Madde 12 – Değişiklik",
  article13: "Madde 13 - Feragat etmeme",
  article14: "Madde 14 – Yargı Yetkisi ve Uygulanacak Hukuk",

  "1.1.1":
    "1.1.1 Bu Şart ve Koşulların amaçları doğrultusunda, aşağıdaki büyük harfle yazılmış terimler aşağıdaki anlamlara sahip olacaktır:",
  "1.1.1.item1":
    "Alıcı: Kullanıcının Volexplay's Marketplace'te Koleksiyon Eşyası edinmesini ifade eder.",
  "1.1.1.item2":
    "Tahsil Edilebilir Hizmet içinde bulunan profesyonel bir futbolcuyu içeren dijital kartları ve bir blok zincirinde kullanılabilecek temel tokenleri ifade eder.",
  "1.1.1.item3":
    "Tahsil Edilebilir İlgili Haklar, Koleksiyon Öğeleri ile ilişkili dünyanın herhangi bir ülkesinde veya yargı alanında tanınan herhangi bir üçüncü taraf patent haklarını, görüntü haklarını, telif haklarını, ticari sırları, ticari markaları, teknik bilgileri veya diğer fikri mülkiyet haklarını ifade eder (sınırlama olmaksızın, kulübün fikri mülkiyet hakları, lig veya oyuncunun imaj hakları).",
  "1.1.1.item4":
    "Kripto Para Birimi, blockchain üzerinde bir değişim aracı olarak çalışmak üzere tasarlanmış ve Hizmetler boyunca ödemede kabul edilen herhangi bir dijital varlığı ifade eder.",
  "1.1.1.item5":
    "Para Birimi, yasal ödeme aracı olan herhangi bir para birimini ifade eder (euro, dolar, pound).",
  "1.1.1.item6":
    "Marketplace, Volexplay tarafından sunulan ve Kullanıcıların kendi aralarında Koleksiyon Eşyalarını takas etmelerine veya satmalarına izin veren hizmeti ifade eder.",
  "1.1.1.item7":
    "NFT, Tahsil Edilebilir dijital kartı (kripto-mal veya kripto-toplanabilir olarak da bilinir) temsil eden karşılıksız token anlamına gelir. Bu token, Tahsil Edilebilir Öğenin azlığını ve Hizmetlerin şeffaflığını sağlar.",
  "1.1.1.item8":
    "Satıcı, Kullanıcının Koleksiyonunu Volexplay's Marketplace'te satmayı teklif ettiğini ifade eder.",
  "1.1.1.item9":
    "Hizmetler, Web Sitesinde erişilebilen ve Volexplay tarafından sağlanan her hizmeti ifade eder.",
  "1.1.1.item10":
    'Şartlar ve Koşullar veya(Şart ve Koşullar) Bu belgeye atıfta bulunur. Bu Hüküm ve Koşullar, sizinle Volexplay (yukarıda tanımlandığı gibi) arasında, Web Sitesine ve ilgili tüm Hizmetlere erişiminizi ve bunları kullanımınızı düzenleyen yasal olarak bağlayıcı bir anlaşmayı (bundan böyle "Şartlar" olarak anılacaktır) teşkil eder.',
  "1.1.1.item11":
    "Üçüncü Şahıs Hakları, herhangi bir üçüncü şahıs patent haklarını, görüntü haklarını, telif haklarını, ticari sırları, ticari markaları, know-how'ı veya dünyanın herhangi bir ülkesinde veya yargı alanında tanınan diğer fikri mülkiyet haklarını ve özellikle bu hakları ifade eder. Koleksiyon ile ilişkilendirilebilir.",
  "1.1.1.item12":
    "Üçüncü Taraf Hizmetleri, Volexplay tarafından işletilmeyen, ancak ödeme hizmeti (Stripe, Apple Pay), yasal ihalede dijital varlıkların alım satımı (Ramp, Moonpay), dijital gibi Kullanıcılara içerik veya ek özellikler sağlayan hizmetleri ifade eder. varlık cüzdanı (WalletConnect, Coinbase Wallet, Portis, Opera, Metamask), oyuncularla ilgili içerik, harici koleksiyon pazarları Web Sitesine entegre edilmiş olsun veya olmasın, bu hizmetler tamamen onları sunan sağlayıcıların sorumluluğunda sağlanır. Bu hizmetlerin kullanımı ilgili hizmet sağlayıcının internet sitesinde bulunabilir.",
  "1.1.1.item13":
    "Kullanıcı, Hizmet tarafından sunulan hizmetleri kullanan veya web sitesini tam hukuki ehliyetiyle ziyaret eden herhangi bir gerçek veya tüzel kişiyi ifade eder.",
  "1.1.1.item14":
    "Web Sitesi Alt alan adları dahil olmak üzere www.volexplay.com web sitesini ve Volexplay'in hizmetlerini sunduğu diğer web sitelerini ifade eder.",

  "2.1.1":
    "2.1.1 Volexplay, Kullanıcıların, burada belirtilen amaçlar doğrultusunda Koleksiyon Öğeleri olarak belirlenmiş ve NFT'lerde gerçekleştirilmiş profesyonel futbolcuları içeren koleksiyon dijital kartlarını edinmelerini, toplamalarını, takas etmelerini ve oynamalarını sağlayan çevrimiçi bir web sitesidir. Hizmet, https web sitesinde mevcuttur. ://volexplay.com.",
  "2.1.2":
    "2.1.2 Daha fazla bilgi için Kullanıcılar, Hizmetlerle ilgili bilgilerin ve Kullanıcılar tarafından sorulan soruların yanıtlarının bulunduğu Yardım Merkezi'ne (https://help.volexplay.com) başvurmaya davet edilir.",
  "2.1.3":
    "2.1.3 Bu Şartlar ve Koşullar, kısıtlama veya çekince olmaksızın, Volexplay tarafından Web Sitesinde sunulan Hizmetlerin herkes için kullanımı için geçerlidir. Kullanıcı Web Sitesinde bir hesap oluşturmayı seçtiğinde, bu Şart ve Koşulları tamamen ve koşulsuz olarak kabul eder.",
  "2.1.4":
    "2.1.4 Kullanıcı, ülkesinde sözleşme yapma ve Hizmetleri kullanma ehliyetine sahip olduğunu kabul eder ve bu amaçla sağlanan kutucuğu işaretleyerek Şartlar ve Koşullar'ı okuyup kabul ettiğini beyan eder. Hesap oluşturma Kullanıcı tarafından, bu Şart ve Koşulların herhangi bir kısıtlama veya çekince olmaksızın kabul edildiği anlamına gelir.",
  "2.1.5":
    "2.1.5 Aksi ispatlanmadıkça ve delil sözleşmesi olarak Volexplays bilgisayar sistemine kaydedilen veriler, Kullanıcı tarafından Hizmetler dahilinde yapılan tüm işlemlerin ispatı niteliğindedir.",
  "2.1.6":
    "2.1.6 Bu Şartlar ve Koşullar, Web Sitesinde herhangi bir zamanda erişilebilir durumdadır ve diğer herhangi bir versiyon veya diğer herhangi bir çelişkili belge üzerinde geçerli olacaktır.",
  "2.1.7":
    "2.1.7 Web Sitesi, Üçüncü Şahıs Hizmetlerinden bağlantılar veya içerik içerebilir. Bu Üçüncü Şahıs Hizmetleri, farklı hüküm ve koşullara ve gizlilik uygulamalarına tabi olabilir. Volexplay, bu tür Üçüncü Şahısların mevcudiyeti veya doğruluğundan sorumlu veya yükümlü değildir. Hizmetler veya bu tür Üçüncü Kişi Hizmetlerinden sağlanan içerik, ürünler veya hizmetler. Bu tür Üçüncü Kişi Hizmetlerine bağlantılar, Volexplay tarafından bu tür Üçüncü Kişi Hizmetlerinin onaylandığı anlamına gelmez.",

  3.1: "Madde 3.1 – Oluşturma ve erişim",
  "3.1.1":
    "Madde 3.1.1 Herhangi bir Kullanıcı, Web Sitesinde şu şekilde bir hesap oluşturabilir:",
  "3.1.1.item1": "kullanıcı adını, e-posta adresini vererek;",
  "3.1.1.item2": "(güçlü) bir parola seçme;",
  "3.1.1.item3": "cep telefonu numarasını sağlama ve doğrulama;",
  "3.1.1.item4":
    "Şart ve Koşulları ve Oyun Kurallarını (https://volexplay.com/game_rules) ve Hizmetlerin kullanımını yöneten tüm ek sözleşme belgelerini kabul ederek.",
  "3.1.2":
    "3.1.2 Volexplay, Kullanıcı tarafından bir şifrenin kaybolmasından veya çalınmasından veya bunun sonucunda hesap kimliğine bürünülmesinden sorumlu tutulamaz. Hesabının giriş ve şifresine erişimi güvence altına almak için tüm önlemleri almak Kullanıcı'nın sorumluluğundadır. Kullanıcıların iki faktörlü kimlik doğrulamayı kurmaları şiddetle tavsiye edilir.Kullanıcı, önceden izni olmadan da dahil olmak üzere hesabını kullanan herhangi bir kişinin eylemlerinden sorumludur. Hesabının herhangi bir hileli kullanımını bildirmeyi kabul eder, Volexplay şifresi ile giriş yapın veya mümkün olduğunca çabuk değiştirin.",
  "3.1.3":
    '3.1.3 Hizmet, Hizmetlerin kullanımını kolaylaştırmak için Koleksiyon Öğelerinin ve Kripto Para Birimlerinin korunmasını ve kullanılmasını sağlamak için her hesaba benzersiz ve kişisel bir cüzdan sağlar ("Cüzdan").',
  "3.1.4":
    "3.1.4 Kullanıcılar, Hizmetlerin kullanımıyla bağlantılı işlemleri gerçekleştirmek için Cüzdan'ı kullanmakta özgürdür. Kendi Kripto Para Birimi ve Koleksiyon Öğeleri ile Cüzdan'ı harici bir cüzdandan herhangi bir zamanda borçlandırabilir veya borçlandırabilir. Cüzdana ve Cüzdandan harici bir cüzdana Koleksiyon veya Kripto Para Biriminin nasıl yatırılacağı ve çekileceği Yardım Merkezinde sağlanmaktadır.",
  3.2: "Madde 3.2 – Silme",
  "3.2.1":
    "3.2.1 Kullanıcı, dilediği zaman Web Sitesi tarafından belirlenen şartlar dahilinde hesabının silinmesini talep edebilir.",
  "3.2.2":
    "3.2.2 Bir hesabın silinmesi, hesapla ilişkili tüm Kullanıcı verilerinin yanı sıra ilişkili Cüzdan'ın ve dolayısıyla depolanan Koleksiyon Öğeleri ve Kripto Para Birimlerinin geri alınamaz şekilde silinmesine neden olabilir. Bu nedenle, neden ne olursa olsun hesabın silinmesi , Koleksiyon Öğelerinin ve Kripto Para Birimlerinin kalıcı olarak kaybolmasına neden olabilir. Kullanıcıların, Cüzdan'da depolanan Koleksiyon Öğelerini ve Kripto Para Birimlerini herhangi bir silme prosedüründen önce harici bir cüzdanda saklamaları gerekir.",
  3.3: "Madde 3.3 – Askıya Alma",
  "3.3.1":
    "3.3.1 Diğer zorlayıcı önlemler hariç tutulmaksızın, Volexplay, Kullanıcı tarafından bu Şart ve Koşulların ihlali, haksız, hileli, suç veya zararlı davranış ve özellikle ancak bunlarla sınırlı olmamak üzere, bir Kullanıcı hesabını geçici veya kalıcı olarak askıya alma hakkını saklı tutar. aşağıdaki durumlar:",
  "3.3.1.item1":
    "Kullanıcı bu Şart ve Koşulların tamamına veya bir kısmına uymuyor",
  "3.3.1.item2":
    "Kullanıcı, Tahsilat satın alma amacı gütmeyen ve Hizmetlerin düzgün işleyişini bozacak nitelikte olduğu kanıtlanan açık artırmalar yapıyorsa",
  "3.3.1.item3":
    "Kullanıcı, özellikle Koleksiyon Ürünlerini açıkça piyasa ile uyumlu olmayan fiyatlarla tekrar tekrar alıp satarak şüpheli işlemler gerçekleştirir",
  "3.3.1.item4": "Kullanıcının dolandırıcılık yaptığından şüpheleniliyor",
  "3.3.1.item5":
    "Kullanıcı, SO5 Oyununu, Yönlendirme programını veya Ortak programını kötüye kullanır veya kötüye kullanma girişiminde bulunur;",
  "3.3.1.item6":
    "Kullanıcının oturum açma bilgileri ve şifresi üçüncü şahıslara iletilmiştir;",
  "3.3.1.item7": "Kullanıcı birkaç farklı hesap kullanır.",
  "3.3.2":
    "3.3.2 Askıya alma durumunda, Volexplay, (i) askıya alma nedenini, (ii) kapsamını, (iii) belirterek, hesapla ilişkili e-posta adresine tedbiri Kullanıcıya bildirir. kalıcı veya geçici niteliği ve ikinci durumda (iv) süresi.",
  "3.3.3":
    ":3.3.3 Kalıcı olarak askıya alma durumunda, Kullanıcının Cüzdanından harici bir cüzdana para çekme işlemine devam etmek için otuz (30) günlük bir süresi vardır ve bu sürenin sonunda, maddede belirtilen koşullar altında hesap silinebilir. 3.2. Şartlar ve Koşullar.",
  "3.3.4":
    "3.3.4 Volexplay, Kullanıcıdan şüpheli olarak kabul edilen hesap etkinliği hakkında açıklama yapmasını isteme hakkına sahiptir, ancak yükümlülüğü yoktur. Kullanıcı, Volexplay'e şüpheli olarak kabul edilen hesap etkinliği ile ilgili herhangi bir yararlı bilgiyi kendiliğinden sağlama hakkına sahiptir. askıya alma önlemiyle ilgili bir anlaşmazlık durumunda, Kullanıcı başka bir işlemden önce destek ekibiyle (support@volexplay.com) iletişime geçmeye davet edilir.",
  3.4: "Madde 3.4 – Yönlendirme programı",
  "3.4.1":
    '3.4.1 Volexplay, Kullanıcılarına bu maddeye tabi olan tavsiye programına ("Yönlendirme Programı") katılma imkanı sunar. Referans Programı, Kullanıcılara ("Yönlendiren") başkalarını davet ederek ödül kazanma fırsatı sunar ("Yönlendiren"). Hakem”) Hizmetleri kullanmak için.',
  "3.4.2": "3.4.2 Yönlendirme Programına katılım aşağıdaki koşullara tabidir:",
  "3.4.2.item1": "Yönlendirenin Web Sitesinde yasal bir hesabı vardır;",
  "3.4.2.item2":
    "Yönlendiren bu Şart ve Koşullara uyar ve Yönlendirme Programının şartlarını açıkça kabul eder;",
  "3.4.2.item3":
    'Yönlendiren, Web Sitesinden (https://volexplay.com/invite) bir yönlendirme bağlantısı oluşturmuştur ("Yönlendirme Bağlantısı").',
  "3.4.3":
    '3.4.3 Geçerli sayılması için ("Nitelikli Yönlendirme"), tavsiyenin aşağıdaki koşulları karşılaması gerekir: i) Hakem, Yönlendirenin Yönlendirme Bağlantısı aracılığıyla Web Sitesine erişerek bir hesap oluşturmuş ve tüm bunları kabul etmiştir. Koşulsuz Şartlar ve Koşullar; ii) Hakem tarafından kullanılan Yönlendirme Bağlantısı, doğrudan veya dolaylı olarak, ücretli reklamlar aracılığıyla veya bunların yardımıyla hiçbir zaman tanıtılmamıştır; iii) Hakem, (i) daha önce hiç hesap oluşturmadığını ve (ii) hesap oluşturma sürecinde olmadığını ima eden yeni bir kullanıcıdır; iv) Hakem birincil piyasadan beş (5) Koleksiyon satın aldı; Pazaryerinden yapılan alışverişlerin dikkate alınmadığı belirtilmiştir.',
  "3.4.4":
    '3.4.4 Nitelikli Yönlendirme olması durumunda, hem Yönlendiren hem de Hakem ödül olarak bir (1) sınırlı Koleksiyonluk Öğe ("Yönlendirme Ödülü") alacaktır. Yönlendirme Ödülü olarak verilen Koleksiyon, tamamen rastgele bir şekilde seçilir. Şüpheye mahal vermemek adına, Nitelikli Yönlendirme olarak nitelendirilmeyen yönlendirmeler herhangi bir ödül için uygun değildir.',
  "3.4.5":
    "3.4.5 Teknik nedenlerden kaynaklanan gecikmeler dışında, Yönlendirme Ödülleri, Hakemin birincil piyasadan beşinci Tahsil Eşyasını satın aldığı tarihten itibaren on dört (14) gün içinde ödenir.",
  "3.4.6":
    "3.4.6 Dolandırıcılık veya dolandırıcılık girişimi (özellikle birden fazla hesap olması durumunda) veya bu Şartların ihlali durumunda, Volexplay, belirtilen koşullar altında hesabı askıya alma ve ilgili Kullanıcıların Yönlendirme Programından kullanıcıyı yasaklama hakkını saklı tutar. işbu Şartlar ve Koşullar'da, Volexplay tarafından ilgili Kullanıcıya karşı yapılabilecek herhangi bir yasal işlem saklı kalmak kaydıyla.",
  "3.4.7":
    "3.4.7 Bu Maddede belirtilen koşullar ve avantajlar, herhangi bir zamanda ve önceden haber verilmeksizin Volexplay tarafından bu Maddede yapılan değişiklikler yoluyla tek taraflı olarak değiştirilebilir veya silinebilir.",

  3.5: "Madde 3.5 – Ortaklık programı",
  "3.5.1":
    '3.5.1 Volexplay, Kullanıcılarına bu madde tarafından yönetilen ortaklık programına katılma olanağı sunar ("Ortak Programı"). Ortak Programı, Kullanıcılara Volexplay\'e tanıttıkları diğer Kullanıcılar tarafından elde edilen gelir üzerinden bir komisyon kazanma fırsatı sunar.',
  "3.5.2": "3.5.2 Ortaklık Programına katılım aşağıdaki koşullara tabidir:",
  "3.5.2.item1": "Kullanıcının Web Sitesinde yasal bir hesabı vardır;",
  "3.5.2.item2":
    "Kullanıcı bu Şartlar ve Koşullara uyar ve Ortaklık Programının şartlarını açıkça kabul eder;:",
  "3.5.2.item3":
    "Kullanıcı (a) Web Sitesi (https://volexplay.com/affiliate_program) aracılığıyla Ortak Programına katılmak için başvurmuş, (b) doğru ve yanıltıcı olmayan bilgi vermiş ve (c) Ortak'a katılımı Program, Volexplay tarafından açıkça ve bireysel olarak kabul edilmiştir; Ortak Programına katılım başvurusunu kabul etme veya reddetme kararı tamamen Volexplay'in takdirindedir; Volexplay herhangi bir zamanda herhangi bir sebep göstermeksizin ve önceden haber vermeksizin bir başvuruyu reddedebilir veya katılımı sonlandırabilir. farkında olmalısınız;",
  "3.5.2.item4":
    "Kullanıcı, Üçüncü Kişi Hizmetinin Ana Kampanya Sözleşmesini kayıtsız şartsız kabul etmiştir;",
  "3.5.2.item5":
    'Kullanıcı, Web Sitesinde belirtilen koşullar altında bir bağlı kuruluş bağlantısı oluşturmuştur ("Ortak Bağlantısı").',
  "3.5.3":
    '3.5.3 Bu Maddenin amaçları doğrultusunda, yukarıdaki koşullara uygun olarak Ortaklık Programına katılmaya hak kazanan Kullanıcılar "Ortak Kuruluşlar" olarak anılacaktır"',
  "3.5.4":
    '3.5.4 Bağlı Kuruluş tarafından yönlendirilen Kullanıcı (bir "Öncü") olarak kabul edilmek için aşağıdaki koşulların karşılanması gerekir: i) Yönlendirilen Kullanıcı, Ortak Bağlantısı aracılığıyla Web Sitesine erişerek bir hesap oluşturmuş ve tüm bu Şartlar ve Koşullar\'ı kabul etmiştir. kayıtsız şartsız; ii) Ortak tarafından kullanılan Ortak Bağlantısı, doğrudan veya dolaylı olarak, ücretli reklamlar aracılığıyla veya bunların yardımıyla hiçbir zaman tanıtılmamıştır; iii) yönlendirilen Kullanıcı, (i) hiç oluşturmadığını ima eden yeni bir kullanıcıdır. daha önce bir hesap ve (ii) hesap oluşturma sürecinde değil.',
  "3.5.5":
    '3.5.5 Yapılan üyelikler karşılığında, Ortak, söz konusu Müşteri Adayları tarafından oluşturulan gelirin tüm vergiler dahil %10\'luk bir komisyonunu ("Ortak Ücretleri") alacaktır. hesabın Lider tarafından oluşturulması.',
  "3.5.6":
    "3.5.6 Ortak, Volexplay'in yalnızca Ortak Bağlantısı aracılığıyla yönlendirilen ve Volexplay sistemleri veya harici sağlayıcıları tarafından otomatik olarak izlenen ve raporlanan Müşteri Adayları için ödenmesi gereken Ortak Ücretlerini ödeyeceğini anlar ve açıkça kabul eder. Ortak, aksi kanıtlanmadıkça, ve bir kanıt sözleşmesi olarak, Volexplay'in veya harici sağlayıcılarının bilgi sistemlerinde tutulan üyeliklerin izleme verileri, ödenmesi gereken Üye Ücretlerinin kanıtını teşkil edecektir.Volexplay, bilgi sistemlerinde veya hizmet sağlayıcıları.",
  "3.5.7":
    "3.5.7 Ortak Ücretleri, Lead'in gelir elde ettiği ayı takip eden ay boyunca ödenir.",
  "3.5.8":
    "3.5.8 Dolandırıcılık veya dolandırıcılık girişimi veya bu Şart ve Koşulların ihlali durumunda, Volexplay, bu Şartlar ve Koşullarda belirtilen koşullar altında, Volexplay tarafından yapılabilecek herhangi bir yasal işlem saklı kalmak kaydıyla, ilgili Kullanıcıların hesabını askıya alma hakkını saklı tutar. ilgili Kullanıcıya karşı.",
  "3.5.9":
    "3.5.9 Ortağın dolandırıcılık yapması veya Ortağın Ortak Programına katılımının Volexplay tarafından yanlış bilgiler temelinde kabul edilmesi durumunda, Ortak, Ortak Ücretlerinin tamamını Volexplay'e iade etmekle yükümlü olacaktır. Ortak açıkça anlar ve kabul eder.",
  "3.5.10":
    "3.5.10 Bu Maddede belirtilen koşullar ve avantajlar, herhangi bir zamanda ve önceden haber verilmeksizin Volexplay tarafından bu Maddede yapılan değişiklikler yoluyla tek taraflı olarak değiştirilebilir veya silinebilir.",

  4.1: "Madde 4.1 - Tahsil edilebilir özellikler ve nitelikler",
  "4.1.1":
    "4.1.1 Koleksiyon Öğeleri, her öğede belirtilen kıtlık düzeyine dayalı olarak Volexplay tarafından verilir. Örneğin, bir Koleksiyon, sunulan toplam öğe sayısına göre sınırlı, nadir, çok nadir veya benzersiz olabilir. Koleksiyon Öğesinin kıtlığı, doğrudan blok zincirinde izlenebilen temel NFT tarafından garanti edilir.",
  "4.1.2":
    "4.1.2 Koleksiyon Öğeleri, Volexplay'in hak sahipleri tarafından verilen lisanslar kapsamında yararlandığı üçüncü tarafların haklarını temsil eder. Volexplay ile bu hakların sahipleri arasındaki tüm sözleşme hükümleri Kullanıcılara karşı uygulanabilir.",
  4.2: "Madde 4.2 - Tahsilat İhracı",
  "4.2.1":
    "4.2.1 Volexplay, Volexplay'in yalnızca Kullanıcılar sabit bir süre için teklif vermeden önce taban fiyatı belirlediği açık artırmalar yoluyla Koleksiyon Öğeleri sunar. Alıcı son teklif verendir. Rezervasyon fiyatı yoktur. Geçerli bir hesap aracılığıyla yapılan web sitesi gerçek ve geçerli kabul edilir. Normal teklifler bağlayıcıdır ve geri alınamaz veya değiştirilemez.",
  "4.2.2":
    "4.2.2 Müzayede bitiminden sonraki 4 saat içinde bir müzayedeye erişimi imkansız kılan bir hizmet kesintisi (teknik sorun) durumunda, Volexplay müzayede süresini uzatma veya sonra yeniden başlatmak için müzayedeyi iptal etme hakkını saklı tutar.",
  "4.2.3":
    "4.2.3 Volexplay ayrıca Koleksiyon paketlerinin satışını da sunmaktadır. Pakete dahil olan Koleksiyon Öğeleri satıştan önce belirtilir.",
  "4.2.4":
    "4.2.4 Profesyonel olmayan Kullanıcı, Birleşik Krallık Tüketici Yasası'nın L. 221-18. Maddesi hükümleri uyarınca sözleşmenin akdedilmesinden itibaren on dört (14) gün içinde kullanabilecek bir cayma hakkına sahiptir. Koleksiyon Öğelerinin niteliği ve satın alma sonrasında Koleksiyon Öğelerinin hemen teslimi, Birleşik Krallık Tüketici Yasası'nın L. 221-18 ve devamındaki Maddelerinde belirtilen cayma süresi ve Tüketici Haklarının Korunması ile ilgili Avrupa direktifinin 9. Maddesi geçerli olmayacaktır.",
  "4.2.5":
    "4.2.5 KULLANICI, SİTE ÜZERİNDEN BİR KOLEKSİYON SATIN ALMAK İLE CAYMA HAKKINDAN AÇIKÇA FERAGAT ETMEKTEDİR",
  4.3: "Madde 4.3 – Devir ve takas",
  "4.3.1":
    "4.3.1 Kullanıcı, Koleksiyon Ürünlerini hesabından çekme ve ayrıca Koleksiyon Ürünlerini Pazar Yeri'nde veya bir üçüncü taraf pazaryeri gibi bir Üçüncü Taraf Hizmeti aracılığıyla takas etme olanağına sahiptir.",
  "4.3.2":
    "4.3.2 Volexplay, Koleksiyon Öğelerinin Web Sitesi dışında aktarılması, geri çekilmesi veya değiştirilmesi sırasında meydana gelebilecek herhangi bir zarardan hiçbir şekilde sorumlu değildir.",
  4.4: "Madde 4.4 – Fantezi futbol",
  "4.4.1":
    '4.4.1 "SO5" adlı oyun, oyuncuların sahadaki gerçek performansını izleyen ve Web Sitesinden erişilebilen sanal bir turnuvaya dayanmaktadır.',
  "4.4.2":
    "Bu promosyon oyunu yasal olarak şuradan bakılabilen kurallara tabidir: https://volexplay.com/game_rules.",
  4.5: "Madde 4.5 – Destek",
  "4.5.1": "4.5.1 Destek Web Sitesinde mevcuttur.",
  "4.5.2":
    "4.5.2 Volexplay, işlemdeki talep sayısına bağlı olarak mümkün olan en kısa sürede yanıt vermeyi taahhüt eder.",

  5.1: "Madde 5.1 – Kullanım Koşulları",
  "5.1.1":
    "5.1.1 Volexplay, Kullanıcıların kendi aralarında Kripto Para Birimleri veya diğer Koleksiyon Öğeleri ile Koleksiyon Öğeleri alışverişinde bulunmalarına izin veren bir Pazar Yeri sağlar.",
  "5.1.2":
    "5.1.2 Bu madde yalnızca Web Sitesinde bir Tahsilat Öğesinin değişimi bağlamında Alıcı ve Satıcı arasındaki ilişkiler için geçerlidir.",
  "5.1.3":
    "5.1.3 PAZAR YERİ KULLANIMI,BU ŞARTLARIN VE ÖZEL OLARAK AŞAĞIDAKİ MAKALELERİN KOŞULSUZ KABUL VE SAĞLANMASINA TABİDİR.",
  5.2: "Madde 5.2 - Satış ve takas",
  "5.2.1":
    "5.2.1 Koleksiyon Ürünlerine sahip olan kullanıcılar, bunları Yardım Merkezi'nde belirtilen prosedürlere uygun olarak Pazaryerinde satma olanağına sahiptir. Bu durumda, Satıcı, teklif edilen sabit satış fiyatını (« Teklif ») belirlemekte özgürdür. Koleksiyon, 48 saat boyunca Pazaryeri'nde sunulmaktadır. Bu süre içinde Koleksiyon Öğesi satılmazsa, teklif Pazaryerinden kaldırılır ve Kullanıcı, Koleksiyonu dilediği zaman yeniden satışa sunabilir.",
  "5.2.2":
    "5.2.2 Volexplay, Pazaryeri'nde satışa sunulan Koleksiyon Ürünlerinin seçimine veya Satıcılar tarafından belirlenen satış fiyatlarının belirlenmesine hiçbir şekilde müdahale etmez. Daha genel olarak Volexplay, aralarındaki satışa hiçbir şekilde müdahale etmez. Pazaryerinde Alıcı ve Satıcı. Sonuç olarak, Volexplay asla bu satışın muadili olarak hareket etmez.",
  "5.2.3":
    "5.2.3 Daha spesifik olarak, Volexplay, Marketplace'te satışa sunulan Koleksiyon Ürünlerinin seçimine veya Satıcılar tarafından belirlenen satış fiyatlarının belirlenmesine hiçbir şekilde müdahale etmez. Kullanıcılar, Volexplay'in Satıcılar tarafından önerilen fiyatların gerçekliği, alaka düzeyi ve adilliği, ister aşırı ister gülünç olsun. Alıcı, Pazar Yeri aracılığıyla önerilen Koleksiyon Ürünlerinin fiyatının takdir edilmesinden tek sorumludur.",
  5.3: "Madde 5.3 - Tekliflerin Listelenmesi ve Listeden Çıkarılması",
  "5.3.1":
    "5.3.1 Herhangi bir kullanıcı, Marketplace'te bir Koleksiyon Teklifi listeleyebilir. Marketplace'te listelenen Koleksiyon Öğeleri, yalnızca onları satışa sunan Satıcılar tarafından seçilir.",
  "5.3.2":
    "5.3.2 volexplay, özellikle, ancak bununla sınırlı olmamak üzere, Satıcının Tekliflerini Pazar Yerinden kaldırma hakkını saklı tutar.",
  "5.3.2.item1": "geçerli düzenlemelere uymuyor",
  "5.3.2.item2": "Şart ve Koşullara uymuyor",
  "5.3.2.item3":
    "Hesabının geçici veya kalıcı olarak askıya alınmasına tabidir",
  "5.3.2.item4": "Hizmetler'den hileli olarak yararlanır.",
  5.4: "Madde 5.4 – Tekliflerin Sıralaması",
  "5.4.1":
    '5.4.1 Pazar Yeri\'nde listelenen Koleksiyon Öğeleri, Satıcı tarafından sunulan fiyatı ve Alıcı tarafından sunulan fiyat arasındaki en iyi orana sahip Teklifleri öne çıkarmak amacıyla Koleksiyon Öğesinin performansını dikkate alan bir algoritmaya göre varsayılan olarak sıralanır. Satıcı ve Koleksiyon tarafından temsil edilen oyuncuların performansı. Bu varsayılan sıralama yöntemi, Pazaryerinde "En İyi Değer" olarak adlandırılan arama sonuçlarının sıralama kriterine karşılık gelir.',
  "5.4.2":
    "5.4.2 Alıcı ayrıca, dilerse, sonuçları aşağıdaki diğer kriterlere göre sıralayarak aramasını iyileştirebilir:",
  "5.4.2.item1":
    'Teklifler en yakın zamandan en yakın zamana ("Yakında Sona Eriyor");',
  "5.4.2.item2": 'Teklifler en yeniden en yeniye ("Yeni Listelenen");',
  "5.4.2.item3": 'En pahalıdan en ucuza ("En Yüksek Fiyat") teklifler;',
  "5.4.2.item4": 'En ucuzdan en pahalıya teklifler ("En Düşük Fiyat");',
  "5.4.3":
    "5.4.3 Koleksiyon Öğeleri daha sonra arama sonuçlarında seçilen listeleme kriterlerine göre sıralanır.",
  5.5: "Madde 5.5 - Vergi ve sosyal güvenlik yükümlülükleri",
  "5.5.1":
    "5.5.1 Volexplay, özellikle Koleksiyon'un satış fiyatının satın alma fiyatından yüksek olduğu durumlarda, Tahsilat Satışının yürürlükteki vergi mevzuatına göre vergiye tabi gelir oluşturmasının muhtemel olduğuna Kullanıcıların dikkatini çeker. Vergi ve varsa sosyal güvenlik yükümlülüklerini yerine getirmelerini sağlamak için yetkili vergi ve sosyal güvenlik makamlarıyla iletişime geçmeleri tavsiye edilir.",
  "5.5.2":
    "5.5.2 Tahsilatlarının Satışından elde edilen geliri yetkili vergi dairesine ve sosyal güvenlik idaresine beyan etmek Kullanıcıların sorumluluğundadır.",
  "5.5.3":
    "5.5.3 Vergi amaçlı olarak Fransa'da ikamet eden kullanıcılar, Birleşik Krallık vergi dairesinin internet sitesi www.impots.gouv.fr aracılığıyla vergi yükümlülüklerini yerine getirebilir ve uygulanabilecek vergi rejimleri ve vergi beyannamesi prosedürleri hakkında bilgi edinilebilir sayfadan aşağıdaki bağlantı aracılığıyla: https://www.impots.gouv.fr/porttail/node/10841",
  "5.5.4":
    "5.5.4 Vergi amacıyla Fransa'da ikamet eden kullanıcılar, uygun olduğu durumlarda, sosyal güvenlik web sitesi www.urssaf.fr aracılığıyla sosyal güvenlik yükümlülüklerini yerine getirebilir ve geçerli olabilecek sosyal güvenlik planları ve raporlama prosedürleri hakkında bilgi alabilir: https ://www.urssaf.fr/porttail/home/espaces-dedies/activites-relevant-de-leconomie.html",
  "5.5.5":
    "5.5.5 Volexplay, yılın başında her Satıcıya, Satıcı tarafından Pazaryeri aracılığıyla gerçekleştirilen işlemlerin sayısını ve miktarını özetleyen bir işlem beyanı sunar.",
  5.6: "Madde 5.6 – Piyasaya İlişkin İhtilaflar",
  "5.6.1":
    "5.6.1 Pazaryerinin bir parçası olarak, Volexplay Satıcılar ve Alıcılar arasında yalnızca bir aracı olarak hareket eder. Bu nedenle, Volexplay bir satıştan, fiyattan veya taraflardan birinin yaptığı bir eylemden kaynaklanan zararlardan sorumlu tutulamaz.",
  "5.6.2":
    "5.6.2 Ayrıca, Marketplace aracılığıyla sağlanan hizmetler yalnızca Alıcı ve Satıcı arasında gerçekleşir. Volexplay'in ilgilenmediği bu tür hizmetlerle ilgili olarak Volexplay'in sorumluluğu üstlenilemez.",
  "5.6.3":
    "5.6.3 Bu nedenle, herhangi bir potansiyel hak talebi Alıcı tarafından Satıcıya yöneltilmelidir. Satıcı, taleplerin işlenmesinden yalnızca sorumludur. Bununla birlikte, Satıcının bir talebe yanıt vermemesi veya bir anlaşmazlığı çözmemesi durumunda Volexplay, kendi takdirine bağlı olarak tahkimde yer alma hakkını saklı tutar.",

  "6.1.1":
    "6.1.1 Kullanıcı, Hizmetlerin kullanımından kaynaklanabilecek, öngörülebilir riski aşan herhangi bir ihlale karşı Volexplay'i garanti eder.",
  "6.1.2":
    "6.1.2 Kullanıcı, Hizmetleri yalnızca Şartlar ve Koşullar uyarınca kullanmayı taahhüt eder. Yürürlükteki yasaların izin verdiği durumlar dışında, Hizmetleri yeniden yapılandırmaya, kaynak koda dönüştürmeye, parçalarına ayırmaya veya içerdiği teknik kısıtlamaları aşmaya yetkili değildir.",
  "6.1.3":
    "6.1.3 Kullanıcı, özellikle hizmetin diğer Kullanıcılar tarafından kullanılmasına müdahale etmesi durumunda, hizmetin hileli, haksız veya kötü niyetli kullanımına karşı Volexplay'i garanti eder.",

  7.1: "Madde 7.1 – Sorumluluk Reddi",
  "7.1.1":
    '7.1.1 Kullanıcılar, Hizmetlere erişimin ve bunları kullanmanın riskinin Kullanıcıya ait olduğunu ve Hizmetlerin herhangi bir garanti olmaksızın "olduğu gibi" ve "mevcut olduğu şekilde" sağlandığını açıkça anlar ve kabul eder. , yasalarca aksi belirtilmedikçe, açık veya zımni olarak.',
  "7.1.2":
    "7.1.2 Yasaların izin verdiği azami ölçüde, Volexplay şunları beyan veya garanti etmez: (i) Hizmetlere erişim veya Hizmetler'in kullanımı kesintisiz, zamanında, güvenli veya hatasız olacaktır; (ii) tarafından sağlanan kullanım verileri. Hizmetler veya Web Sitesindeki hizmetler her zaman doğru olacaktır; (iii) Hizmetlerde kötü amaçlı yazılımlar veya diğer zararlı bileşenler bulunmaz.",
  "7.1.3":
    "7.1.3 Kullanıcılar, bilgi sağlama, internette çevrimiçi ticaret ve blokajlar ve değiştirilemez tokenler gibi deneysel teknolojiler ile ilgili güvenlik risklerini kabul eder ve Volexplay'in ciddi bir güvenlik ihlalinden kaynaklanmadıkça herhangi bir güvenlik . Volexplay'in sorumlu olmayacağını kabul eder.",
  "7.1.4":
    "7.1.4 Volexplay, Blockchain ağının ve dijital varlık cüzdanlarının kullanılması sonucunda Kullanıcıların uğrayacağı zararlardan sorumlu değildir. Volexplay, blockchain ağı üzerinde herhangi bir kontrole sahip değildir ve blok zincirinden kaynaklanan herhangi bir kayıptan sorumlu olmayacaktır.",
  "7.1.5":
    "7.1.5 Bazı yargı bölgeleri, tüketici sözleşmelerinde zımni garantilerin hariç tutulmasına izin vermez, bu nedenle yukarıdaki istisnaların bazıları veya tümü tüm Kullanıcılar için geçerli olmayabilir.",
  7.2: "Madde 7.2 – Hizmet içeriği",
  "7.2.1":
    "7.2.1 Bir barındırma sağlayıcısı olarak Volexplay, E-ticaretle ilgili 8 Haziran 2000 (2000/31/EC) tarihli AB Yönergesi tarafından sağlanan sınırlı sorumluluk rejimine tabidir.",
  "7.2.2":
    "7.2.2. Bu nedenle Volexplay, Kullanıcılar tarafından yayınlanan içeriklerden ve aralarında değiş tokuş edilen içeriklerden sorumlu tutulamaz ve bu konuda açık veya zımni hiçbir garanti vermez. Volexplay, Kullanıcı ilişkilerinde üçüncü taraftır ve dolayısıyla bu konuda herhangi bir sorumluluk kabul etmez.",
  7.3: "Madde 7.3 – Sorumluluk",
  "7.3.1":
    "7.3.1 Volexplay, sağlanan Hizmetlerin en iyi şekilde sunulmasını sağlamak için gerekli tüm araçları uygulamayı taahhüt eder. Ancak, Volexplay yalnızca en iyi çaba gösterme yükümlülüğüne bağlı olabilir.",
  "7.3.2":
    "7.3.2 Volexplay'in başarısız olması durumunda, yasa veya yönetmelik tarafından aksi belirtilmedikçe, yalnızca doğrudan ve makul olarak öngörülebilir zararlardan sorumlu olacaktır. Hizmetin başarısızlığından veya kullanımından kaynaklanabilecek dolaylı zararlar açıkça hariç tutulmuştur.",
  "7.3.3":
    "7.3.3 Her halükarda, Volexplay, yasa veya yönetmelikler tarafından aksi belirtilmedikçe, son on iki ay içinde Kullanıcıya fatura edilen tutarların ötesinde sorumlu tutulamaz.",
  "7.3.4":
    "7.3.4 Özellikle, kanun veya yönetmelik tarafından aksi belirtilmedikçe, Volexplay şunlardan sorumlu tutulamaz:",
  "7.3.4.item1":
    "24 saatten kısa süren veya Volexplay'in sorumluluğunda olmayan Hizmetlerin arızalanması veya arızalanması;",
  "7.3.4.item2":
    "Volexplay'e isnat edilemeyen Kullanıcıların veri, Koleksiyon veya Kripto Para Birimi kayıpları, özellikle, ancak bununla sınırlı olmamak üzere, Kullanıcının bağlantı detaylarının korunmasındaki ihmal nedeniyle hesabının hacklenmesi durumunda.;",
  "7.3.4.item3":
    "Üçüncü bir kişi veya bir Kullanıcı tarafından sunulan hizmetlerin olağandışı veya yasa dışı kullanımı;",
  "7.3.4.item4":
    "Sunulan Hizmetlerin kullanımından kaynaklanan doğrudan veya dolaylı zararlar;",
  "7.3.4.item5": "bir arıza veya siber saldırı;",
  "7.3.4.item6":
    "Bu Şartlar ve Koşullar'ın 3.3. maddesinde belirtilen koşullar altında gerçekleştirilen bir hesabın askıya alınmasının zararlı sonuçları;",
  "7.3.4.item7":
    "Müzayede sırasında hem süreç içerisinde hem de taban fiyatın ve nihai fiyatın belirlenmesi aşamasında meydana gelebilecek bir olumsuzluk;",
  "7.3.4.item8":
    "Hizmetleri çalıştırmak için kullanılan blok zinciri protokolünün başarısızlığı da dahil olmak üzere, Birleşik Krallık medeni kanununun 1218. Maddesi anlamında herhangi bir mücbir sebep durumu.",

  "8.1.1":
    "8.1.1 Kullanıcı, bu Şartlar ve Koşullar'ın 3.2 Maddesi uyarınca hesabının silinmesini talep ederek herhangi bir zamanda bu Şart ve Koşulları feshedebilir.",
  "8.1.2":
    "8.1.2 Volexplay, herhangi bir zamanda, herhangi bir nedenle ve tamamen kendi takdirine bağlı olarak, bu Şartlar ve Koşullar'ı feshedebilir ve Hizmetleri bildirimde bulunmaksızın askıya alabilir. Kullanıcılar, yasaların izin verdiği en geniş ölçüde, Hizmetlere erişimin askıya alınmasının veya sona erdirilmesinin önceden haber vermeksizin olabilir ve Volexplay bu tür herhangi bir askıya alma veya feshin sonuçlarından sorumlu olmayacaktır.",
  "8.1.3":
    "8.1.3 Bu Şart ve Koşulların feshedilmesi veya bir Kullanıcının hesabının askıya alınması durumunda, Volexplay, bu Şart ve Koşulların ihlali nedeniyle hesabın feshedilmesine veya askıya alınmasına karar verilmedikçe, Kullanıcılara Koleksiyon Öğelerini ve Kripto Para Birimlerini geri çekme fırsatı sağlayacaktır. Kullanıcı veya herhangi bir dolandırıcılık, taciz veya yasa dışı faaliyet iddiası nedeniyle. İkinci durumda, hesabın feshedilmesi ve/veya askıya alınması, Volexplay'in Kullanıcıya karşı yapabileceği diğer yasal işlemlere halel getirmeyecektir.",

  "9.1.1":
    "9.1.1 Kullanıcılar tarafından oluşturulan içerikler hariç olmak üzere, Web Sitesi içeriği üzerindeki tüm fikri mülkiyet hakları Volexplay'in mülkiyetindedir. Bunlar, Hizmetlerin münhasır kullanımı çerçevesinde ücretsiz olarak sağlanmaktadır. Bu nedenle, bu içeriklerin önceden izin verilmeden herhangi bir şekilde kullanılması, Volexplay'in izni dışıdır.",
  "9.1.2":
    "9.1.2 Volexplay, hizmetin veri tabanı üreticisidir. İzinsiz herhangi bir şekilde çıkarılması veya çoğaltılması yasaktır.",
  "9.1.3":
    "9.1.3 Kullanıcı, bu Şartlar ve Koşullar uyarınca meşru bir şekilde ve iyi niyetle satın aldığı NFT'nin nihai sahibidir.",
  "9.1.4":
    "9.1.4 Kullanıcı, işbu Şart ve Koşullardaki aksine hükümlere bakılmaksızın, sahibi olduğu NFT'leri (satış, ödünç verme, bağışlama, devir vb.) serbestçe tasarruf etme hakkına sahiptir.",
  "9.1.5":
    "9.1.5 Aksi açıkça belirtilmedikçe, Hizmetlerin kullanılması veya bir Koleksiyon Öğesinin satın alınması, Web Sitesinde veya Hizmetler üzerinden erişilebilen içerik, kod, veri veya diğer materyaller üzerinde herhangi bir mülkiyet veya başka hak vermez. veya Volexplay'in Hizmetlerle ilişkilendirilebilecek hakları veya Üçüncü Taraf Hakları üzerinde.",
  "9.1.6":
    "9.1.6 Bu Şartlar ve Koşullara uymaya tabi olarak, Kullanıcı, yasal olarak sahip olduğu NFT'ler tarafından temsil edilen Tahsil Edilebilir Ürünlerle ilişkili Tahsil Edilebilir İlgili Hakları kişisel amaçlarla kullanmak, çoğaltmak ve sergilemek için münhasır olmayan bir temelde yetkilendirilir. ve yalnızca ticari olmayan amaçlarla.",
  "9.1.7":
    "9.1.7 Kullanıcı, hiçbir koşulda, Volexplay'in önceden yazılı izni olmaksızın, Kullanıcıya ait olsun veya olmasın Koleksiyon Öğelerine aşağıdakilerden herhangi birini yapamaz veya yapmaya teşebbüs edemez: (i) Tahsil Edilebilir Öğeyi ve ilişkili tüm Tahsil Edilebilir İlgili Hakları kullanmak ticari amaçlar, üçüncü taraf bir ürün veya hizmetin reklamı veya tanıtımı için kendisine ait olan Koleksiyon Öğeleri ile; (ii) satın alınan Koleksiyon Öğesini temsil eden fiziksel veya dijital ticari malları pazarlamak; (iii) Koleksiyon Öğesi ile ilişkili görüntüyü değiştirmek; ( iv) Koleksiyon ile ilgili herhangi bir ek fikri mülkiyet hakkı talep etmeye çalışmak.",
  "9.1.8":
    "9.1.8 Ayrıca, Kullanıcı, ilgili hakların sahibi tarafından verilen lisansa uygunluğu sağlamak için Üçüncü Şahıs Haklarını temsil eden Koleksiyon Öğelerinin kullanımına daha sonra ek kısıtlamalar getirilebileceğini anlar ve kabul eder. Kullanıcı, tüm bunlara uymayı açıkça kabul eder. bu tür kısıtlamalar, bu tür kısıtlamaların Volexplay tarafından e-postayla bildirildiği tarihten itibaren.",

  10.1: "Madde 10.1 – Kişisel veriler",
  "10.1.1":
    '10.1.1 Kullanıcıları doğrudan veya dolaylı olarak tanımlayan bilgiler (bundan böyle "Kişisel veriler" olarak anılacaktır), çerezler de dahil olmak üzere Kullanıcının Hizmetleri kullanımıyla bağlantılı olarak Volexplay tarafından işlenir.',
  "10.1.2":
    "10.1.3 Volexplay'in gizlilik politikası şurada mevcuttur: https://volexplay.com/privacy-policy.",
  "10.1.3": "",
  10.2: "Madde 10.2 – Çerezler",
  "10.2.1":
    "10.2.1 Web Sitesine danışma sırasında, web sitesinin kullanımını kolaylaştırmak ve katılım ve erişimle ilgili istatistikleri toplamak için Kullanıcının elektronik iletişim ekipmanına çerezler kaydedilebilir.",
  "10.2.2":
    "10.2.2 Çerezler, Web Sitesinin kullanımını kolaylaştırmak ve Kullanıcıları hakkında istatistiksel bilgi toplamak için çeşitli teknik verileri depolamak amacıyla web sitesinin terminalde kaydettiği metin dosyalarıdır.",
  "10.2.3":
    "10.2.3 Kullanıcılar, terminal bilgileri hakkında mağaza hakkında önceden bilgilendirilir. Kullanıcılar, çerezlerin saklanmasını kabul veya reddetmekte serbesttir. Bilgilendirildikten sonra Web Sitesinde gezinmeye devam edilmesi, Kullanıcının veri toplanmasını kabul ettiği anlamına gelir.",

  11.1: "Madde 11 – Köprü",
  "11.1.1":
    "Hizmet, üçüncü şahıs sitelerine yönlendiren köprü metni bağlantıları içerebilir. Hizmetin bu siteler üzerinde hiçbir kontrolü yoktur ve bu sitelerin erişimi, içeriği veya kullanımı ile ilgili tüm sorumluluğu reddeder.",

  12.1: "Madde 12 – Değişiklik",
  "12.1.1":
    "12.1.1 Volexplay bu Hüküm ve Koşulları herhangi bir zamanda değiştirebilir. Hüküm ve Koşulların geçerli versiyonu, Hizmetlerin kullanıldığı tarihte yayınlanan en son versiyondur. Hüküm ve Koşulların sürüm numarası ve yürürlük tarihi belgenin başında belirtilmiştir. Volexplay, önemli değişiklikleri, yürürlüğe girme tarihinden en az 15 gün önce Kullanıcılara e-posta ile bildirecektir. Bu amaçla contact@volexplay.com adresine açık bir e-posta göndermek veya bu Şartlar ve Koşullar kapsamındaki hesaplarını silmek. revize edilmiş Şart ve Koşulların kabul edildiği anlamına gelir.",
  "12.1.2":
    "12.1.2 Volexplay, Hizmetlerin ve/veya Web Sitesinin veya uygulamaların tamamının veya bir kısmının erişilebilirliğini dilediği zaman değiştirme veya durdurma hakkını saklı tutar.",

  13.1: "Madde 13 – Feragatname",
  "13.1.1":
    "13.1.1 Volexplay'in herhangi bir zamanda diğer taraftan bu Şartlar ve Koşullar kapsamındaki yükümlülüklerinden herhangi birini yerine getirmesini talep etmemesi, Volexplay'in daha sonra bunların icrasını talep etme hakkını etkilemeyecektir.",
  "13.1.2":
    "13.1.2 Bu Şart ve Koşulların herhangi bir maddesinin geçersizliği, geçerliliğini ve diğer maddelere saygı gösterme yükümlülüğünü etkilemez.",

  14.1: "14.1 - Geçerli yasa",
  "14.1.1":
    "14.1.1 Bu Şartlar ve Koşullar yalnızca Birleşik Krallık yasalarına tabidir.",
  14.2: "14.2 – Uyuşmazlık ve Arabuluculuk",
  "14.2.1":
    "14.2.1 Volexplay ile bir Kullanıcı arasında bir anlaşmazlık çıkması durumunda, kullanıcı aşağıdaki adresteki müşteri hizmetleri departmanını bilgilendirmeyi taahhüt eder: contact@volexplay.com, iletişim bilgilerini belirtmeye özen göstererek ve kullanıcıya izin veren her türlü bilgiyi sağlar. anlaşmazlığın kökenini ve sonuçlarını takdir ediyorum.",
  "14.2.2":
    "14.2.2 Tüketiciler, bu amaçla bir talep göndererek, e-posta ile, contact@volexplay.com adresine veya Şirketin adresine posta yoluyla ücretsiz olarak bağımsız bir Ombudsman arama olanağına sahiptir. Aldıktan sonra, Volexplay düzenlemeyi taahhüt eder. en kısa sürede arabuluculuk yapılması.",
  "14.2.3":
    "14.2.3 Kullanıcı ayrıca aşağıdaki adresten Avrupa Komisyonu'nun çevrimiçi anlaşmazlık çözüm servisiyle iletişime geçebilir: ec.europa.eu/consumers/odr.",
  14.3: "14.3 – Yargı Yetkisi",
  "14.3.1":
    "Uyuşmazlık halinde Tüketici, kanun kapsamında bölgesel yargı yetkisine sahip yetkili yargı yetkisini kullanabilir. Kanunen zorunlu yargı yetkisi bulunmaması halinde, yalnızca Paris İstinaf Mahkemesinin yargı yetkisine sahip mahkemeler yetkili olacaktır.",
  "14.3.2":
    "14.3.2 Kullanıcı ayrıca aşağıdaki adresten Avrupa Komisyonu'nun çevrimiçi anlaşmazlık çözüm servisiyle iletişime geçebilir: https://ec.europa.eu/consumers/odr.",
  14.4: "14.4 – Jurisdiction",
  "14.4.1":
    "Uyuşmazlık halinde Tüketici, kanun kapsamında bölgesel yargı yetkisine sahip yetkili yargı yetkisini kullanabilir. Kanunen zorunlu yargı yetkisi bulunmaması halinde, yalnızca Paris İstinaf Mahkemesinin yargı yetkisine sahip mahkemeler yetkili olacaktır.",
};

export default termsAndConditionTr;
