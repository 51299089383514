import {
  ButtonBase,
  CircularProgress,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { t } from "locales";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "./CustomButton";
import _ from "lodash";
import { inputAutoFillStyles, numberInputStyle } from "assets/styles/styles";
import axios from "library/http";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  connectWalletValidation,
  verifyConnectWalletValidation,
} from "validations/marketValidation";
import { getHumanError } from "library/translateServerErrors";
import { useEffect, useState } from "react";
import ErrorAlert from "./ErrorAlert";
import OtpInput from "react-otp-input";
import { Close } from "@mui/icons-material";
import { openToast } from "./Toast";

const OtpInputCustom = styled(OtpInput)(({ theme }) => ({
  input: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid gray",
    fontSize: "18px",
    width: "3rem !important",
    textAlign: "center",
    padding: "0.5rem",
    margin: "0 .5rem",
    borderRadius: "8px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(162 111 255 / 25%)",
    },
  },
}));

const changeStep = 0.01;

const ConnectWallet = ({ onClose = null, id }) => {
  const [error, setError] = useState();
  const [step, setStep] = useState(0);
  const [verifyToken, setVerifyToken] = useState(0);
  const [validation, setValidation] = useState(connectWalletValidation);
  const [exchangeWallet, setExchangeWallet] = useState();
  const [loading, setLoading] = useState(true);
  const [amountSlider, setAmountSlider] = useState(1);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    clearErrors,
    setValue,
    setError: setHookFormError,
  } = useForm({
    defaultValues: { email: "", password: "", code: "", amount: amountSlider },
    resolver: validation ? yupResolver(validation) : undefined,
  });

  useEffect(() => {
    const initWallet = async () => {
      try {
        const checkResponse = await axios.get("wallet/volex/connect");

        if (!checkResponse?.data) return;

        const walletResponse = await axios.get("wallet/volex");
        setExchangeWallet(walletResponse?.data?.data);

        setValidation(null);
        setStep(2);

        setLoading(false);
      } catch (error) {
        setStep(0);

        setLoading(false);
      }
    };

    initWallet();
  }, []);

  const onSubmit = async (data) => {
    try {
      if (step === 0) {
        const { email, password } = data;

        const response = await axios.post("wallet/volex/connect", {
          email,
          password,
        });

        setVerifyToken(response.data?.data?.token);
        setValidation(verifyConnectWalletValidation);
        setStep(1);
      } else if (step === 1) {
        const { code } = data;

        const response = await axios.post("wallet/volex/verify", {
          code,
          token: verifyToken,
        });

        const walletResponse = await axios.get("wallet/volex");

        setExchangeWallet(walletResponse?.data?.data);

        setValidation(null);
        setStep(2);
      } else if (step === 2) {
        const { amount } = data;

        await axios.post("wallet/volex", {
          amount,
        });

        openToast("success", "Your VLX transferred successfully.");
        onClose?.();
      }
    } catch (error) {
      getHumanError(error);
    }
  };

  const amountChangeHandler = (e, newValue) => {
    if (newValue > +exchangeWallet?.wallet?.amount) {
      setHookFormError("amount", {
        type: "insufficient",
        message: "insufficientWalletAmount",
      });
    } else {
      clearErrors("amount");
    }

    setValue("amount", newValue);

    setAmountSlider(newValue);
  };

  useEffect(() => {
    if (amountSlider < 1) {
      setHookFormError("amount", {
        type: "minimumError",
        message: "minimumTransferFromVolex",
      });
    } else {
      clearErrors("amount");
    }
  }, [amountSlider]);

  let renderedFields;
  if (step === 0) {
    renderedFields = (
      <>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.email}
              helperText={t(errors?.email?.message)}
              margin="normal"
              fullWidth
              id="email"
              label={t("email")}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={inputAutoFillStyles}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.password}
              helperText={t(errors?.password?.message)}
              margin="normal"
              fullWidth
              name="password"
              label={t("password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={{ ...inputAutoFillStyles, mb: 3 }}
            />
          )}
        />
      </>
    );
  } else if (step === 1) {
    renderedFields = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Controller
          control={control}
          name="code"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Box sx={{ marginBottom: 3 }}>
              <OtpInputCustom
                value={value}
                // onChange={handleChange}
                onChange={onChange}
                onBlur={onBlur}
                numInputs={4}
                separator={<span>-</span>}
                hasErrored={!!errors.code}
              />
            </Box>
          )}
        />
      </Box>
    );
  } else if (step === 2) {
    renderedFields = (
      <>
        <Box
          sx={{ display: "flex", gap: 1, cursor: "pointer" }}
          onClick={() => setAmountSlider(exchangeWallet?.wallet?.amount)}
        >
          <Typography variant="body2">{t("volexBalance")}:</Typography>
          <Typography variant="body1">
            {exchangeWallet?.wallet?.amount} VLX
          </Typography>
        </Box>
        <TextField
          InputLabelProps={{ shrink: true }}
          error={errors?.amount}
          helperText={t(errors?.amount?.message)}
          margin="normal"
          fullWidth
          name="amount"
          label="Amount - VLX"
          type="number"
          step={changeStep}
          min={1}
          id="amount"
          onChange={(event) => amountChangeHandler(event, event?.target?.value)}
          value={amountSlider}
          sx={{
            ...inputAutoFillStyles,
            mb: 3,
            input: {
              ...numberInputStyle,
            },
          }}
        />
        <Box sx={{ p: "0 8px" }}>
          <Slider
            step={changeStep}
            getAriaLabel={() => "Temperature range"}
            value={amountSlider}
            onChange={amountChangeHandler}
            valueLabelDisplay="off"
            size="small"
            min={1}
            max={exchangeWallet?.wallet?.amount}
            // getAriaValueText={valuetext}
          />
        </Box>
      </>
    );
  }

  let renderedTitle = t("connectToVolexWallet");
  if (step === 1) {
    renderedTitle = t("verifyYourWallet");
  } else if (step === 2) {
    renderedTitle = t("swapVlxToPlay");
  }

  let renderedButtonText = "connect";
  if (step === 1) {
    renderedButtonText = "verify";
  } else if (step === 2) {
    renderedButtonText = "swap";
  }

  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: { xs: "95vw", sm: "70vw", lg: "40vw", xl: "400px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {renderedTitle}
        </Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      {error && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={error} />
        </Box>
      )}
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 3,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          onSubmit={handleSubmit(onSubmit)}
          as="form"
          autocomplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {renderedFields}
          <CustomButton
            type="submit"
            fullWidth
            variant="outlined"
            loading={isSubmitting}
            disabled={!_.isEmpty(errors, true)}
            loaderHeight="40"
            extraSx={{ p: "15px 12px", width: "100%" }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {t(renderedButtonText)}
            </Typography>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default ConnectWallet;
