import { Box, Typography, Avatar, Link } from "@mui/material";
import FollowButton from "routes/Community/components/FollowButton";

const User = ({ user }) => {
  const avatar =
    user.avatar.length > 0
      ? user.avatar[0].location
      : "./broken.jpg";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        my: 1,
        alignItems: "center",
      }}
    >
      <Link
        sx={{ display: "flex", alignItems: "center" }}
        href={"/community/users/" + user.userId}
        underline="none"
        color="textPrimary"
      >
        <Avatar
          alt={user.name}
          src={avatar}
          sx={{ width: 50, height: 50, mr: 2, bgcolor: "secondary.main" }}
        />
        <Box>
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            {user.name}
          </Typography>
          {user.slug && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              @{user.slug}
            </Typography>
          )}
        </Box>
      </Link>
      <Box sx={{ display: "flex", height: "fit-content" }}>
        <FollowButton id={user.userId} followed={user.followed} />
      </Box>
    </Box>
  );
};

export default User;
