import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import setupAxios from "./config/setupAxios";
import axios from "library/http";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "assets/styles/index.css";
import "assets/styles/fa.css";
import "assets/styles/en.css";
import "assets/styles/poppins.css";
import "react-multi-carousel/lib/styles.css";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import SocketProvider from "context/socket/SocketProvider";

setupAxios(axios, store);

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketProvider>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename="/">
            <CssBaseline>
              <App />
            </CssBaseline>
          </BrowserRouter>
        </PersistGate>
      </SocketProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
