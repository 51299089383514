import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import LargeField from "./LargeField";
import SmallField from "./SmallField";
import Chart from "./Chart";

const Index = ({
  team,
  teamPercentages,
  openCardList,
  changeCardType,
  changeCardPercent,
  changeActiveCard,
  activeCard,
}) => {
  const showBigField = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        px: { xs: 0, lg: "30px" },
        pb: { xs: 2, lg: 0 },
        // overflow: "auto",
        flexGrow: "1",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212429" : "#eee",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            width: "100%",
            height: "100%",
            gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr 1fr" },
            gridTemplateRows: { xs: "1fr 1fr", lg: "1fr 1fr 1fr" },
            justifyItems: "center",
            gap: 1,

            // style for slider - in small devices
            ".slick-list, .slick-track, .slick-slide > div": {
              height: "100%",
            },
          }}
        >
          <Box
            sx={{
              gridColumn: { xs: "span 1", lg: "span 3" },
              gridRow: "span 2",
              width: { xs: "100%", xl: "1200px" },
              height: { xs: "70vh", lg: "auto" },
              minHeight: "300px",
            }}
          >
            <Chart team={team} activeCard={activeCard} />
          </Box>
          {showBigField ? (
            <LargeField
              team={team}
              teamPercentages={teamPercentages}
              openCardList={openCardList}
              changeCardType={changeCardType}
              changeCardPercent={changeCardPercent}
              changeActiveCard={changeActiveCard}
            />
          ) : (
            <SmallField
              team={team}
              teamPercentages={teamPercentages}
              openCardList={openCardList}
              changeCardType={changeCardType}
              changeCardPercent={changeCardPercent}
              changeActiveCard={changeActiveCard}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
