import SingleDeck from "./SingleDeck";

const LargeField = ({
  team,
  teamPercentages,
  openCardList,
  changeCardType,
  changeCardPercent,
  changeActiveCard,
}) => {
  return team.map((card, index) => (
    <SingleDeck
      key={index} // using index - DONOT use card.id here (It will cause problem)
      card={card}
      openCardListModal={openCardList}
      changeCardPercent={changeCardPercent}
      changeCardType={changeCardType}
      changeActiveCard={changeActiveCard}
      percent={teamPercentages?.[index]}
    />
  ));
};

export default LargeField;
