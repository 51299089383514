import {
  Box,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FlagIcon from "@mui/icons-material/Flag";
import axios from "library/http";
import { openToast } from "components/Toast";
import { Capitalize } from "library/helper";
import { useSelector } from "react-redux";

const MoreMenu = ({ id, resource, profileId }) => {
  const user = useSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteopen, setDeleteopen] = useState(false);
  const [reportopen, setReportopen] = useState(false);
  const [description, setDescription] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setReportopen(false);
    setDeleteopen(false);
  };

  const handleClickDelete = () => {
    setDeleteopen(true);
    setAnchorEl(null);
  };
  const handleClickReport = () => {
    setReportopen(true);
    setAnchorEl(null);
  };

  const deleteResource = async () => {
    try {
      await axios.delete("gravity/" + resource + "s/" + id);
      openToast("success", Capitalize(resource) + " deleted");
      window.location.reload();
    } catch (error) {
      console.error(error);
      openToast("error", "Something went wrong");
    }
    setDeleteopen(false);
  };

  const reportResource = async () => {
    try {
      await axios.post("gravity/reports", {
        resource: resource,
        resourceId: id,
        description: description,
      });
      openToast("success", Capitalize(resource) + " Reported");
    } catch (error) {
      console.error(error);
      openToast("error", "Something went wrong");
    }
    setReportopen(false);
  };

  return (
    <Box>
      <MoreVertIcon
        id="menu-button"
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        sx={{ "& svg": { mr: "5px" } }}
      >
        <MenuItem onClick={handleClickReport}>
          <FlagIcon /> Report
        </MenuItem>
        {user && user.id == profileId && (
          <MenuItem
            onClick={handleClickDelete}
            sx={{ color: "rgb(235, 0, 20)" }}
          >
            <DeleteForeverIcon /> Delete
          </MenuItem>
        )}
      </Menu>

      <Dialog open={deleteopen} onClose={handleClose}>
        <DialogTitle>Delete {resource}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action can't be undone and it will delete {resource} from your
            profile and timeline of your friends and cannot be restored.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteResource}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={reportopen} onClose={handleClose}>
        <DialogTitle>Report {resource}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This {resource} will be shared with admin privately and will be
            reviewed soon.
            <TextField
              autoFocus
              margin="dense"
              label="Reason"
              type="email"
              fullWidth
              variant="standard"
              onChange={(e) => setDescription(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={reportResource}>Report</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MoreMenu;
