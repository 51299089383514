import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { inputAutoFillStyles, rtlTextFieldStyle } from "assets/styles/styles";
import { t } from "locales";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const EmailTab = ({ onSubmit, control, errors, loading }) => {
  return (
    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                error={errors?.email}
                helperText={t(errors?.email?.message)}
                margin="normal"
                fullWidth
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={[
                  { ...inputAutoFillStyles },
                  (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                ]}
              />
            )}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={loading}
      >
        {t("next")}
      </Button>
      <Grid container>
        <Grid item xs>
          <Typography
            as={Link}
            to="/auth/login"
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {t("back")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailTab;
