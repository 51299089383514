import { Box, Link, TableCell, TableRow } from "@mui/material/";
import { BASE_URL } from "config/Config";
import { formatPrice, percentChange } from "library/helper";

const ListItem = ({ coin, count }) => {
  const filter =
    coin.percentChange7d > 0
      ? "invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%)"
      : "hue-rotate(300deg) saturate(210%) brightness(0.7) contrast(240%)";
  return (
    <TableRow key={coin.id} sx={{ "& td": { pl: 0, pr: 0.5 } }}>
      <TableCell sx={{ px: "4px !important" }}>
        {/* {coin.rank ? coin.rank : "0"} */}
        {count}
      </TableCell>
      <TableCell>
        <Box
          component="span"
          sx={{
            "& a": {
              display: "flex",
              alignItems: "center",
            },
            "& img": {
              mr: 0.5,
              width: "24px",
              height: "24px",
            },
            "& span": {
              pr: 0.5,
            },
          }}
        >
          <Link
            href={"/cryptos/" + coin.name.toLowerCase()}
            underline="none"
            color="textPrimary"
          >
            <img src={coin.icon[0].location} alt={coin.name} width="24" />
            <Box component="span" sx={{ fontWeight: "800" }}>
              {coin.name}
            </Box>
            <Box component="span" sx={{ color: "rgb(128, 138, 157)" }}>
              {" "}
              {coin.symbol}
            </Box>
          </Link>
        </Box>
      </TableCell>
      <TableCell>{formatPrice(coin.price)}</TableCell>
      {[coin.percentChange1h, coin.percentChange24h, coin.percentChange7d].map(
        (index) => (
          <TableCell key={index}>
            {percentChange(index, "mainColor", { fontSize: "12px" })}
          </TableCell>
        )
      )}
      <TableCell sx={{ pr: "16px !important" }}>
        {formatPrice(coin.marketCap, 0, 0)}
      </TableCell>
      {/* <TableCell>{formatPrice(coin.circulatingSupply)}</TableCell> */}
      <TableCell>{formatPrice(coin.volume24h, 0, 0)}</TableCell>
      {/* <TableCell>{coin.circulatingSupply}</TableCell> */}
      <TableCell
        sx={{
          "& img": {
            filter: filter,
          },
        }}
      >
        <img
          src={BASE_URL + "public/sparklines/" + coin.id + ".svg"}
          height="35"
        />
      </TableCell>
    </TableRow>
  );
};

export default ListItem;
