const errorsEn = {
  //************************* GAME CENTER ERRORS *************************
  "error-1000": "The entered mobile number is incorrect",
  "error-1001": "Email or Password is incorrect",
  "error-1002": "Mobile or Password is incorrect",
  "error-1003": "The entered mobile number is already registered in the system",
  "error-1004": "The entered email address is already registered in the system",
  "error-1005": "There is no user with the details entered in the system",
  "error-1006": "The token sent is incorrect",
  "error-1007": "Password update operation failed",
  "error-1008": "The password sent is incorrect",
  "error-1009": "Update operation failed",
  "error-1010": "You're not authorized to access this resource",
  "error-1011": "Asset Network not found",
  "error-1012": "Minimum allowed for withdraw: ",
  "error-1013": "User Wallet not found",
  "error-1014": "The requested amount is more than the users balance",
  "error-1015": "An error occurred while registering the transaction",
  "error-1016": "Asset not found",
  "error-1017": "An error occurred while registering",
  "error-1018": "An error occurred while validating the token",
  "error-1019": "An error occurred while registering the user",
  "error-1020": "An error occurred while registering the blog",
  "error-1021": "Blog not found",
  "error-1022": "Payment token not found",
  "error-1023": "Payment record not found",
  "error-1024": "Duplicate payment record",
  "error-1025": "Payment res num not found",
  "error-1026": "Payment failed",
  "error-1027": "Payment failed during confirmation stage",
  "error-1028": "Notification not found",
  "error-1029": "Pair not found",
  "error-1030": "An error occurred while registering the Payment",
  "error-1031": "An error occurred while registering the Setting",
  "error-1032": "Setting not found",
  "error-1033": "Already edited",
  "error-1034": "Transaction not found",
  "error-1035": "Asset not found",
  "error-1036": "Wallet not found",
  "error-1037": "List not found",
  "error-1038": "An error occurred while logging in",
  "error-1039": "An error occurred during the password forgetting operation",
  "error-1040": "You are not authorized to access this content",
  "error-1041": "amount is low",
  "error-1042": "low wallet amount",
  "error-1043": "Fiat account not found",
  "error-1044": "Category not found.",
  "error-1045": "Payment partner not found",
  "error-1046": "amount is not in range",
  "error-1047": "An error occurred while creating the payment partner",
  "error-1048": "An error occurred while generating the address",
  "error-1049": "An error occurred while registering the achievement",
  "error-1050": "Achievement not found",
  "error-1051": "An error occurred while registering the auction",
  "error-1052": "auction not found",
  "error-1053": "offer not found",
  "error-1054": "Please enter at least one of these fields, mobile or email.",
  "error-1055": "An error occurred while adding!",
  "error-1056": "An error occurred while updating!",
  "error-1057": "An error occurred while deleting!",
  "error-1058": "Item not found!",
  "error-1059": "The item is not editable!",
  "error-1060": "Swap failed!",
  "error-1061": "One of playerId and assetId is required!",
  "error-1062": "Auction offer not found!",
  "error-1063": "Competition not found!",
  "error-1064": "Competition league not found!",
  "error-1065": "Prize not found!",
  "error-1066": "Prize pool not found!",
  "error-1067": "One or more auction offer exist!",
  "error-1068": "Language not found!",
  "error-1069": "Language creation failed!",
  "error-1070": "Cards must contain at least 5 items",
  "error-1071": "Cards position is wrong!",
  "error-1072": "Wallet is low!",
  //************************* FC ERRORS *************************
  "error-2001": "An error occurred while creating the competitions",
  "error-2002": "Competitions not found",
  "error-2003": "An error occurred while creating the country",
  "error-2004": "Country not found",
  "error-2005": "An error occurred while creating the match",
  "error-2006": "Match not found",
  "error-2007": "An error occurred while creating the player",
  "error-2008": "Player not found",
  "error-2009": "An error occurred while creating the team",
  "error-2010": "Team not found",
};

export default errorsEn;
