import { Box } from "@mui/system";
import Header from "./Header";
import Cards from "./Cards";
import { CircularProgress } from "@mui/material";

const Index = ({ cards, loading = false }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
      <Header />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            minHeight: "30vh",
          }}
        >
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Cards cards={cards} />
      )}
    </Box>
  );
};

export default Index;
