import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { forwardRef, useImperativeHandle, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: (theme) =>
    theme.palette.mode === "dark" ? "card.dark" : "background.paper",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowY: "auto",
};

const AppModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(null);
  const [disableBackDropClose, setDisableBackdropClose] = useState(false);

  useImperativeHandle(ref, (...rest) => ({
    show(component, disableBackDropClose) {
      setOpen(component);
      setDisableBackdropClose(disableBackDropClose);
    },
    hide() {
      setOpen(null);
    },
  }));

  const closeHandler = (e, reason) => {
    if (!disableBackDropClose) {
      setOpen(null);
    } else if (reason !== "backdropClick") {
      setOpen(null);
    }
  };

  return (
    <Modal
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>{open && open}</Box>
    </Modal>
  );
});

export default AppModal;
