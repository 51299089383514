const assetsFa = {
  attentionInfo:
    "* جهت افزایش امنیت کیف پول لطفا موارد زیر را رعایت نمایید",
  attentionDeposit1:
    "آدرس صرفا در شبکه ی [network] موجود می باشد. واریز ارز به شبکه های دیگر ممکن است موجب مفقود شدن ارز شما شود.",
  attentionDeposit2:
    "کمترین مقدار جهت واریز : [min] درصورت واریز مقدار ناکافی حساب شما شارژ نخواهد شد.",
  attentionDeposit3:
    "واریز شما بعد از تایید تعداد [confirm] در شبکه شارژ خواهد شد.",
  attentionDeposit4:
    "واریز شما بعد از تایید  [unlock] اعمال خواهد شد",
  protocolType: "نوع پروتکل",
  depositAttention: "باید تعداد واریز # شود",
  depositConfirm: "از ریسک واریز آگاه هستم و میخواهم ادامه دهم",
  confirm: "تایید",
  copied: "کپی شد",
  attentionWithdraw1:
    "توجه داشته باشید شبکه ی برداشت شما با آدرس مقصد همخوانی داشته باشد در غیراینصورت احتمال عدم انتفال ارز می باشد",
  attentionWithdraw2:
    "بعد از درخواست ارسال و تایید مقدار شما ارسال خواهد شد. زمان ارسال بستگی به تعداد تایید حداقل در شبکه دارد.",
  attentionWithdraw3:
    "حداقل مقدار در برداشت: [min]. مقادیر کمتر از حداقل ارسال نمیشود",
  withdrawInfo: "* آدرس برداشت روی شبکه ی  # می باشد",
  depositInfo: "* آدرس واریز ارز بر روی شبکه ی  # می باشد",
  noDepositConfigBecauseOfUpdate:
    "به دلیل بروزرسانی کیف پول امکان واریز وجود ندارد.",
  noWithdrawConfigBecauseOfUpdate:
    "به دلیل بروزرسانی کیف پول امکان برداشت وجود ندارد.",
  tagInfoDeposit: "برای واریز آدرس و همچنین تگ نیاز است.",
  tagInfoWithdraw: "لطفا تایید کنید اگر برای دریافت تگ نیاز است.",
  successfulWithdraw: "موفقیت آمیز بود.",
  withdrawDetails: "درخواست برداشت شما در حال بررسی می باشد.",
  confirmWithdrawDesc:
    "لطفا کد ۴ رقمی ارسالی به (#) را وارد کنید. کد برای ۱ دقیقه معتبر است.",
  successDeposit: "Your deposit has been successfully registered",

  depositTo: "واریز به",
  tag: "تگ",
  attention: "توجه",
  depositRecords: "تاریخچه واریزها",
  time: "زمان",
  coin: "کوین",
  amount: "مقدار",
  address: "آدرس",
  status: "وضعیت",
  TXID: "TXID",
  noDataFound: "داده ای یافت نشد!",
  deposit: "واریز",
  balance: "موجودی",
  available: "در دسترس",
  inAuction: "در مزایده",
  pending: "در حال بررسی",

  notValidWithdrawAmount: "لطفا مقدار مجاز وارد کنید!",
  insuffientBalance: "موجودی کافی نمی باشد!",
  belowMinWitdrawAmount: "مقدار وارد شده از حداقل مجاز کمتر می باشد!",
  withdrawAmountRequired: "مقدار را حتما مشخص کنید!",
  withdrawAddressRequired: "آدرس را حتما مشخص کنید!",
  notValidWithdrawAddress: "لطفا آدرس صحیح را وارد کنید",
  withdrawTagRequired: "حتما تگ را وارد کنید!!",
  actualArrived: "مقدار دقیق دریافتی",
  withdrawable: "مقدار قابل برداشت",
  withdrawFee: "هزینه برداشت",
  withdraw: "برداشت",
  confirmWithdraw: "تایید برداشت",
  withdrawRecords: "تاریخچه برداشت ها",
};

export default assetsFa;
