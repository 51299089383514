import { useQuery } from "react-query";
import ApiCall from "_clients/apiCall";

export const useGetFeedsSlider = (fakeData = []) => {
  return useQuery("cryptowars-feeds-slider", () =>
    ApiCall(
      "GET",
      "public/blog/search",
      null,
      { category: [15] },
      (res) => res.data?.data?.data || fakeData
    )
  );
};

export const useGetSmallFeeds = (fakeData = []) => {
  return useQuery("cryptowars-small-feeds", () =>
    ApiCall("GET", "public/blog/search", null, { category: [12] }, (res) => {
      const data = res?.data?.data?.data;

      return data ? data?.slice(0, Math.min(4, data?.length)) : fakeData;
    })
  );
};

export const useGetNewPacks = () => {
  return useQuery("cryptowars-new-packs", () =>
    ApiCall(
      "GET",
      "public/bundles",
      null,
      { type: "PREDICTION" },
      (res) => res.data?.data
    )
  );
};

export const useGetTradingCards = (fakeData = []) => {
  return useQuery("cryptowars-trading-cards", () =>
    ApiCall(
      "GET",
      "pd/user/auction-list",
      null,
      { page: 1, limit: 10 },
      (res) => res.data?.data?.data || fakeData
    )
  );
};

export const useGetLandingBanner = (fakeData = []) => {
  return useQuery("cryptowars-banner", () =>
    ApiCall(
      "GET",
      "public/blog/search",
      null,
      { category: [17] },
      (res) => res.data?.data?.data?.[0] || fakeData
    )
  );
};
