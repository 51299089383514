// import io from "socket.io-client";
import { Box, Grid, CircularProgress } from "@mui/material";
import { SkeletonLoader } from "components/Skeleton";
import axios from "library/http";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import io from "socket.io-client";
import BottomSummary from "./BottomSummary";
import Breadcrumb from "./Breadcrumb";
import TabsSection from "./TabsSection/index";
import TopSummary from "./TopSummary";
import SideSummary from "./SideSummary";
import { SOCKET_URL } from "config/Config";

const CryptoDetail = () => {
  const [loading, setLoading] = useState(true);
  const { coinname } = useParams();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const list = async () => {
      try {
        const result = await axios.get("public/cryptos", {
          params: { keyword: coinname },
        });
        if (result.data?.status == "success" && result.data.data.total > 0) {
          // Calculate full diluted market cap
          let data = result.data.data.data[0];
          let fullDilutedMarketCap;
          if (data.maxSupply) {
            fullDilutedMarketCap = Number(data.maxSupply) * Number(data.price);
          } else if (data.totalSupply) {
            fullDilutedMarketCap =
              Number(data.totalSupply) * Number(data.price);
          } else {
            fullDilutedMarketCap = "-";
          }
          data["fdmc"] = fullDilutedMarketCap;
          setDetails(data);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (error) {}
    };

    list();
  }, []);

  useEffect(() => {
    const socket = io(SOCKET_URL);
    socket.on("crypto-update", (coin) => {
      coin = JSON.parse(coin);
      if (coin.id != "price") {
        return;
      }
      if (typeof details.id != "undefined" && details.id == coin.d.cr.id) {
        const newcoin = {
          price: coin.d.cr.p,
          volume24h: coin.d.cr.v,
          percentChange1h: coin.d.cr.p1h,
          percentChange24h: coin.d.cr.p24h,
          percentChange7d: coin.d.cr.p7d,
          marketCap: coin.d.cr.mc,
          // coin.d.cr.mc24hpc,
          // coin.d.cr.vol24hpc,
          // coin.d.cr.fmc24hpc,
        };

        let newDetails = { ...details, ...newcoin };

        setDetails(newDetails);
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [details]);

  return (
    <>
      <Box m={2}>
        {loading ? (
          <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
        ) : (
          <>
            <Breadcrumb name={details.name} />
            <TopSummary details={details} />
            <BottomSummary details={details} />
            <Grid container sx={{ my: 1 }} spacing={2}>
              <Grid item md={9} sm={12}>
                <TabsSection details={details} />
              </Grid>
              <Grid item md={3} sm={12}>
                <SideSummary details={details} />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default CryptoDetail;
