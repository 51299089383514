import { Box, Typography } from "@mui/material";
import CryptosMain from "routes/Cryptos/List";

const Charts = () => {
  return (
    <Box
      sx={{ p: { md: 5, sm: 1 }, textAlign: "center", borderRadius: "25px" }}
    >
      <Typography
        component="p"
        sx={{ fontWeight: "700", fontSize: "30px", fontFamily: "poppins" }}
      >
        Today's Cryptocurrency Prices by Market Cap
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>
        Cryptocurrency Categories
      </Typography>
      <CryptosMain embed={false} />
    </Box>
  );
};

export default Charts;
