const howToPlayTr = {
  // howToPlayLeagueHeader: "Birinci liginize katılın",
  // howToPlayPlayerHeader: "İlk oyuncunuzu takip edin",

  // next: "Sonraki",
  // leagueInfoTitle: "Ligler nedir?",
  // leagueInfoDesc:
  //   "Her biri kendi giriş kriterlerine sahip birden fazla yarışma var. İşte üç örnek:",
  // leagueInfoItem1Title: "Tüm Başlangıç",
  // leagueInfoItem2Title: "23 Yaş Altı",
  // leagueInfoItem3Title: "Avrupa",
  // leagueInfoItem1Type: "Küresel",
  // leagueInfoItem2Type: "Küresel",
  // leagueInfoItem3Type: "Şampiyon",
  // leagueInfoItem1Desc:
  //   "Dünyanın dört bir yanından oyunculardan oluşan bir rüya takımı yaratın.",
  // leagueInfoItem2Desc:
  //   "23 yaşın altındaki oyunculardan oluşan bir takım oluşturun.",
  // leagueInfoItem3Desc: "Avrupa'nın en iyi 5 liginde oynayan oyuncuları seçin.",

  // leagueWinningTitle: "Lig kazanmak",
  // leagueWinningDesc:
  //   "Bir turnuvada yarışan yöneticiler, takımlarının performansına göre bir liderlik tablosunda sıralanır.",
  // leagueWinningHint:
  //   "Puanlar, takımınızdaki oyuncuların gerçek hayattaki performansları aracılığıyla kazanılır. Goller, asistler, temiz sayfalar ve daha fazlası, toplam puanlarınıza katkıda bulunur.",
  // leagueWinningBtn: "Ne kazanırım?",

  // leagueRewardsTitle: "Ödül kazanma",
  // leagueRewardsDesc:
  //   "En iyi performans gösteren Menajerler ödüller kazanır, genellikle güçlü kartlar. Ödül Havuzunda her turnuva için potansiyel ödülleri görüntüleyebilirsiniz.",
  // leagueRewardsBtn: "Sonraki",
  // viewLess: "Daha az görüntüle",
  // viewMore: "Daha fazlasını görüntüle",

  // cardDetailTitle: "Oyuncu Kartı ayrıntıları",
  // cardDetailDesc:
  //   "Bir kart seçtiğinizde, bir oyuncunun ortalama puanı, herhangi bir bonus ve deneyim puanı gibi değerli bilgiler bulacaksınız.",
  // cardDetailHint:
  //   "Turnuvalarda kartlar oynandığında deneyim puanı (XP) kazanıyorlar ve seviye atlıyorlar. Bunlar bonus puanlar veriyor ve sıralamalarda yükselmenizi sağlıyor.",
  // cardDetailItem1Title: "Ortalama puan",
  // cardDetailItem2Title: "Bonus",
  // cardDetailItem1Desc: "Son 5 maça göre ortalama 0'dan 100'e çıkabilir",
  // cardDetailItem2Desc: "Oyuncunun performansını artırır ve ek puan verir.",
  // cardDetailItem3Desc: "23 Yaş Altı Liginde Uygun",
  // cardDetailBtn: "Oynamaya Başla",

  // cardTypeTitle: "Oyuncu Kartı kıtlığı",
  // cardTypeDesc:
  //   "Xbit Play'de Oyuncu Kartları kıt, tedavülde sınırlı sayıda var. 4 farklı kıtlıkta geliyorlar.",
  // cardTypeHintTitle: "Her sezon yeni kartlar oluşturulur",
  // cardTypeBodyTitle:
  //   "Her oyuncu bir kulüp veya lig tarafından lisanslanmıştır.",
  // cardTypeBodyDesc:
  //   "Ve bir Blockchain üzerinde oluşturuldu. Kanıtlanabilir bir kıtlık var. Kartlar kopyalanamaz.",

  // wizardStartPlayTitle: "Hadi başlayalım",
  // wizardStartPlayDesc:
  //   "Yeni bir menajer olarak Casual League'de yarışabilirsin. Bu görevi tamamlamak için takımını kaydet!",

  // done: "Bitti",
  // revealAll: "Tümünü göster",
  // openPackTitle: "Paketinizi açın",
  // openPackDesc: "Sizi takımınızla tanıştıralım",
  // openPackBtn: "Paketi aç",
};

export default howToPlayTr;
