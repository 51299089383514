import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import rareCard from "assets/img/cards/gold.jpg";
import eligibleImg from "assets/img/eligible-image.svg";
import eligibleBg from "assets/img/eligible-bg.svg";
import { getPowerBadgeColor } from "library/helper";
import { t } from "locales";

const CardsType = ({ goNextStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: {
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          {t("cardDetailTitle")}
        </Typography>
        <Typography variant="body1">{t("cardDetailDesc")}</Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90vw", md: "550px" },
          mx: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            pb: "10px",
            borderRadius: "8px",

            "& > *:not(:first-child)": {
              borderTop: "1px solid",
              borderColor: (theme) =>
                theme.palette.mode === "dark" ? "border.dark" : "border.light",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              p: "20px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                left: { xs: "0", md: "calc(100% + 20px)" },
                mb: "10px",
                top: { xs: "0", md: "0" },
                width: { xs: "100%", md: "220px" },
                margin: { xs: "", md: "0" },
                position: { xs: "relative", md: "absolute" },
                zIndex: "1500",
                borderRadius: "8px",
                opacity: ".85",
                border: "1px solid",
                borderColor: "primary.dark",
                bgcolor: "rgb(37 22 55)",
                p: "8px 10px",
                color: "#fff",
              }}
            >
              {t("cardDetailHint")}
            </Typography>
            <Box
              sx={{
                display: { xs: "none", md: "initial" },
                right: "-8px",
                top: "40px",
                width: "17px",
                height: "17px",
                zIndex: "1500",
                position: "absolute",
                flexGrow: "0",
                flexShrink: "0",
                borderRadius: "50%",
                backgroundColor: "primary.dark",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "#D21C10",
                p: "18px 0",
                borderRadius: "8px",
                gap: 2,
                img: {
                  borderRadius: "8px",
                  width: { xs: "200px", sm: "240px" },
                  height: "auto",
                  filter: "brightness(1.1)",
                },
              }}
            >
              <img src={rareCard} alt="" />
            </Box>
          </Box>
          <Box
            sx={{
              p: "15px 20px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#fff",
                  bgcolor: getPowerBadgeColor(98),
                  borderRadius: "4px",
                  p: "2px 8px",
                  fontWeight: "500",
                }}
              >
                92
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  {t("cardDetailItem1Title")}
                  {t("cardDetailItem1Desc")}
                </Typography>
                <Typography variant="body2" sx={{ opacity: ".75" }}>
                  {t("cardDetailItem1Title")}
                  {t("cardDetailItem1Desc")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "inline-flex",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#eee" : "#414244",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "#414244" : "#fff",
                  padding: "3px 5px",
                  borderRadius: "6px",
                }}
              >
                +5%
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  {t("cardDetailItem2Title")}
                </Typography>
                <Typography variant="body2" sx={{ opacity: ".75" }}>
                  {t("cardDetailItem2Desc")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    backgroundImage: `url(${eligibleBg})`,
                    mx: "5px",
                  }}
                >
                  <img src={eligibleImg} alt="" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    {t("cardDetailItem3Desc")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            onClick={goNextStep}
            sx={(theme) => ({
              backgroundImage: theme.palette.gradient.purpule,
              color: theme.palette.common.white,
              width: "fit-content",
              padding: "10px 30px",
              fontSize: "1rem",
              mx: "auto",
              textDecoration: "none",
            })}
          >
            {t("next")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CardsType;
