import { InputBase, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import { t } from "locales";
import { useGetCryptoTeamCards } from "_hooks/Investment/competitions";

const Index = ({ cardsCount, setKeyword, setLoading }) => {
  const onSearch = (e) => {
    setLoading(true);
    setKeyword(e.target.value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        p: "0 10px",
        borderBottom: (theme) =>
          `2px solid ${theme.palette.background[theme.palette.mode]}`,
        borderRight: (theme) =>
          `2px solid ${theme.palette.background[theme.palette.mode]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <SearchIcon fontSize="small" />
        <InputBase
          sx={{ padding: "5px 7px", flexGrow: 1 }}
          placeholder={t("search")}
          onChange={onSearch}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography variant="body2">{t("cards")}</Typography>
        <Typography sx={{ color: "gray" }} component="span" variant="body2">
          {cardsCount}
        </Typography>
      </Box>
    </Box>
  );
};

export default Index;
