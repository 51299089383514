import { Route, Routes } from "react-router";
import { PrivateRoute, AuthRoute } from "./CustomRoutes";
import Login from "routes/auth/Login/index";
// import Register from "routes/auth/Register/index";
import Verify from "routes/auth/Verify/index";
import ForgotPassword from "routes/auth/ForgotPassword";
import ResetPassword from "routes/auth/ResetPassword";
import BuyVlx from "routes/BuyVlx";
import PrivacyPolicy from "routes/PrivacyPolicy";
import XbitCoin from "routes/XbitCoin";
import TermsAndCondition from "routes/TermsAndCondition";
import Dashboard from "routes/User/Dashboard";
import Deposit from "routes/Deposit";
import Withdraw from "routes/Withdraw";
import Invite from "routes/Invite";
import Contact from "routes/Contact";
import Main from "routes/CryptoWars/Main";
import GameList from "routes/Games/GameList";

const authRoutes = [
  // {
  //   path: "/auth/register",
  //   component: Register,
  // },
  {
    path: "/auth/login",
    component: Login,
  },
  {
    path: "/auth/verify",
    component: Verify,
  },
  {
    path: "/auth/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/auth/reset-password",
    component: ResetPassword,
  },
];

const commonPublicRoutes = [
  {
    path: "/",
    component: Main,
    sidebar: false,
    exact: true,
  },

  {
    path: "/games/list",
    component: GameList,
    exact: true,
    sidebar: true,
    banner: true,
  },
  {
    path: "/buy-vlx",
    component: BuyVlx,
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/terms-and-condition",
    component: TermsAndCondition,
    exact: true,
  },
  {
    path: "/xbit-coin",
    component: XbitCoin,
    exact: true,
    sidebar: true,
    banner: true,
  },
];

const commonPrivateRoutes = [
  {
    path: "/dashboard/*",
    component: Dashboard,
    exact: false,
  },
  {
    path: "/deposit",
    component: Deposit,
    exact: true,
  },
  {
    path: "/deposit/:coin",
    component: Deposit,
    exact: true,
  },
  {
    path: "/withdraw",
    component: Withdraw,
    exact: true,
  },
  {
    path: "/withdraw/:coin",
    component: Withdraw,
    exact: true,
  },
  {
    path: "/invite",
    component: Invite,
    exact: true,
  },
  {
    path: "/contact",
    component: Contact,
    exact: true,
  },
];

export const AuthRoutes = () => (
  <Routes>
    {authRoutes.map(({ component: Component, path }, i) => (
      <Route
        key={i}
        path={path}
        element={
          <AuthRoute>
            <Component />
          </AuthRoute>
        }
      />
    ))}
  </Routes>
);

export const CommonPublicRoutes = () => (
  <Routes>
    {commonPublicRoutes.map(({ sidebar, exact, path, component: Component }, i) => {
      return (
        <Route
          key={i}
          exact={exact}
          path={path}
          element={<Component sidebar={sidebar} />}
        />
      );
    })}
  </Routes>
);

export const CommonPrivateRoutes = () => (
  <Routes>
    {commonPrivateRoutes.map(({ component: Component, path }, i) => (
      <Route
        key={i}
        path={path}
        element={
          <PrivateRoute>
            <Component />
          </PrivateRoute>
        }
      />
    ))}
  </Routes>
);
