import { createSlice } from "@reduxjs/toolkit";
import { getImageUrl } from "library/helper";
import {
  changePassword,
  forgotPassword,
  getWizardData,
  login,
  logout,
  refreshUserToken,
  register,
  resetPassword,
  updateUserData,
  updateWallets,
  updateWalletsFromSocket,
  verify,
} from "./asyncActions";

const initialState = {
  user: null,
  accessToken: undefined,
  refreshToken: undefined,
  accessTokenExpire: undefined,
  refreshTokenExpire: undefined,
  resendEmail: null,
  resendMobile: null,
  resendPassword: null,
  resendName: null,
  verifyToken: null,
  verifyType: null,
  forgotPasswordToken: null,
  loginError: null,
  registerError: null,
  verifyError: null,
  changePasswordError: null,
  resetPasswordError: null,
  logoutError: null,
  status: "idle",

  wallets: null,
  notifications: [],
  wizard: [], // [{ type: 'CARD' || 'CRYPTO', step: 1-3 }]
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, { payload }) => {
      state.resendEmail = payload?.email;
      state.resendMobile = payload?.mobile;
      state.resendPassword = payload?.password;
      state.resendName = payload?.name;
    },
    setAuthDataByToken: (state, { payload }) => {
      // state.user = payload?.user;
      state.accessToken = payload?.accessToken;
      state.refreshToken = payload?.refreshToken;
      state.accessTokenExpire = payload?.accessTokenExpire;
      state.refreshTokenExpire = payload?.refreshTokenExpire;
    },
    replaceNewRefreshToken: (state, { payload }) => {
      const { data } = payload;

      state.status = "idle";
      state.accessToken = data?.accessToken?.token;
      state.refreshToken = data?.refreshToken?.token;
      state.accessTokenExpire = data?.accessToken?.expiresAt;
      state.refreshTokenExpire = data?.refreshToken?.expiresAt;
    },
    setNotifications: (state, { payload }) => {
      const newNotif = Array.isArray(payload) ? payload : [payload];

      state.notifications = [...state.notifications, ...newNotif];
    },
    readNotifications: (state, { payload }) => {
      state.notifications = state.notifications.filter((n) => n.id !== payload);
    },
    readAllNotifications: (state) => {
      state.notifications = [];
    },
    goBackFromVerify: (state) => {
      state.status = "idle";
    },
  },
  // HANDLE STATE UPDATE FOR ASYNC ACTIONS
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.status = "loading";
        state.registerError = null;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.status = "fulfilled-register";
        state.verifyToken = payload?.token;
        state.verifyType = payload?.verifyType;
        state.registerError = null;
      })
      .addCase(register.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.registerError = payload;
      })

      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.loginError = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.status = "fulfilled-login";
        state.accessToken = payload?.accessToken?.token;
        state.refreshToken = payload?.refreshToken?.token;
        state.accessTokenExpire = payload?.accessToken?.expiresAt;
        state.refreshTokenExpire = payload?.refreshToken?.expiresAt;
        state.loginError = null;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.loginError = payload;
      })

      .addCase(logout.pending, (state) => {
        state.status = "loading";
        // state.logoutError = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = "fulfilled-logout";
        state.status = "idle";
        state.user = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.accessTokenExpire = null;
        state.refreshTokenExpire = null;
        state.logoutError = null;
        state.wallets = null;
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.logoutError = payload;
      })

      .addCase(verify.pending, (state) => {
        state.status = "loading";
        state.verifyError = null;
      })
      .addCase(verify.fulfilled, (state, { payload }) => {
        if (state.verifyType === "FORGOT_PASSWORD") {
          state.forgotPasswordToken = payload?.token;
        } else {
          state.accessToken = payload?.accessToken?.token;
          state.refreshToken = payload?.refreshToken?.token;
          state.accessTokenExpire = payload?.accessToken?.expiresAt;
          state.refreshTokenExpire = payload?.refreshToken?.expiresAt;
        }
        // COMMON CHANGES
        state.status = "fulfilled";
        state.verifyToken = null;
        state.verifyType = null;
        state.resendEmail = null;
        state.resendPassword = null;
        state.verifyError = null;
      })
      .addCase(verify.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.verifyError = payload;
      })

      .addCase(updateUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserData.fulfilled, (state, { payload }) => {
        state.status = "fulfilled";
        state.user = {
          ...payload,
          avatar: getImageUrl(payload?.avatar) || null,
        };
      })
      .addCase(updateUserData.rejected, (state, { payload }) => {
        state.status = "rejected";
      })

      .addCase(refreshUserToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(refreshUserToken.fulfilled, (state, { payload }) => {
        const { data } = payload;

        state.status = "fulfilled";
        state.accessToken = data?.accessToken?.token;
        state.refreshToken = data?.refreshToken?.token;
        state.accessTokenExpire = data?.accessToken?.expiresAt;
        state.refreshTokenExpire = data?.refreshToken?.expiresAt;
      })
      .addCase(refreshUserToken.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.user = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.accessTokenExpire = null;
        state.refreshTokenExpire = null;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.status = "fulfilled";
        state.verifyToken = payload?.token;
        state.verifyType = payload?.verifyType;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.status = "rejected";
      })

      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.status = "fulfilled";
        state.resetPasswordError = null;
        state.forgotPasswordToken = null;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.status = "rejected";
      })

      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
        state.changePasswordError = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = "fulfilled-changePassword";
        state.changePasswordError = null;
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.changePasswordError = payload;
      })

      .addCase(updateWallets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateWallets.fulfilled, (state, { payload }) => {
        state.wallets = payload;
        state.status = "fulfilled-updateWallets";
      })
      .addCase(updateWallets.rejected, (state, { payload }) => {
        state.status = "rejected";
      })

      .addCase(updateWalletsFromSocket.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateWalletsFromSocket.fulfilled, (state, { payload }) => {
        state.wallets = payload;
        state.status = "fulfilled-updateWalletsFromSocket";
      })
      .addCase(updateWalletsFromSocket.rejected, (state, { payload }) => {
        state.status = "rejected";
      })

      .addCase(getWizardData.pending, (state) => {})
      .addCase(getWizardData.fulfilled, (state, { payload }) => {
        state.wizard = payload;
      })
      .addCase(getWizardData.rejected, (state, { payload }) => {
        state.wizard = [];
      });
  },
});

export const {
  setAuthData,
  setAuthDataByToken,
  replaceNewRefreshToken,
  setNotifications,
  readAllNotifications,
  readNotifications,
  goBackFromVerify,
} = authSlice.actions;
export default authSlice.reducer;
