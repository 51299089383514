import { Box, Typography, Avatar, CircularProgress, Link } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useEffect, useState } from "react";
import { getArticles } from "../requests.js";
import { useNavigate } from "react-router-dom";
import MoreMenu from "routes/Community/components/MoreMenu";
import ArticleLikeButton from "routes/Community/components/ArticleLikeButton";

const Article = ({ data }) => {
  let navigate = useNavigate();

  const SquareDivider = () => {
    return <SquareIcon sx={{ fontSize: "8px", color: "#D9D9D9" }} />;
  };

  const openArticle = () => {
    navigate(data.id);
  };

  const media = data.image.length > 0 ? data.image[0].location : null;

  return (
    <Box
      sx={{
        width: { sx: "100%", sm: "47%", md: "30%" },
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 1,
        gap: "12px",
        margin: 1,
        "& .thumbnail": {
          width: "100%",
          height: "200px",
          objectFit: "cover",
        },
        "& .bottomPart": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          alignItems: "center",
          maxHeight: "35px",
          width: "100%",
          justifyContent: "space-around",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "relative",
            "&:hover .moreMenu": {
              display: "flex",
            },
          }}
        >
          <Box onClick={() => openArticle()} sx={{ cursor: "pointer" }}>
            <img src={media} alt={data.title} className="thumbnail" />
          </Box>
          <Box
            sx={{
              transition: "all 0.5s ease",
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "#fff",
              display: "none",
            }}
            className="moreMenu"
          >
            <MoreMenu
              id={data.id}
              resource={"article"}
              profileId={data.profile.userId}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => openArticle()}
        >
          <Typography sx={{ fontWeight: "bold" }}>{data.title}</Typography>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
            fontSize: "14px",
          }}
        >
          {data.content.substr(0, 75)}...
        </Typography>
      </Box>
      <Box className="bottomPart">
        <Link
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          href={"/community/users/" + data.profile.id}
          underline="none"
        >
          <Avatar
            alt={data.profile.name}
            src={
              data.profile.avatar.length > 0
                ? data.profile.avatar[0]
                : "profile.jpg"
            }
            sx={{ width: "30px", height: "30px", mr: "5px" }}
          />
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {data.profile.name}
          </Typography>
        </Link>

        <SquareDivider />

        <Box
          component="span"
          sx={{ display: "flex", alignItems: "center", fontSize: "10px" }}
        >
          <RemoveRedEyeOutlinedIcon sx={{ mr: "5px", fontSize: "18px" }} />
          {data.viewCount}
        </Box>

        <SquareDivider />

        <ArticleLikeButton article={data} />

        {/* <SquareDivider /> */}

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            "& img": { height: "20px", width: "20px" },
            justifyContent: "center",
            alignItems: "center",
            padding: 0.5,
            background: "#F1F1F1",
            borderRadius: "1px",
            justifyContent: "space-evenly",
            gap: "2px",
          }}
        >
          <img
            src="https://xbit-bucket.s3.amazonaws.com/game-public/images/4b9169eb-3e07-30e8-85eb-62f7bfc41a33.png"
            alt="tag"
          />
          <Typography sx={{ fontWeight: "400", fontSize: "10px" }}>
            BTC
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

const LatestArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    let result = await getArticles();
    if (result.data?.status === "success") {
      setArticles(result.data.data.data);
      setLoading(false);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      {loading ? (
        <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
      ) : (
        articles.map((_, index) => <Article key={index} data={_} />)
      )}
    </Box>
  );
};

export default LatestArticles;
