const howToPlayFa = {
  // howToPlayLeagueHeader: "به اولین لیگ خود بپیوند!",
  // howToPlayPlayerHeader: "اولین بازیکن خود را دنبال کن!",

  // next: "بعدی",
  // leagueInfoTitle: "لیگ  چیست ؟",
  // leagueInfoDesc:
  //   "چندین رقابت وجود دارد که شرایط ورود متفاوتی از هم دارند. در اینجا به ۳ تا نمونه می پردازیم:",
  // leagueInfoItem1Title: "همه",
  // leagueInfoItem2Title: "زیر ۲۳ ساله ها",
  // leagueInfoItem3Title: "اروپا",
  // leagueInfoItem1Type: "جهانی",
  // leagueInfoItem2Type: "جهانی",
  // leagueInfoItem3Type: "قهرمانی",
  // leagueInfoItem1Desc: "تیم رویایی خود را از سراسر جهان بساز.",
  // leagueInfoItem2Desc: "تیمی از بازیکن های زیر ۲۳ سال بساز.",
  // leagueInfoItem3Desc:
  //   "بازیکنانی را انخاب کن که در ۵ لیگ برتر اروپایی بازی می کنند.",

  // leagueWinningTitle: "برد یک لیگ",
  // leagueWinningDesc:
  //   "مدیر هایی که در یک مسابقه شرکت می کنند امتیاز می گیرند و امتیازشان وارد جدول امتیازات می شود.",
  // leagueWinningHint:
  //   "امتیازدهی براساس عملکرد بازیکنان تیم شما در دنیای واقعی می باشد.امتیازات از نمرات گلزنی ، پاس گل و دروازه بسته و . . . تشکیل شده است. ",
  // leagueWinningBtn: "چطور برنده می شوم؟",

  // leagueRewardsTitle: "دریافت جایزه ها",
  // leagueRewardsDesc:
  //   "مدیران با بهترین عملکرد تیم برنده می شوند.شما می توانی جایزه هر مسابقه را در استخر جایزه ها ببینید",
  // leagueRewardsBtn: "بازی کن !",
  // viewLess: "کمتر نشان بده",
  // viewMore: "بیشتر نشان بده",

  // cardDetailTitle: "جزییات کارت بازیکن",
  // cardDetailDesc:
  //   "وقتی کارتی را انتخاب می کنید اطلاعات مهمی نظیر میانگین امتیاز بازیکن ، هرگونه بونوس و امتیاز تجربه را می توانید مشاهده کنید. ",
  // cardDetailHint:
  //   "وقتی کارت ها در مسابقه ای شرکت می کنند امتیاز تجربه (XP) دریافت می کنند و پیشرفت در سطحش ایجاد می شودو باعث گرفتن بونوس و بالا رفتن در رتبه بندی مسابقات می شود.",
  // cardDetailItem1Title: "میانگین امتیاز",
  // cardDetailItem2Title: "بونوس",
  // cardDetailItem1Desc:
  //   "براساس ۵ بازی آخر بازیکن میانگین امتیازی بین ۰ تا ۱۰۰ می گیرد.",
  // cardDetailItem2Desc: ".عملکرد بازیکن را تقویت می کند و امتیاز بیشتر می دهد",
  // cardDetailItem3Desc: "مجاز برای بازی در لیگ زیر ۲۳ ساله ها",
  // cardDetailBtn: "شروع بازی",

  // cardTypeTitle: "محدودیت تعداد کارت بازیکنه ها",
  // cardTypeDesc:
  //   "در بازی ایکس بیت تعداد کارت هر بازیکن محدود می باشد.این محدودیت ها ۴ مدل دارد.",
  // cardTypeHintTitle: "کار تهای جدید در آغاز هر فصل ساخته می شود.",
  // cardTypeBodyTitle: "هر بازیکن فقط مربوط به یک تیم یا لیگ می باشد.",
  // cardTypeBodyDesc:
  //   "تولید کار تها براساس قرداد های هوشمند بر روی بلاک چین انجام مشود و امکان ساخت خارج از این سیستم وجود نداردو کسی نمی تواند در ساختار آن دخالت کند.",

  // wizardStartPlayTitle: "شروع کن!",
  // wizardStartPlayDesc:
  //   "به عنوان مدیر جدید شما می توانید در لیگ عادی به رقابت بپردازید و تیمتان را ثبت کنید تا این وظایف را کامل کنند.",

  // done: "انجام شد",
  // revealAll: "همه را نشان بده",
  // openPackTitle: "مجموعه تان را باز کنید",
  // openPackDesc: "شما را به تیمتان معرفی می کنیم",
  // openPackBtn: "مجموعه را باز کن",
};

export default howToPlayFa;
