import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import Info from "./League/Info";
import GameInfo from "./League/GameInfo";
import Winning from "./League/Winning";
import Rewards from "./League/Rewards";
import CardsType from "./Player/CardsType";
import CardDetail from "./Player/CardDetail";
import { t } from "locales";

const maxStep = 6;

const CryptoWarsHowToPlay = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const goNextStep = () => {
    setStep((prev) => prev + 1);
    window.scrollTo(0, 0);
  };
  const goPrevStep = () => {
    setStep((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  let body;
  if (step === 1) {
    body = <GameInfo goNextStep={goNextStep} />;
  } else if (step === 2) {
    body = <Info goNextStep={goNextStep} />;
  } else if (step === 3) {
    body = <Winning goNextStep={goNextStep} />;
  } else if (step === 4) {
    body = <Rewards goNextStep={goNextStep} />;
  } else if (step === 5) {
    body = <CardsType goNextStep={goNextStep} />;
  } else if (step === 6) {
    body = <CardDetail goNextStep={goNextStep} />;
  } else if (step === 7) {
    navigate("/crypto-wars/play");
  }

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 64px)",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212429" : "#eee",
        pb: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "15px 20px",
          bgcolor: (theme) => (theme.palette.mode === "dark" ? "#000" : "#fff"),
        }}
      >
        {step !== 1 && (
          <Box
            onClick={goPrevStep}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              color: "#6E737C",
              cursor: "pointer",
            }}
          >
            <ArrowBackIos
              fontSize="small"
              sx={{
                transform: (theme) =>
                  theme.direction === "rtl" && "rotate(180deg)",
              }}
            />
            <Typography variant="body1">{t("back")}</Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {step <= 3
              ? t("howToPlayLeagueHeader")
              : t("howToPlayPlayerHeader")}{" "}
            – {step}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#6E737C",
            }}
          >
            /{maxStep}
          </Typography>
        </Box>
        <Box></Box>
      </Box>

      <Box
        className="container"
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {body}
      </Box>
    </Box>
  );
};

export default CryptoWarsHowToPlay;
