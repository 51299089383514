import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState, forwardRef, useEffect } from "react";
import Login from "routes/auth/Login/index";
import axios from "library/http";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FollowButton = ({ id, followed, setRefresh, style }) => {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [followStatus, setFollowStatus] = useState(followed);

  useEffect(() => {
    setFollowStatus(followed);
  }, [followed]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = async () => {
    if (!user) return setOpen(true);
    try {
      let url = "gravity/profiles/" + id + "/follow";

      setFollowStatus(!followStatus);
      followStatus ? await axios.delete(url) : await axios.put(url);
    } catch (error) {
      console.error(error);
    }
    setRefresh && setRefresh(new Date());
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained" style={style}>
        <Typography component="span" sx={{ mr: "5px" }}>
          {followStatus ? "-" : "+"}
        </Typography>
        <Typography>{followStatus ? "Unfollow" : "Follow"}</Typography>
      </Button>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-for-login-page"
        >
          <DialogTitle>
            {"You have to login first in order to continue"}
          </DialogTitle>
          <DialogContent>{!user && <Login />}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Not now</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FollowButton;
