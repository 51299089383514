import { Box } from "@mui/system";
import CryptoWarsPlaySidebar from "./Sidebar";
import CryptoWarsPlayTopbar from "./Topbar";
import CryptoWarsPlayBanner from "./Banner";
import {
  EmojiEvents,
  CardGiftcardOutlined,
  LeaderboardOutlined,
  DataThresholdingOutlined,
} from "@mui/icons-material";
import { useParams } from "react-router";
import { cloneElement, isValidElement, useEffect, useState } from "react";
import { useGetCompettion } from "_hooks/Investment/competitions";

const getMenu = (competitionId, bannerData) => {
  if (!bannerData || bannerData?.status === "OPEN") {
    return [
      {
        title: "competitions",
        link: `/investment/play/${competitionId}/competitions?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/competitions`,
        icon: EmojiEvents,
      },
      {
        title: "prize",
        link: `/investment/play/${competitionId}/prize?type=${bannerData?.types[0]?.id}`,
        icon: CardGiftcardOutlined,
      },
    ];
  }
  if (bannerData?.status === "LIVE") {
    return [
      {
        title: "competitions",
        link: `/investment/play/${competitionId}/competitions?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/competitions`,
        icon: EmojiEvents,
      },
      {
        title: "overview",
        link: `/investment/play/${competitionId}/overview`,
        icon: DataThresholdingOutlined,
      },
      {
        title: "leaderboards",
        link: `/investment/play/${competitionId}/leaderboards?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/leaderboards`,
        icon: LeaderboardOutlined,
      },
      {
        title: "prize",
        link: `/investment/play/${competitionId}/prize?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/prize`,
        icon: CardGiftcardOutlined,
      },
    ];
  }
  if (bannerData?.status === "COMPLETED") {
    return [
      {
        title: "competitions",
        link: `/investment/play/${competitionId}/competitions?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/competitions`,
        icon: EmojiEvents,
      },
      {
        title: "overview",
        link: `/investment/play/${competitionId}/overview`,
        icon: DataThresholdingOutlined,
      },
      {
        title: "leaderboards",
        link: `/investment/play/${competitionId}/leaderboards?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/leaderboards`,
        icon: LeaderboardOutlined,
      },
      {
        title: "prize",
        link: `/investment/play/${competitionId}/prize?type=${bannerData?.types[0]?.id}`,
        activePath: `/investment/play/${competitionId}/prize`,
        icon: CardGiftcardOutlined,
      },
    ];
  }

  return [];
};

const InvestmentPlayLanding = ({
  children,
  sidebar = false,
  banner = false,
}) => {
  const { competitionId } = useParams();
  const { isLoading: loading, data: initData } =
    useGetCompettion(competitionId);
  const [menu, setMenu] = useState(() => getMenu(competitionId, initData));

  useEffect(() => {
    setMenu(getMenu(competitionId, initData));
  }, [initData, competitionId]);

  return (
    <Box className="container">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 0, sm: 3 },
          pb: 4,
        }}
      >
        {sidebar && <CryptoWarsPlaySidebar menu={menu} />}
        {sidebar && <CryptoWarsPlayTopbar menu={menu} />}
        <Box
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1, gap: 1 }}
        >
          {banner && (
            <Box>
              <CryptoWarsPlayBanner loading={loading} data={initData} />
            </Box>
          )}
          <Box>
            {isValidElement(children) &&
              cloneElement(children, {
                initData: initData,
                initLoading: loading,
                competitionId,
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvestmentPlayLanding;
