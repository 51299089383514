import { Box } from "@mui/system";
import Header from "./Header";
import List from "./List";

const Index = ({ cards, loading }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
      <Header />
      <List cards={cards} loading={loading} />
    </Box>
  );
};

export default Index;
