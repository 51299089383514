import { Box } from "@mui/system";
import { t } from "locales";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useLocation } from "react-router";

const SecondarySidebar = ({ menu, asMenu = false, findActiveCallback }) => {
  const { pathname } = useLocation();

  return (
    <Box
      sx={[
        {
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          gap: 1,
        },
        asMenu && { width: "80vw", display: "flex !important" },
      ]}
    >
      {menu.map(({ title, link, icon: Icon, activePath }) => {
        const isActive = findActiveCallback
          ? findActiveCallback(activePath || link)
          : pathname?.includes(activePath || link);

        return (
          <Box
            key={title}
            as={Link}
            to={link}
            className="item"
            sx={[
              {
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: "10px",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#eee" : "inherit",
                textDecoration: "none",
              },
            ]}
          >
            <Box
              sx={[
                {
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                  borderRadius: "12px",
                  justifyContent: "center",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "inherit" : "primary.main",
                },
                isActive && {
                  bgcolor: ({ palette }) =>
                    palette.mode === "dark"
                      ? palette.primary.main
                      : palette.primary.dark,
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "inherit" : "#fff",
                },
              ]}
            >
              <Icon fontSize="small" />
            </Box>
            <Typography
              variant="body1"
              sx={[
                isActive && {
                  color: ({ palette }) =>
                    palette.mode === "dark"
                      ? palette.primary.main
                      : palette.primary.dark,
                },
              ]}
            >
              {t(title)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default SecondarySidebar;
