import { SocketContext } from "context/socket/socket";
import { useContext, useEffect, useState } from "react";

export const useAuction = (
  refetchCallback,
  initialValue = [],
  isActive = true
) => {
  const [list, setList] = useState(initialValue);
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!isActive) return;

    socket.setListener("auction-offer", (data) => {
      if (!!data) {
        const newOffer = JSON.parse(data);

        if (newOffer.status === "WON") {
          refetchCallback?.();
          return;
        }

        setList((prevList) => {
          if (!prevList) return;

          const curIndex = prevList?.findIndex(
            (c) => c?.id === newOffer?.auctionId
          );

          if (!isNaN(curIndex) && curIndex !== -1) {
            const newItem = { ...prevList?.[curIndex] };
            if (newItem.max < newOffer.amount) newItem.max = newOffer.amount;
            newItem.count = +newItem.count + 1;

            const newLIst = [...prevList];
            newLIst[curIndex] = newItem;

            return newLIst;
          } else {
            return prevList;
          }
        });
      }
    });

    socket.setListener("auction-offers-delete", (data) => {
      if (!!data) {
        // because we don't have prev offer amount we should refetch the list
        refetchCallback?.();
      }
    });

    socket.setListener("auction-create", (data) => {
      if (!!data) {
        refetchCallback?.();
      }
    });

    socket.setListener("auction-update", (data) => {
      if (!!data) {
        // refetch because we don't have max price - new price = max || basePrice
        refetchCallback?.();
      }
    });

    socket.setListener("auction-delete", (data) => {
      if (!!data) {
        const deletedItem = JSON.parse(data);

        setList((prevList) => prevList.filter((i) => i.id !== deletedItem?.id));
      }
    });

    return () => {
      socket.removeListener?.("auction-create");
      socket.removeListener?.("auction-update");
      socket.removeListener?.("auction-delete");
      socket.removeListener?.("auction-offer");
      socket.removeListener?.("auction-offers-delete");
    };
  }, [isActive]);

  return [list, setList];
};
