const inviteTr = {
  inviteFirstHeader: "Bir arkadaşınızı Volexplay'e davet edin",
  inviteSecondHeader: "İkiniz de ücretsiz bir kart alacaksınız",
  inviteDesc:
    "Futbol tutkunu olan birini tanıyor musunuz? Arkadaşınız müzayedede 5 yeni kart toplayınca ikiniz de ücretsiz kazanacaksınız",
  inviteModalDesc: "Arkadaşlarınızı davet edin ve ücretsiz bir kart kazanın.",
  inviteModalCodeTitle: "Davetiye kodu",
  user: "Kullanıcı",
  cardNumbers: "Kart numaraları",
  COMPELETED: "Tamamlandı",
  COMPLETED: "Tamamlandı",
  EXPIRED: "Süresi doldu",
  IN_PROGRESS: "Devam ediyor",
  noInvitedFriend: "Hiçbir şey bulunamadı",
  inviteSidebarHeader: "Nasıl çalışır?",
  levelOnePrizeTitle: "Arkadaşın 5 kart alırsa kazanırsın",
  milestoneInviteTitle: "Kilometre Taşları",
  milestoneInviteCondition: "30. ARKADAŞ",
  completedReferrals: "Tamamlanan referanslar",
  friendsInvited: "Arkadaşlar davet edildi",
  inviteHistoryTitle: "Davetiyeler gönderildi",
  inviteFriendsNavLink: "Arkadaşlarını davet et",
};

export default inviteTr;
