import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { truncateToDecimals } from "library/helper";
import defaultAvatar from "assets/img/default-profile.png";
import {
  Dashboard,
  DownloadRounded,
  LogoutOutlined,
  PersonAddAlt1Outlined,
  PersonOutline,
  SettingsOutlined,
  UploadRounded,
} from "@mui/icons-material";
import { t } from "locales";
import { logout } from "redux/auth/asyncActions";
// import { MAIN_COIN } from "library/constants";

const NavbarUser = () => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  // const [mainCoinImage, setMainCoinImage] = useState();
  const [xbtCoin, setXbtCoin] = useState();
  const { wallets, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };
  const fetchImage = async () => {
    // const maincoin = MAIN_COIN ? MAIN_COIN : "USDT";
    // const mainCoinImage = await import(
    //   `assets/icons/crypto/${maincoin.toLowerCase()}.png`
    // );
    const xbtCoin = await import(`assets/icons/crypto/xbt.png`);
    // setMainCoinImage(mainCoinImage);
    setXbtCoin(xbtCoin);
  };
  useEffect(() => {
    fetchImage();
  }, []);
  return (
    <Box
      onMouseEnter={() => setShowUserMenu(true)}
      onMouseLeave={() => setShowUserMenu(false)}
      sx={{
        position: "relative",
        display: { xs: "none", md: "flex" },
        alignItems: "center",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#24262c" : "#eee",
        color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#222"),
        padding: "3px 10px",
        borderRadius: "12px",
        margin: "0 7px",
        gap: 1,
        cursor: "pointer",
        img: {
          width: "25px",
          height: "25px",
          borderRadius: "50%",
        },
        ".avatar": {
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          transform: "scale(1.5)",
          marginLeft: (theme) => theme.direction === "ltr" && "7px",
          marginRight: (theme) => theme.direction === "rtl" && "7px",
          border: "1px solid #d93ca7",
          objectFit: "cover",
        },
      }}
    >
      {/* <img src={wallets?.[MAIN_COIN]?.icon} alt={wallets?.[MAIN_COIN]?.coin} /> */}
      <img src={xbtCoin?.default} alt={wallets?.["XBT"]?.coin} />
      <span>
        {wallets?.["XBT"]?.amount
          ? truncateToDecimals(wallets?.["XBT"]?.amount, 2)
          : 0}
      </span>
      <img className="avatar" src={user?.avatar ?? defaultAvatar} alt="" />
      {showUserMenu && (
        <Box
          onClick={() => setShowUserMenu(false)}
          sx={[
            {
              position: "absolute",
              top: "100%",
              borderRadius: "8px",
              minWidth: "110%",
              width: "fit-content",
              filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.06))",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "card.dark" : "card.light",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            },
            (theme) => (theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
          ]}
        >
          <ButtonBase
            as={Link}
            to="/dashboard"
            sx={{
              p: "10px 15px",
              justifyContent: "flex-start",
              gap: 1,

              borderBottom: (theme) =>
                theme.palette.mode === "dark"
                  ? "1px solid #2a2e34"
                  : "1px solid #eee",
              img: {
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                objectFit: "cover",
              },
            }}
          >
            <img src={user?.avatar ?? defaultAvatar} alt="" />
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {user?.name}
            </Typography>
          </ButtonBase>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              "& > *": {
                display: "flex",
                alignItems: "center",
                gap: 1,
                p: "10px 15px !important",
                justifyContent: "flex-start !important",
                width: "100%",
                "&:hover": {
                  opacity: 0.7,
                },
              },
            }}
          >
            <ButtonBase
              sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "default",
                img: {
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                },
                "& > div": {
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  gap: 2,
                  width: "100%",
                },
                "&:hover": {
                  opacity: "1",
                },
              }}
            >
              <Box>
                {/* <img src={wallets?.VLX?.icon} alt={wallets?.VLX?.coin} /> */}
                <img src={xbtCoin?.default} alt={wallets?.VLX?.coin} />
                <Typography variant="body2" sx={{ width: "max-content" }}>
                  {wallets?.VLX?.amount} XBT
                </Typography>
              </Box>
            </ButtonBase>
            <ButtonBase as={Link} to="/dashboard/overview">
              <Dashboard fontSize="small" />
              <Typography variant="body2">{t("dashboard")}</Typography>
            </ButtonBase>
            <ButtonBase as={Link} to="/dashboard/setting?tab=profile">
              <PersonOutline fontSize="small" />
              <Typography variant="body2">{t("profile")}</Typography>
            </ButtonBase>
            <ButtonBase as={Link} to="/deposit">
              <DownloadRounded fontSize="small" />
              <Typography variant="body2">{t("deposit")}</Typography>
            </ButtonBase>
            <ButtonBase as={Link} to="/withdraw">
              <UploadRounded fontSize="small" />
              <Typography variant="body2">{t("withdraw")}</Typography>
            </ButtonBase>
            <ButtonBase as={Link} to="/dashboard/setting?tab=account">
              <SettingsOutlined fontSize="small" />
              <Typography variant="body2">{t("setting")}</Typography>
            </ButtonBase>
            <ButtonBase as={Link} to="/invite">
              <PersonAddAlt1Outlined fontSize="small" />
              <Typography variant="body2">
                {t("inviteFriendsNavLink")}
              </Typography>
            </ButtonBase>
            <ButtonBase onClick={logoutHandler}>
              <LogoutOutlined fontSize="small" />
              <Typography variant="body2">{t("logout")}</Typography>
            </ButtonBase>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NavbarUser;
