import SecondarySidebar from "components/SecondarySidebar";

const UserSidebar = ({ menu, asMenu = false, findActiveCallback }) => {
  return (
    <SecondarySidebar
      menu={menu}
      asMenu={asMenu}
      findActiveCallback={findActiveCallback}
    />
  );
};

export default UserSidebar;
