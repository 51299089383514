import { Alert } from "@mui/material";
import { t } from "locales";

const ErrorAlert = ({
  variant = "outlined",
  severity = "error",
  text = "somethingWentWrong",
}) => {
  return (
    <Alert variant={variant} severity={severity}>
      {t(text)}
    </Alert>
  );
};

export default ErrorAlert;
