import { Divider, Box } from "@mui/material";
import User from "routes/Community/components/User";

const Users = ({ users }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {users.map((_, index) => (
        <Box key={index}>
          <User user={_} />
          {users.length - 1 !== index && <Divider sx={{ m: 1 }} />}
        </Box>
      ))}
    </Box>
  );
};

export default Users;
