import { Box, Typography, Grid, CircularProgress, Avatar } from "@mui/material";
import axios from "library/http";
import { useEffect, useState } from "react";
import { formatPrice, percentChange } from "library/helper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const SeeMore = ({ url }) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(url);
      }}
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        mt: "auto",
      }}
    >
      <Typography sx={{ fontWeight: "600", mr: 1, color: "#F31136 !important" }}>
        See More
      </Typography>
      <ArrowForwardIcon />
    </Box>
  );
};

const Trending = () => {
  const [trending, setTrending] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const result = await axios.get("public/cryptos", {
        params: { trending: true },
      });
      if (result.data?.status == "success") {
        setTrending(result.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Box>
      <Typography className="newsHeading">Trending</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "8px",
            }}
          >
            {trending.slice(0, 3).map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                  alignItems: "center",
                  "& img": { width: "35px", height: "35px" },
                }}
              >
                <img src={item.icon[0].location} alt={item.name} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.symbol}
                    </Typography>
                    <Typography>{item.name}</Typography>
                  </Box>
                  <Box>{formatPrice(item.price)}</Box>
                  <Box>
                    {percentChange(item.percentChange24h, "mainColor", {
                      fontSize: "12px",
                    })}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          <SeeMore url="/cryptos" />
        </>
      )}
    </Box>
  );
};

const Recently = () => {
  const [recents, setRecents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const result = await axios.get("public/cryptos", {
        params: { sort: "dateAdded", order: "DESC" },
      });
      if (result.data?.status == "success") {
        setRecents(result.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Box>
      <Typography className="newsHeading">Recently Added</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "8px",
            }}
          >
            {recents.slice(0, 3).map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                  alignItems: "center",
                  "& img": { width: "35px", height: "35px" },
                }}
              >
                <img src={item.icon[0].location} alt={item.name} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.symbol}
                    </Typography>
                    <Typography>{item.name}</Typography>
                  </Box>
                  <Box>{formatPrice(item.price)}</Box>
                </Box>
              </Box>
            ))}
          </Box>
          <SeeMore url="/cryptos" />
        </>
      )}
    </Box>
  );
};

const TopPost = () => {
  const [recommended, setRecommended] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const result = await axios.get("gravity/posts", {
        params: { recommended: true },
      });
      if (result.data?.status == "success") {
        setRecommended(result.data.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Box>
      <Typography className="newsHeading">Top Community Post</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "8px",
            }}
          >
            {recommended.slice(0, 1).map((item, index) => (
              <Box
                key={index}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Avatar
                    alt={item.profile.name}
                    src={
                      item?.profile.avatar.length > 0
                        ? item?.profile.avatar[0].location
                        : "./broken.jpg"
                    }
                    sx={{ m: 0.5, bgcolor: "secondary.main", float: "left" }}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>
                    {item.profile.name}
                  </Typography>
                  <Typography>{item.profile.slug}</Typography>
                </Box>
                <Typography sx={{ mt: 1 }}>{item.content}</Typography>
              </Box>
            ))}
          </Box>
          <SeeMore url="/community" />
        </>
      )}
    </Box>
  );
};

const News = () => {
  return (
    <Box sx={{ p: { md: "125px", sm: "20px", sx: "5px" } }}>
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiGrid-item > div": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "12px 24px",
            gap: "32px",
            height: "300px",
            borderRadius: "25px",
            transition: "all 0.3s ease-in-out",
            border: "1px solid #e0e0e0",
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#201D26" : "rgb(0 0 0 / 8%)",
          },
          "& .MuiGrid-item > div:hover": {
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "15px",
          },
          "& .newsHeading": {
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "130%",
            fontFamily: "poppins",
            color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
          },
          "& p": {
            color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
          },
        }}
      >
        <Grid item xs={12} md={4}>
          <Trending />
        </Grid>

        <Grid item xs={12} md={4}>
          <Recently />
        </Grid>

        <Grid item xs={12} md={4}>
          <TopPost />
        </Grid>
      </Grid>
    </Box>
  );
};

export default News;
