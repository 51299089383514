import { SkeletonLoader } from "components/Skeleton";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import SoccerSingle from "routes/CryptoWars/Market/Auction/SingleCard";

const responsive = {
  xl: {
    breakpoint: { min: 2000, max: 1000000 },
    items: 5,
  },
  lg: {
    breakpoint: { min: 1200, max: 2000 },
    items: 5,
  },
  md: {
    breakpoint: { min: 900, max: 1200 },
    items: 4,
  },
  sm: {
    breakpoint: { min: 600, max: 900 },
    items: 3,
  },
  xs: {
    breakpoint: { min: 370, max: 600 },
    items: 2,
  },
  xxs: {
    breakpoint: { min: 270, max: 370 },
    items: 1,
  },
  xxxs: {
    breakpoint: { min: 0, max: 270 },
    items: 1,
  },
};

const CardsRowList = ({ list, loading, updateList }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");

  return (
    <Carousel responsive={responsive}>
      {loading
        ? Array(10)
            .fill({})
            .map((_, i) => (
              <SkeletonLoader
                key={i}
                isLight={isLight}
                width="90%"
                height="220"
                borderRadius="5"
              />
            ))
        : list.map((item) => (
            <SoccerSingle
              key={item.id}
              noResponsive={true}
              updateList={updateList}
              {...item}
            />
          ))}
    </Carousel>
  );
};

export default CardsRowList;
