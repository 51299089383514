import { Button, Typography, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
// import { t } from "locales";
import { useSelector } from "react-redux";
// import { RTLS } from "library/constants";
import TradeCards from "./TradeCards";

const Heading = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Box
      sx={{
        display: "flex",
        p: { md: 5, sm: 1 },
        flexDirection: "column",
        height: "100%",
        alignContent: "center",
        justifyContent: "center",
        alignItems: { md: "center", sm: "flex-start" },
      }}
    >
      <Box>
        <Box
          id="heading"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark" ? "#fff" : "#2B2C3B",

            mb: 2,
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "& p, h1, h2, h3, h4": {
              fontFamily: "Poppins",
              fontWeight: "700",
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              borderLeft: (theme) =>
                theme.palette.mode === "dark" ? "#fff" : "#2B2C3B",
              pl: 1,
              fontSize: "33px",
            }}
          >
            NFT GAME PLATFORM
          </Typography>
          <Typography variant="h1" sx={{ fontSize: "80px" }}>
            Xbit Play
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#898CA9",
            lineHeight: "150%",
            fontFamily: "Poppins",
            "& span": {
              fontWeight: "500",
              display: "block",
            },
          }}
        >
          <Typography component="span">REAL WORLD EXPRIENCE</Typography>
          <Typography component="span">FANTASY WORLD STRATEGIES</Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            mt: 4,
            flexDirection: "column",
            "& div": {
              display: "flex",
              gap: "25px",
              minWidth: "40%",
              justifyContent: "space-between",
              "& a": {
                width: "50%",
              },
            },
          }}
        >
          <Box>
            <Button
              // as={Link}
              href={!user ? "/auth/login" : "/crypto-wars/play"}
              sx={(theme) => ({
                backgroundImage: theme.palette.gradient.purpule,
                color: theme.palette.common.white,
                padding: "15px 25px",
                textDecoration: "none",
                borderRadius: "5px",
              })}
            >
              Get Started
            </Button>
            <Button
              // as={Link}
              href="/crypto-wars/how-to-play"
              sx={(theme) => ({
                background: "transparent",
                border: (theme) =>
                  theme.palette.mode === "dark"
                    ? "solid 1px #fff"
                    : "solid 1px #000",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#fff" : "#000",
                width: "fit-content",
                padding: "15px 25px",
                textDecoration: "none",
                borderRadius: "5px",
              })}
            >
              Learn More
            </Button>
          </Box>
          <Box>
            <Button
              // as={Link}
              href="cryptos"
              sx={(theme) => ({
                background: "transparent",
                border: (theme) =>
                  theme.palette.mode === "dark"
                    ? "solid 1px #fff"
                    : "solid 1px #000",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#fff" : "#000",
                width: "fit-content",
                padding: "15px 25px",
                textDecoration: "none",
                borderRadius: "5px",
              })}
            >
              Listed Projects
            </Button>
            <Button
              // as={Link}
              href="exchanges"
              sx={(theme) => ({
                background: "transparent",
                border: (theme) =>
                  theme.palette.mode === "dark"
                    ? "solid 1px #fff"
                    : "solid 1px #000",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#fff" : "#000",
                width: "fit-content",
                padding: "15px 25px",
                textDecoration: "none",
                borderRadius: "5px",
              })}
            >
              Exchanges
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Header = () => {
  return (
    <Box sx={{ px: 5 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Heading />
        </Grid>
        <Grid item xs={12} md={6}>
          <TradeCards />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
