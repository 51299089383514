const mainEn = {
  brandName: "Volex",
  home: "Home",
  howToPlay: "How To Play",
  nftMarketplace: "NFT Marketplace",
  listedprojects: "Projects",
  exchanges: "Exchanges",
  community: "Community",
  telegram: "Telegram",
  twitter: "Twitter",
  instagram: "Instagram",
  gravity: "Gravity",
  discord: "Discord",
  leaderboard: "Leaderboard",
  games: "Games",
  buyVLX: "Buy crypto",
  support: "Support",
  dashboard: "Dashboard",
  setting: "Setting",
  logout: "Log Out",
  profile: "Profile",
  somethingWentWrong: "Something went wrong!",
  loading: "Loading",
  noData: "No Data",

  mainPageTag: "NFT GAME PLATFORM",
  mainPageHeader: "Xbit Play",
  mainPageDesc1: "REAL WORLD EXPERIENCE",
  mainPageDesc2: "FANTASY WORLD STRATEGIES",
  mainPageBtn: "Start the journey",

  feedTitle: "PLAY Feed",
  feedDesc: "What is new on Xbit Play ?",
  feedBtn: "HOW TO PLAY ?",
  newPackTitle: "New Packs",
  newPackDesc: "Announced New Cards",
  tradeCardsTitle: "Collect & Trade",
  tradeCardsDesc: "NFT Collectable digital cards",
  bannersTitle: "All new games this year",
  bannersDesc:
    "dolor sit amet consectetur adipisicing elit. Doloremque sit cupiditate magnam natus ratione distinctio doloribus omnis!",
  bannersBtn: "Learn more",
  language: "Language",

  footerTitle: "NFT game platform",
  aboutUs: "About us",
  quickLinks: "Quick links",
  all: "All",
  newPackSliderBtn: "Buy Now",
  blog: "Blog",
  tournament: "Tournament",
  xbitcoin: "XbitCoin",
  soccer: "Soccer",
  cryptoWars: "CryptoWars",
  exchange: "Exchange",
  gaming: "Gaming",
  earnCrypto: "Earn Crypto",
  marketCap: "Market Cap",
  fullyDilutedMarketCap: "Fully Diluted Market Cap",
  volume: "Volume",
  volumeMarketCap: "Volume / Market Cap",
  circulatingSupply: "Circulating Supply",
  maxSupply: "Max Supply",
  totalSupply: "Total Supply",
};

export default mainEn;
