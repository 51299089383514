import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { Close } from "@mui/icons-material";
import { t } from "locales";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import defaultProfileImg from "assets/img/default-profile.png";
import SingleCardDetail from "routes/CryptoWars/SingleCard/SingleCard";
import PutOfferCryptoWars from "components/PutOfferCryptoWars";
import { useNavigate } from "react-router";
import { openToast } from "components/Toast";
import { getHumanError } from "library/translateServerErrors";
import { useGetBundle } from "_hooks/CryptoWars/market";
import { MAIN_COIN } from "library/constants";
import {
  usePostAuctionOffer,
  useGetAuctionOfferList,
} from "_hooks/Shared/market";

const gridConfig = {
  6: {
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  5: {
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  4: {
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr",
  },
  3: {
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  2: {
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
  },
  1: {
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
  },
};

const widthConfig = {
  6: "344px",
  5: "410px",
  4: "230px",
  3: "308px",
  2: "308px",
  1: "308px",
};

const SingleBundle = ({ onClose, id, updateList, asPage = false }) => {
  const modal = useSelector((state) => state.app.modal);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { isLoading: loadingBundle, data: auctionData } = useGetBundle({
    id,
    onError: () => {
      openToast("error", "auctionNotFound");
      onClose?.();
    },
  });
  const {
    data: offers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAuctionOfferList(id);
  const { isLoading: putOfferLoading, mutate: postAnOffer } =
    usePostAuctionOffer();

  const openPutOfferModal = () => {
    if (!user) {
      onClose?.();
      navigate("/auth/login");
    } else {
      modal.show(
        <PutOfferCryptoWars
          onClose={modal.hide}
          id={id}
          updateList={updateList}
          isBundle
        />
      );
    }
  };

  const openSingleCardDetail = (cardId) => {
    modal.show(<SingleCardDetail onClose={modal.hide} cardId={cardId} />);
  };

  const buyImmediately = async () => {
    if (!user) {
      onClose?.();
      navigate("/auth/login");
    } else {
      modal.show(
        <PutOfferCryptoWars
          onClose={modal.hide}
          id={id}
          updateList={updateList}
          isBundle
        />
      );
    }
    // postAnOffer(
    //   {
    //     auctionId: id,
    //     amount: auctionData?.immediatePrice,
    //   },
    //   {
    //     onSuccess: () => {
    //       openToast("success", "buyCardSuccessful");
    //       updateList?.();
    //       onClose?.();
    //     },
    //     onError: (error) => openToast("error", getHumanError(error)),
    //   }
    // );
  };

  if (loadingBundle || putOfferLoading) {
    return (
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 5,
          },
          !asPage && {
            minWidth: "50vw",
            minHeight: "50vh",
          },
        ]}
      >
        <CircularProgress />
      </Box>
    );
  }

  const bundleCards = auctionData?.bundleCards || [];

  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          position: "relative",
        },
        !asPage && {
          maxWidth: "1000px",
          width: "90vw",
        },
      ]}
    >
      {!!onClose && (
        <Box
          sx={{ position: "absolute", top: "15px", right: "15px", zIndex: 10 }}
          onClick={onClose}
        >
          <ButtonBase
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          pt: "60px",
          mt: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: widthConfig[bundleCards?.length],
            maxHeight: "410px",
            display: "grid",
            gap: "5px",
            flexGrow: 1,
            position: "relative",
            ...gridConfig[bundleCards?.length],
          }}
        >
          {bundleCards?.map(({ card }, i) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                img: {
                  width: "100%",
                  height: "auto",
                  filter: "brightness(1.1)",
                  borderRadius: "8px",
                },
                gridColumn:
                  (bundleCards?.length === 5 || bundleCards?.length === 3) &&
                  i === 0
                    ? "span 2"
                    : "span 1",
                gridRow:
                  (bundleCards?.length === 5 || bundleCards?.length === 3) &&
                  i === 0
                    ? "span 2"
                    : "span 1",
              }}
            >
              <img src={getImageUrl(card?.image)} alt="" />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          padding: { xs: "50px 15px", sm: "50px 40px 90px 40px" },
          // maxWidth: "1280px",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {(auctionData?.title || auctionData?.title) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "20px",
              borderRadius: "8px",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {auctionData?.title && (
                <Typography variant="h5" sx={{ fontWeight: "500" }}>
                  {auctionData?.title}
                </Typography>
              )}
              {auctionData?.description && (
                <Typography variant="body1" sx={{ opacity: ".7" }}>
                  {auctionData?.description}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            p: "20px",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: (theme) => `border.${theme.palette.mode}`,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5" sx={{ fontWeight: "500" }}>
              {auctionData?.max ?? auctionData?.basePrice} {MAIN_COIN}
            </Typography>
            <Typography variant="body1" sx={{ opacity: ".7" }}>
              Finished on {auctionData?.end}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 1 },
            }}
          >
            <ButtonBase
              onClick={buyImmediately}
              sx={{
                height: "100%",
                border: "1px solid",
                borderColor: (theme) => `border.${theme.palette.mode}`,
                borderRadius: "8px",
                backgroundColor: (theme) => theme.palette.solid.blue,
                color: (theme) => theme.palette.common.white,
                px: "25px",
                py: "18px",
                transition: "0.3s",
                "&:hover": {
                  boxShadow: "0px 0px 8px 2px #2b7ae5",
                }
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                Buy it Now •{" "}
                {(+auctionData?.immediatePrice)?.toLocaleString?.() || 0}{" "}
                {MAIN_COIN}
              </Typography>
            </ButtonBase>
            <Button
              onClick={openPutOfferModal}
              sx={(theme) => ({
                height: "100%",
                border: "rgb(179,17,12) solid 1px",
                backgroundColor: "rgb(179,17,12)",
                color: theme.palette.common.white,
                borderRadius: "8px",
                px: "25px",
                py: "18px",
                transition: "0.3s",
                fontSize: "1rem",

                "&:hover": {
                  color: "#000",
                  opacity: ".9",
                },
              })}
            >
              {t("bid")}
            </Button>
          </Box>
        </Box>

        {offers?.pages?.[0]?.total !== 0 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="h5">{t("bids")}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid",
                  borderColor: (theme) => `border.${theme.palette.mode}`,
                  borderRadius: "8px",
                  "& > * + *": {
                    borderTop: "1px solid",
                    borderColor: (theme) => `border.${theme.palette.mode}`,
                  },
                }}
              >
                {offers?.pages?.map((group, index) =>
                  group?.list?.map?.(({ amount, user, id }) => (
                    <Box
                      key={id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: "10px 20px",
                      }}
                    >
                      <Typography variant="body1">
                        {amount} {MAIN_COIN}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          img: {
                            width: "32px",
                            height: "32px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <Typography variant="body1">{user?.name}</Typography>
                        <img
                          src={getImageUrl(user?.avatar) || defaultProfileImg}
                          alt=""
                        />
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              {isFetchingNextPage ? (
                <CircularProgress size={25} />
              ) : hasNextPage ? (
                <Typography
                  onClick={fetchNextPage}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  {t("loadMore")}
                </Typography>
              ) : null}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "20px",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: (theme) => `border.${theme.palette.mode}`,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "500", mb: 2 }}>
            In this bundle
          </Typography>
          <Grid container spacing={2}>
            {bundleCards?.map?.(({ card }, i) => {
              const {
                bonus,
                power,
                image,
                id,
                initialNumber,
                tokens: tokenData,
                PD_asset: assetData,
              } = card || {};

              const { serialNumber } = tokenData || {};

              const cardSerialTag =
                !isNaN(+serialNumber) && serialNumber !== null
                  ? `${serialNumber}/${initialNumber}`
                  : "Common";

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    height: "100%",
                  }}
                >
                  <Box
                    onClick={() => openSingleCardDetail(id)}
                    sx={{
                      width: "90px",
                      cursor: "pointer",
                      img: {
                        width: "90px",
                        filter: "brightness(1.1)",
                        transition: "box-shadow 0.1s ease-out",
                        borderRadius: "8px",
                      },

                      transition: "transform 0.1s ease-out",
                      "&:hover": {
                        transform: "scale(1.02)",

                        img: {
                          boxShadow: (theme) =>
                            theme.palette.mode === "dark"
                              ? "2px 10px 32px 0px rgb(0 0 0 / 90%)"
                              : "2px 10px 32px 0px rgba(0,0,0,0.1)",
                        },
                      },
                    }}
                  >
                    <img src={getImageUrl(image)} alt="" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {power && (
                        <Box
                          sx={{
                            width: "30px",
                            height: "24px",
                            display: "inline-flex",
                            alignItems: "center",
                            flexShrink: "0",
                            borderRadius: "36px",
                            justifyContent: "center",
                            backgroundColor: (theme) =>
                              getPowerBadgeColor(power, theme?.palette?.mode),
                          }}
                        >
                          <Typography variant="caption">{power}</Typography>
                        </Box>
                      )}
                      {!isNaN(+bonus) && (
                        <Typography
                          variant="caption"
                          sx={{
                            display: "inline-flex",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#eee"
                                : "#414244",
                            border: "1px solid",
                            borderColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#414244"
                                : "#fff",
                            padding: "1px 8px",
                            borderRadius: "3px",
                            height: "24px",
                          }}
                        >
                          {bonus}%
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "500",
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "primary.light"
                              : "primary.dark",
                        }}
                      >
                        {assetData?.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ fontSize: "13px" }}>
                        {cardSerialTag}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleBundle;
