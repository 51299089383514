import {
  Instagram,
  Twitter,
  LinkedIn,
  YouTube,
  Telegram,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { Link } from "react-router-dom";
import logoDark from "assets/img/logo-dark.png";
import logoLight from "assets/img/logo-light.png";
import { useSelector } from "react-redux";
import xbitFooterImage from "assets/img/casino-xbit-main-banner.jpg";
import { SOCIAL_LINKS } from "components/MainSidebar/mainSidebar.config";
// Partners
import Foundico from "assets/img/partners/Foundico.png";
import jurnal from "assets/img/partners/jurnal.png";
import counoslogo from "assets/img/partners/counoslogo.png";
import btc6x from "assets/img/partners/btc6x.png";
import real from "assets/img/partners/real.png";
import xbitplay from "assets/img/partners/xbitplay.png";
import xbitlotto from "assets/img/partners/xbitlotto.png";
import counosescrow from "assets/img/partners/counosescrow.png";
import paperwallet from "assets/img/partners/paperwallet.png";
import xbitcc from "assets/img/partners/xbitcc.png";
import webwallet from "assets/img/partners/webwallet.png";
import xbitcoin from "assets/img/partners/xbitcoin.png";
// Coins
import xbit from "assets/img/partners/xbit.png";
import counos from "assets/img/partners/counos.png";
import counosx from "assets/img/partners/counosx.png";
import wxbit from "assets/img/partners/wxbit.png";
import dash from "assets/img/partners/dash.png";
import btc from "assets/img/partners/btc.png";
import eth from "assets/img/partners/eth.png";
import ltc from "assets/img/partners/ltc.png";
import usdt from "assets/img/partners/usdt.png";

const Footer = () => {
  const theme = useSelector((state) => state.setting.theme);

  return (
    <>
      {/* <Box
        sx={{
          mt: 10,
          "& img": {
            width: "100%",
          },
        }}
      >
        <a href="https://www.xbitcc.com" alt="xbit panel">
          <img src={xbitFooterImage} alt="xbit panel" />
        </a>
      </Box> */}
      <Box
        sx={{
          py: 6,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#2a2e34" : "#F1F0F5",
          borderTop: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark"
              ? " rgba(255, 255, 255, 0.12)"
              : " rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box className="container">
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  alignItems: { xs: "center", md: "flex-start" },
                  justifyContent: { xs: "center", md: "flex-start" },
                  mb: { xs: 4, md: 0 },
                }}
              >
                <Box
                  sx={{
                    img: {
                      height: "60px",
                    },
                  }}
                >
                  <Link to="/">
                    {theme === "dark" ? (
                      <img src={logoDark} alt="" />
                    ) : (
                      <img src={logoLight} alt="" />
                    )}
                  </Link>
                </Box>
                <Typography variant="h6">{t("footerTitle")}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2.5,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {SOCIAL_LINKS.map(
                    ({ key, icon: Icon, link, iconProps = {} }) => (
                      <a key={key} href={link} target="_blank" rel="noreferrer">
                        <Icon
                          {...iconProps}
                          fontSize="small"
                          sx={{ color: "gray" }}
                        />
                      </a>
                    )
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 4, sm: 0 },
                "& > div": { flexGrow: 1 },
                a: {
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#ccc" : "#444",
                  textDecoration: "none",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: { xs: "center", md: "flex-start" },
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {t("aboutUs")}
                  </Typography>
                  <a
                    href="https://casinoxbit.com/en/privacy-policy"
                    alt="privacy-policy"
                  >
                    <Typography variant="body1">
                      {t("privacyPolicy")}
                    </Typography>
                  </a>
                  <a
                    href="https://casinoxbit.com/en/terms-and-conditions"
                    alt="terms-and-conditions"
                  >
                    <Typography variant="body1">
                      {t("termsAndConditions")}
                    </Typography>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://xbitplatform.com/en/blogs"
                  >
                    <Typography variant="body1">{t("blog")}</Typography>
                  </a>
                  <Link to="/contact">
                    <Typography variant="body1">{t("contact")}</Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 4, sm: 0 },
                "& > div": { flexGrow: 1 },
                a: {
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#ccc" : "#444",
                  textDecoration: "none",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 1,
                    alignItems: { xs: "center", md: "flex-start" },
                    justifyContent: { xs: "center", md: "flex-start" },
                    "& a": {
                      width: 115,
                      "& img": {
                        width: "100%",
                      },
                    },
                  }}
                >
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.counos.io/"
                  >
                    <img
                      src={counoslogo}
                      alt="Counos"
                    />
                  </a>
                  <a rel="noreferrer" target="_blank" href="https://btc6x.com">
                    <img
                      src={btc6x}
                      alt="btc6x"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.real001.com"
                  >
                    <img
                      src={real}
                      alt="real"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://foundico.com/"
                  >
                    <img src={Foundico} alt="real" />
                  </a>

                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.cintjournal.com/"
                  >
                    <img src={jurnal} alt="real" />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://xbitplay.com/"
                  >
                    <img
                      src={xbitplay}
                      alt="xbit-play"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://lotto.xbitcc.com"
                  >
                    <img
                      src={xbitlotto}
                      alt="xbit-lotto"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://escrow.counos.io"
                  >
                    <img
                      src={counosescrow}
                      alt="Counos Escrow"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://walletgenerator.counos.io"
                  >
                    <img
                      src={paperwallet}
                      alt="Paper Wallet"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://xbitcc.com/xbit/buy/"
                  >
                    <img
                      src={xbitcc}
                      alt="Direct Buy"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://app.counos.io/wallet/"
                  >
                    <img
                      src={webwallet}
                      alt="Web Wallet"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://xbitcc.com/"
                  >
                    <img
                      src={xbitcoin}
                      alt="Xbit Coin"
                    />
                  </a>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 2,
                    gap: 1,
                    alignItems: { xs: "center", md: "flex-start" },
                    justifyContent: { xs: "center", md: "flex-start" },
                    "& a": {
                      width: 50,
                    },
                    "& img": {
                      width: 50,
                    },
                  }}
                >
                  <a rel="noreferrer" target="_blank" href="https://xbitcc.com">
                    <img
                      src={xbit}
                      alt="xbit"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.counos.io/counos-coin"
                  >
                    <img
                      src={counos}
                      alt="Counos Coin"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.counos.io/CounosX"
                  >
                    <img
                      src={counosx}
                      alt="Counos X"
                    />
                  </a>
                  <img
                    src={wxbit}
                    alt="W xbit"
                  />
                  <img
                    src={dash}
                    alt="Dash"
                  />
                  <img
                    src={btc}
                    alt="Bitcoin"
                  />
                  <img
                    src={eth}
                    alt="Ethereum"
                  />
                  <img
                    src={ltc}
                    alt="Litecoin"
                  />
                  <img
                    src={usdt}
                    alt="Tether"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
