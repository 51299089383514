import { useQuery, useQueryClient, useMutation } from "react-query";
import ApiCall from "_clients/apiCall";
import { calcTotalPages } from "library/helper";
import { MAIN_COIN } from "library/constants";

export const useGetBuyCryptoHistory = (type, page) => {
  return useQuery(
    ["buy-crypto-history", type, page],
    () =>
      ApiCall("GET", `asset/${type}`, null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetDepositHistory = (page) => {
  return useQuery(
    ["deposit-history", page],
    () =>
      ApiCall("GET", "asset/deposit", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetWithdrawHistory = (page) => {
  return useQuery(
    ["withdraw-history", page],
    () =>
      ApiCall("GET", "asset/withdraw", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetSubmittedOffers = (page) => {
  return useQuery(
    ["submitted-offers", page],
    () =>
      ApiCall("GET", "user/auction-offer", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useDeleteSubmittedOffer = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => ApiCall("delete", `user/auction-offer/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries("submitted-offers");
    },
  });
};

export const useGetMyAuctions = (page) => {
  return useQuery(
    ["my-auctions", page],
    () =>
      ApiCall("GET", "user/auction", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};
export const useGetMyAuction = (id, onSuccess) => {
  return useQuery(
    ["my-auction", id],
    () => ApiCall("GET", `user/auction/${id}`, null, null),
    { onSuccess }
  );
};
export const useDeleteMyAuction = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => ApiCall("delete", `user/auction/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries("my-auctions");
    },
  });
};
export const useEditMyAuction = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => ApiCall("PUT", "user/auction", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("my-auctions");
    },
  });
};

export const useGetUserCards = (params) => {
  return useQuery(
    ["user-cards", params],
    () =>
      ApiCall("GET", "user/card", null, params, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};
export const usePutCardForSale = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => ApiCall("POST", "user/auction", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("user-cards");
    },
  });
};

export const useGetAllNotifications = (page) => {
  return useQuery(
    ["notifications", page],
    () =>
      ApiCall("GET", "user/notifications", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};
export const useReadAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation(() => ApiCall("PATCH", "user/notifications"), {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });
};
export const useReadSingleNotification = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => ApiCall("PATCH", `user/notifications/${id}`), {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });
};

export const useGetWallet = () => {
  return useQuery("wallet", () =>
    ApiCall("GET", `wallet`, null, null, (res) =>
      res?.data?.data?.find((w) => w?.asset?.coin === MAIN_COIN)
    )
  );
};
