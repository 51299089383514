import { useState } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import { useSelector } from "react-redux";
import formatDate from "library/formatDate";
import { Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { useGetWithdrawHistory } from "_hooks/User/queries";

const TYPES = {
  AUDITING: "bg-primary",
  PENDING: "bg-warning",
  REJECTED: "bg-danger",
  DONE: "bg-success",
  0: "bg-warning", // pending
  "-1": "bg-danger", // canceled
  1: "bg-success", // success
};
const STATUS = {
  0: "pending",
  "-1": "canceled",
  1: "successfull",
};
const LOADING_LIST = Array(10).fill({
  time: "",
  coin: "",
  amount: "",
  status: "",
  depositAddress: "",
  txid: "",
});

function WithdrawHistory() {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [page, setPage] = useState(1);
  const { data: requestData, isLoading: loading } = useGetWithdrawHistory(page);

  const onPageChange = (_, value) => setPage(value);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          p: "20px",
          borderRadius: "12px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("withdrawRecords")}
        </Typography>
        <Box>
          <DataTable
            columns={[
              {
                title: t("time"),
                field: "createdAt",
                render: ({ createdAt }) => {
                  return loading ? (
                    <SkeletonLoader width="50" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">
                      {formatDate(createdAt, {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        lang: "en",
                      })}
                    </Typography>
                  );
                },
              },
              {
                title: t("coin"),
                field: "coin",
                render: (row) => {
                  const asset =
                    row?.assetNetworks?.asset?.coin || row?.currency;

                  return loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">{asset ?? "-"}</Typography>
                  );
                },
              },
              {
                title: t("amount"),
                field: "amount",
                render: ({ amount }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">{amount}</Typography>
                  ),
              },
              {
                title: t("address"),
                field: "address",
                render: ({ address }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography variant="body2">{address ?? "-"}</Typography>
                  ),
              },
              {
                title: t("status"),
                field: "status",
                render: ({ status }) =>
                  loading ? (
                    <SkeletonLoader width="40" height="15" isLight={isLight} />
                  ) : (
                    <Typography
                      variant="body2"
                      className={
                        "badge rounded-pill py-2 px-2 " + TYPES[status]
                      }
                    >
                      {typeof status === "number"
                        ? t(STATUS[status])
                        : t(status.toLowerCase())}
                    </Typography>
                  ),
              },
              {
                title: t("TXID"),
                field: "txid",
                render: ({ txid }) =>
                  loading ? (
                    <SkeletonLoader width="80" height="15" isLight={isLight} />
                  ) : txid ? (
                    <Typography variant="body2">{txid}</Typography>
                  ) : (
                    "-"
                  ),
              },
            ]}
            data={loading ? LOADING_LIST : requestData?.list}
            rowClass="py-2"
            headClass="table-head"
          />
          {!loading && requestData?.list?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 5,
              }}
            >
              <Typography variant="body1">{t("noDataFound")}</Typography>
            </Box>
          ) : (
            <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
              <Pagination
                sx={{ width: "fit-content" }}
                count={requestData?.total || 1}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
export default WithdrawHistory;
