import {
  Button,
  ButtonGroup,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  useMediaQuery,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box } from "@mui/system";
import React from "react";
import {
  ArrowBackIos,
  ArrowForwardIos,
  LanguageOutlined,
  ExpandLess,
  ExpandMore,
  ChatBubbleOutlineOutlined,
} from "@mui/icons-material";
import { t } from "locales";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleExpandedSidebar, toggleSidebar } from "redux/app/appSlice";
import { logout } from "redux/auth/asyncActions";
import { changeTheme } from "redux/setting/settingSlice";
import { OPPOSIT_THEME, RTLS } from "library/constants";
import LanguageModal from "../LanguageModal";
import logoDark from "assets/img/logo-dark.png";
import logoLight from "assets/img/logo-light.png";
import DiscordIcon from "assets/icons/Discord";

const drawerExpandedWidth = 240;
const drawerNotExpandedWidth = 40;
const drawerStyle = {
  width: drawerExpandedWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    right: "0 !important",
    width: drawerExpandedWidth,
    boxSizing: "border-box",
    backgroundImage: "none",
    backgroundColor: (theme) => theme.palette.background[theme.palette.mode],
  },
};

const MainSidebarContent = ({
  publicItems = [],
  authItemsMobile = [],
  socialLinks = [],
}) => {
  const { theme } = useSelector((state) => state.setting);
  const lang = useSelector((state) => state.setting.lang);
  const modal = useSelector((state) => state.app.modal);
  const { user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { mobileSidebar, expandedSidebar } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const isRtl = RTLS.includes(lang);

  const expandingSidebarHandler = () => dispatch(toggleExpandedSidebar());
  const toggleSidebarHandler = () => dispatch(toggleSidebar());

  const logoutHandler = () => {
    dispatch(logout());
  };

  const themeChangeHandler = (newTheme) => {
    dispatch(changeTheme(newTheme));
  };

  const openLanguageModal = () => {
    modal?.show(<LanguageModal />);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  function CommunityCollapse() {
    return (
      <>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <ChatBubbleOutlineOutlined
              sx={{ color: "gray" }}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText
            primary={t("community")}
            sx={{
              color: "gray",
            }}
          />
          {open ? (
            <ExpandLess sx={{ color: "gray" }} />
          ) : (
            <ExpandMore sx={{ color: "gray" }} />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              "& svg": {
                color: "gray",
              },
            }}
          >
            <ListItem
              button
              component="a"
              href="https://t.me/XBITCasino"
              target="_blank"
              sx={{
                color: "gray",
                pl: 4,
              }}
            >
              <ListItemIcon>
                <TelegramIcon />
              </ListItemIcon>
              <ListItemText primary={t("telegram")} />
            </ListItem>

            <ListItem
              button
              component="a"
              href="https://twitter.com/xbitplatform"
              target="_blank"
              sx={{ color: "gray", pl: 4 }}
            >
              <ListItemIcon>
                <TwitterIcon />
              </ListItemIcon>
              <ListItemText primary={t("twitter")} />
            </ListItem>

            <ListItem
              button
              component="a"
              href="https://instagram.com/xbit_gamingplatform?utm_medium=copy_link"
              target="_blank"
              sx={{ color: "gray", pl: 4 }}
            >
              <ListItemIcon>
                <InstagramIcon />
              </ListItemIcon>
              <ListItemText primary={t("instagram")} />
            </ListItem>

            <ListItem
              button
              component="a"
              href="/community"
              // target="_blank"
              sx={{ color: "gray", pl: 4 }}
            >
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={t("gravity")} />
            </ListItem>

            <ListItem
              button
              component="a"
              href="https://discord.gg/qVvxrCpE"
              target="_blank"
              sx={{ color: "gray", pl: 4 }}
            >
              <ListItemIcon>
                <DiscordIcon />
              </ListItemIcon>
              <ListItemText primary={t("discord")} />
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Box
      sx={[
        {
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          display: "none",
        },
        // (theme) => ({
        //   [theme.breakpoints.down("md")]: {
        //     display: "none",
        //   },
        // }),
      ]}
    >
      <CssBaseline />
      <Drawer
        anchor={isRtl ? "right" : "left"}
        variant="permanent"
        sx={{
          width: expandedSidebar ? drawerExpandedWidth : drawerNotExpandedWidth,
          transition: "width 300ms",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: expandedSidebar
              ? drawerExpandedWidth
              : drawerNotExpandedWidth,
            transition: "width 300ms",
            boxSizing: "border-box",
            // display: pathname == "/" ? "none" : "inherit", // For hide sidebar in home page
            backgroundImage: "none",
            backgroundColor: (theme) =>
              theme.palette.background[theme.palette.mode],
          },
          ".MuiListItemText-primary": {
            textAlign: (theme) => theme.direction === "rtl" && "right",
          },

          display: pathname?.includes("auth")
            ? "none"
            : { xs: "none", md: "block" },
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "64px",
            flexGrow: 1,
          }}
        >
          <List>
            {publicItems.map(
              ({ title, icon: Icon, path = "/", as, href, target }, index) => (
                <ListItem
                  as={as}
                  to={path}
                  href={href}
                  target={target}
                  key={index}
                  sx={{
                    padding: expandedSidebar ? "8px 16px" : "8px 0",

                    color: "gray",
                    ".MuiListItemText-primary, .icon": {
                      color: (theme) =>
                        pathname === path
                          ? `primary.${OPPOSIT_THEME[theme.palette.mode]}`
                          : "gray",
                    },

                    ".MuiListItemIcon-root": [
                      !expandedSidebar && { minWidth: "auto" },
                    ],
                  }}
                >
                  <ListItemIcon>
                    <Icon fontSize="small" className="icon" />
                  </ListItemIcon>
                  {expandedSidebar && <ListItemText primary={t(title)} />}
                </ListItem>
              )
            )}
            {expandedSidebar && <CommunityCollapse />}
          </List>
          {expandedSidebar && (
            <Box
              sx={{
                display: "flex",
                gap: 2.5,
                marginTop: 4,
                flexWrap: "wrap",
                justifyContent: "center",
                padding: "0 20px",
              }}
            >
              {socialLinks.map(({ key, icon: Icon, link, iconProps = {} }) => (
                <a key={key} href={link} target="_blank" rel="noreferrer">
                  <Icon
                    {...iconProps}
                    fontSize="small"
                    sx={{ color: "gray" }}
                  />
                </a>
              ))}
            </Box>
          )}
        </Box>
        <Box
          onClick={expandingSidebarHandler}
          sx={{
            display: "flex",
            justifyContent: expandedSidebar ? "flex-end" : "center",
            alignItems: "center",
            color: "#bbb",
            cursor: "pointer",
            height: "50px",
            padding: expandedSidebar ? ".5rem 1rem" : "0",
            transition:
              "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
            svg: {
              transform: `rotate(${isRtl ? "180deg" : "0deg"})`,
            },
          }}
        >
          {expandedSidebar ? (
            <ArrowBackIos fontSize="small" />
          ) : (
            <ArrowForwardIos fontSize="small" />
          )}
        </Box>
      </Drawer>

      {/* FOR MOBILE */}
      <Drawer
        variant="temporary"
        open={smallDevice && mobileSidebar}
        onClose={toggleSidebarHandler}
        sx={{
          ...drawerStyle,
          ".MuiListItemText-primary": {
            textAlign: (theme) => theme.direction === "rtl" && "right",
          },
        }}
        // anchor={isRtl ? "right" : "left"}
      >
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <List>
            <ListItem
              as={Link}
              to="/"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                flexGrow: 1,
                mb: 2,
                a: {
                  display: "flex",
                },
                img: {
                  height: 40,
                  margin: "10px 0",
                },
                padding: "5px 16px",
              }}
            >
              <Link to="/">
                {theme === "dark" ? (
                  <img src={logoDark} alt="" />
                ) : (
                  <img src={logoLight} alt="" />
                )}
              </Link>
            </ListItem>

            {!!user ? (
              <Box>
                <Button
                  onClick={logoutHandler}
                  color="primary"
                  variant="outlined"
                  sx={(theme) => ({
                    my: 2,
                    color:
                      theme.palette.mode === "light"
                        ? "primary.main"
                        : "primary.light",
                    padding: "7px 20px",
                    transition: "background-color 250ms",
                    width: "100%",

                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "common.white",
                    },
                  })}
                >
                  {t("logout")}
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {/* <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    bgcolor: "common.white",
                    color: "common.black",
                    "&:hover": {
                      bgcolor: "#fff",
                    },
                  }}
                  component={Link}
                  to="/auth/register"
                >
                  {t("signup")}
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={[
                    {
                      boxShadow: "none",
                      padding: "7px 20px",
                    },
                    (theme) => ({
                      backgroundImage: theme.palette.gradient.purpule,
                      color: theme.palette.common.white,
                    }),
                  ]}
                  component={Link}
                  to="/auth/login"
                >
                  {t("login")}/{t("REGISTER")}
                </Button>
              </Box>
            )}

            <ButtonGroup
              variant="contained"
              sx={{
                boxShadow: "none",
                width: "100%",
                my: 2,
                direction: "ltr",
                "& > *": { fontFamily: "en" },
              }}
            >
              <Button
                onClick={() => themeChangeHandler("dark")}
                sx={{
                  bgcolor: (theme) => theme.palette.mode === "light" && "#aaa",
                  flexGrow: "1",
                }}
              >
                Dark
              </Button>
              <Button
                onClick={() => themeChangeHandler("light")}
                sx={{
                  bgcolor: (theme) => theme.palette.mode === "dark" && "#444",
                  flexGrow: "1",
                }}
              >
                Light
              </Button>
            </ButtonGroup>

            <ListItem
              onClick={openLanguageModal}
              sx={{
                padding: "5px 16px",
              }}
            >
              <ListItemIcon>
                <LanguageOutlined fontSize="small" className="icon" sx={{}} />
              </ListItemIcon>
              <ListItemText primary={t("language")} />
            </ListItem>

            <Box
              sx={{
                my: 1,
                width: "90%",
                mx: "auto",
                height: "2px",
                bgcolor: "gray",
              }}
            ></Box>

            {!!user &&
              authItemsMobile.map(
                ({ title, icon: Icon, path = "/" }, index) => (
                  <ListItem
                    as={Link}
                    to={path || ""}
                    key={index}
                    sx={{
                      padding: "5px 16px",
                      ".MuiListItemText-primary, .icon": {
                        color: (theme) =>
                          pathname === path
                            ? `primary.${OPPOSIT_THEME[theme.palette.mode]}`
                            : "gray",
                      },
                    }}
                  >
                    <ListItemIcon>
                      <Icon fontSize="small" className="icon" />
                    </ListItemIcon>
                    <ListItemText primary={t(title)} />
                  </ListItem>
                )
              )}

            {publicItems.map(
              ({ title, icon: Icon, path = "/", as, href, target }, index) => (
                <ListItem
                  as={as}
                  to={path}
                  href={href}
                  target={target}
                  key={index}
                  sx={{
                    padding: "5px 16px",
                    ".MuiListItemText-primary, .icon": {
                      color: (theme) =>
                        pathname === path
                          ? `primary.${OPPOSIT_THEME[theme.palette.mode]}`
                          : "gray",
                    },
                  }}
                >
                  <ListItemIcon>
                    <Icon fontSize="small" className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={t(title)} />
                </ListItem>
              )
            )}
            {expandedSidebar && <CommunityCollapse />}
          </List>
          <Box
            sx={{
              display: "flex",
              gap: 2.5,
              marginTop: 4,
              flexWrap: "wrap",
              justifyContent: "center",
              padding: "0 20px",
            }}
          >
            {socialLinks.map(({ key, icon: Icon, link, iconProps = {} }) => (
              <a key={key} href={link} target="_blank" rel="noreferrer">
                <Icon
                  color="gray"
                  {...iconProps}
                  fontSize="small"
                  sx={{ color: "gray" }}
                />
              </a>
            ))}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MainSidebarContent;
