import { Button, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { getImageUrl, getPowerBadgeColor } from "library/helper";
import { Box } from "@mui/system";
import { t } from "locales";
import BundleIcon from "assets/icons/BundleIcon";
import { MAIN_COIN } from "library/constants";

const SingleCard = ({
  count,
  power,
  image,
  bonus,
  name,
  playerName,
  end,
  lastPrice,
  bundleCards,
  initialNumber,
  serialNumber,
  openCardDetail,
  openPutOfferModal,
}) => {
  const cardSerialTag =
    !isNaN(+serialNumber) && serialNumber !== null
      ? `${serialNumber}/${initialNumber}`
      : "Common";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        p: 2,
        height: "100%",
      }}
    >
      {!bundleCards ? (
        <Box
          onClick={openCardDetail}
          sx={{
            position: "relative",
            width: "90px",
            flexGrow: { xs: 0, sm: 1 },
            cursor: "pointer",

            img: {
              width: "90px",
              filter: "brightness(1.1)",
              transition: "box-shadow 0.1s ease-out",
              borderRadius: "8px",
            },

            transition: "transform 0.1s ease-out",
            "&:hover": {
              transform: "scale(1.02)",

              img: {
                boxShadow: (theme) =>
                  theme.palette.mode === "dark"
                    ? "2px 10px 32px 0px rgb(0 0 0 / 90%)"
                    : "2px 10px 32px 0px rgba(0,0,0,0.1)",
              },
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              top: "2px",
              left: "5px",
              color: cardSerialTag === "Common" ? "#333" : "#eaeaea",
              fontWeight: cardSerialTag === "Common" ? "500" : "400",
              fontSize: "10px",
            }}
          >
            {!isNaN(+serialNumber) && serialNumber !== null
              ? `${serialNumber}/${initialNumber}`
              : "Common"}
          </Typography>
          <img src={getImageUrl(image)} alt="" />
        </Box>
      ) : (
        <Box
          sx={{
            width: "90px",
            flexGrow: { xs: 0, sm: 1 },
            position: "relative",

            img: {
              width: "90px",
              filter: "brightness(1.1)",
              transition: "box-shadow 0.1s ease-out",
              borderRadius: "8px",
            },

            transition: "transform 0.1s ease-out",
            "&:hover": {
              transform: "scale(1.02)",

              img: {
                boxShadow: (theme) =>
                  theme.palette.mode === "dark"
                    ? "2px 10px 32px 0px rgb(0 0 0 / 90%)"
                    : "2px 10px 32px 0px rgba(0,0,0,0.1)",
              },
            },
          }}
        >
          <img src={getImageUrl(bundleCards?.[1]?.card?.image)} alt="" />
          <Box
            sx={{
              position: "absolute",
              left: "5px",
              bottom: "8px",
              display: "flex",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "card.dark",
              color: "white",
              p: "2px 6px",
            }}
          >
            <Typography variant="body2">x{bundleCards?.length}</Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: 1,
          p: "5px 0 8px 0",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              gap: 1,
              alignItems: "center",
            }}
          >
            {bundleCards && (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <BundleIcon />
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  Bundle
                </Typography>
              </Box>
            )}
            {power && (
              <Box
                sx={{
                  width: "30px",
                  height: "24px",
                  display: "inline-flex",
                  alignItems: "center",
                  flexShrink: "0",
                  borderRadius: "36px",
                  justifyContent: "center",
                  backgroundColor: (theme) =>
                    getPowerBadgeColor(power, theme?.palette?.mode),
                }}
              >
                <Typography variant="caption">{power}</Typography>
              </Box>
            )}
            {!isNaN(bonus) && (
              <Typography
                variant="caption"
                sx={{
                  display: "inline-flex",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#eee" : "#414244",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "#414244" : "#fff",
                  padding: "1px 8px",
                  borderRadius: "3px",
                  height: "24px",
                }}
              >
                {name}
              </Typography>
            )}
            {/* <IconButton size="large" sx={{ color: "inherit" }}>
              {favorite ? (
                <Favorite fontSize="small" color="error" />
              ) : (
                <FavoriteBorder fontSize="small" />
              )}
            </IconButton> */}
          </Box>
          <Typography variant="caption">
            {formatDistanceToNowStrict(new Date(end))}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{playerName}</Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: "auto" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {lastPrice} {MAIN_COIN}
            </Typography>
            <Typography variant="caption">
              {count} {t("bids")}
            </Typography>
          </Box>
          <Button
            onClick={() => openPutOfferModal(!!bundleCards)}
            color="primary"
            variant="outlined"
            sx={(theme) => ({
              // backgroundImage: theme.palette.gradient.purpule,
              color: theme.palette.common.white,
              padding: "7px 20px",
              transition: "background-color 250ms",
              width: "fit-content",

              "&:hover": {
                backgroundColor: "primary.main",
              },
            })}
          >
            {t("bid")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleCard;
