const contactEn = {
  contact: "Contact",
  finance: "Fincance",
  contactHeader: "Submit a request",
  contactEmailLabel: "Your email address",
  contactDepartmentLabel: "Department",
  contactSubjectLabel: "Subject",
  contactDescriptionLabel: "Description",
  contactHelperText:
    "Please enter the details of your request. A member of our support staff will respond as soon as possible.",
  contactFormEmailRequired: "Email is a required field!",
  contactFormDepartmentRequired: "Department is a required field!",
  contactFormSubjectRequired: "Subject is a required field!",
  contactFormDescriptionRequired: "Description is a required field!",
  contactRequestSubmitted: "Your request sent successfully",
};

export default contactEn;
