import { Box } from "@mui/system";
import coverImg from "assets/img/card-cover.png";
import maskImg from "assets/img/card-mask.png";
import { getImageUrl } from "library/helper";

const SingleDeck = ({ item, showCard, showedCards }) => {
  const { coin, image } = item;

  return (
    <Box
      sx={[
        { gridColumn: "span 1", gridRow: "span 1" },
        showedCards?.includes?.(coin) && {
          animation: "card-pack-scale 1s",
        },
      ]}
      onClick={() => showCard?.(coin)}
    >
      <Box
        sx={{
          position: "relative",
          height: "292px",
          width: "236px",
          cursor: "pointer",
          mx: "auto",
          borderRadius: "25px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={[
            {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: "2",
              backgroundSize: "cover",
              backgroundImage: `url(${coverImg})`,
              backgroundPosition: "center",
              maskSize: "cover",
              maskImage: `url(${maskImg})`,
            },
            showedCards?.includes?.(coin) && {
              animation:
                "card-pack-mask 1s steps(31) forwards, card-pack-fade-out 0.1s 0.9s forwards",
            },
          ]}
        ></Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            zIndex: "2",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${getImageUrl(image)})`,
            backgroundPosition: "left center",
            animation: "card-pack-fade-in 0.1s forwards",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default SingleDeck;
