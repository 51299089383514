import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useParams } from "react-router";
import Header from "./Header";
import List from "./List";
import UserResult from "../UserResult";

const SoccerPlayOverview = () => {
  const { competitionId } = useParams();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          padding: "10px 20px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500", my: 2 }}>
          {t("overview")}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "20px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        }}
      >
        <UserResult competitionId={competitionId} />
        <Header />
        <List competitionId={competitionId} />
      </Box>
    </Box>
  );
};

export default SoccerPlayOverview;
