import { Link } from "react-router-dom";
import {
  InfoOutlined,
  ShoppingCartOutlined,
  SportsEsportsOutlined,
  HomeOutlined,
  Dashboard,
  SettingsOutlined,
  ListAltOutlined,
} from "@mui/icons-material";
import { Link as MuiLink } from "@mui/material";
import DiscordIcon from "assets/icons/DiscordIcon";
import {
  Instagram,
  Twitter,
  LinkedIn,
  YouTube,
  Telegram,
} from "@mui/icons-material";
import BitTalkIcon from "assets/icons/BitTalk";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const authItemsMobile = [
  { title: "dashboard", icon: Dashboard, path: "/dashboard/overview" },
  // {
  //   title: "profile",
  //   icon: PersonOutline,
  //   path: "/dashboard/setting?tab=profile",
  // },
  {
    title: "setting",
    icon: SettingsOutlined,
    path: "/dashboard/setting?tab=account",
  },
];

// export const COMMON_SIDEBAR = {
//   publicItems: [
//     { title: "home", icon: HomeOutlined, path: "/crypto-wars", as: Link },
//     // {
//     //   title: "nftMarketplace",
//     //   icon: ShoppingCartOutlined,
//     //   path: "/soccer/market",
//     //   as: Link,
//     // },
//     // {
//     //   title: "howToPlay",
//     //   icon: InfoOutlined,
//     //   path: "/soccer/how-to-play",
//     //   as: Link,
//     // },
//     // {
//     //   title: "community",
//     //   icon: ChatBubbleOutlineOutlined,
//     //   href: "https://discord.com/channels/929330082877681747/931841582460731402",
//     //   path: "https://discord.com/channels/929330082877681747/931841582460731402", // neseccary for handling active link
//     //   as: MuiLink,
//     //   target: "_blank",
//     // },
//     {
//       title: "games",
//       icon: SportsEsportsOutlined,
//       path: "/games/list",
//       as: Link,
//     },
//     {
//       title: "xbitcoin",
//       icon: MonetizationOnOutlinedIcon ,
//       path: "/xbit-coin",
//       as: Link,
//     },
//     // {
//     //   title: "buyVLX",
//     //   icon: AccountBalanceWalletOutlined,
//     //   path: "/buy-vlx",
//     //   as: Link,
//     // },
//   ],

//   authItemsMobile,
// };

export const COMMON_SIDEBAR = [
  // {
  //   regex: /^\/soccer+/gi,
  //   items: {
  //     publicItems: [
  //       { title: "home", icon: HomeOutlined, path: "/crypto-wars", as: Link },
  //       {
  //         title: "nftMarketplace",
  //         icon: ShoppingCartOutlined,
  //         path: "/soccer/market",
  //         as: Link,
  //       },
  //       {
  //         title: "howToPlay",
  //         icon: InfoOutlined,
  //         path: "/soccer/how-to-play",
  //         as: Link,
  //       },
  //       {
  //         // title: "community",
  //         // icon: ChatBubbleOutlineOutlined,
  //         // href: "https://discord.com/channels/929330082877681747/931841582460731402",
  //         // path: "https://discord.com/channels/929330082877681747/931841582460731402", // neseccary for handling active link
  //         // as: MuiLink,
  //         // target: "_blank",
  //       },
  //       {
  //         title: "games",
  //         icon: SportsEsportsOutlined,
  //         path: "/soccer/play",
  //         as: Link,
  //       },
  //       // {
  //       //   title: "buyVLX",
  //       //   icon: AccountBalanceWalletOutlined,
  //       //   path: "/buy-vlx",
  //       //   as: Link,
  //       // },
  //     ],

  //     authItemsMobile,
  //   },
  // },

  {
    // regex: /^\/crypto-wars+/gi,
    items: {
      publicItems: [
        { title: "home", icon: HomeOutlined, path: "/crypto-wars", as: Link },
        {
          title: "nftMarketplace",
          icon: ShoppingCartOutlined,
          path: "/crypto-wars/market",
          as: Link,
        },
        {
          title: "listedprojects",
          icon: ListAltOutlined,
          path: "/cryptos",
          as: Link,
        },
        {
          title: "exchanges",
          icon: CurrencyExchangeIcon,
          path: "/exchanges",
          as: Link,
        },
        {
          title: "howToPlay",
          icon: InfoOutlined,
          path: "/crypto-wars/how-to-play",
          as: Link,
        },
        // {
        //   title: "community",
        //   icon: ChatBubbleOutlineOutlined,
        //   href: "https://discord.com/channels/929330082877681747/931841582460731402",
        //   path: "https://discord.com/channels/929330082877681747/931841582460731402", // neseccary for handling active link
        //   as: MuiLink,
        //   target: "_blank",
        // },
        {
          title: "tournament",
          icon: SportsEsportsOutlined,
          path: "/crypto-wars/play",
          as: Link,
        },
        {
          title: "xbitcoin",
          icon: MonetizationOnOutlinedIcon,
          path: "/xbit-coin",
          as: Link,
        },
        // {
        //   title: "buyVLX",
        //   icon: AccountBalanceWalletOutlined,
        //   path: "/buy-vlx",
        //   as: Link,
        // },
      ],

      authItemsMobile,
    },
  },
];

export const SOCIAL_LINKS = [
  {
    key: "discord",
    icon: DiscordIcon,
    iconProps: {},
    link: "https://discord.gg/qVvxrCpE",
  },
  {
    key: "instagram",
    icon: Instagram,
    iconProps: {},
    link: "https://instagram.com/xbit_gamingplatform?utm_medium=copy_link",
  },
  {
    key: "twitter",
    icon: Twitter,
    iconProps: {},
    link: "https://twitter.com/xbitplatform",
  },
  {
    key: "youtube",
    icon: YouTube,
    iconProps: {},
    link: "https://m.youtube.com/channel/UC7pVXGdWlzbdJ6tlOpipFRA",
  },
  {
    key: "linkedin",
    icon: LinkedIn,
    iconProps: {},
    link: "https://www.linkedin.com/company/xbit-gaming/",
  },
  {
    key: "telegram",
    icon: Telegram,
    iconProps: {},
    link: "https://t.me/XBITCasino",
  },
  {
    key: "bittalk",
    icon: BitTalkIcon,
    iconProps: {},
    link: "https://bitcointalk.org/index.php?action=profile;u=3321042;sa=summary",
  },
];
