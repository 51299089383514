import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import Card from "./Card/index";

const Index = ({ cards = [], onAdd, onRemove, team }) => {
  return (
    <Box sx={{ height: "calc(100vh - 108px)", overflow: "auto" }}>
      {cards?.length ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            p: "10px 4px",
          }}
        >
          {cards.map((card, index) => (
            <Card
              onAdd={onAdd}
              onRemove={onRemove}
              key={card?.id ?? index}
              data={card ?? {}}
              index={index}
              team={team}
            />
          ))}
        </Box>
      ) : (
        !cards?.length && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              p: "1rem 0",
            }}
          >
            <Typography variant="body1">{t("noCardFound")}</Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default Index;
