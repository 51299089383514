import { ArrowRight, Mail, NotificationsOutlined } from "@mui/icons-material";
import { IconButton, Menu, Typography } from "@mui/material";
import { Box } from "@mui/system";
import NotificationModal from "components/NotificationModal";
import { formatDistanceToNowStrict } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readAllNotifications } from "redux/auth/authSlice";
import { Link } from "react-router-dom";
import { useReadAllNotifications } from "_hooks/User/queries";

const NavbarNotification = () => {
  const notifications = useSelector((state) => state.auth.notifications);
  const modal = useSelector((state) => state.app.modal);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { mutate: readAllNotificationsRequest } = useReadAllNotifications();

  const clearAll = () => {
    if (!notifications?.length) return;

    dispatch(readAllNotifications());

    readAllNotificationsRequest();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: (theme) =>
            theme.direction === "ltr" ? "0 1rem 0 0" : "0 0 0 1rem",
        }}
      >
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="large"
          sx={{
            position: "relative",
            color: "gray",
          }}
        >
          <NotificationsOutlined fontSize="small" sx={{ color: "gray" }} />
          {!!notifications?.length && (
            <Typography
              variant="body2"
              component="span"
              sx={{
                position: "absolute",
                display: "inline-block",
                top: 0,
                right: 0,
                fontSize: "12px",
                padding: "1px 7px",
                borderRadius: "7px",
                backgroundImage: (theme) => theme.palette.gradient.pink,
                color: "#fff",
              }}
            >
              {notifications?.length}
            </Typography>
          )}
        </IconButton>
      </Box>

      <Menu
        variant="menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(false)}
        sx={{ ".MuiList-padding": { p: 0 } }}
      >
        {"showNotifications" && (
          <Box
            onClick={() => setAnchorEl(false)}
            sx={[
              {
                borderRadius: "8px",
                width: { xs: "90vw", sm: "400px" },
                filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.06))",
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "card.dark" : "card.light",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                p: "1rem",
              },
              (theme) =>
                theme.direction === "rtl" ? { left: 0 } : { right: 0 },
            ]}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  borderRadius: "0.3rem",
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#3d3e4e" : "#f8f9fa",
                  padding: "0.5rem 1rem",
                  marginBottom: "1rem",
                  p: { m: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { sm: "center" },
                    flexGrow: 1,
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "white" : "#212429",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ p: { sm: "0 1rem 0 0.5rem" } }}
                  >
                    <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                      {notifications?.length ?? 0}
                    </Typography>
                    Unread notifications
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "primary.light",
                      flexGrow: 1,
                      pr: 2,
                      display: { xs: "none", sm: "flex" },
                      cursor: "pointer",
                    }}
                    onClick={clearAll}
                  >
                    Clear All
                  </Typography>
                </Box>
                <Box
                  onClick={() => setAnchorEl(null)}
                  as={Link}
                  to="/dashboard/setting?tab=notifications"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "white" : "#212429",
                  }}
                >
                  <Typography variant="body2">View all</Typography>
                  <ArrowRight size={15} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {notifications?.length ? (
                  notifications.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          padding: "0.5rem 1rem",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#3d3e4e"
                                : "#eee",
                            borderRadius: "0.4rem",
                          },
                        }}
                        onClick={() =>
                          modal.show(
                            <NotificationModal
                              item={item}
                              onClose={modal.hide}
                            />
                          )
                        }
                      >
                        <Box>
                          <Mail size={12} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 0.7rem",
                            ".title": {
                              color: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "white"
                                  : "#212429",
                            },
                            ".title, .desc": {
                              display: "-webkit-box",
                              "-webkitLineClamp": "1",
                              "-webkitBoxOrient": "vertical",
                              wordBreak: "break-word",
                              overflow: "hidden",
                            },
                            "& > *": {
                              width: "fit-content",
                            },
                          }}
                        >
                          <Typography variant="body2" className="title">
                            {item?.title}
                          </Typography>
                          <Typography variant="body2" className="desc">
                            {item?.description}
                          </Typography>
                          <Typography variant="caption">
                            {!!item?.createdAt &&
                              formatDistanceToNowStrict(
                                new Date(item?.createdAt),
                                {
                                  addSuffix: true,
                                }
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box>
                      <NotificationsOutlined size={16} />
                    </Box>
                    <Typography variant="body2">No new notification</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default NavbarNotification;
