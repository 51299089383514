import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TableSortLabel,
} from "@mui/material";
import Paper from "@mui/material/Paper";
// import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import ListItem from "./ListItem";
import io from "socket.io-client";
import { SOCKET_URL } from "config/Config";

const List = ({ coins, page, pagesize }) => {
  const [coinsList, setCoinsList] = useState(coins);
  // const [order, setOrder] = useState("asc");
  // const [orderBy, setOrderBy] = useState("rank");

  useEffect(() => {
    const socket = io(SOCKET_URL);
    socket.on("crypto-update", (coin) => {
      coin = JSON.parse(coin);
      if (coin.id != "price") {
        return;
      }
      const foundIndex = coins.findIndex((c) => c.id == coin.d.cr.id);
      if (foundIndex > -1) {
        const newcoin = {
          price: coin.d.cr.p,
          volume24h: coin.d.cr.v,
          percentChange1h: coin.d.cr.p1h,
          percentChange24h: coin.d.cr.p24h,
          percentChange7d: coin.d.cr.p7d,
          marketCap: coin.d.cr.mc,
          // coin.d.cr.mc24hpc,
          // coin.d.cr.vol24hpc,
          // coin.d.cr.fmc24hpc,
        };

        coins[foundIndex] = { ...coins[foundIndex], ...newcoin };
        setCoinsList([...coins]);
      }
      return () => {
        socket.disconnect();
      };
    });
  }, []);

  let count = (page - 1) * pagesize;

  const headCells = [
    {
      id: "rank",
      numeric: false,
      label: "#",
    },
    {
      id: "name",
      numeric: false,
      label: "Name",
    },
    {
      id: "price",
      numeric: true,
      label: "Price",
    },
    {
      id: "percentChange1h",
      numeric: true,
      label: "1h",
    },
    {
      id: "percentChange24h",
      numeric: true,
      label: "24h",
    },
    {
      id: "percentChange7d",
      numeric: true,
      label: "7d",
    },
    {
      id: "marketCap",
      numeric: true,
      label: "Market Cap",
    },
    {
      id: "volume24h",
      numeric: true,
      label: "Volume 24h",
    },
    // {
    //   id: "circulatingSupply",
    //   numeric: true,
    //   label: "Circulating Supply",
    // },
    {
      id: "last7days",
      numeric: true,
      label: "Last 7 Days",
    },
  ];

  // const createSortHandler = (property) => (event) => {
  //   handleRequestSort(event, property);
  // };

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="currencies table">
          <TableHead
            sx={{
              "& th": {
                mr: 0.5,
                pl: 0,
                fontSize: "12px",
              },
            }}
          >
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  // align={headCell.numeric ? "right" : "left"}
                  align="center"
                  // sortDirection={orderBy === headCell.id ? order : false}
                >
                  {/* <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  onClick={handleRequestSort(headCell.id)}
                  > */}
                  {headCell.label}
                  {/* {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel> */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "tr:nth-of-type(odd)": {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
              td: {
                border: 0,
              },
            }}
          >
            {coinsList.map((coin) => (
              <ListItem key={coin.id} coin={coin} count={++count} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;
