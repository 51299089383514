import axios from "axios";
import { BASE_URL } from "config/Config";
import { calcTotalPages } from "library/helper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import ApiCall from "_clients/apiCall";

export const useGetTicketsList = (params) => {
  return useQuery(
    ["tickets", params],
    () =>
      ApiCall("GET", "user/ticket", null, params, (res) => ({
        list: res?.data?.data?.data,
        total: calcTotalPages(
          res?.data?.data?.total,
          res?.data?.data?.pageSize
        ),
      })),
    { keepPreviousData: true }
  );
};

export const useGetTicketDepartments = (addAll = false) => {
  return useQuery("ticket-departments", () =>
    ApiCall("GET", "user/department/selector", null, { limit: 100 }, (res) => {
      let list = [];

      if (addAll) list.push({ label: "All", id: undefined });

      list = [
        ...list,
        ...res.data?.data?.data?.map((item) => ({
          label: item?.name,
          id: item?.id,
        })),
      ];

      return list;
    })
  );
};

export const usePostCreateTicket = (setProgress) => {
  const queryClient = useQueryClient();
  const accessToken = useSelector((state) => state.auth.accessToken);

  return useMutation(
    ({ title, text, priority, department, files }) => {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("text", text);
      formData.append("priority", priority?.id);
      formData.append("departmentId", department?.id);

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      const config = {
        method: "post",
        url: `${BASE_URL}user/ticket`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          const newProgress = (
            (progressEvent.loaded * 100) /
            progressEvent.total
          )?.toFixed(1);
          setProgress(newProgress);
        },
        data: formData,
      };

      return axios(config);
    },
    { onSuccess: () => queryClient.invalidateQueries("tickets") }
  );
};

export const useGetSingleTicket = (id) => {
  return useQuery(["ticket", id], () =>
    ApiCall("GET", `user/ticket/${id}`, null, null, (res) => res.data?.data)
  );
};

export const useGetTicketReplies = (params) => {
  return useQuery(
    ["ticket-replies", params],
    () =>
      ApiCall("Get", "user/reply", null, params, (res) => ({
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
        list: res.data?.data?.data,
      })),
    { keepPreviousData: true }
  );
};

export const usePostReply = (setProgress) => {
  const queryClient = useQueryClient();
  const accessToken = useSelector((state) => state.auth.accessToken);

  return useMutation(
    ({ id, text, files }) => {
      const formData = new FormData();
      formData.append("text", text);
      formData.append("ticketId", id);

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      const config = {
        method: "post",
        url: `${BASE_URL}user/reply`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          const newProgress = (
            (progressEvent.loaded * 100) /
            progressEvent.total
          )?.toFixed(1);
          setProgress(newProgress);
        },
        data: formData,
      };

      return axios(config);
    },
    { onSuccess: () => queryClient.invalidateQueries("ticket-replies") }
  );
};
