import { calcTotalPages } from "library/helper";
import { useMutation, useInfiniteQuery, useQuery } from "react-query";
import ApiCall from "_clients/apiCall";

export const useGetAuctionOfferList = (auctionId) => {
  return useInfiniteQuery(
    ["auction-offer-list", auctionId],
    ({ pageParam = 1 }) =>
      ApiCall(
        "GET",
        `user/auction-offer-list`,
        null,
        { auctionId, limit: 5, page: pageParam },
        (res) => ({
          list: res.data?.data?.data,
          total: calcTotalPages(
            res.data?.data?.total,
            res.data?.data?.pageSize
          ),
        })
      ),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.total === pages.length ? undefined : pages.length + 1,
    }
  );
};

export const usePostAuctionOffer = () => {
  return useMutation((params) => ApiCall("post", "user/auction-offer", params));
};

export const useGetCardTypeSelectors = () => {
  return useQuery(["card-type-selector"], () =>
    ApiCall(
      "Get",
      "user/card-type/selector",
      null,
      null,
      (res) => res?.data?.data?.data
    )
  );
};
