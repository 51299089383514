import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import stars from "assets/img/stars.png";
import player1 from "assets/img/player1.jpg";
import player2 from "assets/img/player2.jpg";
import player3 from "assets/img/player3.jpg";
import player4 from "assets/img/player4.jpg";
import player5 from "assets/img/player5.jpg";
import { t } from "locales";

const players = [
  {
    id: 1,
    name: "Arsene",
    image: player1,
    score: 438,
    hint: "leagueWinningHint",
  },
  {
    id: 2,
    name: "Mou",
    image: player2,
    score: 423,
  },
  {
    id: 3,
    name: "Jurgen",
    image: player3,
    score: 422,
  },
  {
    id: 4,
    name: "Lizard",
    image: player4,
    score: 417,
  },
  {
    id: 5,
    name: "Butternut",
    image: player5,
    score: 409,
  },
];

const Winning = ({ goNextStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: {
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          {t("leagueWinningTitle")}
        </Typography>
        <Typography variant="body1">{t("leagueWinningDesc")}</Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90vw", md: "500px" },
          mx: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            pb: "10px",
            borderRadius: "8px",
            img: {
              borderRadius: "8px",
              width: "35px",
              height: "35px",
            },
            "& > *:not(:last-child)": {
              borderBottom: "1px solid",
              borderColor: (theme) =>
                theme.palette.mode === "dark" ? "border.dark" : "border.light",
            },
            "&::after": {
              left: "0",
              right: "0",
              bottom: "0",
              height: "160px",
              content: '""',
              position: "absolute",
              borderRadius: "0 0 8px 8px",
              background: (theme) =>
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg, rgb(20 21 24 / 82%), rgb(20 21 24))"
                  : "linear-gradient(180deg, rgba(255,255,255,0.5), rgba(255,255,255,1))",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              p: "20px",
            }}
          >
            <img src={stars} alt="" />
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              All Star
            </Typography>
          </Box>
          {players.map(({ name, score, image, id, hint }, i) => (
            <Box key={i} sx={{ p: "15px 20px", position: "relative" }}>
              <Box
                key={id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    img: {
                      borderRadius: "8px",
                      width: "40px",
                      height: "40px",
                    },
                  }}
                >
                  <Typography variant="body2" sx={{}}>
                    {i + 1}
                  </Typography>
                  <img src={image} alt="" />
                  <Typography variant="body1" sx={{}}>
                    {name}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{}}>
                  {score} pts
                </Typography>
              </Box>
              {!!hint && (
                <Box
                  sx={{
                    position: { md: "absolute" },
                    left: { xs: "", md: "calc(100% + 20px)" },
                    top: 0,
                    width: { md: "220px" },
                    zIndex: "1500",
                    mt: "20px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      borderRadius: "8px",
                      color: "#fff",
                      opacity: ".85",
                      border: "1px solid",
                      borderColor: "primary.dark",
                      bgcolor: "rgb(37 22 55)",
                      p: "8px 10px",
                    }}
                  >
                    {t(hint)}
                  </Typography>
                </Box>
              )}
              {!!hint && (
                <Box
                  sx={{
                    right: "-8px",
                    top: "40px",
                    width: "17px",
                    height: "17px",
                    zIndex: "1500",
                    position: "absolute",
                    flexGrow: "0",
                    flexShrink: "0",
                    borderRadius: "50%",
                    backgroundColor: "primary.dark",
                    display: { xs: "none", md: "initial" },
                  }}
                ></Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Button
        onClick={goNextStep}
        sx={(theme) => ({
          backgroundImage: theme.palette.gradient.purpule,
          color: theme.palette.common.white,
          width: "fit-content",
          padding: "10px 30px",
          fontSize: "1rem",
          mx: "auto",
        })}
      >
        {t("leagueWinningBtn")}
      </Button>
    </Box>
  );
};

export default Winning;
