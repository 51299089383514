import { Box, Button, Grid, Slider, Stack, Typography } from "@mui/material";
import { formatPrice, percentChange } from "library/helper";
import { t } from "locales";

// const percentChange = (percent) => {
//   const color = percent > 0 ? "green" : "#D8494A";
//   const symbol = percent > 0 ? "\\25b2" : "\\25bc";
//   return (
//     <Box
//       component="span"
//       sx={{
//         color: "#fff",
//         backgroundColor: color,
//         p: "0.5em",
//         borderRadius: "2px",
//         ml: "0.5em",
//         display: "flex",
//         alignItems: "center",
//         fontSize: "10px",
//         "&:before": {
//           content: `"${symbol}"`,
//           fontSize: "0.8em",
//           marginRight: "0.5em",
//         },
//       }}
//     >
//       {Number(percent).toFixed(2) + "%"}
//     </Box>
//   );
// };

const TopSummary = ({ details }) => {
  return (
    <Box m={2}>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.background[theme.palette.mode],
          py: 2,
        }}
      >
        <Grid item md={6}>
          <Box sx={{ display: "inline-flex", alignItems: "center" }}>
            <img src={details.icon[0].location} alt={details.name} width="48" />
            <Typography
              variant="h6"
              color="textPrimary"
              sx={{ fontWeight: "600", m: "10px" }}
            >
              {details.name}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {details.symbol}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="textPrimary">
              {formatPrice(details.price)}
            </Typography>
            <Typography
              sx={{
                fontSize: "10 px",
                fontWeight: "300",
              }}
            >
              {percentChange(details.percentChange24h, "bgColor")}
            </Typography>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              <Box component="span">
                Low:
                <Typography component="span" color="textPrimary" sx={{ mx: 1 }}>
                  {formatPrice(details.low24h)}
                </Typography>
              </Box>
              <Slider
                disabled
                defaultValue={30}
                aria-label="Disabled slider"
                sx={{
                  width: "120px",
                  mx: 1,
                  "& .MuiSlider-thumb": {
                    borderLeft: "5px solid transparent",
                    borderRight: "5px solid transparent",
                    borderBottom: "5px solid #ab0f09",
                    borderRadius: "0 !important",
                    width: "0",
                    // width: "100%",
                    height: "0",
                    color: "transparent",
                    marginTop: "8px",
                  },
                }}
              />
              <Box component="span">
                High:
                <Typography component="span" color="textPrimary" sx={{ mx: 1 }}>
                  {formatPrice(details.high24h)}
                </Typography>
              </Box>
            </Typography>
          </Stack>
        </Grid>
        {/* <Grid item xs={4} sx={{ p: "0 !important" }}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& button": {
                backgroundImage: (theme) => theme.palette.gradient.purpule,
                color: (theme) => theme.palette.common.white,
                width: "120px",
                boxShadow: "none",
                m: "5px",
              },
            }}
          >
            <Box>
              <Button variant="contained" color="primary">
                {t("buy")}
              </Button>
              <Button variant="contained" color="primary">
                {t("exchange")}
              </Button>
            </Box>
            <Box sx={{ mt: "0 !important" }}>
              <Button variant="contained" color="primary">
                {t("gaming")}
              </Button>
              <Button variant="contained" color="primary">
                {t("earnCrypto")}
              </Button>
            </Box>
          </Stack>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default TopSummary;
