const buyVlx = {
  iWantToSpend: "I Want To Spend",
  iWantToRecieve: "I Want To Recieve",
  moreDetail: "More detail",
  spendAmount: "Spend amount",
  receivedAmount: "Received amount",
  arrivalAmount: "Arrival amount",
  deductedAmount: "Deducted amount",
  transactionFee: "Transaction fee",
  paymentMethods: "Payment methods",
  confirm: "Confirm",
  noGateway: "No gateway found!",
  buyVlxAmountError: "Please enter a valid value!",
  paymentPartner: "Payment Partner",
  referencePrice: "Reference Price",
  singleOrder: "Single Order",
  type: "Type",
  amount: "Amount",
  currency: "Currency",
  gateway: "Gateway",
  status: "Status",
  traceNumber: "Trace Number",
  paymentHistory: "Payment History",

  unsuccessPaymentMessage: "Something went wrong. Please try again",
  successPaymentMessage: "Your payment was successful",
  paymentResult: "Payment Result",
};

export default buyVlx;
