import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { RTLS } from "library/constants";
import { useSelector } from "react-redux";

const CustomArrowSlider = ({ prevHandler, nextHandler }) => {
  const isRtl = useSelector((state) => RTLS.includes(state.setting.lang));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        "& > div": {
          position: "relative",
          cursor: "pointer",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#2a2e34" : "#acb7c6",
          color: "white",
          width: "35px",
          height: "35px",
          borderRadius: "50%",
        },
        ".next, .prev": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%) ${isRtl ? "rotate(180deg)" : ""}`,
        },
        ".prev": {
          left: "47%",
        },
      }}
    >
      <Box onClick={prevHandler}>
        <ArrowBackIosRounded className="prev" fontSize="40" />
      </Box>
      <Box onClick={nextHandler}>
        <ArrowForwardIosRounded className="next" fontSize="40" />
      </Box>
    </Box>
  );
};

export default CustomArrowSlider;
