import {
  Typography,
  Grid,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/CustomButton";
import ErrorAlert from "components/ErrorAlert";
import Qrcode from "components/Qrcode";
import { SkeletonLoader } from "components/Skeleton";
import { replaceJSX } from "library/helper";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";

const BodyRow = ({
  active = [],
  asset,
  loading = false,
  walletDepositConfig,
  changeNetwork,
  error,
  success,
  address,
  getAddress,
  disable,
  tag,
  confirm,
  setConfirm,
}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  return (
    <Grid
      container
      sx={{ justifyContent: "space-between", gap: { xs: 2, lg: 0 } }}
    >
      <Grid
        item
        xs={12}
        lg={6.95}
        sx={{
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          p: "20px",
          borderRadius: "12px",
        }}
        className="col-md-7 col-sm-12 mt-3"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ fontWeight: "500" }}>
              {t("protocolType")}
            </Typography>
            <ButtonGroup variant="contained" sx={{ boxShadow: "none" }}>
              {loading ? (
                <SkeletonLoader height="40" width="80" isLight={isLight} />
              ) : walletDepositConfig?.length > 0 ? (
                walletDepositConfig?.map((type, i) => {
                  return (
                    <Button
                      key={type.id ?? i}
                      onClick={() => changeNetwork(type)}
                      sx={{
                        bgcolor: (theme) =>
                          active?.id !== type.id &&
                          (theme.palette.mode === "dark" ? "#444" : "#aaa"),
                      }}
                    >
                      {type.protocolType}
                    </Button>
                  );
                })
              ) : (
                <Button disabled>Deactive</Button>
              )}
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              gap: 2,
            }}
          >
            {error && <ErrorAlert text={error} />}
            {success && (
              <Alert variant="outlined" severity="success">
                {t(success)}
              </Alert>
            )}
            {address == null ? (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    px: "10px",
                    py: "8px",
                    color: "#222",
                    bgcolor: "#ece2ff",
                    borderRadius: "10px",
                  }}
                >
                  {replaceJSX(
                    t("depositInfo"),
                    "#",
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontWeight: "500" }}
                    >
                      {active?.network}
                    </Typography>
                  )}
                </Typography>
                <Typography variant="body1" className="my-1 text-muted">
                  {replaceJSX(
                    t("depositAttention"),
                    "#",
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontWeight: "500" }}
                    >
                      {asset?.coin}
                    </Typography>
                  )}
                </Typography>
                <Typography variant="body1" className="my-3 text-muted">
                  {replaceJSX(
                    t("attentionDeposit1"),
                    "[network]",
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontWeight: "500" }}
                    >
                      {asset?.coin + (active?.type ? "-" + active?.type : "")}
                    </Typography>
                  )}
                </Typography>
                <Box sx={{ mx: "auto", mb: "auto" }}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="agree"
                          id="agree"
                          onChange={() =>
                            active != null ? setConfirm(!confirm) : null
                          }
                          checked={confirm}
                        />
                      }
                      label={t("signupAgreement")}
                    />
                  </FormControl>
                  {/* {errors?.agree && (
                    <FormHelperText sx={{ color: "#f44336" }}>
                      {t(errors?.agree?.message)}
                    </FormHelperText>
                  )} */}
                </Box>
                {loading ? (
                  <SkeletonLoader height="40" width="100%" isLight={isLight} />
                ) : (
                  <CustomButton
                    disabled={!confirm || !active || disable}
                    type={"primary"}
                    onClick={getAddress}
                    fullWidth
                    variant="contained"
                    loaderHeight="40"
                  >
                    {t("confirm")}
                  </CustomButton>
                )}
              </>
            ) : (
              <>
                <Alert variant="outlined" severity="info">
                  {replaceJSX(
                    t("depositInfo"),
                    "#",
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontWeight: "500" }}
                    >
                      {active?.network}
                    </Typography>
                  )}
                </Alert>
                <Typography variant="body1">{t("depositTo")}</Typography>
                <Qrcode text={address} />
                {!!tag && (
                  <>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Typography variant="body1">{t("tag")}</Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "primary.light"
                              : "primary.dark",
                          fontSize: "80%",
                        }}
                      >
                        ( {t("tagInfoDeposit")} )
                      </Typography>
                    </Box>
                    <Qrcode text={tag} />
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4.95}
        sx={{
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          borderRadius: "12px",
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">{t("attention")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="body1"
              sx={{
                px: "10px",
                py: "8px",
                color: "#222",
                bgcolor: "#ece2ff",
                borderRadius: "10px",
              }}
            >
              {t("attentionInfo")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: {
                  opacity: ".8",
                  ".keyword": {
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#222" : "#fff",
                  },
                },
              }}
            >
              <Typography variant="body2">
                1.{" "}
                {replaceJSX(
                  t("attentionDeposit1"),
                  "[network]",
                  <span className="keyword">
                    {asset?.coin + (active?.type ? "-" + active?.type : "")}
                  </span>
                )}
              </Typography>
              <Typography variant="body2">
                2.{" "}
                {replaceJSX(
                  t("attentionDeposit2"),
                  "[min]",
                  <span className="keyword">{active?.depositMin}</span>
                )}
              </Typography>
              <Typography variant="body2">
                3.{" "}
                {replaceJSX(
                  t("attentionDeposit3"),
                  "[confirm]",
                  <span className="keyword">{active?.minConfirm}</span>
                )}
              </Typography>
              <Typography variant="body2">
                4.{" "}
                {replaceJSX(
                  t("attentionDeposit4"),
                  "[unlock]",
                  <span className="keyword">{active?.unlockConfirm}</span>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BodyRow;
