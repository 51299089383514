import { Box, Grid, Stack, Typography, ButtonBase } from "@mui/material";
import { gameListStyles } from "./GameListStyle";
import CryptoWars from "assets/img/CryptoWars.jpg";
import Soccer from "assets/img/soccer.jpg";
import { t } from "locales";
import { Link } from "react-router-dom";

const GameList = () => {
  return (
    <Box mt={4} className="container">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack direction="column" alignItems="center" pl={1} pr={1}>
            <ButtonBase
              as={Link}
              to="/crypto-wars"
              sx={gameListStyles.gameButton}
            >
              <Box
                sx={{
                  ...gameListStyles.gameWrapper,
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "card.dark" : "card.light",
                }}
              >
                <Box sx={gameListStyles.gameImageContent}>
                  <img src={CryptoWars} alt="Volex crypto war" />
                </Box>
                <Typography variant="h5" textAlign="center" mt={2}>
                  {t("gameCryptoWar")}
                </Typography>
              </Box>
            </ButtonBase>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameList;
