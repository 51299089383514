const buyVlxFa = {
  iWantToSpend: "می خواهم خرج کنم",
  iWantToRecieve: "می خواهم دریافت کنم",
  moreDetail: "جزییات بیشتر",
  spendAmount: "مقدار خرج شده",
  receivedAmount: "مقدار دریافتی",
  arrivalAmount: "مقدار رسیده",
  deductedAmount: "مقدار کسر شده",
  transactionFee: "هزینه تراکنش",
  paymentMethods: "روش پرداخت",
  confirm: "تایید",
  noGateway: "هیچ در گاه پرداختی یافت نشد!",
  buyVlxAmountError: "لطفا مقدار وارد شده را تصحیح کنید!",
  paymentPartner: "درگاه پرداخت",
  referencePrice: "قیمت پایه",
  singleOrder: "محدوده سفارش",
  type: "نوع",
  amount: "مقدار",
  currency: "ارز",
  gateway: "درگاه",
  status: "وضعیت",
  traceNumber: "شماره پیگیری",
  paymentHistory: "تاریخچه پرداخت",
};

export default buyVlxFa;
