import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";

const XbitCoin = () => {
  const lang = useSelector((state) => state.setting.lang);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", my: 5 }}
      className="container"
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Xbit Coin
        </Typography>
        <Typography variant="body1">
          Xbit Coin (also known as XBT) is a cryptocurrency that has been
          designed and developed using the SHA-256 algorithm. Xbit Coin can be
          used as a comprehensive cryptocurrency for conventional financial
          transactions, but it was created specifically for use in the online
          gaming industry and also to manage the reward chain of Xbit Gaming
          Platform and Xbit Play.
        </Typography>
        <Typography variant="body1">
          The most important feature of Xbit Coin is that it has been especially
          designed to benefit owners. First, coin holders can stake their coins.
          With this passive income method, the coins are frozen and cannot be
          transferred. The annual rate is 5%. An additional advantage is that
          when betting coins in the gaming platform, their distribution on the
          stock exchanges is decreasing. As a result, the price of coins will
          rise.{" "}
        </Typography>
        <Typography variant="body1">
          Additionally, direct ownership of Xbit Coins can be beneficial. Of
          course Xbit project offers an online gaming platform and the Xbit Coin
          is one of the accepted cryptos in this platform. As can be observed,
          Xbit Coin is backed by Xbit Gaming Platform, a well-established
          financial institution. As a result, Xbit Coins are considered as
          representative shares of the Xbit Gaming Platform.
        </Typography>
        <Typography variant="body1">
          The same applies to Xbit Play, where players or managers can purchase
          playing cards with Xbit Coin and compete against each other to win
          more Xbit Coins.
        </Typography>
        <Box
          sx={{
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            p: "10px",
            m: "20px 10px",
            borderRadius: "5px",
            a: {
              mb: 1,
              display: "block",
              opacity: ".8",
              fontWeight: "500",
              color: "rgb(171,15,9)",
            },
          }}
        >
          <a target="_blank" href="https://xbitcc.com/xbit/buy/">
            Purchase Xbit Coin Directly
          </a>
          <a target="_blank" href="https://btc6x.com/markets">
            Purchase Xbit Coin at BTC6X
          </a>
          <a target="_blank" href="https://counos.exchange/">
            Purchase Xbit Coin at Counos Exchange
          </a>
          <a target="_blank" href="https://dex.counos.io/">
            Purchase Xbit Coin at Counos Dex
          </a>
          <a target="_blank" href="https://www.coingecko.com/en/coins/xbit">
            Xbit Coin at CoinGecko
          </a>
          <a target="_blank" href="https://coinmarketcap.com/currencies/4xbit/">
            Xbit Coin at CoinMarketCap
          </a>
          <a target="_blank" href="https://www.coinbase.com/price/4xbit">
            Xbit Coin at Coinbase
          </a>
        </Box>
      </Box>
      {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {bodyContent?.map?.(({ TITLE, ITEMS }) => (
          <Box
            sx={{
              bgcolor: (theme) => `card.${theme.palette.mode}`,
              p: "20px 10px",
              borderRadius: "5px",
              li: {
                mb: 1,
                listStyleType: "square",
              },
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              {t(TITLE)}
            </Typography>
            <ul>
              {ITEMS?.map?.((item) => (
                <li>
                  <Typography variant="body2">{t(item)}</Typography>
                </li>
              ))}
            </ul>
          </Box>
        ))}
      </Box> */}
    </Box>
  );
};

export default XbitCoin;
