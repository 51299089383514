import React from "react";

const DiscordIcon = () => {
  return (
    <svg
      fill="gray"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="24px"
      height="24px"
    >
      <path
        fill="none"
        stroke="gray"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M31.17,34.851l1.702,2.362c0.69,0.958,1.861,1.421,3.007,1.19c2.901-0.585,7.612-2.206,9.545-5.636 c0-9.433-4.058-19.413-5.843-21.079c-2.161-1.754-5.13-2.65-7.221-3.092c-1.476-0.312-2.965,0.52-3.45,1.971 c-0.202,0.604-0.368,1.21-0.411,1.665"
      />
      <path
        fill="none"
        stroke="gray"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M19.5,12.234c-0.043-0.455-0.209-1.061-0.411-1.665c-0.485-1.451-1.974-2.283-3.45-1.971c-2.09,0.442-5.06,1.338-7.221,3.092 c-1.785,1.665-5.843,11.646-5.843,21.079c1.933,3.43,6.644,5.051,9.545,5.636c1.146,0.231,2.316-0.232,3.007-1.19l1.702-2.362"
      />
      <path
        fill="none"
        stroke="gray"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M9.892,31.53c0,0,4.674,3.989,14.108,3.989s14.108-3.989,14.108-3.989"
      />
      <path
        fill="none"
        stroke="gray"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M36.5,15.5c-4.783-2.841-10.434-2.993-12.5-2.993s-7.717,0.152-12.5,2.993"
      />
      <ellipse cx="17.535" cy="25.96" rx="3.465" ry="3.96" />
      <ellipse cx="30.445" cy="26" rx="3.5" ry="4" />
    </svg>
  );
};

export default DiscordIcon;
