import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getWizardData } from "redux/auth/asyncActions";
import ApiCall from "_clients/apiCall";

export const useGetCryptoWarsPack = (id) => {
  const dispatch = useDispatch();

  return useQuery(
    ["crypto-wars-pack", id],
    () => ApiCall("GET", `user/wizard-crypto/${id}`),
    { onSuccess: () => dispatch(getWizardData()), retry: false }
  );
};
