import authFa from "./auth";
import mainFa from "./main-fa";
import validationFa from "./validation";
import soccerFa from "./soccer";
import games from "./games";
import cryptoWarsFa from "./crypto-wars";
import userFa from "./user";
import marketFa from "./market";
import errorsFa from "./errors";
import buyVlxFa from "./buy-vlx";
import assetsFa from "./assets";
import howToPlayFa from "./howToPlay";
import inviteFa from "./invite";
import contactFa from "./contact";
import ticketFa from "./ticket-fa";

const fa = {
  ...mainFa,
  ...authFa,
  ...validationFa,
  ...soccerFa,
  ...games,
  ...cryptoWarsFa,
  ...userFa,
  ...marketFa,
  ...errorsFa,
  ...buyVlxFa,
  ...assetsFa,
  ...howToPlayFa,
  ...inviteFa,
  ...contactFa,
  ...ticketFa,
};

export default fa;
