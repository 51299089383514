import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from "@mui/material";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import RecommendedAccounts from "./RecommendedAccounts";

const SideBar = () => {
  let trendingArr = ["$BTC", "$ETH", "$ETC", "Merge", "$USDT"];

  const trending = () => {
    return;
    return (
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          Trending
          <WhatshotIcon />
        </Typography>
        <List>
          {trendingArr.map((coin, index) => (
            <Box key={index}>
              <ListItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  px: 1,
                  backgroundColor: (theme) =>
                    theme.palette.background[theme.palette.mode],
                  "& span:nth-of-type(1)": { fontSize: "12px" },
                }}
              >
                <Typography component="span" sx={{ color: "gray" }}>
                  {index + 1}
                </Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {coin}
                </Typography>
                <Typography component="span" sx={{ color: "gray", ml: "auto" }}>
                  {(Math.random() * 100).toFixed(0)}
                </Typography>
              </ListItem>
              {index + 1 !== trendingArr.length && <Divider />}
            </Box>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box sx={{ px: 1 }}>
      {trending()}
      <RecommendedAccounts />
    </Box>
  );
};

export default SideBar;
