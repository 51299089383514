import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { CircleSkeleton } from "components/Skeleton";
import { t } from "locales";
import { useSelector } from "react-redux";
import SingleReply from "./SingleReply";
import { styles } from "./SingleReply/styles";

const RepliesList = ({ replies, ticketData, loading }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");

  let renderedReplies = loading
    ? Array(6)
        .fill({})
        .map((_, i) => (
          <Box
            sx={{
              ...styles.shared.reply,
              ...styles[i % 2 === 0 ? "user" : "manager"]?.reply,
            }}
          >
            <Box
              sx={{
                ...styles.shared.body,
                ...styles[i % 2 === 0 ? "user" : "manager"]?.body,
              }}
            >
              <CircleSkeleton radius="20" width="50" isLight={isLight} />
              <SkeletonLoader
                width="100%"
                height="40"
                borderRadius="5"
                isLight={isLight}
              />
            </Box>
          </Box>
        ))
    : replies.map((rep, i) => <SingleReply key={rep?.id ?? i} {...rep} />);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", marginBottom: "2rem" }}
    >
      <Typography variant="h5">{t("replies")}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {renderedReplies}
      </Box>
    </Box>
  );
};

export default RepliesList;
