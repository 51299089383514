import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Grid,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/CustomButton";
import { SkeletonLoader } from "components/Skeleton";
import { addComma, replaceJSX, truncateToDecimals } from "library/helper";
import { t } from "locales";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import addressValidator from "multicoin-address-validator";
import axios from "library/http";
import { inputAutoFillStyles } from "assets/styles/styles";
import _ from "lodash";
import VerifyWithdraw from "./VerifyWithdraw";
import { getHumanError } from "library/translateServerErrors";
import { openToast } from "components/Toast";

const getValidation = (walletBalance, tagRequired, min, network) => {
  if (tagRequired) {
    return yup.object({
      amount: yup
        .number()
        .typeError("notValidWithdrawAmount")
        .max(+walletBalance, "insuffientBalance")
        .min(min ?? 0, "belowMinWitdrawAmount")
        .required("withdrawAmountRequired"),
      address: yup
        .string()
        .required("withdrawAddressRequired")
        .test("checkAddress", "notValidWithdrawAddress", (value) => {
          try {
            return addressValidator.validate(value, network);
          } catch (error) {
            return true;
          }
        }),
      tag: yup.string().required("withdrawTagRequired"),
    });
  } else {
    return yup.object({
      amount: yup
        .number()
        .typeError("notValidWithdrawAmount")
        .max(+walletBalance, "insuffientBalance")
        .min(min ?? 0, "belowMinWitdrawAmount")
        .required("withdrawAmountRequired"),
      address: yup
        .string()
        .required("withdrawAddressRequired")
        .test("checkAddress", "notValidWithdrawAddress", (value) => {
          try {
            return addressValidator.validate(value, network);
          } catch (error) {
            return true;
          }
        }),
    });
  }
};

const BodyRow = ({
  wallet,
  active,
  asset,
  loading = false,
  walletWithdrawConfig,
  changeNetwork,
  getAddress,
  disable,
  confirm,
  setLoading,
  setWallet,
}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [tagRequired, setTagRequired] = useState(false);
  const [confirmToken, setConfirmToken] = useState("");
  const withdrawData = useRef({});
  const available = +wallet?.amount;

  const resolver = yupResolver(
    getValidation(
      +available - (active?.withdrawFee ? active?.withdrawFee : 0),
      tagRequired,
      active?.withdrawMin,
      active?.network
    )
  );

  const {
    handleSubmit,
    control,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      amount: 0,
      address: "",
    },
    resolver,
  });

  const onSubmit = async (data) => {
    const requestData = {
      amount: data.amount,
      address: data.address,
      id: active?.id,
      tag: tagRequired ? data.tag : undefined,
    };

    withdrawData.current = requestData;

    try {
      const respone = await axios
        .post("asset/withdraw", requestData)
        .then((response) => {
          const data = response?.data;
          if (data?.status == "success") {
            openToast("success", "withdrawSuccess");
            // setConfirmToken(data?.data);
            refreshWallet();
          } else {
            throw data?.error;
          }
        });
      // setConfirmToken(respone?.data?.data?.token);
    } catch (error) {
      const errorMessage = getHumanError(error);
      openToast("error", errorMessage);
    }
  };

  const refreshWallet = () => {
    setLoading(true);

    reset({
      amount: 0,
      address: "",
    });

    axios
      // .get(`asset/config?type=withdraw&coin=${asset?.coin}`)
      // Fix wrong api
      .get(`wallet`)
      .then((response) => {
        const data = response?.data?.data;
        if (data.length > 0) {
          // const refreshedWallet = data.find(
          //   (w) => w?.id === active?.id
          // )?.wallet;
          const refreshedWallet = data[0];

          setWallet(refreshedWallet);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const amountChangeHandler = (e, onChange) => {
    const value = e?.target?.value;
    const formattedAmount = truncateToDecimals(
      value,
      asset?.precision <= 6 ? asset?.precision : 6
    );

    if (+formattedAmount === +value) {
      onChange(e);
    }
  };

  return (
    <Grid
      container
      sx={{ justifyContent: "space-between", gap: { xs: 2, lg: 0 } }}
    >
      <Grid
        item
        xs={12}
        lg={6.95}
        sx={{
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          p: "20px",
          borderRadius: "12px",
        }}
        className="col-md-7 col-sm-12 mt-3"
      >
        <Box
          container
          sx={{ justifyContent: "space-between", gap: { xs: 2, lg: 0 } }}
        >
          {!confirmToken ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  {t("protocolType")}
                </Typography>
                <ButtonGroup variant="contained" sx={{ boxShadow: "none" }}>
                  {loading ? (
                    <SkeletonLoader height="40" width="80" isLight={isLight} />
                  ) : (
                    walletWithdrawConfig?.map((type, i) => {
                      return (
                        <Button
                          key={type.id ?? i}
                          onClick={() => changeNetwork(type)}
                          sx={{
                            bgcolor: (theme) =>
                              active?.id !== type.id &&
                              (theme.palette.mode === "dark" ? "#444" : "#aaa"),
                          }}
                        >
                          {type.protocolType}
                        </Button>
                      );
                    })
                  )}
                </ButtonGroup>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography
                  variant="body1"
                  sx={{
                    px: "10px",
                    py: "8px",
                    color: "#222",
                    bgcolor: "#ece2ff",
                    borderRadius: "10px",
                  }}
                >
                  {replaceJSX(
                    t("withdrawInfo"),
                    "#",
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontWeight: "500" }}
                    >
                      {active?.network}
                    </Typography>
                  )}
                </Typography>
                <Box
                  component="form"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      component="label"
                      htmlFor="taddressag"
                      sx={{ width: "fit-content" }}
                    >
                      {t("address")}
                    </Typography>
                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <TextField
                            id="address"
                            name="address"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors?.address}
                            helperText={t(errors?.address?.message)}
                            fullWidth
                            ref={ref}
                            sx={inputAutoFillStyles}
                            size="small"
                          />
                        );
                      }}
                    />
                  </Box>
                  {active?.hasTag && (
                    <Box sx={{ opacity: !tagRequired ? ".7" : "1" }}>
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="tag"
                        sx={{ display: "flex", gap: 1, width: "fit-content" }}
                      >
                        {t("tag")}
                        <Typography
                          variant="caption"
                          sx={{
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "primary.light"
                                : "primary.dark",
                          }}
                        >
                          ( {t("tagInfoWithdraw")} )
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        name="tag"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <TextField
                              id="tag"
                              name="tag"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={tagRequired && errors?.tag}
                              helperText={t(errors?.tag?.message)}
                              fullWidth
                              ref={ref}
                              sx={inputAutoFillStyles}
                              disabled={!tagRequired}
                              size="small"
                            />
                          );
                        }}
                      />
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isSubmitting}
                              color="primary"
                              name="nullTag"
                              id="nullTag"
                              onChange={() => {
                                clearErrors("tag");
                                setTagRequired((prev) => !prev);
                              }}
                              checked={confirm}
                            />
                          }
                          label="null Tag"
                        />
                      </FormControl>
                    </Box>
                  )}
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="amount"
                        className="flex-1"
                      >
                        {t("actualArrived")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => setValue("amount", available || 0)}
                      >
                        <Typography variant="body2" sx={{ opacity: ".9" }}>
                          {t("withdrawable")}:
                        </Typography>

                        <Typography variant="body2" sx={{ fontWeight: "500" }}>
                          {loading ? (
                            <SkeletonLoader
                              height="15"
                              width="30"
                              isLight={isLight}
                            />
                          ) : !isNaN(available) ? (
                            `${addComma(
                              truncateToDecimals(available, asset.precision)
                            )} ${asset?.coin || ""}`
                          ) : (
                            0
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <TextField
                            id="amount"
                            helperText={replaceJSX(
                              t(errors?.amount?.message),
                              active?.withdrawMin ?? 0
                            )}
                            size="small"
                            fullWidth
                            ref={ref}
                            sx={inputAutoFillStyles}
                            disabled={isSubmitting}
                            value={value}
                            onChange={(e) => amountChangeHandler(e, onChange)}
                            onBlur={onBlur}
                            error={errors?.amount}
                          />
                        );
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                        fontSize: "80%",
                        p: {
                          opacity: ".9",
                        },
                      }}
                    >
                      {/* <Typography variant="body2">
                        Deduction:{" "}
                        {addComma(
                          (!isNaN(active?.withdrawFee)
                            ? +getValues("amount") + +active?.withdrawFee
                            : getValues("amount")
                          )?.toPrecision(6)
                        )}
                      </Typography> */}
                      <Typography variant="body2">
                        {t("withdrawFee")}:{" "}
                        {`${addComma(
                          active?.withdrawFee ? active?.withdrawFee : 0
                        )} ${asset?.coin || ""}`}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    {loading ? (
                      <SkeletonLoader
                        height="40"
                        width="100%"
                        isLight={isLight}
                      />
                    ) : (
                      <CustomButton
                        loading={isSubmitting}
                        disabled={
                          available === 0 ||
                          isSubmitting ||
                          !_.isEmpty(errors) ||
                          disable
                        }
                        type={"primary"}
                        onClick={getAddress}
                        fullWidth
                        variant="contained"
                        loaderHeight="40"
                        extraSx={{ mt: "auto" }}
                      >
                        {t("confirm")}
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <VerifyWithdraw
              setConfirmToken={setConfirmToken}
              confirmToken={confirmToken}
              refreshWallet={refreshWallet}
              withdrawData={withdrawData}
            />
          )}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        lg={4.95}
        sx={{
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          borderRadius: "12px",
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">{t("attention")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="body1"
              sx={{
                px: "10px",
                py: "8px",
                color: "#222",
                bgcolor: "#ece2ff",
                borderRadius: "10px",
              }}
            >
              {t("attentionInfo")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: {
                  opacity: ".8",
                  ".keyword": {
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#222" : "#fff",
                  },
                },
              }}
            >
              <Typography variant="body2">
                1.{" "}
                {replaceJSX(
                  t("attentionWithdraw1"),
                  "[network]",
                  <span className="keyword">
                    {asset?.coin + (active?.type ? "-" + active?.type : "")}
                  </span>
                )}
              </Typography>
              <Typography variant="body2">
                2.{" "}
                {replaceJSX(
                  t("attentionWithdraw2"),
                  "[min]",
                  <span className="keyword">{active?.withdrawMin}</span>
                )}
              </Typography>
              <Typography variant="body2">
                3.{" "}
                {replaceJSX(
                  t("attentionWithdraw3"),
                  "[confirm]",
                  <span className="keyword">{active?.minConfirm}</span>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BodyRow;
