import * as React from "react";

const LinkIcon = (props) => (
  <svg
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.293 4.707a3.857 3.857 0 0 1 .009 5.449l-.01.01-1.705 1.705a3.864 3.864 0 0 1-5.458 0 3.863 3.863 0 0 1 0-5.458l.942-.942c.25-.25.68-.083.693.27.016.45.097.902.246 1.338a.409.409 0 0 1-.096.422l-.332.332c-.712.712-.734 1.87-.03 2.59a1.83 1.83 0 0 0 2.598.012L6.857 8.73a1.828 1.828 0 0 0-.263-2.803.407.407 0 0 1-.176-.32c-.01-.268.085-.545.297-.757l.534-.534c.14-.14.36-.158.523-.044.186.13.36.276.52.436ZM11.87 1.13a3.864 3.864 0 0 0-5.458 0L4.707 2.835a3.857 3.857 0 0 0 .521 5.895.408.408 0 0 0 .523-.045l.534-.534c.212-.212.307-.489.297-.757a.407.407 0 0 0-.176-.32 1.828 1.828 0 0 1-.263-2.803L7.85 2.565a1.83 1.83 0 0 1 2.598.013c.704.718.682 1.877-.03 2.589l-.332.332a.409.409 0 0 0-.096.422c.149.436.23.888.246 1.338.013.353.443.52.693.27l.942-.942a3.863 3.863 0 0 0 0-5.458Z"
      fill="#000"
    />
  </svg>
);

export default LinkIcon;
