export const RTLS = ["fa", "ar"];

export const OPPOSIT_THEME = { dark: "light", light: "dark" };

export const CRYPTOWARS_START_TIME = {
  "00": "00:00",
  "06": "06:00",
  12: "12:00",
  18: "18:00",
};

export const CRYPTOWARS_END_TIME = {
  "00": "06:00",
  "06": "12:00",
  12: "18:00",
  18: "00:00",
};

export const MAIN_COIN = "XBT";
