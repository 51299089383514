const privacyPolicyEn = {
  privacyPolicy: "Privacy policy",
  headerTitle: "PRIVACY POLICY",
  headerDesc:
    "LEGAL NOTICE Volex Vision Limited company is a UK company having its registered office located on 59 St. Martin's Lane, Suite 8, London, England, WC2N 4JS. Contact : info@volexplay.com The Website is mainly hosted by AWS",

  title1: "1. Intro",
  title2: "2. The Width of the Policy",
  title3: "3. What Data Are Collected and The Purpose",
  title4: "4. The Recipients of Data",
  title5: "5. How Long Are Your Personal Data Stored?",
  title6: "6. What Are the Users’ Rights?",
  title7: "7. Cookies at XbitPlay Website",
  title8: "8. Safety & Security",

  "text1-1":
    "XbitPlay allows players or users who have registered on the website XbitPlay.com to receive the advantaged of an enhanced user interface. XbitPlay is a global NFT based online gaming experience. The Website provides a marketplace for its registered users for acquiring, collecting and exchanging NFT cards and their underlying tokens which are based on Blockchain technology.",
  "text1-2":
    "To use the services provided by XbitPlay, users will need to create an account.",
  "text1-3":
    "Please feel free to read out Terms and Conditions for more information regarding the services that we provide.",
  "text1-4":
    "XbitPlay firmly believes that trust is an important part of the relationship between us and the players. As such, the protection of your personal data and privacy is our top priority.",
  "text1-5":
    "Thereby, XbitPlay emphasizes collecting and processing your data with utmost care and complying with the applicable legal framework.",
  "text1-6":
    "In the spirit of transparency and full scrutiny, XbitPlay has implemented the privacy policy below.",

  "text2-1":
    "The privacy policy that is set out in this document is intended to inform you, as a visitor or a player on the XbitPlay website about the way the XbitPlay processes, as controller, information enabling to identify you either directly or indirectly – i.e. your personal data and information –  in the context of your use of our services.",
  "text2-2":
    "By agreeing to this policy, the users agree and allow the use of personal data by XbitPlay in line with this Policy.",
  "text2-3":
    "The privacy policy that is set out here in this document will always be accessible at any time on the website and prevails over any previous version.",
  "text2-4":
    "The privacy policy that is set out here in this document may evolve. The up-to-date version is the one available on the website.",

  "text3-1":
    "XbitPlay processes users’ personal data for special purposes, each of them being duly legitimated by a valid legal basis.",
  "text3-2":
    "Firstly, as a user, you may have created an account enabling you to access the services that are provided by XbitPlay. In order to manage this account, XbitPlay will collect and process personal data (including but not limited to your first name, last name, username and your contact details).",
  "text3-3":
    "The legal basis of this processing is the requirement for XbitPlay to execute a contract in which you are a party in accordance with legal frameworks.",
  "text3-4":
    "Secondly, XbitPlay is fully willing to understand how users interact with its services and need to process various browsing information resulting from cookies.",
  "text3-5":
    `Thirdly, user’s personal data are collected and processed by XbitPlay for the use of the services by players. This processing requires XbitPlay to collect and process the following forms of personal data:
    •	Username of the players;
    •	for the purchase, transfer, withdrawal and exchange of collectibles: username and the Blockchain public keys;
    •	for the participation in the game: username, Blockchain public keys and prize winners;
    •	for the purposes of leaderboard: username.
    `,
  "text3-6":
    "Fourthly, XbitPlay collects and processes user’s personal data for managing and following up any questions and/or requests that may have been submitted by the users.",
  "text3-7": "XbitPlay may, depending on the results of the control carried out, take security measures, in particular requesting additional supporting documents.",
  "text3-8":
    "The personal data that are to be collected for this specific purpose are the information account and transaction history and details.",
  "text3-9":
    "The legal basis on which XbitPlay relies for this purpose is the legitimate interest of XbitPlay. ",
  "text3-10": "Additionally, XbitPlay may collect some browsing data which will enable us to provide the security of our services and to detect, prevent or trace any attempt at malicious or hacking activities.",
  "text3-11":
    "Finally, please note that the XbitPlay will comply with a strict data minimization principle and therefore shall only collect and process personal data that are necessary for the above mentioned purposes.",

  "text4-1":
    "XbitPlay might share your personal data with third-party service providers and suppliers that might aid us in fulfilling the purposes specified in the privacy policy that is set here.",
  "text4-2":
    "XbitPlay might share your personal data with courts of law and any other governmental and/or public authorities, within the extent legally permitted.",
  "text4-3":
    "It must be stated that XbitPlay communicates your personal data to the above mentioned recipients on a strictly need-to-know basis.",

  "text5-1":
    "XbitPlay might share your personal data with third-party service providers and suppliers which might aid XbitPlay in fulfilling the purposes specified in the privacy policy that is set out here in this document.",
  "text5-2":
    "XbitPlay will retain your personal data with regard to the management of your account. However, if XbitPlay needs to retain your personal data for evidential purposes beyond the date of closure of your account, then the maximum applicable retention period will be in accordance with the statutory limitation.",
  "text5-3":
    "The personal data that are processed by or on behalf of XbitPlay to perform analysis operations will not be retained by XbitPlay after such operations.",
  "text5-4":
    "Personal data processed for fraud prevention are kept for three (3) years from inclusion on an alert list. ",
  "text5-5":
    "Personal data processed for security matters are kept for five (5) years from the last activity.",
  "text5-6":
    "Furthermore, with regard to the management of your questions and/or requests, XbitPlay will not retain your personal data beyond three (3) years.",
  "text5-7":
    "XbitPlay may store certain personal data for a longer period of time than required in the immediate and current needs due to legitimate interests and legal obligations.",

  "text6-1":
    "The users and players at XbitPlay are informed that they do indeed have a right of access, a right to rectification and erasure, a right to restriction of processing of personal data.",
  "text6-2":
    "The users and players at XbitPlay also have the right to specify instructions defining the way personal data shall be collected and processed after their death.",
  "text6-3":
    "XbitPlay shall make attempts to reply to all users’ questions and inquiries without undue delay and at the latest within one month of receipt of the request.",
  "text6-4":
    "XbitPlay is committed to protect your personal data and comply with the applicable data protection legal framework.",
  "text6-5":
    "Additionally, in the event you would provide XbitPlay with information enabling to identify directly or indirectly any other natural persons, you represent and warrant that, prior to sharing this information with XbitPlay, such other natural persons have been provided with the privacy policy that is set out here in this document.",

  "text7-1":
    "When users and players at XbitPlay browse our website, cookies will be placed on your browser terminal, in both forms of being direct or after obtaining your consent when required by the regulations on cookies. ",
  "text7-2":
    "Cookies are pieces of information placed on the user’s or client’s terminal by the server of the website visited. Cookies may be implemented by a website to send information to the browser of the user and to allow the mentioned browser to send information back to the original website.",
  "text7-3":
    "It needs to be specified that only the sender of a cookie can read or modify the information contained in it.",
  "text7-4":
    `As with most websites that operate based on cookies, there are different types of cookies:
    •	Session Cookies: these are cookies that will disappear the moment you leave the website;
    •	Permanent Cookies: these are cookies that will remain on your terminal until their lifetime expires or until you delete them from your terminal with any tools that might be available to you.
    `,
  "text7-5":
    "The users and players at XbitPlay will be informed that during their visit to the website and use of services, cookies may be installed on their terminal.",

  "text8-1":
    "XbitPlay has implemented steps and measures in order to ensure the protection and the confidentiality, security and integrity of our users’ personal data against all and any unauthorized access or malicious attempts of data penetration and disclosure, modification, alteration, damage, accidental loss or accidental or illicit destruction.",
  "text8-2":
    "Access to the users’ personal data is restricted to those employees, partners and service providers who need to know the information, and whom are of course upheld with strict security and information protection rules.",
  "text8-3":
    "Nevertheless, XbitPlay is not able to fully guarantee users’ personal data against any loss, destruction or damage. XbitPlay is not held responsible to carry out a safeguard of your data present on your personal space.",
  "text8-4": "As such, it needs to be pointed out that our backup services shall not carry out to make a backup of your data that are present in your personal space.",
  "text8-5": "Additionally, when you are required to choose a password to access certain parts of the XbitPlay website that require you to log in to your personal space, it is your responsibility to choose a secure password and keep it somewhere safe and secure.",
  "text8-6":
    "XbitPlay reserves the right to change the privacy policy that has been set out in this document if and when necessity dictates.",
  "text8-7":
    "When users and players are using the services provided by the XbitPlay website, it is assumed that they have already fully read and accepted our privacy policy.",

  // "text9-1":
  //   "The Company has implemented measures to protect the confidentiality, security and integrity of your personal data, against unauthorised access and disclosure, modification, alteration, damage, accidental loss or accidental or illicit destruction, as well as against any other form of illicit processing or communication to unauthorised persons.",
  // "text9-2":
  //   "Access to personal data is restricted to those employees, partners and service providers who need to know the information, to whom we impose strict security and information protection rules.",
  // "text9-3":
  //   "However, the Company cannot guarantee you against any loss, destruction or damage of your personal data. The Company is not held, or able to carry out a safeguard of your data present on your personal space.",
  // "text9-4":
  //   "Our backup services will not make a backup of your data present in your personal space; consequently, you must use a secondary backup source. In other words, unless it is caused by our negligence or willful misconduct, we take no responsibility for the loss of data.",
  // "text9-5":
  //   "In addition, when you need to choose a password to access certain parts of the Website that require you to log in to your personal space, it is your responsibility to choose a secure password (strong, unique (i.e. not used for another site and/or application, etc.) and to keep it confidential.",

  // "text10-1":
  //   "The Company reserves the right to change this Policy as necessary, as the Services evolves or as required by applicable laws.",
  // "text10-2":
  //   "When using the Company’s Services, the User is deemed to have accepted the terms of the Policy when it is published on our Website.",

  // "text11-1": "The policy is governed and interpreted according to UK law.",
  // "text11-2":
  //   "Unless otherwise provided by the law. the Policy will subject Users to the exclusive jurisdiction of the UK courts.",
};

export default privacyPolicyEn;
