import { Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { t } from "locales";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Single from "./Single";
import { useGetCompettions } from "_hooks/Investment/competitions";

const Index = () => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const wizard = useSelector((state) => state.auth?.wizard);
  const user = useSelector((state) => state.auth?.user);
  const [page, setPage] = useState(1);
  const { isLoading: loading, data } = useGetCompettions(page);

  const navigate = useNavigate();
  useEffect(() => {
    const cryptoWizardStep = wizard?.CRYPTO;

    if (user && !cryptoWizardStep) {
      navigate("/investment/wizard/league");
    }

    if (cryptoWizardStep === 1) {
      navigate("/investment/wizard/league");
    } else if (cryptoWizardStep === 2) {
      navigate("/investment/wizard/player");
    }
  }, []);

  const onPageChange = (_, value) => setPage(value);

  const { list = [], total = 1 } = data || {};

  return (
    <Box
      className="container"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
        mt: "4rem",
        pb: 5,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "500", fontSize: { xs: "26px", sm: "24px" } }}
        >
          {t("events")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {loading
          ? Array(5)
              .fill({})
              .map((_, i) => (
                <Box key={i}>
                  <SkeletonLoader width="100%" height="170" isLight={isLight} />
                  <SkeletonLoader width="100%" height="60" isLight={isLight} />
                </Box>
              ))
          : list.map((item) => <Single key={item.id} {...item} />)}
      </Box>
      <Box sx={{ mx: "auto" }}>
        <Pagination
          sx={{ width: "fit-content" }}
          count={total}
          page={page}
          onChange={onPageChange}
        />
      </Box>
    </Box>
  );
};

export default Index;
