import SingleDeck from "./SingleDeck";
import Slider from "react-slick";
import FadeIn from "react-fade-in";

const SmallFields = ({ cards, showCard, showedCards }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    // centerMode: true,
  };

  return (
    <Slider {...settings}>
      {cards.map((item, i) => (
        <FadeIn
          key={item.coin || i}
          transitionDuration={500}
          delay={300 * (i + 1)}
        >
          <SingleDeck
            showCard={showCard}
            item={item}
            showedCards={showedCards}
          />
        </FadeIn>
      ))}
    </Slider>
  );
};

export default SmallFields;
