import React from "react";
import GlobalStyle from "./useAuthLayoutStyle";

const AuthLayout = (props) => {
  const { children } = props;

  return (
    <>
      <GlobalStyle />
      <div>{children}</div>
    </>
  );
};

export default AuthLayout;
