import { Route, Routes } from "react-router";
import { PrivateRoute } from "./CustomRoutes";
import ExchangesList from "routes/Exchanges/List";
import ExchangesDetails from "routes/Exchanges/Details";

export const exchangesRoutes = [
  {
    path: "/exchanges",
    component: ExchangesList,
    exact: true,
  },
  {
    path: "/exchanges/:exchangeName",
    component: ExchangesDetails,
    exact: true,
  },
];

export const ExchangesRoutes = () => (
  <Routes>
    {exchangesRoutes.map(({ exact, path, component: Component }, i) => {
      return (
        <Route key={i} exact={exact} path={path} element={<Component />} />
      );
    })}
  </Routes>
);
