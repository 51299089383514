import { Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";
import { privaceyPolicyContent } from "./privacyPolicy.config";

const PrivacyPolicy = () => {
  const lang = useSelector((state) => state.setting.lang);

  const headerContent = privaceyPolicyContent.HEADER;
  const bodyContent =
    privaceyPolicyContent.BODY[lang] || privaceyPolicyContent.BODY.en;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", my: 5 }}
      className="container"
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t(headerContent.TITLE)}
        </Typography>
        {/* <Typography variant="body1">{t(headerContent.DESC)}</Typography> */}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {bodyContent?.map?.(({ TITLE, ITEMS }) => (
          <Box
            sx={{
              bgcolor: (theme) => `card.${theme.palette.mode}`,
              p: "20px 10px",
              borderRadius: "5px",
              li: {
                mb: 1,
                listStyleType: "square",
              },
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              {t(TITLE)}
            </Typography>
            <ul>
              {ITEMS?.map?.((item) => (
                <li>
                  <Typography variant="body2">
                    {t(item)
                      .split("\n")
                      .map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
