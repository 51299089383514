import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { inputAutoFillStyles, rtlTextFieldStyle } from "assets/styles/styles";
import CustomButton from "components/CustomButton";
import { openToast } from "components/Toast";
import { t } from "locales";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { contactFormValidation } from "validations/contactValidation";

const DEPARTMENT = [
  { label: "finance", value: "finance" },
  { label: "support", value: "support" },
  { label: "nftMarketplace", value: "nftMarketplace" },
];

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "", subject: "", description: "", department: "" },
    resolver: yupResolver(contactFormValidation),
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      setTimeout(() => {
        openToast("success", "contactRequestSubmitted");
        reset();
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box className="container">
      <Box sx={{ maxWidth: "700px", py: 5 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {t("contactHeader")}
          </Typography>
        </Box>
        <Box onSubmit={handleSubmit(onSubmit)} as="form">
          <Box>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  error={errors?.email}
                  helperText={t(errors?.email?.message)}
                  margin="none"
                  fullWidth
                  name="email"
                  label={t("contactEmailLabel")}
                  type="email"
                  id="email"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={[
                    { ...inputAutoFillStyles, mb: 3 },
                    (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                  ]}
                />
              )}
            />
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel id="department-label">
                {t("contactDepartmentLabel")}
              </InputLabel>
              <Controller
                control={control}
                name="department"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    error={errors?.department}
                    margin="none"
                    labelId="department-label"
                    fullWidth
                    value={value}
                    label={t("contactDepartmentLabel")}
                    onChange={onChange}
                  >
                    {DEPARTMENT.map(({ label, value }) => (
                      <MenuItem value={value}>{t(label)}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.department && (
                <FormHelperText
                  sx={[
                    { color: "#f44336" },
                    (theme) =>
                      theme.direction === "rtl" && {
                        textAlign: "right",
                        direction: "rtl",
                      },
                  ]}
                >
                  {t(errors?.department?.message)}
                </FormHelperText>
              )}
            </FormControl>
            <Controller
              control={control}
              name="subject"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  error={errors?.subject}
                  helperText={t(errors?.subject?.message)}
                  margin="none"
                  fullWidth
                  name="subject"
                  label={t("contactSubjectLabel")}
                  type="text"
                  id="subject"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={[
                    { ...inputAutoFillStyles, mb: 3 },
                    (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                  ]}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  multiline
                  minRows={4}
                  error={errors?.description}
                  helperText={t(errors?.description?.message)}
                  margin="none"
                  fullWidth
                  name="description"
                  label={t("contactDescriptionLabel")}
                  type="text"
                  id="description"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={[
                    { ...inputAutoFillStyles, mb: 3 },
                    (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                    { mb: 0 },
                  ]}
                />
              )}
            />{" "}
            <Typography variant="body2" sx={{ mb: 3, mt: 1 }}>
              {t("contactHelperText")}
            </Typography>
          </Box>

          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isSubmitting || loading}
            disabled={isSubmitting}
            loaderHeight="40"
            extraSx={{ py: "8px", fontSize: "18px", mt: 2 }}
          >
            {t("submit")}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
