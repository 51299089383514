import { Box, Typography, Avatar, Link } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ArticleLikeButton from "routes/Community/components/ArticleLikeButton";

const Article = ({ article }) => {
  const avatar =
    article.profile.avatar.length > 0
      ? article.profile.avatar[0].location
      : "./broken.jpg";
  const name = article.profile.name;
  const username = article.profile.slug;
  const authorId = article.profile.userId;

  const title = article.title;
  const media = article.image.length > 0 ? article.image[0].location : null;

  const content = article.content.substr(0, 75) + "...";

  const SquareDivider = () => {
    return <SquareIcon sx={{ fontSize: "8px", color: "#D9D9D9" }} />;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{ width: "40%", alignSelf: "center", "& img": { width: "100%" } }}
      >
        <img src={media} alt={title} />
      </Box>
      <Box
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          // p: 2,
          gap: 1,
        }}
      >
        <Link
          sx={{ display: "flex", alignItems: "center" }}
          href={"/community/users/" + authorId}
          underline="none"
          color="textPrimary"
        >
          <Avatar
            alt={name}
            src={avatar}
            sx={{
              mr: 1,
              bgcolor: "secondary.main",
              float: "left",
              cursor: "pointer",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {name}
            </Typography>
            <Typography variant="body2" sx={{ color: "gray" }}>
              {username && (
                <>
                  <Typography variant="body2" component="span">
                    &#x2022;
                  </Typography>
                  <Typography variant="body2" component="span">
                    @{username}
                  </Typography>
                </>
              )}
            </Typography>
          </Box>
        </Link>
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
        <Typography sx={{ color: "gray" }}>{content}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            alignItems: "center",
            maxHeight: "35px",
            gap: 1,
            width: "fit-content",
            justifyContent: "space-around",
          }}
        >
          <Box
            component="span"
            sx={{ display: "flex", alignItems: "center", fontSize: "10px" }}
          >
            <RemoveRedEyeOutlinedIcon sx={{ mr: "5px", fontSize: "18px" }} />
            {article.viewCount}
          </Box>

          <SquareDivider />

          <ArticleLikeButton article={article} />
        </Box>
      </Box>
    </Box>
  );
};

export default Article;
