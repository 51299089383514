import { TuneRounded } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { stringQueriesToObj } from "library/helper";
import { t } from "locales";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetCardTypeSelectors } from "_hooks/Shared/market";
import Filters from "../Filters";

const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState(searchParams.get("type"));
  const modal = useSelector((state) => state.app.modal);
  const isLight = useSelector((state) => state.setting.theme === "light");
  const {
    data: cardTypes,
    isLoading: cardTypesLoading,
    isError,
  } = useGetCardTypeSelectors();
  const searchParamTypeValue = searchParams.get("type");

  useEffect(() => {
    if (!searchParamTypeValue) setSelected(null);
  }, [searchParamTypeValue]);

  useEffect(() => {
    if (!!selected) {
      const queries = { ...stringQueriesToObj(searchParams.toString() ?? "") };
      setSearchParams({ ...queries, type: selected });
    } else {
      const queries = {
        ...stringQueriesToObj(searchParams.toString() ?? "", "type"),
      };
      setSearchParams({ ...queries });
    }
  }, [selected, searchParams, setSearchParams]);

  const openFilters = () => modal.show(<Filters />);
  const typeChangeHandler = (value) =>
    value === selected ? setSelected(null) : setSelected(value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        alignItems: { xs: "center", md: "" },
        gap: { xs: 4, md: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexWrap: "wrap",
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        {!isError &&
          (cardTypesLoading
            ? Array(4)
                .fill({})
                .map((_, i) => (
                  <SkeletonLoader
                    key={i}
                    isLight={isLight}
                    borderRadius="4"
                    width="110"
                    height="40"
                  />
                ))
            : cardTypes.map(
                ({ status, id, title, color }) =>
                  status === "ACTIVE" && (
                    <ButtonBase
                      key={id}
                      onClick={() => typeChangeHandler(id)}
                      sx={[
                        {
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "7px 12px",
                          border: (theme) =>
                            theme.palette.mode === "dark"
                              ? "1px solid #2a2e34"
                              : "1px solid #eee",
                          borderRadius: "7px",
                          gap: 1.2,
                        },
                        selected === id && {
                          bgcolor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "primary.dark"
                              : "primary.light",
                          borderColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "primary.dark"
                              : "primary.light",
                        },
                      ]}
                    >
                      <Box
                        sx={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "50%",
                          bgcolor: color,
                        }}
                      ></Box>
                      <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                        {t(title)}
                      </Typography>
                    </ButtonBase>
                  )
              ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.5,
          marginLeft: "auto",
          width: { xs: "100%", md: "fit-content" },
          alignSelf: { md: "flex-start" },
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        <ButtonBase
          onClick={openFilters}
          sx={{
            padding: "7px 20px",
            border: (theme) =>
              theme.palette.mode === "dark"
                ? "1px solid #2a2e34"
                : "1px solid #eee",
            borderRadius: "7px",
            display: { xs: "flex", md: "none" },
            gap: 2,
          }}
        >
          <TuneRounded />
          <Typography variant="body1">{t("showFilters")}</Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default Header;
