import { Box } from "@mui/system";
import { useState } from "react";
import moment from "jalali-moment";
import { CircularProgress, Pagination, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "components/NotificationModal";
import { t } from "locales";
import {
  useGetAllNotifications,
  useReadAllNotifications,
} from "_hooks/User/queries";
import { readAllNotifications } from "redux/auth/authSlice";

// STATUS === FALSE -> NOT READ YET
// STATUS === TRUE -> ALREADY READ

function Notification() {
  const modal = useSelector((state) => state.app.modal);
  const [page, setPage] = useState(1);
  const { data, isLoading: loading } = useGetAllNotifications(page);
  const { mutate: readAllNotificationsRequest } = useReadAllNotifications();
  const dispatch = useDispatch();

  const clearAll = () => {
    if (!list?.length) return;

    readAllNotificationsRequest(null, {
      onSuccess: () => dispatch(readAllNotifications()),
    });
  };
  const onPageChange = (_, value) => setPage(value);
  const openSingleNotification = (item) => {
    modal.show(<NotificationModal item={item} onClose={modal.hide} />);
  };

  const { list = [], total = 1 } = data || {};

  return (
    <>
      <Box
        sx={{
          p: "20px",
          borderRadius: "8px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 1, sm: 0 },
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "500" }}>
            {t("notificationRecords")}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? "primary.light"
                  : "primary.dark",
              cursor: "pointer",
            }}
            onClick={clearAll}
          >
            {t("markAllAsRead")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              list.map((notif) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      cursor: "pointer",
                      py: 2,
                      "&:not(:last-child)": {
                        borderBottom: "1px solid",
                        borderColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? "rgba(47, 50, 65, 0.8)"
                            : "#ccc",
                      },
                    }}
                    key={notif.id}
                    onClick={() => openSingleNotification(notif)}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={[
                          {
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "rgba(47, 50, 65, 0.8)"
                                : "#ccc",
                            marginTop: "6px",
                          },
                          !notif?.status && {
                            backgroundColor: "primary.light",
                          },
                        ]}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: "1",
                          padding: "0 1rem",
                          maxWidth: "600px",
                          "& > *": {
                            m: 0,
                          },
                        }}
                      >
                        <Typography variant="body1">{notif?.title}</Typography>
                        {notif?.description && (
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "500px",
                            }}
                          >
                            {notif?.description}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        ml: "auto",
                      }}
                    >
                      <Typography variant="body2" sx={{ opacity: ".8" }}>
                        {moment(notif.createdAt)
                          .locale("en")
                          .format("YYYY-MM-DD HH:mm")}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            )}
          </Box>
          {list.length === 0 && !loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                my: 2,
                "& > *": {
                  width: "fit-content",
                },
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "500" }}>
                {t("noNotification")}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                sx={{ width: "fit-content" }}
                count={total}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
export default Notification;
