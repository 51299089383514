import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

const Chart = ({ team, activeCard }) => {
  const theme = useSelector((state) => state.setting.theme);

  const getCoin = () => {
    const card = team?.find?.((c) => c?.id === activeCard);

    const coin = card?.card?.PD_asset?.coin || "BTC";

    return coin + 'USD';
  };

  return (
    <Box
      sx={{
        height: "100%",
        "html.theme-dark #widget-container": { border: "none" },
      }}
    >
      <TradingViewWidget
        symbol={getCoin()}
        theme={Themes?.[theme === "dark" ? "DARK" : "LIGHT"]}
        hide_side_toolbar={false}
        allow_symbol_change={false}
        withdateranges
        autosize
        isTransparent
      />
    </Box>
  );
};

export default Chart;
