const buyVlx = {
  iWantToSpend: "Harcamak İstiyorum",
  iWantToRecieve: "Almak İstiyorum",
  moreDetail: "Daha fazla ayrıntı",
  spendAmount: "Harcama tutarı",
  receivedAmount: "Alınan miktar",
  arrivalAmount: "Varış miktarı",
  deductedAmount: "Kesilen tutar",
  transactionFee: "İşlem ücreti",
  paymentMethods: "Ödeme yöntemleri",
  confirm: "Onayla",
  noGateway: "Ağ geçidi bulunamadı!",
  buyVlxAmountError: "Lütfen geçerli bir değer girin!",
  paymentPartner: "Ödeme Ortağı",
  referencePrice: "Referans Fiyat",
  singleOrder: "Tek Sipariş",
  type: "Tür",
  amount: "Tutar",
  currency: "Para Birimi",
  gateway: "Ağ geçidi",
  status: "Durum",
  traceNumber: "İz Numarası",
  paymentHistory: "Ödeme Geçmişi",

  unsuccessPaymentMessage: "Bir şeyler ters gitti. Lütfen tekrar deneyin",
  successPaymentMessage: "Ödemeniz başarılı oldu",
  paymentResult: "Ödeme Sonucu",
};

export default buyVlx;
