export const termsAndConditionContent = {
  header: { title: "headerTitle", desc: "headerDesc" },
  body: {
    en: [
      {
        title: "article1",
        items: [
          {
            // title: "1.1.1",
            list: ["1.1.1", "1.1.2"],
          },
        ],
      },
      {
        title: "article2",
        items: [
          {
            list: [
              "2.1.1",
              "2.1.2",
              "2.1.3",
              "2.1.4",
              "2.1.5",
              "2.1.6",
              "2.1.7",
              "2.1.8",
              "2.1.9",
            ],
          },
        ],
      },
      {
        title: "article3",
        items: [{ list: ["3.1.1", "3.1.2", "3.1.3", "3.1.4", "3.1.5"] }],
      },
      {
        title: "article4",
        items: [{ list: ["4.1.1", "4.1.2"] }],
      },
      {
        title: "article5",
        items: [{ list: ["5.1.1", "5.1.2"] }],
      },
      {
        title: "article6",
        items: [{ list: ["6.1.1", "6.1.2", "6.1.3"] }],
      },
      {
        title: "article7",
        items: [{ list: ["7.1.1"] }],
      },
      {
        title: "article8",
        items: [
          {
            list: ["8.1.1", "8.1.2", "8.1.3"],
          },
        ],
      },
      {
        title: "article9",
        items: [{ list: ["9.1.1", "9.1.2"] }],
      },
      {
        title: "article10",
        items: [{ list: ["10.1.1", "10.1.2", "10.1.3"] }],
      },
      {
        title: "article11",
        items: [{ list: ["11.1.1", "11.1.2", "11.1.3"] }],
      },
    ],
  },
};
