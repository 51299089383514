import { Box } from "@mui/system";
import { t } from "locales";
import Instruction from "./Instruction"
import { Typography } from "@mui/material";

const MarketBody = () => {
  return (
    <Box
      className="container"
      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
    >
          <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: "20px",
        borderRadius: "8px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="h6">Deposit</Typography>
      </Box>
      <Instruction />
    </Box>
    </Box>
  );
};

export default MarketBody;
