const mainTr = {
  brandName: "Volex",
  home: "Ana sayfa",
  howToPlay: "Nasıl Oynanır",
  nftMarketplace: "NFT Pazar yeri",
  community: "Mesajlar",
  leaderboard: "Lider tahtası",
  games: "Oyunlar",
  buyVLX: "VLX satın al",
  support: "Destek",
  dashboard: "Gösterge Tablosu",
  setting: "Ayar",
  logout: "Oturumu Kapat",
  profile: "Profil",
  somethingWentWrong: "Bir şeyler ters gitti!",
  loading: "Yükleniyor",
  noData: "Veri Yok",

  mainPageTag: "NFT OYUN PLATFORMU",
  mainPageHeader: "Xbit Play",
  mainPageDesc1: "GERÇEK DÜNYA DENEYİMİ",
  mainPageDesc2: "FANTAZİ DÜNYA STRATEJİLERİ",
  mainPageBtn: "Yolculuğa başlayın",

  feedTitle: "OYUN Besleme",
  feedDesc: "Volex'te yeni ne var ?",
  feedBtn: "NASIL OYNANIR ?",
  newPackTitle: "Yeni Paketler",
  newPackDesc: "Yeni Kartlar Açıklandı",
  tradeCardsTitle: "Topla ve Ticaret Yap",
  tradeCardsDesc: "NFT Koleksiyon Dijital Kartları",
  bannersTitle: "Bu yılki tüm yeni oyunlar",
  bannersDesc:
    "Ağrı Acı ile açgözlülük arasında büyük bir fark vardır ve tüm ağrılar yüzünden acı doğar!",
  bannersBtn: "Daha fazla bilgi edinin",
};

export default mainTr;
