import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  FullscreenRounded,
  FullscreenExitRounded,
  VolumeUpRounded,
  VolumeOffRounded,
} from "@mui/icons-material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const fullscreenEnabled =
  document.fullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.documentElement.webkitRequestFullScreen;

const Penalty = () => {
  const accessToken = useSelector((state) => state.auth?.accessToken);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const fullscreen = useFullScreenHandle();

  const onFullscreenChange = () => {
    if (fullscreen.active) fullscreen.exit();
    else fullscreen.enter();
  };

  const onSoundChange = () => {
    setIsSoundOn((prev) => !prev);
  };

  return (
    <FullScreen handle={fullscreen}>
      <Box
        sx={{
          width: "100%",
          height: fullscreen?.active ? "100vh" : "calc(100vh - 64px)",
          position: "relative",
          iframe: { border: "none" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            bgcolor: "card.light",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            p: "5px 10px",
            borderRadius: "7px",
            svg: { color: "#333" },
            "& > div": {
              display: "flex",
              cursor: "pointer",
            },
          }}
        >
          {fullscreenEnabled && (
            <Box onClick={onFullscreenChange}>
              {fullscreen?.active ? (
                <FullscreenExitRounded fontSize="large" />
              ) : (
                <FullscreenRounded fontSize="large" />
              )}
            </Box>
          )}
          {/* <Box onClick={onSoundChange}>
            {isSoundOn ? (
              <VolumeUpRounded fontSize="large" />
            ) : (
              <VolumeOffRounded fontSize="large" />
            )}
          </Box> */}
        </Box>
        <iframe
          width="100%"
          height="100%"
          title="penalty-game"
          src={`https://penalty.algobotai.com/?token=${accessToken}`}
        />
      </Box>
    </FullScreen>
  );
};

export default Penalty;
