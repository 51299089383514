import { Close, DownloadRounded, UploadRounded } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MAIN_COIN } from "library/constants";
import { truncateToDecimals } from "library/helper";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const actions = [
  { title: "deposit", icon: DownloadRounded, content: null, link: "/deposit" },
  { title: "withdraw", icon: UploadRounded, content: null, link: "/withdraw" },
];

const FinanceModal = ({ onClose }) => {
  const modal = useSelector((state) => state.app.modal);
  const wallets = useSelector((state) => state.auth.wallets);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: { xs: "95vw", sm: "60vw", lg: "40vw", xl: "500px" },
        maxWidth: "500px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("wallet")}</Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          img: {
            width: "25px",
            height: "25px",
            borderRadius: "50%",
          },
          "& > div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",

            "& > div": {
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
          },
          "& p": {
            fontWeight: "500",
          },
          "& span": {
            opacity: ".8",
          },
        }}
      >
        <Box>
          <Box>
            {" "}
            <img
              src={wallets?.[MAIN_COIN]?.icon}
              alt={wallets?.[MAIN_COIN]?.coin}
            />
            <Typography variant="body1">
              {wallets?.[MAIN_COIN]?.name}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body1">
              {wallets?.[MAIN_COIN]?.amount || 0}
            </Typography>
            <Typography component="span" variant="body1">
              {MAIN_COIN}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <img src={wallets?.VLX?.icon} alt={wallets?.VLX?.coin} />
            <Typography variant="body1">{wallets?.VLX?.name}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              {wallets?.VLX?.amount
                ? truncateToDecimals(wallets?.VLX?.amount, 2)
                : 0}{" "}
            </Typography>
            <Typography component="span" variant="body1">
              XBT
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {actions.map(({ title, icon: Icon, content: Content, link }) => (
          <Box
            onClick={() => {
              Content
                ? modal?.show(<Content onClose={onClose} />)
                : navigate(link);
              onClose?.();
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
              p: "10px",
              textDecoration: "none",
            }}
          >
            <Icon fontSize="large" sx={{ color: "primary.light" }} />
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#111",
              }}
            >
              {t(title)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FinanceModal;
