import { Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useSearchParams, Link } from "react-router-dom";
import ListItem from "./ListItem";
import { SkeletonLoader } from "components/Skeleton";
import { useSelector } from "react-redux";
import cardsImg from "assets/img/cards.png";
import { useCheckCardsForCompetition } from "_hooks/Shared/competitions";

const List = ({ data, status, loading = false, competitionId }) => {
  const [searchParams] = useSearchParams();
  const isLight = useSelector((state) => state.setting.theme === "light");
  const { data: checkData, isLoading: checkLoading } =
    useCheckCardsForCompetition({
      id: searchParams.get("type"),
      type: "PREDICTION",
      minimumCards: 3,
      status,
    });

  const type = data?.find((item) => item?.id === searchParams.get("type"));
  const hasTeam = type?.leagues?.find?.((item) => item.isExist);
  const isLoading = loading || checkLoading;

  return (
    <Box
      sx={{
        padding: "10px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {isLoading ? (
        <Grid container spacing={isLoading ? 2 : 1.5}>
          {Array(2)
            .fill({})
            .map((_, i) => (
              <Grid key={i} item xs={12} md={6}>
                <SkeletonLoader
                  isLight={isLight}
                  width="100%"
                  height="200"
                  borderRadius="10"
                />
              </Grid>
            ))}
        </Grid>
      ) : checkData?.allowToCompete || status !== "OPEN" || hasTeam ? (
        <Grid container spacing={isLoading ? 2 : 1.5}>
          {type?.leagues.map((item) => (
            <Grid key={item.id} item xs={12} md={6}>
              <ListItem
                {...item}
                status={status}
                competitionId={competitionId}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
            py: 4,
            img: {
              width: "80px",
              height: "auto",
              objectFit: "contain",
            },
          }}
        >
          <img src={cardsImg} alt="" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {`You need 5 ${type?.title} cards to unlock this championship`}
            </Typography>
            <Typography variant="body1" sx={{ opacity: ".8" }}>
              {`You have ${checkData?.requirement} ${type?.title} Cards`}
            </Typography>
          </Box>
          <Button
            LinkComponent={Link}
            to="/crypto-wars/market"
            variant="contained"
            color="primary"
            sx={{ p: "12px 16px", borderRadius: "5px" }}
          >
            Get {type?.title} cards
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default List;
