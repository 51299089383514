import { ArrowLeft, Person } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import { Link, useParams } from "react-router-dom";

const Header = ({ returnToAllPrize, id, title, participants, image }) => {
  const { competitionId } = useParams();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "10px 20px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
        gap: 4,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <ButtonBase
          onClick={returnToAllPrize}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
          }}
        >
          <ArrowLeft />
        </ButtonBase>
        <Typography variant="body1">{t("prize")}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          img: {
            width: "40px",
            height: "40px",
            objectFit: "cover",
            borderRadius: "8px",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <img src={getImageUrl(image)} alt="" />
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Person />
            <Typography variant="body1">{participants}</Typography>
          </Box>
          <ButtonBase
            as={Link}
            to={`/crypto-wars/play/${competitionId}/competitions`}
            sx={{
              p: "4px 12px",
              borderRadius: "7px",
              bgcolor: "primary.main",
              color: "common.white",
            }}
          >
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: "bold" }}
            >
              {t("register")}
            </Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
