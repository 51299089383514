import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import SingleCard from "./SingleCard";

const Index = ({ cards }) => {
  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="center">
        {cards?.map((item) => (
          // <Grid key={item?.id} item xs={12} sm={3} md={4} lg={3} xl={3}>
          <SingleCard key={item.id} {...item} />
          // </Grid>
        ))}
      </Stack>
      {/* <Grid container></Grid> */}
    </Box>
  );
};

export default Index;
