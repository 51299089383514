import authTr from "./auth";
import mainTr from "./main-tr";
import validationTr from "./validation";
import soccerTr from "./soccer";
import gameTr from "./games";
import cryptoWarsTr from "./crypto-wars";
import userTr from "./user";
import marketTr from "./market";
import errorsTr from "./errors";
import buyVlx from "./buy-vlx";
import assetsTr from "./assets";
import howToPlayTr from "./howToPlay";
import contactTr from "./contact";
import inviteTr from "./invite";
import privacyPolicyTr from "./privacyPolicy";
import termsAndConditionTr from "./termsAndCondition";

const tr = {
  ...mainTr,
  ...authTr,
  ...validationTr,
  ...soccerTr,
  ...gameTr,
  ...cryptoWarsTr,
  ...userTr,
  ...marketTr,
  ...errorsTr,
  ...buyVlx,
  ...assetsTr,
  ...howToPlayTr,
  ...contactTr,
  ...inviteTr,
  ...privacyPolicyTr,
  ...termsAndConditionTr,
};

export default tr;
