import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { setLang } from "redux/setting/settingSlice";
import { useDispatch, useSelector } from "react-redux";

const LanguageModal = () => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.app);
  const { lang: activeLang, supportedLangs } = useSelector(
    (state) => state.setting
  );

  const changeLanguage = (lang) => {
    dispatch(setLang(lang));
    modal.hide();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        gap: { xs: 3, sm: "3rem" },
        width: { xs: "90vw", sm: "500px" },
        padding: "2rem 3rem",
      }}
    >
      {supportedLangs?.map(({ title, font, key }) => (
        <Typography
          onClick={() => changeLanguage(key)}
          variant="body1"
          key={key}
          sx={[
            { cursor: "pointer", fontFamily: font || "en" },
            activeLang === key ? { fontWeight: "bold" } : { opacity: ".8" },
          ]}
        >
          {title}
        </Typography>
      ))}
    </Box>
  );
};

export default LanguageModal;
