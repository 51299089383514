import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
// import allStarImg from "assets/img/all_star-limited.png";
import stars from "assets/img/stars.png";
import rewardImg from "assets/img/reward.png";
import { MAIN_COIN } from "library/constants";
import { t } from "locales";
import { useState } from "react";

const rewards = [
  {
    id: "1st",
    image: rewardImg,
    card: "1x star rare",
    value: `1,713.23 ${MAIN_COIN}`,
  },
  {
    id: "2nd",
    image: rewardImg,
    card: "1x star rare",
    value: `1,027.94 ${MAIN_COIN}`,
  },
  {
    id: "3rd",
    image: rewardImg,
    card: "1x tier-1 rare",
    value: `685.29 ${MAIN_COIN}`,
  },
];

const moreRewards = [
  {
    id: "4th to 13th",
    image: rewardImg,
    card: "1x tier-2 rare",
  },
  {
    id: "14th to 33rd",
    image: rewardImg,
    card: "1x tier-3 rare",
  },
  {
    id: "34th to 130th",
    image: rewardImg,
    card: "1x tier-3 rare",
  },
];

const Rewards = ({ goNextStep }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: {
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "500" }}>
          {t("leagueRewardsTitle")}
        </Typography>
        <Typography variant="body1">{t("leagueRewardsDesc")}</Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90vw", md: "500px" },
          mx: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            pb: "10px",
            borderRadius: "8px",
            img: {
              borderRadius: "8px",
              width: "35px",
              height: "35px",
            },
            "& > *:not(:first-child)": {
              borderTop: "1px solid",
              borderColor: (theme) =>
                theme.palette.mode === "dark" ? "border.dark" : "border.light",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              p: "20px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              Prize pool
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <img src={stars} alt="" />
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                All Star
              </Typography>
            </Box>
          </Box>
          {rewards.map(({ name, image, card, value, id, hint }, i) => (
            <Box key={i} sx={{ p: "15px 20px", position: "relative" }}>
              <Box
                key={id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" sx={{}}>
                  {id}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    img: {
                      borderRadius: "2px",
                      width: "30px",
                      height: "50px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="body2" sx={{}}>
                      {card}
                    </Typography>
                    <Typography variant="body1" sx={{}}>
                      {value}
                    </Typography>
                  </Box>
                  <img src={image} alt="" />
                </Box>
              </Box>
            </Box>
          ))}
          {showMore &&
            moreRewards.map(({ name, image, card, value, id, hint }, i) => (
              <Box key={i} sx={{ p: "15px 20px", position: "relative" }}>
                {i === 0 && (
                  <Typography
                    variant="body2"
                    sx={{ opacity: ".7", pb: "15px", pt: "8px" }}
                  >
                    Participation
                  </Typography>
                )}
                <Box
                  key={id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" sx={{}}>
                    {id}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      img: {
                        borderRadius: "2px",
                        width: "30px",
                        height: "50px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="body2" sx={{}}>
                        {card}
                      </Typography>
                      <Typography variant="body1" sx={{}}>
                        {value}
                      </Typography>
                    </Box>
                    <img src={image} alt="" />
                  </Box>
                </Box>
              </Box>
            ))}
          <Typography
            onClick={() => setShowMore((prev) => !prev)}
            variant="body2"
            sx={{
              color: "primary.main",
              width: "fit-content",
              cursor: "pointer",
              p: "5px 20px",
              borderTop: "none !important",
            }}
          >
            {showMore ? t("viewLess") : t("viewMore")}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={goNextStep}
        sx={(theme) => ({
          backgroundImage: theme.palette.gradient.purpule,
          color: theme.palette.common.white,
          width: "fit-content",
          padding: "10px 30px",
          fontSize: "1rem",
          mx: "auto",
        })}
      >
        {t("leagueRewardsBtn")}
      </Button>
    </Box>
  );
};

export default Rewards;
