const cryptoWarsEn = {
  "crypto-card1": "Select",
  "crypto-card2": "Select",
  "crypto-card3": "Select",
  createTeamBtn: "Submit cards",
  events: "Events",
  topCoins: "Top Coins",
  sumOfPercentShouldBe100:
    "The sum of the selected percentages must be equal to 100",
};

export default cryptoWarsEn;
