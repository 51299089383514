const inviteEn = {
  inviteFirstHeader: "Invite a friend to XbitPlay",
  inviteSecondHeader: "You'll both get a free card",
  inviteDesc:
    "Know someone passionate about football? When your friend collects 5 new cards on auction, you'll both win a free",
  inviteModalDesc: "Invite your friends and get a free card.",
  inviteModalCodeTitle: "Invitation code",
  user: "User",
  cardNumbers: "Card numbers",
  COMPELETED: "Completed",
  COMPLETED: "Completed",
  EXPIRED: "Expired",
  IN_PROGRESS: "In progress",
  noInvitedFriend: "Nothing found",
  inviteSidebarHeader: "How it works?",
  levelOnePrizeTitle: "If your friend buy 5 cards you win",
  milestoneInviteTitle: "Milestones",
  milestoneInviteCondition: "30TH FRIEND",
  completedReferrals: "Completed referrals",
  friendsInvited: "Friends invited",
  inviteHistoryTitle: "Invitations sent",
  inviteFriendsNavLink: "Invite friends",
};

export default inviteEn;
