import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import React from "react";

const Sidebar = ({ prizes = {} }) => {
  return (
    <Box
      sx={{
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        p: "20px",
        borderRadius: "5px",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h5">{t("inviteSidebarHeader")}</Typography>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            {t("levelOnePrizeTitle")}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                bgcolor: prizes?.levelOneCardType?.color,
              }}
            ></Box>
            <Typography variant="body1">
              1 {prizes?.levelOneCardType?.title}{" "}
              {prizes?.levelOneCardTire?.title}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            {t("milestoneInviteTitle")}
          </Typography>
          <Typography variant="body2">
            {t("milestoneInviteCondition")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                bgcolor: prizes?.levelTwoCardType?.color,
              }}
            ></Box>
            <Typography variant="body1">
              1 {prizes?.levelTwoCardType?.title}{" "}
              {prizes?.levelOneCardTire?.title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
