import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import SingleItem from "./SingleItem";

const Loader = ({ isLight, ...props }) => {
  const [itemHeight, setItemHeight] = useState(110);

  let bgColor = {};
  if (isLight) {
    // bgColor = { backgroundColor: "#E9ECEF", foregroundColor: "#ecebe2" };
    bgColor = { backgroundColor: "#ccc", foregroundColor: "#E9ECEF" };
  } else {
    bgColor = { backgroundColor: "#22232D", foregroundColor: "#252733" };
  }

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth < 786) {
        setItemHeight(310);
      } else {
        setItemHeight(110);
      }
    };

    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <ContentLoader
      speed={3}
      interval={0}
      width="100%"
      height={5 * itemHeight + 80}
      // viewBox="0 0 100% 830"
      {...bgColor}
      {...props}
    >
      {Array(3)
        .fill(undefined)
        .map((x, i) => {
          return (
            <rect
              key={i}
              x="0"
              y={i * (itemHeight + 20)}
              rx="12"
              ry="12"
              width="100%"
              height={itemHeight}
            />
          );
        })}
    </ContentLoader>
  );
};

const List = ({
  loading,
  data,
  selectedAssets,
  amount,
  inputError,
  setInputError,
}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");

  const renderedList = data?.map((item) => {
    const partnerIcon = getImageUrl(item.icon);

    return (
      <SingleItem
        key={item.id}
        amount={amount}
        paymentTermId={item.id}
        partnerIcon={partnerIcon}
        selectedAssets={selectedAssets}
        inputError={inputError}
        setInputError={setInputError}
        {...item}
      />
    );
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      {loading ? <Loader isLight={isLight} /> : renderedList}
      {!loading && !data?.length && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 3,
          }}
        >
          <Typography variant="h6">{t("noGateway")}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default List;
