import { Typography } from "@mui/material";
import CustomArrowSlider from "components/CustomArrowSlider";
import CardsSlider from "./CardsSlider";
import { useRef } from "react";
import { t } from "locales";
import { Box } from "@mui/system";

const TradeCards = () => {
  const sliderRef = useRef();

  const nextHandler = () => sliderRef.current?.slickNext?.();
  const prevHandler = () => sliderRef.current?.slickPrev?.();

  return (
    <Box
      className="container"
      sx={{
        ".react-multi-carousel-track": { gap: "16px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("tradeCardsTitle")}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            {t("tradeCardsDesc")}
          </Typography>
        </Box>
        <CustomArrowSlider
          nextHandler={nextHandler}
          prevHandler={prevHandler}
        />
      </Box>
      <CardsSlider sliderRef={sliderRef} />
    </Box>
  );
};

export default TradeCards;
