const termsAndConditionEn = {
  headerTitle: "TERMS AND CONDITIONS",
  termsAndConditions: "Terms & conditions",
  headerDesc:
    "VOLEXPLAY online service is made available by Volex Vision Limited company, registered in the Algovision Limited ( UK London )  with registrations code: 13339570  on 59 St. Martin's Lane, Suite 8, London, England, WC2N 4JS. VOLEXPLAY is mainly hosted by Amazon Web Services. Contact : info@volexplay.com",

  article1: "1. The XbitPlay Website",
  article2: "2. User Accounts at XbitPlay",
  article3: "3. Collectibles at XbitPlay",
  article4: "4. Payment",
  article5: "5. Information, Content, and Feedback Related to Users",
  article6: "6. XbitPlay’s Content",
  article7: "7. Services Might Be Modified",
  article8: "8. Prohibited Use",
  article9: "9. Disclaimer",
  article10: "10. Liability",
  article11: "11. Miscellaneous",

  "1.1.1":
    "The services include all products, offerings, games, and features, including the marketplace, offered by XbitPlay through the website. Through the website of XbitPlay, you can acquire, collect, exchange, and play collectible digital cards that are based on the Blockchain technology.",
  "1.1.2":
    "The website of XbitPlay might also contain links or content from services not operated by XbitPlay that provide additional content or features such as payment services, the purchase and sale of digital assets in legal tender, digital asset wallets as well as player data and third-party collectible. The third-party links that might be provided in the website or use of any other third-Party services are not to be construed as a way of endorsement by XbitPlay of such third-party services. Please consult the relevant third-party service’s terms and conditions and/or privacy policy for more information.",

  "2.1.1":
    `XbitPlay is free to use. In order to create an account, you must provide:
    •	a username;
    •	and a valid email address.`,
  "2.1.2":
    "When creating a user account, you must agree to terms and conditions that are set out here in this document, and by doing so you represent and warrant that you (i) are eighteen (18) years old or older; (ii) agree to these terms and conditions; (iii) will use the XbitPlay website in compliance with these terms and any applicable law, rule or regulation.",
  "2.1.3":
    "The XbitPlay website and user accounts are free for individual, non-commercial, personal use. You shall not sign up on behalf of or act in coordination with another individual, group of individuals and/or a company.",
  "2.1.4":
    "While creating a user account, you will be asked to pick a username that will be associated with any purchases and game participation of your user account. You shall not change your username more than once during a three-month period.",
  "2.1.5":
    "You shall not attempt to mislead, confuse and/or defraud other users via elements in your username. You may not include any abusive, harassing, offensive or otherwise malicious language or suggestions in your username.",
  "2.1.6":
    "Since in the process of creating an account at XbitPlay, you will be required to pick a password, please choose a strong password, store your information securely, do not share your account information with third parties and observe other security best practices.",
  "2.1.7":
    "XbitPlay is not liable for the loss or theft of your password or other account information. It is your responsibility to take all measures to secure access to your login and account information, including your password. We strongly encourage you to set up two-factor authentication. You are responsible for the actions of any person using your User account, including without your prior consent.",
  "2.1.8":
    "You may not create more than one user account and/or manage the user account(s) of other users (even if given explicit permission by such users).",
  "2.1.9":
    "You may not create a user account if your user account has been temporarily suspended or if XbitPlay has permanently deleted a user account associated with you in the past.",

  "3.1.1": "Collectibles are unique digital trading cards with a non-fungible token, otherwise known as an NFT, based on the Blockchain technology and issued by XbitPlay. The NFT ensures the scarcity and authenticity of the collectible and the transparency of the services.",
  "3.1.2":
    "Collectibles are classified based on a level of scarcity specified for each issuance. When you purchase a collectible, you become the definitive owner of the underlying NFT, based on the fundamentals of Web 3, and in accordance with the terms and conditions set out here in this document.",
  "3.1.3":
    "You may not, under any circumstances and without XbitPlay’s prior written consent, carry out or attempt to carry out any of the following to the collectibles, whether or not owned by you: (i) use the collectible and any of the third-party collectible elements for commercial purposes, advertising or promotion of a third-party product or service; (ii) market merchandise, physical or digital, that represents the purchased collectible; (iii) alter the image associated with the collectible, and/or alter, edit or modify the third-party collectible elements in any other way; (iv) attempt to claim any additional intellectual property rights relating to the collectible or the third-Party collectible elements.",
  "3.1.4":
    "You can buy a collectible via the auctions on the website offered by XbitPlay. When collectibles are first issued, XbitPlay offers them for sale at auction. You and other users may bid up from the floor price that is initially set. The last, highest bidder acquires the Collectible. There is no reservation price.",
  "3.1.5":
    "In the event of a service interruption or technical problem that is unforeseeable and/or outside of our reasonable control that causes an auction to be inaccessible for any period of time, XbitPlay has the right to extend the auction period or to cancel the auction and relaunch it at a later date.",

  "4.1.1":
    "When a payment is made in relation to the services offered by XbitPlay, your payment will be processed by one of our trusted, third-party service payment providers. In order to process your payment, the relevant provider may ask you for additional information in order to verify your identity or validate the transaction, in compliance with that payment provider’s terms of service and privacy policy.",
  "4.1.2":
    "You fully accept and agree that because of the nature of Blockchain, when a transaction has been processed, the associated transaction data will be irreversibly relating to the relevant collectible on the Blockchain.",

  "5.1.1":
    "Please keep your user account information up to date. Note that pursuant to legal and/or regulatory requirements, we might request more information from time-to-time. We will contact you via your user account or the email associated with your user account if this is the case.",
  "5.1.2":
    "In accordance with the terms and conditions set out in this document, you grant and/or agree to grant to XbitPlay the transferable, free of charge, global right to exclusively use, copy, distribute, adapt, creative derivative works, reproduce, distribute, modify, translate, and otherwise exploit, any content you produce when using the services offered by XbitPlay, by any present or future process and by any means of distribution on the XbitPlay website, social networks, or media (such as Facebook, Twitter, Instagram, Reddit, YouTube, etc.).",

  "6.1.1":
    "The website and services of XbitPlay, including but not limited to its proprietary content, information, design, logos, text, graphics, images, icons, data, software, algorithms and scripts are and will remain the proprietary property of XbitPlay.",
  "6.1.2":
    "In the terms and conditions set out in this document, nothing should be construed as granting you any property, patent, or other right in the proprietary content. You may not copy, imitate, or use, in whole or in part, any of the proprietary content without prior written permission from XbitPlay.",
  "6.1.3":
    "In order to avoid the occurrence of doubt, purchase of a collectible does not confer any ownership or other rights in the proprietary content, third-party collectible elements, content, code, data or other materials or over XbitPlay’s rights.",

  "7.1.1":
    'XbitPlay reserves the right to modify, suspend, or discontinue, in each case temporarily or permanently, part or all of the services, products, offerings, promotions and/or features of the website of XbitPlay, or we may introduce new features and/or services if we have another valid reason. Valid reasons are those that might potentially lead to the improvement of the website or the services, necessary modifications for technical reasons, an increased or decreased number of users, a change in the website. This list of valid reasons can be unilaterally modified by XbitPlay when such modification reflects a mere technical improvement of the website or the services.',

  "8.1.1":
    `XbitPlay wants the website and services to be inclusive places where fans and players and gamers from across the globe can engage with this game and also with each other. In connection with the website of XbitPlay, you shall not:
    - post or otherwise create material that is misleading, suspicious and/or that intends to defraud or confuse other users;
    - post or otherwise create material that is abusive, obscene, pornographic, defamatory, harassing, offensive, vulgar, threatening, or malicious or infringing on privacy or publicity rights, inciting violence, racial or ethnic hatred or which may be described as gross indecency or incitement to commit certain crimes or offences.
    - interfere with, disrupt, or attempt to gain unauthorized access to any other users’ accounts, other Users’ wallets and/or computer networks;
    - use or attempt to use or access another user’s account;
    - use a XbitPlay username to represent that you work for the company and/or for the purpose of selling collectibles;
    - access (or attempt to access) any non-public areas of the website of XbitPlay;
    - interfere with any access or use restrictions, or prevent (or attempt to prevent) another user from accessing or using the website of XbitPlay;
    - use any robot, spider, or other automated means to access, scrape or scan the website of XbitPlay in violation of the terms and conditions set out in this document;
    - use any data mining or data gathering or extraction methods, or otherwise collect information about XbitPlay and other players or users;
    - send viruses, worms, malware, ransomware, junk email, spam, chain letters, phishing emails, unsolicited messages, promotions or advertisements of any kind and for any purpose;
    - modify, adapt, translate, reverse engineer, disassemble or decompile any part of the website of XbitPlay;
    - decompile, disassemble or reverse engineer, or determine any source code, algorithms, methods, or techniques of the website of XbitPlay;
    - use data collected from the services for any commercial activity; violate any applicable laws, regulations or rules;
    - remove or alter any copyright, trademark, confidentiality or other proprietary notices, designations, or marks from the website of XbitPlay;
    - use the services for money laundering or other illicit financial activities;
    - use the website of XbitPlay for any illegal or unauthorized purpose.`,
  "8.1.2":
    "Users and players at XbitPlay expressly understand and agree that access to and use of the services is at your own risk, and that the website of XbitPlay and services are provided on an \"as is\" and \"as available\" basis without warranty of any kind, either express or implied, to the extent permitted by applicable law.",
  "8.1.3":
    "To the fullest extent permitted by applicable law, we make no representation or warranty that access to, or use of the services will be uninterrupted, timely, secure or error-free; usage data provided by the services or on the website of XbitPlay will always be accurate; and/or the services are free of malware or other harmful components.",

  "9.1.1":
    "To the fullest extent allowed under applicable law, XbitPlay disclaims all other warranties, representations and/or conditions except to the extent that they are expressly set out in the terms and conditions set out in this document, express or implied, including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, title and/or non-infringement. ",
  "9.1.2":
    "XbitPlay will not be responsible for any loss or action taken or taken in reliance on the website of XbitPlay, the Services, information, or data presented therein or in connection with, or on any third-party website. XbitPlay disclaims all responsibility for any and all losses that may be incurred by users as a result of using the Blockchain technology and/or digital asset wallets. Additionally, no advice, data, or information, whether presented in writing or orally and/or whether presented by XbitPlay, associated parties or through the website or services of XbitPlay, will create any warranty or representation.",

  "10.1.1":
    `To the fullest extent permitted by applicable law XbitPlay disclaims all liability arising out of or in connection with the website and/or services provided by XbitPlay. We shall not be held liable for:
  •	Your reliance on any statement, information and/or data made available on the website of XbitPlay, the service, and/or any social media platform or blog operated by XbitPlay;
  •	Loss of users’ content, data, common cards, collectibles, or cryptocurrencies;
  •	any event at auction, including but not limited to the price, the Collectible, scarcity level, etc.;
  •	temporary or permanent account suspensions;
  •	direct or indirect damage resulting from the use of the services;
  •	interruptions in the website of XbitPlay or services, and/or any losses caused by errors, bugs, breaches and/or malfunctions;
  •	technologies provided by third parties;
  •	a malfunction or cyberattack;`,
  "10.1.2":
    "you understand accept the risks inherent in the provision of information, online internet exchanges and experimental technologies such as the Blockchain and non-fungible tokens otherwise known as NFTs, and agree that XbitPlay shall not be liable for any failure of the foregoing technologies and/or breach of the security of the website of XbitPlay and/or services provided by XbitPlay, unless caused by gross negligence on the part of XbitPlay.",
  "10.1.3": "XbitPlay is a hosting provider of the content released by the users and or any content exchanged between users. Such content is neither attributable to XbitPlay nor does it represent XbitPlay’s opinion. XbitPlay cannot be held liable for any third-party content such as the content released by users and/or any content exchanged between users. XbitPlay disclaims all responsibility for any and all content as well as the nature of such content exchanged between users.",

  "11.1.1":
    "Failure on the part of XbitPlay at any time to exercise its rights under the terms and conditions set out here in this document shall not constitute the waiver of such right.",
  "11.1.2":
    "If any term, provision, or section of the terms and conditions set out here in this document is held invalid or unenforceable, then that term, provision or section will be deemed removed from the terms and conditions set out here in this document and will not affect the validity or enforceability of any remaining part of such provision, section or any other term, provision or section of the terms and conditions in this document.",
  "11.1.3":
    "All obligations which by their nature should or are intended to survive the termination or expiration of the terms and conditions set out here in this document shall continue in full force and effect, regardless of the termination of these terms and conditions.",
};

export default termsAndConditionEn;
