import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuction } from "_hooks/Socket/auction";
import BundlesRowList from "./List";

const BundlesRow = ({
  list = [],
  title,
  refetchCallback,
  buttonText,
  loading,
}) => {
  const [bundles, setBundles] = useAuction(refetchCallback, list);

  useEffect(() => {
    if (!loading) setBundles(list || []);
  }, [list, setBundles, loading]);

  if (!bundles?.length && !loading) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: "20px",
        borderRadius: "8px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="h6">{t(title)}</Typography>
        <ButtonBase
          as={Link}
          to="/crypto-wars/market/auction?saleType=bundles"
          sx={{
            p: "2px 12px",
            borderRadius: "8px",
            bgcolor: "primary.light",
            color: "common.white",
          }}
        >
          <Typography variant="body2">
            {buttonText ? buttonText : t("viewAllBundles")}
          </Typography>
        </ButtonBase>
      </Box>
      <Box>
        <BundlesRowList list={bundles} loading={loading} />

        {!loading && !bundles?.length && (
          <Typography variant="body1" sx={{ textAlign: "center", my: 2 }}>
            {t("noCardFound")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default BundlesRow;
