import {
  HighlightOffRounded,
  CheckCircleOutlineRounded,
  Close,
} from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";

const PaymentResult = ({ data, onClose }) => {
  let renderedData;
  if (data?.message && data?.message === "Error: Payment failed") {
    renderedData = (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
          pb: 3,
        }}
      >
        <Box>
          <HighlightOffRounded sx={{ color: "#ff0070", fontSize: 120 }} />
        </Box>
        <Typography variant="h6">{t("unsuccessPaymentMessage")}</Typography>
      </Box>
    );
  } else if (data?.status && data?.status === "success") {
    const { status, refNum, amount } = data;

    renderedData = (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          pb: 3,
        }}
      >
        <Box>
          <CheckCircleOutlineRounded sx={{ color: "#4FBE19", fontSize: 120 }} />
        </Box>
        <Typography variant="h6">{t("successPaymentMessage")}</Typography>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2">{t("traceNumber")}: </Typography>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {refNum}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        p: "20px",
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        width: { xs: "95vw", sm: "60vw", lg: "40vw", xl: "500px" },
        maxWidth: "600px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("paymentResult")}
        </Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      <Box>{renderedData}</Box>
    </Box>
  );
};

export default PaymentResult;
