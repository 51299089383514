import Users from ".";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Self = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {user ? (
        <Users selfID={user.id || null} active="profile" />
      ) : (
        navigate("/auth/login")
      )}
    </>
  );
};

export default Self;
