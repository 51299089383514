import { Box, Divider, CircularProgress } from "@mui/material";
import Post from "routes/Community/components/Post";
import InfiniteScroll from "react-infinite-scroll-component";

const Posts = ({ posts, fetchMoreData, hasMore }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
        }
      >
        {posts.map((_, index) => (
          <Box key={index}>
            <Post post={_} />
            {posts.length - 1 !== index && <Divider sx={{ m: 1 }} />}
          </Box>
        ))}
      </InfiniteScroll>
    </Box>
  );
};

export default Posts;
