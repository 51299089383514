import { Box, Divider, Grid, Link, CircularProgress } from "@mui/material";
import Tabs from "./Tabs/";
import TopSummary from "./TopSummary";
import { useEffect, useState } from "react";
import axios from "library/http";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Header from "routes/Community/components/Header";
import SideBar from "routes/Community/Feed/SideBar";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Users = ({ active, selfID }) => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState();
  const [user, setUser] = useState([]);
  const { userID } = useParams();
  const id = typeof selfID !== "undefined" ? selfID : userID ? userID : null;
  const navigate = useNavigate();

  useEffect(async () => {
    if (!id) navigate("/auth/login");
    try {
      const result = await axios.get("/gravity/profiles/" + id);

      if (result.data?.status === "success") {
        setLoading(false);
        setUser(result.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [refresh]);

  return (
    <>
      <Header active={active} />
      <Grid container>
        <Grid item xs={12} md={8} sx={{ px: 2 }}>
          {loading ? (
            <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
          ) : (
            <Box>
              <Link
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  pb: 2,
                  textDecoration: "none",
                }}
                href="/community"
                color="text.primary"
                underline="none"
              >
                <ArrowBackIosNewIcon sx={{ fontSize: "12px", m: 2 }} />
                {user.name}
              </Link>
              <Divider />

              <TopSummary userDetails={user} setRefresh={setRefresh} />

              <Divider />
              <Tabs id={id} />
              <Divider sx={{ m: 1 }} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <SideBar />
        </Grid>
      </Grid>
    </>
  );
};

export default Users;
