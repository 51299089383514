const assetsEn = {
  attentionInfo:
    "* For your asset security, please pay attention to the following info!",
  attentionDeposit1:
    "The address is ONLY available for [network] deposit. Deposit of other assets will lead to permanent asset loss.",
  attentionDeposit2:
    "Minimum amount per deposit: [min]. Assets less than min amount will NOT be credited until the total sum of deposit reaches the minimum amount.",
  attentionDeposit3:
    "Deposit requires confirmation of the entire network and will be credited after [confirm] confirmations.",
  attentionDeposit4:
    "Deposit requires available for withdrawal after [unlock] confirmations",
  protocolType: "Protocol Type",
  depositAttention: "Must read before # deposit",
  depositConfirm: "I'm aware of the risks and would like to proceed",
  confirm: "Confirm",
  copied: "Copied",
  attentionWithdraw1:
    "Please confirm that your withdrawal address matches the type of your chosen mainnet, in case of unnecessary asset loss.",
  attentionWithdraw2:
    "After withdrawal audition, your asset will be sent out as soon as possible. The actual arrival time is determined by the number of confirmations of the recipient.",
  attentionWithdraw3:
    "Minimum amount per Withdraw: [min]. Assets less than min amount will NOT be send.",
  withdrawInfo: "* Withdrawal address based on # network",
  depositInfo: "* Deposit address based on # network",
  noDepositConfigBecauseOfUpdate:
    "Deposit service is suspended due to wallet maintenance.",
  noWithdrawConfigBecauseOfUpdate:
    "Withdrawal service is suspended due to wallet maintenance.",
  tagInfoDeposit: "BOTH Tag and address are required for deposit",
  tagInfoWithdraw: "Please confirm if a Tag is required by the recipient",
  successfulWithdraw: "Successfull",
  withdrawDetails: "Your withdrawal request is being auditing",
  confirmWithdrawDesc:
    "Please enter the 4-digit verification code that was sent to #. The code is valid for 1 minute.",
  successDeposit: "Your deposit has been successfully registered",

  depositTo: "Deposit To",
  tag: "Tag",
  attention: "Attention",
  depositRecords: "Deposit Records",
  time: "Time",
  coin: "Coin",
  amount: "Amount",
  address: "Address",
  status: "Status",
  TXID: "TXID",
  noDataFound: "No data found",
  deposit: "Deposit",
  balance: "Balance",
  available: "Available",
  inAuction: "In Auction",
  pending: "Pending",

  notValidWithdrawAmount: "Enter a valid amount!",
  insuffientBalance: "Insuffient balance!",
  belowMinWitdrawAmount: "Entered amount is less than minimum value!",
  withdrawAmountRequired: "Amount is a required field!",
  withdrawAddressRequired: "Address is a required field!",
  notValidWithdrawAddress: "Please enter a valid address!",
  withdrawTagRequired: "Tag is a required field!",
  actualArrived: "Actual arrived",
  withdrawable: "Withdrawable",
  withdrawFee: "Withdraw fee",
  withdraw: "Withdraw",
  confirmWithdraw: "Confirm Withdraw",
  withdrawRecords: "Withdraw Records",
};

export default assetsEn;
