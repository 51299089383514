import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import Info from "./Info";
import Winning from "./Winning";
import Rewards from "./Rewards";
import CardPack from "../CardPack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { t } from "locales";

const maxStep = 3;

const CryptoWarsLeagueWizard = () => {
  const wizard = useSelector((state) => state.auth?.wizard);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const goNextStep = () => {
    setStep((prev) => prev + 1);
    window.scrollTo(0, 0);
  };
  const goPrevStep = () => {
    setStep((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const cryptoWizardStep = wizard?.CRYPTO;

    if (cryptoWizardStep >= 3) navigate("/investment/play");

    if (!cryptoWizardStep) setStep(0);
    else if (cryptoWizardStep === 1) setStep(1);
    else if (cryptoWizardStep === 2) setStep(1);
    else navigate("/investment/wizard/player");
  }, []);

  let body;
  if (step === 0) {
    body = <CardPack packId={1} nextHandler={goNextStep} />;
  } else if (step === 1) {
    body = <Info goNextStep={goNextStep} />;
  } else if (step === 2) {
    body = <Winning goNextStep={goNextStep} />;
  } else if (step === 3) {
    body = <Rewards goNextStep={goNextStep} />;
  } else if (step === 4) {
    // body = <StartPlay goNextStep={goNextStep} />;
    body = <CardPack redirectPath="/investment/wizard/player" packId={2} />;
  }
  // else if (step === 5) {
  //   body = <CardPack redirectPath="/investment/wizard/player" packId={2} />;
  // }

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 64px)",
        minWidth: "100%",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212429" : "#eee",
        pb: 5,
      }}
    >
      {step !== 4 && step !== 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "15px 20px",
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#000" : "#fff",
          }}
        >
          <Box>
            {step !== 1 && (
              <Box
                onClick={goPrevStep}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  color: "#6E737C",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIos
                  fontSize="small"
                  sx={{
                    transform: (theme) =>
                      theme.direction === "rtl" && "rotate(180deg)",
                  }}
                />
                <Typography variant="body1">{t("back")}</Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {t("howToPlayLeagueHeader")} – {step}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#6E737C",
              }}
            >
              /{maxStep}
            </Typography>
          </Box>
          <Box></Box>
        </Box>
      )}
      <Box
        className="container"
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          minWidth: "100%",
        }}
      >
        {body}
      </Box>
    </Box>
  );
};

export default CryptoWarsLeagueWizard;
