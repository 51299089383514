import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { ButtonBase, TextField, Typography } from "@mui/material";
import { Box, width } from "@mui/system";
import { inputAutoFillStyles } from "assets/styles/styles";
import { t } from "locales";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "components/CustomButton";
import ErrorAlert from "components/ErrorAlert";
import _ from "lodash";
import { putForWithdrawValidation } from "validations/marketValidation";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MAIN_COIN } from "library/constants";
import { usePutCardForSale } from "_hooks/User/queries";
import { getHumanError } from "library/translateServerErrors";
import { useDispatch, useSelector } from "react-redux";
import { addComma, replaceJSX, truncateToDecimals } from "library/helper";
import { openToast } from "components/Toast";
import { BASE_URL } from "config/Config";

const today = new Date();

const WithdrawCard = ({ onClose, id, auctionType }) => {
  const { user, accessToken } = useSelector((state) => state.auth);

  function get_fee() {
    var withdrawFee;
    var url = `${BASE_URL}user/card-crypto/fee`;

    fetch(url, {
      method: "GET",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "success") {
          withdrawFee = json.data.value;
        } else {
          console.error(json);
          throw "API Error";
        }
      })
      .catch((err) => {
        console.error(err);
        withdrawFee = 0;
      })
      .finally(() => {
        var withdrawbox = document.querySelector("#withdrawFee");
        if (withdrawbox !== null) {
          withdrawbox.innerHTML = withdrawFee + " " + MAIN_COIN;
        }
        return withdrawFee;
      });
  }
  const { mutate: putForSale, isError, error } = usePutCardForSale();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(putForWithdrawValidation),
  });

  function withdrawCard(data) {
    const { wallet } = data;
    var url = `${BASE_URL}user/card-crypto/withdraw`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        assignedCardId: id,
        address: wallet,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "success") {
          onClose();
          window.location.reload();
          openToast("success", "withdrawSuccess");
        } else {
          console.error(json);
          if (json.lang.code == 1015) {
            throw "Transaction registering failed, please try later or contact administrator";
          }
          throw "Sorry some error happened, please try later";
        }
      })
      .catch((err) => {
        openToast("error", err);
        console.error(err);
      });
  }

  const onSubmit = (postedData) => {
    withdrawCard(postedData);
  };

  const withdrawFee = get_fee() || 0;

  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: { xs: "95vw", sm: "70vw", lg: "40vw", xl: "400px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("withdraw")}
        </Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      {isError && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={getHumanError(error)} />
        </Box>
      )}

      <Box
        onSubmit={handleSubmit(onSubmit)}
        as="form"
        autocomplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Controller
          control={control}
          name="wallet"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.wallet}
              helperText={t(errors?.wallet?.message)}
              margin="normal"
              fullWidth
              name="walletAddress"
              label={`${t("walletAddress")} (Polygon)`}
              type="text"
              id="walletAddress"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={{ ...inputAutoFillStyles, mb: 3 }}
            />
          )}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">{t("attention")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="body1"
              sx={{
                px: "10px",
                py: "8px",
                color: "#222",
                bgcolor: "#ece2ff",
                borderRadius: "10px",
              }}
            >
              {t("attentionInfo")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: {
                  opacity: ".8",
                  ".keyword": {
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#222" : "#fff",
                  },
                },
              }}
            >
              <Typography variant="body2">
                1. {t("attentionWithdraw1")}
              </Typography>
              <Typography variant="body2">
                2. {t("attentionWithdraw2")}
              </Typography>
              {/* <Typography variant="body2">
                3. Withdraw fee: {withdrawFee} XBT
              </Typography> */}
              <Typography variant="body2">
                3. {t("withdrawFee")}:{" "}
                <Box
                  fontWeight="bold"
                  id="withdrawFee"
                  display="inline"
                  component="span"
                >{`${addComma(withdrawFee || 0)} XBT`}</Box>
              </Typography>
            </Box>
          </Box>
        </Box>
        <CustomButton
          type="submit"
          fullWidth
          variant="outlined"
          id="btnWithdraw"
          loading={isSubmitting}
          disabled={!_.isEmpty(errors, true)}
          loaderHeight="40"
          extraSx={{ p: "15px 12px", width: "100%", mt: 2 }}
        >
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {t("submit")}
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default WithdrawCard;
