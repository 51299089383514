const assetsTR = {
  attentionInfo:
    "* Varlık güvenliğiniz için lütfen aşağıdaki bilgilere dikkat ediniz!",
  attentionDeposit1:
    "Adres YALNIZCA [ağ] mevduatı için kullanılabilir. Diğer varlıkların yatırılması kalıcı varlık kaybına yol açar.",
  attentionDeposit2:
    "Depozito başına minimum miktar: [min]. Asgari miktardan az olan varlıklar, toplam mevduat toplamı minimum miktara ulaşana kadar kredilendirilmeyecektir.",
  attentionDeposit3:
    "Para yatırma, tüm ağın onaylanmasını gerektirir ve [onayla] onaylarından sonra krediye aktarılacaktır.",
  attentionDeposit4:
    "Para yatırma, [kilit açma] onaylarından sonra para çekme için kullanılabilir olmasını gerektirir",
  protocolType: "Protokol Tipi",
  depositAttention: "depozit # yatırmadan önce okunmalıdır",
  depositConfirm: "Risklerin farkındayım ve devam etmek istiyorum",
  confirm: "Onayla",
  copied: "Kopyalandı",
  attentionWithdraw1:
    "Gereksiz varlık kaybı durumunda lütfen para çekme adresinizin seçtiğiniz ana ağ türüyle eşleştiğini onaylayın.",
  attentionWithdraw2:
    "Çekilme seçmelerinden sonra, varlığınız mümkün olan en kısa sürede gönderilecektir. Gerçek varış zamanı, alıcının onay sayısına göre belirlenir.",
  attentionWithdraw3:
    "Çekim başına minimum miktar: [min]. Minum miktarın altındaki varlıklar GÖNDERİLMEYECEKTİR.",
  withdrawInfo: "* # ağa dayalı para çekme adresi",
  depositInfo: "* # ağa dayalı para yatırma adresi",
  noDepositConfigBecauseOfUpdate:
    "Cüzdan bakımı nedeniyle para yatırma hizmeti askıya alındı.",
  noWithdrawConfigBecauseOfUpdate:
    "Ağ bakımı nedeniyle para çekme hizmeti askıya alındı.",
  tagInfoDeposit: "Depozito için HEM Etiket ve adres gereklidir",
  tagInfoWithdraw:
    "Lütfen alıcı tarafından bir Etiket gerekip gerekmediğini onaylayın",
  successfulWithdraw: "Başarılı",
  withdrawDetails: "Para çekme talebiniz denetleniyor",
  confirmWithdrawDesc:
    "Lütfen Ağ # adresine gönderilen 4 haneli doğrulama kodunu giriniz. Kod 1 dakika süreyle geçerlidir.",
  successDeposit: "Para yatırma işleminiz başarıyla kaydedildi",

  depositTo: "Para Yatır",
  tag: "Etiket",
  attention: "dikkat",
  depositRecords: "Para Yatırma Kayıtları",
  time: "Zaman",
  coin: "Coin",
  amount: "Miktar",
  address: "Adres",
  status: "Durum",
  TXID: "TXID",
  noDataFound: "Veri bulunamadı",
  deposit: "Depozit",
  balance: "Denge",
  available: "Müsait",
  inAuction: "Açık Artırmada",
  pending: "Beklemede",

  notValidWithdrawAmount: "Geçerli bir miktar girin!",
  insuffientBalance: "Yetersiz bakiye!",
  belowMinWitdrawAmount: "Girilen miktar minimum değerden az!",
  withdrawAmountRequired: "Miktar zorunlu bir alandır!",
  withdrawAddressRequired: "Adres zorunlu bir alandır!",
  notValidWithdrawAddress: "Lütfen geçerli bir adres girin!",
  withdrawTagRequired: "Etiket gerekli bir alandır!",
  actualArrived: "Gerçek geldi",
  withdrawable: "Çekilebilir",
  withdrawFee: "Para çekme ücreti",
  withdraw: "Çekme",
  confirmWithdraw: "Çekmeyi Onayla",
  withdrawRecords: "Kayıtları Geri Çek",
};

export default assetsTR;
