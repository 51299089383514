import { Box } from "@mui/system";
import Footer from "components/Footer";
import Banner from "./Banner";
import Header from "./Header";
import Navbar from "components/Navbar/Navbar";
import News from "./News";
import Chart from "./Chart";
import GetStart from "./GetStart";
import star1 from "assets/img/star-1-index.svg";
import star2 from "assets/img/star-2-index.svg";
import star3 from "assets/img/star-3-index.svg";
import xmrIcon from "assets/img/xmr-index.png";
import MainSidebar from "components/MainSidebar";

const CryptoWarsMain = () => {
  return (
    <Box>
      <Box
        sx={{
          pb: 5,
          // paddingTop: { xs: "60px", sm: "64px" },
          background: (theme) =>
            theme.palette.mode === "dark" ? "#000" : `#fff`,
        }}
      >
        {/* <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <Box
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              // background: `url(${indexbg}), linear-gradient(183.14deg, rgba(99, 116, 195, 0) 0.18%, rgba(255, 255, 255, 0) 97.4%)`,
              backgroundSize: "cover",
              zIndex: "-1",
              content: '""',
              opacity: "0.6",
              top: 0,
              left: 0,
            }}
          ></Box>
        </Box> */}
        <Navbar />
        <MainSidebar />

        <News />

        <Box
          sx={{
            position: "relative",
            "& img": {
              position: "absolute",
              top: "0",
              left: "40%",
            },
          }}
        >
          <img src={xmrIcon} alt="XMR icon" />
        </Box>
        <Box
          sx={{
            position: "relative",
            "& img": {
              position: "absolute",
              top: "0",
              left: "5%",
              width: "25px",
              filter: (theme) => theme.palette.mode !== "dark" && "invert(1)",
            },
          }}
        >
          <img src={star2} alt="star icon" />
        </Box>
        <Box
          sx={{
            position: "relative",
            "& img": {
              position: "absolute",
              top: "20px",
              left: "8%",
              width: "40px",
              filter: (theme) => theme.palette.mode !== "dark" && "invert(1)",
            },
          }}
        >
          <img src={star1} alt="star icon" />
        </Box>
        <Box
          sx={{
            position: "relative",
            "& img": {
              position: "absolute",
              top: "20px",
              right: "8%",
              width: "50px",
              filter: (theme) => theme.palette.mode !== "dark" && "invert(1)",
            },
          }}
        >
          <img src={star3} alt="star icon" />
        </Box>

        <Box sx={{ mt: "50px" }}>
          <Header />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 5,
            "& img": {
              top: "20px",
              right: "8%",
              width: "40px",
              filter: (theme) => theme.palette.mode !== "dark" && "invert(1)",
            },
          }}
        >
          <img src={star3} alt="star icon" />
        </Box>
        <Chart />
        {/* <Box sx={{ px: "125px" }}>
          <Subscription />
        </Box> */}
        {/* <Feeds />
        <NewPacks /> */}
        <Banner />
        <GetStart />
      </Box>
      <Footer />
    </Box>
  );
};

export default CryptoWarsMain;
