const userTr = {
  // Setting
  publicProfile: "Genel Profil",
  accountSetting: "Hesap Ayarı",
  notifications: "Bildirimler",
  security: "Güvenlik",
  paymentMethods: "Ödeme Yöntemleri",
  connectYourDiscordAccount: "Discord Hesabınızı Bağlayın",
  connectYourTwitterAccount: "Twitter Hesabınızı Bağlayın",
  clubName: "Kulüp Adı",
  uploadAnImage: "Bir resim yükleyin",
  updateProfile: "Profili Güncelle",
  update: "Güncelleme",
  accountSettings: "Hesap Ayarları",
  change: "Değiştir",
  add: "Ekle",
  submit: "Gönder",
  changePassword: "Şifreyi Değiştir",
  currentPassword: "Geçerli Şifre",
  oldPassword: "Geçerli Şifre",
  newPassword: "Yeni Şifre",
  newPasswordConfirm: "Yeni Şifre Onayı",
  addEmail: "E-posta Ekle",
  addMobile: "Mobil Ekle",
  status: "Durum",
  overview: "Genel Bakış",
  cards: "Kartlar",
  achievements: "Başarılar",
  squads: "Takımlar",
  myAuctions: "Müzayedelerim",
  offersReceived: "Alınan teklifler",
  offersSent: "Teklifler gönderildi",
  transactions: "İşlemler",
  delete: "Sil",
  edit: "Düzenle",
  editAuction: "Açık Artırmayı Düzenle",
  volexBalance: "Volex dengesi",
  notificationRecords: "Bildirim Kayıtları",
  markAllAsRead: "Tümünü okundu olarak işaretle",
  noNotification: "Bildirim bulunamadı!",
  depositHistory: "Para Yatırma Geçmişi",
  withdrawHistory: "Geçmişi Geri Çek",
  paymentHistory: "Ödeme Geçmişi",
};

export default userTr;
