import { Box } from "@mui/system";
import AccountSidebar from "./Sidebar";
import AccountTopbar from "./Topbar";
import {
  Person,
  Settings,
  DownloadRounded,
  UploadRounded,
  NotificationsOutlined,
  Receipt,
  Outbox,
} from "@mui/icons-material";
import AuctionIcon from "assets/icons/AuctionIcon";
import { UserSettingRoutes } from "./setting.routes";
import { useSearchParams } from "react-router-dom";

const ITEMS = [
  {
    title: "publicProfile",
    link: "/dashboard/setting?tab=profile",
    icon: Person,
  },
  {
    title: "accountSetting",
    link: "/dashboard/setting?tab=account",
    icon: Settings,
  },
  {
    title: "myAuctions",
    link: "/dashboard/setting?tab=auctions",
    icon: AuctionIcon,
  },
  {
    title: "notifications",
    link: "/dashboard/setting?tab=notifications",
    icon: NotificationsOutlined,
  },
  {
    title: "depositHistory",
    link: "/dashboard/setting?tab=deposit-history",
    icon: DownloadRounded,
  },
  {
    title: "withdrawHistory",
    link: "/dashboard/setting?tab=withdraw-history",
    icon: UploadRounded,
  },
  // {
  //   title: "paymentHistory",
  //   link: "/dashboard/setting?tab=payment-history",
  //   icon: Receipt,
  // },
  {
    title: "submittedOffers",
    link: "/dashboard/setting?tab=submitted-offers",
    icon: Outbox,
  },
];

const UserSettingMain = () => {
  const [searchParams] = useSearchParams();

  return (
    <Box className="container">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          marginTop: { xs: 0, sm: 4 },
          gap: { xs: 0, sm: 3 },
        }}
      >
        <AccountSidebar
          menu={ITEMS}
          findActiveCallback={(link = "") =>
            link.includes(searchParams.get("tab"))
          }
        />
        <AccountTopbar
          menu={ITEMS}
          findActiveCallback={(link = "") =>
            link.includes(searchParams.get("tab"))
          }
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: 1,
            pb: 4,
          }}
        >
          <Box>
            <UserSettingRoutes />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserSettingMain;
