import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  ButtonBase,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { inputAutoFillStyles } from "assets/styles/styles";
import CustomButton from "components/CustomButton";
import ErrorAlert from "components/ErrorAlert";
import { t } from "locales";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, updateUserData } from "redux/auth/asyncActions";
import { changePasswordValidation } from "validations/authValidation";
import _ from "lodash";

const ChangePasswordModal = ({ closeModal = null }) => {
  const dispatch = useDispatch();
  const { status, changePasswordError } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState({
    cur: false,
    new: false,
    confirm: false,
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    resolver: yupResolver(changePasswordValidation),
  });

  const submitHandler = async (data) => {
    try {
      const reqData = {
        oldPassword: data?.currentPassword,
        newPassword: data?.newPassword,
      };

      await dispatch(changePassword(reqData));

      if (status === "fulfilled-changePassword" && !changePasswordError) {
        await dispatch(updateUserData());
        closeModal?.();
      }
    } catch (error) {
      console.error(error?.response);
    }
  };

  useEffect(() => {
    if (status === "fulfilled-changePassword" && !changePasswordError) {
      dispatch(updateUserData());
      closeModal?.();
    }
  }, [status]);

  const handleClickShowPassword = (input) => {
    setShowPassword((prev) => ({ ...prev, [input]: !prev[input] }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: "20px",
        minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "650px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{t("changePassword")}</Typography>
        <ButtonBase
          onClick={closeModal}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      <Box sx={{ width: "100%" }}>
        {changePasswordError && <ErrorAlert text={changePasswordError} />}
      </Box>

      <Box
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Controller
          control={control}
          name="currentPassword"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.currentPassword}
              helperText={t(errors?.currentPassword?.message)}
              fullWidth
              name="currentPassword"
              label={t("oldPassword")}
              id="currentPassword"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={inputAutoFillStyles}
              type={showPassword?.cur ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ mr: "5px" }}>
                    <IconButton
                      onClick={() => handleClickShowPassword("cur")}
                      edge="end"
                    >
                      {showPassword?.cur ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.newPassword}
              helperText={t(errors?.newPassword?.message)}
              fullWidth
              name="newPassword"
              label={t("newPassword")}
              id="newPassword"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={inputAutoFillStyles}
              type={showPassword?.new ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ mr: "5px" }}>
                    <IconButton
                      onClick={() => handleClickShowPassword("new")}
                      edge="end"
                    >
                      {showPassword?.new ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="newPasswordConfirm"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              error={errors?.newPasswordConfirm}
              helperText={t(errors?.newPasswordConfirm?.message)}
              fullWidth
              name="newPasswordConfirm"
              label={t("newPasswordConfirm")}
              id="newPasswordConfirm"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              sx={inputAutoFillStyles}
              type={showPassword?.confirm ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ mr: "5px" }}>
                    <IconButton
                      onClick={() => handleClickShowPassword("confirm")}
                      edge="end"
                    >
                      {showPassword?.confirm ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          loading={isSubmitting}
          disabled={!_.isEmpty(errors, true)}
          loaderHeight="40"
          sx={{ fontSize: "1.1rem", marginTop: 2 }}
          withSpinner
        >
          {t("submit")}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ChangePasswordModal;
