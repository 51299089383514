import {
  Box,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ImageIcon from "@mui/icons-material/Image";
import { useSelector } from "react-redux";
import { useState, useRef } from "react";
import axios from "library/http";
import { openToast } from "components/Toast";
import { useParams } from "react-router";

const NewPost = ({ isComment = false, setRefresh, resource }) => {
  const fileInputRef = useRef();
  const user = useSelector((state) => state.auth.user);
  const [position, setPosition] = useState();
  const [values, setValues] = useState({});
  const [error, setError] = useState();
  const [charsCount, setCharsCount] = useState(0);
  const { id } = useParams();
  const accessToken = useSelector((state) => state.auth.accessToken);

  if (!user) return false;

  console.log(resource);

  const handleChange = (event, value) => {
    setPosition(value);
    value === "bullish"
      ? setValues({ ...values, bullish: true })
      : value === "bearish"
      ? setValues({ ...values, bullish: false })
      : setValues({ ...values, bullish: undefined });
  };

  const contentInput = (event) => {
    let value = event.target.value.trim();
    setValues({ ...values, content: value });
    !value ? setError(true) : setError(false);
  };

  const countTextInput = (event) => {
    setCharsCount(event.target.value.length);
  };

  const fileInputHandle = (event) => {
    const file = event.target.files[0];
    if (file.size > 1048576) {
      fileInputRef.current.value = "";
      return openToast("error", "Image maximum size is 1MB");
    }
    setValues({ ...values, image: file });
  };

  const post = async (event) => {
    event.preventDefault();
    if (!values.content) return setError(true);
    if (error) return;

    let url;
    let type;
    let postValues;
    let formdata = new FormData();

    typeof values.content !== "undefined" &&
      formdata.append("content", values.content);
    typeof values.bullish !== "undefined" &&
      formdata.append("bullish", values.bullish);
    typeof values.image !== "undefined" &&
      formdata.append("image", values.image);
    try {
      if (isComment) {
        url = "gravity/comments";
        type = "Comment";
        postValues = {
          resource: resource,
          resourceId: id,
          content: values.content,
        };

        // Comments need raw data so convert formdata to raw data
        formdata = postValues;
      } else {
        url = "gravity/posts";
        type = "Post";
      }

      console.log("Resource: " + resource);
      console.log(postValues);

      await axios({
        method: "post",
        url: url,
        data: formdata,
        headers: {
          authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      openToast("success", type + " has been sent");
      setValues({});
      setPosition();
      document.getElementById("new-post").reset();
      setRefresh && setRefresh(new Date());
    } catch (error) {
      console.error(error);
      openToast("error", "Something goes wrong, please try again");
    }
  };

  return (
    <Box>
      <form id="new-post" onSubmit={post}>
        <Avatar
          alt={user.name}
          src={user?.avatar || "./broken.jpg"}
          sx={{ m: 1, bgcolor: "secondary.main", float: "left" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ fontWeight: "500" }}>
              {user.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                "& p": {
                  m: 1,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                },
              }}
            >
              {!isComment && (
                <ToggleButtonGroup
                  sx={{ mb: 1, "& button": { p: 0.5 } }}
                  value={position}
                  onChange={handleChange}
                  exclusive={true}
                >
                  <ToggleButton value="bullish">
                    <ArrowDropUpIcon color="success" fontSize="small" />
                    <Typography component="span">Bullish</Typography>
                  </ToggleButton>
                  <ToggleButton value="bearish">
                    <ArrowDropDownIcon color="error" fontSize="small" />
                    <Typography component="span">Bearish</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Box>
          </Box>
          <TextField
            placeholder="What do you think about the markets today?"
            autoComplete="off"
            fullWidth={true}
            name="content"
            required={true}
            error={error}
            helperText={error && "You have to enter post content"}
            multiline={true}
            onInput={contentInput}
            onChange={countTextInput}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: (theme) =>
                  theme.palette.background[theme.palette.mode],
              },
              "& .MuiOutlinedInput-root fieldset": {
                border: "none",
              },
              "& .Mui-focused fieldset": {
                border: "2px solid rgb(171 15 9)",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <IconButton
                aria-label="upload picture"
                component="label"
                sx={{
                  color: "gray",
                  p: 0,
                  "&:hover": {
                    background: "none !important",
                  },
                }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onInput={fileInputHandle}
                  onChange={fileInputHandle}
                  ref={fileInputRef}
                />
                <ImageIcon />
                {values.image && (
                  <Typography
                    sx={{ color: "secondary.main", fontSize: "12px" }}
                  >
                    {values.image.name}
                  </Typography>
                )}
              </IconButton>
              {values.image && (
                <Typography
                  onClick={() => {
                    setValues(delete values["image"]);
                    fileInputRef.current.value = "";
                  }}
                  sx={{ cursor: "pointer", fontSize: "12px" }}
                >
                  {" "}
                  x
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                sx={{
                  color: "gray",
                  ...(charsCount > 255 && { color: "red" }),
                  fontSize: "11px",
                }}
              >
                {charsCount + "/255"}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={post}
                type="submit"
                disabled={charsCount > 255 && true}
              >
                Post
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default NewPost;
