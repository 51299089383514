import Posts from "routes/Community/components/Posts";
import axios from "library/http";
import { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";

const PostsTab = ({ id }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  const getPosts = async () => {
    try {
      const result = await axios.get("/gravity/posts", {
        params: {
          profileId: id,
          page: page,
          limit: limit,
        },
      });

      if (result.data?.status === "success") {
        setPosts([...posts, ...result.data.data.data]);
        setPage(page + 1);
        setHasMore(result.data.data.total > limit * page);

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
      ) : (
        <>
          {posts.length <= 0 ? (
            <Typography variant="h6" sx={{ textAlign: "center", my: 3 }}>
              No posts found
            </Typography>
          ) : (
            <Posts posts={posts} hasMore={hasMore} fetchMoreData={getPosts} />
          )}
        </>
      )}
    </>
  );
};

export default PostsTab;
