import { calcTotalPages } from "library/helper";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import ApiCall from "_clients/apiCall";

export const useGetCompettions = (page) => {
  return useQuery(
    ["cryptowars-competitions", page],
    () =>
      ApiCall("GET", "investment/user/competitions", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    {
      keepPreviousData: true,
    }
  );
};

export const useGetCompettion = (id) => {
  return useQuery(["cryptowars-competition", id], () =>
    ApiCall(
      "GET",
      `investment/user/competitions/${id}`,
      null,
      null,
      (res) => res?.data?.data || []
    )
  );
};

export const usePostCompetition = (data) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => ApiCall("post", "investment/user/competitions", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cryptowars-competition");
      },
    }
  );
  // const result = ApiCall("post", "investment/user/competitions", data);

  // return result;
};
export const useDeleteCompetition = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => ApiCall("delete", `investment/user/competitions/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cryptowars-competition");
        queryClient.invalidateQueries("check-user-cards");
      },
    }
  );
};
export const useGetUserResault = (id) => {
  return useQuery("cryptowars-userResault", () =>
    ApiCall("GET", `investment/user/results/${id}`)
  );
};
export const useGetLeaderBoard = (params) => {
  return useQuery(["cryptowars-leaderboard", params], () =>
    ApiCall("GET", "investment/user/leaderboards", null, params)
  );
};
export const useGetRankingDetails = (params) => {
  return useQuery(["cryptowars-rankingdetails", params], () =>
    ApiCall("GET", "investment/user/ranking-details", null, params)
  );
};
export const useGetOverView = (id) => {
  return useInfiniteQuery(
    ["cryptowars-overview", id],
    ({ pageParam = 1 }) =>
      ApiCall(
        "GET",
        `investment/user/overview`,
        null,
        { id, page: pageParam, limit: 10 },
        (res) => ({
          list: res.data?.data?.data,
          total: calcTotalPages(
            res.data?.data?.total,
            res.data?.data?.pageSize
          ),
        })
      ),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.total === pages.length ? undefined : pages.length + 1,
    }
  );
};
export const useGetPrize = (id) => {
  return useQuery(["cryptowars-prize", id], () =>
    ApiCall(
      "GET",
      `investment/user/prizes/${id}`,
      null,
      null,
      (res) => res?.data?.data?.types || []
    )
  );
};

export const useGetCryptoTeamCards = (params) => {
  return useQuery("cryptowars-team-cards", () =>
    // ApiCall("GET", "public/cryptos", null, params)
    ApiCall("GET", "public/cryptos", null, params)
  );
};
