import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import noImage from "assets/img/unknowncoin.png";

const ListItem = ({ item }) => {
  const imageRef = useRef();
  // const { PD_asset: PD, changePercentage } = item || {};
  const { crypto: PD, changePercentage } = item || {};
  const { name, icon } = PD || {};

  let image;
  try {
    // image = require(`assets/icons/crypto/${name
    //   .split("/")[0]
    //   .toLowerCase()}.png`);
    image = icon[0];
  } catch (e) {}
  return (
    <Box
      key={name}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: "10px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          img: {
            height: "30px",
            width: "30px",
            borderRadius: "50%",
            objectFit: "contain",
          },
        }}
      >
        <img
          src={image?.location || noImage}
          ref={imageRef}
          onError={() => (imageRef.current.src = noImage)}
          alt={name}
        />
        <Box>
          <Typography variant="body1">{name}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "5px 12px",
          display: "inline-flex",
          alignItems: "center",
          flexShrink: "0",
          borderRadius: "5px",
          justifyContent: "center",
          backgroundColor: changePercentage > 0 ? "#116530" : "#c41d0d",
          color: "white",
        }}
      >
        <Typography variant="body2">{changePercentage}%</Typography>
      </Box>
    </Box>
  );
};

export default ListItem;
