import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import OptionsFilter from "components/Filters/OptionsFilter";
import RangeFilter from "components/Filters/RangeFilter";
import {
  levelConfig,
  positionOptions,
  priceConfig,
  saleTypeOptions,
} from "components/Filters/options";

const Index = ({ style = {}, onClear }) => {
  return (
    <Box
      sx={{
        flexDirection: "column",
        width: "240px",
        minWidth: "240px",
        height: "fit-content",
        padding: "0 20px",
        border: (theme) =>
          theme.palette.mode === "dark"
            ? "1px solid #2a2e34"
            : "1px solid #eee",

        // backgroundColor: (theme) =>
        //   theme.palette.mode === "dark" ? "#2a2e34" : "",
        borderRadius: "7px",
        ...style,
      }}
    >
      <OptionsFilter options={saleTypeOptions} title="saleType" />
      {/* <OptionsFilter options={positionOptions} title="position" /> */}
      <RangeFilter {...priceConfig} />
      {/* <RangeFilter {...levelConfig} /> */}
      <ButtonBase
        onClick={onClear}
        variant="text"
        sx={{
          width: "fit-content",
          justifyContent: "flex-start",
          // fontSize: "15px",
          margin: "12px 0 18px 0",
          color: "primary.light",
        }}
      >
        <Typography>{t("deleteAllFilters")}</Typography>
      </ButtonBase>
    </Box>
  );
};

export default Index;
