import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Link,
} from "@mui/material";
import { formatPrice } from "library/helper";
import InfiniteScroll from "react-infinite-scroll-component";

export const MarketsList = ({ markets, fetchMoreData, hasMore }) => {
  return (
    <TableContainer>
      <InfiniteScroll
        dataLength={markets.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
        }
      >
        <Table aria-label="markets table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Pairs</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Volume</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {markets.map((market, _) => (
              <TableRow key={market.id}>
                <TableCell>{_ + 1}</TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "right",
                    "& img": {
                      mr: 0.5,
                    },
                  }}
                >
                  <img
                    src={market.exchange.icon[0].location}
                    alt={market.exchange.name}
                    width="24"
                  />
                  {market.exchange.name}
                </TableCell>
                <TableCell>
                  {market.url ? (
                    <Link
                      href={market.url}
                      target="_blank"
                      underline="none"
                      rel="noreferrer"
                      sx={{
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      {market.pair}
                    </Link>
                  ) : (
                    market.pair
                  )}
                </TableCell>
                <TableCell>{formatPrice(market.price)}</TableCell>
                <TableCell>{formatPrice(market.volume24h)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};
