import { Close } from "@mui/icons-material";
import { Box } from "@mui/system";
import { getImageUrl } from "library/helper";
import React from "react";

const FileModal = ({ onClose, file }) => {
  if (!file) return;

  return (
    <Box
      sx={{
        position: "relative",

        "& > div": {
          display: "flex",
          position: "absolute",
          top: "10px",
          right: "10px",
          bgcolor: "#111",
          p: 0.5,
          borderRadius: "50%",
          cursor: "pointer",
          boxShadow: "0px 0px 20px 0px #111",
        },
      }}
    >
      {onClose && (
        <Box>
          <Close fontSize="large" onClick={onClose} />
        </Box>
      )}
      <img src={getImageUrl(null, file.key) || file?.location} alt="" />
    </Box>
  );
};

export default FileModal;
