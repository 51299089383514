import { Box } from "@mui/system";
import DashboardBody from "./Body";
import DashboardHeader from "./Header";

const Dashboard = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <DashboardHeader />
      <DashboardBody />
    </Box>
  );
};

export default Dashboard;
