import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
// import bannerImg from "assets/img/test/banner-image.png";
// import bannerBg from "assets/img/test/banner-bg.png";
import bannerIMG from "assets/img/bnr-index.jpg";
import { t } from "locales";
import { getImageUrl } from "library/helper";
import { Link } from "react-router-dom";
import { useGetLandingBanner } from "_hooks/CryptoWars/landing";

const fakeData = {
  // fakeThumbnail: bannerBg,
  // fakeImage: bannerImg,
  title: "All new games this year",
  description:
    "dolor sit amet consectetur adipisicing elit. Doloremque sit cupiditate magnam natus ratione distinctio doloribus omnis!",
};

// thumbnail -> bg image
// image -> characer image

const Banner = () => {
  const { data = {} } = useGetLandingBanner(fakeData);

  return (
    <Box className="container" sx={{ maxWidth: '100%', width: '100%', p: 0, m: 0 }}>
      <Box sx={{ marginTop: "4rem", pt: "45px", overflow: "hidden" }}>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundImage: `url(${
              data?.thumbnails
                ? getImageUrl(data?.thumbnails)
                : data?.fakeThumbnail
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            borderRadius: "16px",
            position: "relative",

            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Box
            sx={{
              mx: { xs: "1.5rem", md: "2.5rem" },
              padding: { xs: "1rem 0 0 0", md: "2rem" },
              zIndex: 3,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              color: "common.white",
              width: { xs: "auto", md: "40%" },
              transform: { xs: "translateY(-40px)", md: "unset" },
              "& > *": {
                textAlign: { xs: "center", md: "initial" },
              },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "25px", md: "2.2rem" },
              }}
            >
              {data?.title}
            </Typography>
            <Typography variant="body2">{data?.description}</Typography>
            {data?.link && (
              <Button
                component={Link}
                to={data.link}
                sx={(theme) => ({
                  backgroundColor: theme.palette.solid.blue,
                  color: theme.palette.common.white,
                  width: "fit-content",
                  padding: "7px 15px",

                  "&:hover": {
                    backgroundColor: "rgb(6 97 220 / 85%)",
                  },
                })}
              >
                {t("bannersBtn")}
              </Button>
            )}
          </Box>
          <Box
            sx={[
              {
                zIndex: 2,
                // overflow: { xs: "hidden", lg: "visible" },
                width: { xs: "100%", md: "60%" },
                height: "100%",
                position: { md: "absolute" },
                bottom: { md: 0 },
                borderRadius: "16px",
                img: {
                  height: { md: "calc(100% + 40px)" },
                  width: { xs: "100%", md: "auto" },
                  position: { md: "absolute" },
                  transform: { xs: "translateY(-40px)", md: "unset" },
                  objectFit: { xs: "scale-down", md: "unset" },
                  bottom: 0,
                },
              },
              (theme) =>
                theme.direction === "ltr"
                  ? {
                      right: 0,
                      img: { right: { xl: "2rem" } },
                    }
                  : { left: 0, img: { left: { xl: "2rem" } } },
            ]}
          >
            <img
              src={data?.images ? getImageUrl(data?.images) : data?.fakeImage}
              alt=""
            />
          </Box>
        </Box> */}
          <Box
            sx={[
              {
                width: { xs: "100%" },
                img: {
                  width: { xs: "100%" },
                },
              }
            ]}
          >
            <img
              src={bannerIMG}
              alt=""
            />
          </Box>
      </Box>
    </Box>
  );
};

export default Banner;
