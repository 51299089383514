import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import CustomReactSelect from "components/CustomReactSelect";
import { SkeletonLoader } from "components/Skeleton";
import { fixNumber } from "library/helper";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "config/Config";

const HeaderRow = ({
  wallet,
  walletDeposit = [],
  loading = false,
  onSelect,
  asset,
}) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const { accessToken } = useSelector((state) => state.auth);

  function getWallet() {
    const url = `${BASE_URL}wallet`;
    var wallet;
    fetch(url, {
      method: "GET",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status != "success") {
          throw "Error while calling API";
        }
        wallet = json.data[0];
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        var frozen = +wallet?.frozen || 0;
        var available = +wallet?.amount || 0;
        var pending = +wallet?.pending || 0;
        var balance = frozen + available + pending;

        document.querySelector("#frozen").innerHTML = frozen;
        document.querySelector("#available").innerHTML = available;
        document.querySelector("#pending").innerHTML = pending;
        document.querySelector("#balance").innerHTML = balance;
        return [frozen, available, pending, balance];
      });
  }

  getWallet();

  const frozen = +wallet?.frozen || 0;
  const available = +wallet?.amount || 0;
  const pending = +wallet?.pending || 0;
  const balance = frozen + available + pending;
  return (
    <Box
      sx={{
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        p: "25px",
        borderRadius: "12px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "500" }}>
            {t("deposit")}
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item xs={12} lg={5.5}>
            <Typography variant="body2" component="label">
              {t("coin")}
            </Typography>
            <Box>
              {walletDeposit.length < 0 || !asset?.coin ? (
                <SkeletonLoader height="50" width="100%" isLight={isLight} />
              ) : (
                <CustomReactSelect
                  isSearchable
                  defaultValue={{
                    value: asset.coin,
                    name: asset.name,
                    label: `${asset.coin} ( ${asset.name} )`,
                  }}
                  options={walletDeposit || []}
                  onChange={onSelect}
                  containerSx={{ fieldset: { border: "none !important" } }}
                  selectSx={{
                    bgcolor: "rgba(0,0,0,.2)",
                    borderRadius: "0",
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: { xs: 2, sm: 0 },
                "& > div": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  textAlign: "center",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#1f2129" : "#eee",
                  px: 2,
                  py: 1,
                  borderRadius: "8px",
                },
              }}
            >
              <Grid item xs={12} sm={2.7}>
                <Box>
                  <Typography variant="body1" sx={{ opacity: ".8" }}>
                    {t("balance")}
                  </Typography>
                </Box>
                {/* {loading ? (
                  <SkeletonLoader height="15" width="35" isLight={isLight} />
                ) : ( */}
                <span id="balance" className="fs-5 mt-2">
                  {/* {!isNaN(balance) ? balance.toFixed(2) : 0} */}
                  {!isNaN(balance) ? fixNumber(balance, 2) : 0}
                </span>
                {/* )} */}
              </Grid>
              <Grid item xs={12} sm={2.7}>
                <Box>
                  <Typography variant="body1" sx={{ opacity: ".8" }}>
                    {t("available")}
                  </Typography>
                </Box>
                {/* {loading ? (
                  <SkeletonLoader height="15" width="35" isLight={isLight} />
                ) : ( */}
                <span id="available" className="fs-5 mt-2">
                  {/* {!isNaN(available) ? available.toFixed(2) : 0} */}
                  {!isNaN(available) ? fixNumber(available, 2) : 0}
                </span>
                {/* )} */}
              </Grid>
              <Grid item xs={12} sm={2.7}>
                <Box>
                  <Typography variant="body1" sx={{ opacity: ".8" }}>
                    {t("inAuction")}
                  </Typography>
                </Box>
                {/* {loading ? (
                  <SkeletonLoader height="15" width="35" isLight={isLight} />
                ) : ( */}
                <span id="frozen" className="fs-5 mt-2">
                  {/* {!isNaN(frozen) ? frozen.toFixed(2) : 0} */}
                  {!isNaN(frozen) ? fixNumber(frozen, 2) : 0}
                </span>
                {/* )} */}
              </Grid>
              <Grid item xs={12} sm={2.7}>
                <Box>
                  <Typography variant="body1" sx={{ opacity: ".8" }}>
                    {t("pending")}
                  </Typography>
                </Box>
                {/* {loading ? (
                  <SkeletonLoader height="15" width="35" isLight={isLight} />
                ) : ( */}
                <span id="pending" className="fs-5 mt-2">
                  {/* {!isNaN(pending) ? pending.toFixed(2) : 0} */}
                  {!isNaN(pending) ? fixNumber(pending, 2) : 0}
                </span>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HeaderRow;
