import UserProfile from "routes/User/Dashboard/Body/Setting/Profile";
import UserAccountSetting from "routes/User/Dashboard/Body/Setting/AccountSetting";
import MyAuctions from "routes/User/Dashboard/Body/Setting/MyAuctions";
import Notifications from "routes/User/Dashboard/Body/Setting/Notifications";
import DepositHistory from "routes/Deposit/DepositHistory";
import WithdrawHistory from "routes/Withdraw/WithdrawHistory";
import BuyVlxHistory from "routes/BuyVlx/BuyVlxHistory";
import SubmittedOffers from "routes/User/Dashboard/Body/Setting/SubmittedOffers";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

const components = {
  profile: UserProfile,
  account: UserAccountSetting,
  notifications: Notifications,
  auctions: MyAuctions,
  transactions: MyAuctions,
  "offers-received": MyAuctions,
  "deposit-history": DepositHistory,
  "withdraw-history": WithdrawHistory,
  "payment-history": BuyVlxHistory,
  "submitted-offers": SubmittedOffers,
};

export const UserSettingRoutes = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const Component = useMemo(() => components?.[tab || "profile"], [tab]);

  return Component ? <Component /> : null;
};
