import { Route, Routes } from "react-router";
import Penalty from "routes/MiniGames/Penalty";

const miniGamesRoutes = [
  {
    path: "/mini-games/penalty",
    component: Penalty,
    exact: true,
  },
];

export const MiniGamesRoutes = () => (
  <Routes>
    {miniGamesRoutes.map(({ exact, path, component: Component }, i) => {
      return (
        <Route key={i} exact={exact} path={path} element={<Component />} />
      );
    })}
  </Routes>
);
