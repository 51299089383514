import { Chart } from "react-charts";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

const ChartSection = ({ values }) => {
  const [mode, setMode] = useState(false);

  const themeMode = useSelector((state) => state.setting.theme);

  const data = useMemo(
    () => [
      {
        label: "Price in USD",
        data: values,
      },
    ],
    []
  );

  var primaryAxis = useMemo(function () {
    return {
      getValue: function (test) {
        return test.x;
      },
    };
  }, []);

  var secondaryAxes = useMemo(function () {
    return [
      {
        getValue: function (test) {
          return test.y;
        },
        elementType: "line",
      },
    ];
  }, []);

  useEffect(() => {
    if (themeMode === "dark") {
      setMode(true);
    } else {
      setMode(false);
    }
  }, [themeMode]);

  return (
    <div style={{ height: "400px", width: "100%", overflow: "hidden" }}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          dark: mode,
          showDebugAxes: true,
          height: 400,

          getDatumStyle: function (datum) {
            return {
              fill: datum.y > 20000 ? "#d3d3d3" : "yellow",
              stroke: datum.y > 20000 ? "#d3d3d3" : "yellow",
              strokeWidth: 2,
            };
          },
          getSeriesStyle: (series) => {
            return {
              stroke: "url(#0)",
            };
          },
          renderSVG: function () {
            return (
              <defs>
                <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#17EAD9" />
                  <stop offset="100%" stopColor="#6078EA" />
                </linearGradient>
                <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#ff8f10" />
                  <stop offset="100%" stopColor="#ff3434" />
                </linearGradient>
                <linearGradient id="2" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#42E695" />
                  <stop offset="100%" stopColor="#3BB2B8" />
                </linearGradient>
                <linearGradient id="3" x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="#ffb302" />
                  <stop offset="100%" stopColor="#ead700" />
                </linearGradient>
              </defs>
            );
          },
        }}
      />
    </div>
  );
};

export default ChartSection;
