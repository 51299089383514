import Header from "routes/Community/components/Header";
import { Typography, Box, Button } from "@mui/material";
import RecommendedArticles from "./HomePage/RecommendedArticles";
import LatestArticles from "./HomePage/LatestArticles";

const Articles = ({ active }) => {
  return (
    <>
      <Header active={active} />
      <Box sx={{ m: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Latest Crypto Updates
            </Typography>
            <Typography
              sx={{ fontSize: "16px", color: "text.secondary", mb: 4 }}
            >
              Community showcases the latest cryptocurrency updates published by
              projects from all parts of the crypto universe.
            </Typography>
          </Box>
          <Box>
            <Button
              href="/community/articles/new"
              sx={(theme) => ({
                backgroundImage: theme.palette.gradient.purpule,
                color: theme.palette.common.white,
                width: "fit-content",
                textDecoration: "none",
                borderRadius: "5px",
                p: 1,
                mr: 2,
              })}
            >
              New
            </Button>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>Recommended</Typography>
            <RecommendedArticles />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", mt: 3 }}>
              Latest Posts
            </Typography>
            <LatestArticles />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Articles;
