import Header from "routes/Community/components/Header";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useSelector } from "react-redux";
import { useState } from "react";
import { openToast } from "components/Toast";
import axios from "library/http";

const NewArticle = ({ active }) => {
  const user = useSelector((state) => state.auth.user);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [error, setError] = useState();

  const submitHandler = async (event) => {
    event.preventDefault();

    let values = {
      title: event.target.title.value,
      content: event.target.content.value,
      image: event.target.image.files[0],
    };

    if (!values.title) {
      return setError("Please fill the title");
    } else if (!values.content) {
      return setError("Please fill the content");
    } else if (!values.image) {
      return setError("Please select an image");
    } else {
      setError(false);
    }

    if (error) return false;

    let formdata = new FormData();

    formdata.append("title", values.title);
    formdata.append("content", values.content);
    formdata.append("image", values.image);
    // typeof values.content !== "undefined" &&
    //   formdata.append("content", values.content);
    // typeof values.bullish !== "undefined" &&
    //   formdata.append("bullish", values.bullish);
    // typeof values.image !== "undefined" &&
    //   formdata.append("image", values.image);

    const url = "gravity/articles";
    try {
      await axios({
        method: "post",
        url: url,
        data: formdata,
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      openToast("success", "Article has been sent");
      document.getElementById("new-post").reset();
    } catch (error) {
      console.error(error);
      openToast("error", "Something goes wrong, please try again");
    }
  };

  return (
    <>
      <Header active={active} />
      <Box sx={{ m: 2 }}>
        <form id="new-post" onSubmit={submitHandler} method="post">
          <Avatar
            alt={user.name}
            src={user?.avatar || "./broken.jpg"}
            sx={{ m: 1, bgcolor: "secondary.main", float: "left" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" sx={{ fontWeight: "500" }}>
                {user.name}
              </Typography>
            </Box>
            <Box
              sx={{
                "& .MuiFormControl-root": {
                  mt: 1.5,
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: (theme) =>
                    theme.palette.background[theme.palette.mode],
                },
                "& .MuiOutlinedInput-root fieldset": {
                  border: "none",
                },
                "& .Mui-focused fieldset": {
                  border: "2px solid rgb(171 15 9)",
                },
              }}
            >
              <TextField
                label="Title"
                autoComplete="off"
                name="title"
                required={true}
                sx={{ minWidth: "50%" }}
              />
              <TextField
                label="Content"
                autoComplete="off"
                fullWidth={true}
                name="content"
                required={true}
                multiline={true}
                minRows={5}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: 1,
                alignItems: "flex-start",
              }}
            >
              <IconButton
                aria-label="upload picture"
                component="label"
                sx={{ color: "gray", p: 0 }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="image"
                  //   onInput={fileInputHandle}
                />
                <ImageIcon />
              </IconButton>

              <Box
                sx={{
                  "& button": { width: "fit-content" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  // onClick={submitHandler}
                  type="submit"
                >
                  Submit
                </Button>
                {error && (
                  <Typography
                    variant="caption"
                    sx={{ color: "red", fontWeight: "700" }}
                  >
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NewArticle;
