import axios from "library/http";

export async function getArticles(recommended = false, limit = null, page = null) {
  return await axios.get("/gravity/articles", {
    params: {
      ...(recommended) && recommended,
      ...(limit) && limit,
      ...(page) && page,
    },
  });

}
