const validationFa = {
  emailRequired: "ایمیل را وارد کنید.",
  emailNotValid: "قالب ایمیل صحیح نیم باشد.",
  mobileRequired: "شماره موبایل را وارد کنید.",
  mobileNotValid: "شماره موبایل معتبر نمی باشد.",
  passwordRequired: "رمز عبور را وارد کنید.",
  passwordNotValid:
    "رمز عبور باید حداقل از ۸ کاراکتر تشکیل شده از حروف و اعداد باشد!",
  passwordConfirmationRequired: "تاییدیه رمز عبور را باید وارد کنید!",
  passwordConfirmationNotMatch: "تاییدیه رمز عبور با رمز عبور متفاوت می باشد!",
  nameRequired: "قسمت نام را باید پر کنید.",
  termNotChecked: "قسمت قوانین و مقررات را باید پر کنید!",
  codeRequired:
    "لطفا کد تاییدیه ارسالی به شماره موبایل یا ایمیلتان را وارد کنید!",
  codeMin: "کد باید یک عدد ۴ رقمی باشد!",
  code_type: "کد باید یک عدد باشد.",
  codeType: "کد باید یک عدد باشد.",
  requiredField: "این قسم را باید پر کنید!",
  insufficientWalletAmount: "مقدار غیر مجاز می باشد!",
  invalidTimeFormat: "لطفا تاریخ صحیح را وارد کنید!",
  mustBeNumber: "شما باید یک شماره مشخض کنید!",

  minimumTransferFromVolex: "حداقل مقدار مجاز به انتقال ۱ VLX می باشد!",
};

export default validationFa;
