import { Typography, Grid, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAuthDataByToken } from "redux/auth/authSlice";
import { login, updateUserData, updateWallets } from "redux/auth/asyncActions";
import { BASE_URL } from "config/Config";

const EmailTab = ({ onSubmit, control, errors, loading, disabled }) => {
  const dispatch = useDispatch();
  const [sso, setSso] = useState([]);
  useEffect(() => {
    const url = `${BASE_URL}user/sso/login`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "success") {
          setSso(json.data);
        } else {
          console.error(json);
          throw "API Error";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // Check if data posted using get method
    if (!window.location.search) {
      return false;
    }
    const url = `${BASE_URL}user/sso/login`;
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    const code = urlParams.get("code");

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ source: source, code: code }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "success") {
          const accessToken = json.data.accessToken.token;
          const accessTokenExpire = json.data.accessToken.expiresAt;
          const refreshToken = json.data.refreshToken.token;
          const refreshTokenExpire = json.data.refreshToken.expiresAt;
          const data = {
            accessToken: accessToken,
            accessTokenExpire: accessTokenExpire,
            refreshToken: refreshToken,
            refreshTokenExpire: refreshTokenExpire,
          };
          dispatch(setAuthDataByToken(data));
          dispatch(updateUserData());
          dispatch(updateWallets());
        } else {
          console.error(json);
          throw "API Error";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Grid container sx={{ mt: 2 }}>
      {sso.map((item, index) => {
        return (
          <Grid
            key={index}
            item
            xs={12}
            sx={{
              bgcolor: "secondary.main",
              border: "1px solid #720303",
              borderRadius: "5px",
              mb: "5px",
              "& a": {
                padding: 1,
                m: "0 auto",
                display: "flex",
                textDecoration: "none",
                // color: (theme) =>
                // theme.palette.mode === "dark" ? "#eee" : "#414244",
                color: "#fff",
                "& img": {
                  width: "32px",
                  mr: 1,
                },
              },
              "&:hover": {
                border: "2px solid #720303",
                boxShadow: "5px 5px 9px 0px rgba(0,0,0,0.65)",
                transition: "0.7s",
              },
            }}
          >
            <Box>
              <a href={item.link}>
                <img src={item.logo} width="32" height="32" alt={item.title} />
                <Typography variant="h6">Sign In with {item.title}</Typography>
              </a>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default EmailTab;
