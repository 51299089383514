import { Box } from "@mui/system";
import MarketBody from "./Body";
import Header from "./Header";

const Index = () => {
  return (
    <Box sx={{ pb: 5 }}>
      <Header />
      <MarketBody />
    </Box>
  );
};

export default Index;
