const mainFa = {
  brandName: "ایکس بیت",
  home: "خانه",
  howToPlay: "نحوه انجام بازی",
  nftMarketplace: "بازار NFT",
  community: "شبکه‌های اجتماعی",
  telegram: "تلگرام",
  twitter: "توئیتر",
  instagram: "اینستاگرام",
  gravity: "گرویتی",
  discord: "دیسکورد",
  leaderboard: "جدول امتیازات",
  games: "بازی ها",
  buyVLX: "خرید توکن",
  support: "پشتیبانی",
  dashboard: "داشبورد",
  setting: "تنظیمات",
  logout: "خروج",
  profile: "حساب کاربری",
  somethingWentWrong: "مشکلی پیش آمده است!",
  loading: "در حالی بارگذاری",
  noData: "داده ای موجود نست!",

  mainPageTag: "NFT بستر بازی",
  mainPageHeader: "بازی ایکس بیت",
  mainPageDesc1: "تجربه دنیای واقعی",
  mainPageDesc2: "راهبردهای دنیای فانتزی",
  mainPageBtn: "ماجراجویی خود را شروع کن!",

  feedTitle: "خبرنامه بازی",
  feedDesc: "جدیدترین های بازی ایکس بیت",
  feedBtn: "چگونه بازی کنم‌؟",
  newPackTitle: "مجموعه های جدید",
  newPackDesc: "کارت های جدید اعلامی",
  tradeCardsTitle: "جمع آوری و معامله کن",
  tradeCardsDesc: "NFT کارت های قابل گرد آوری",
  bannersTitle: "تمامی بازی های جدید امسال",
  bannersDesc:
    "در بازی ایکس بیت رایگان ثبت نام کن ، تیم خود را جمع کن و به کسب در آمد بپرداز!",
  bannersBtn: "اطلاعات بیشتر",
  language: "زبان",

  footerTitle: "پلتفرم بازی NFT",
  aboutUs: "درباره ما",
  quickLinks: "درسترسی سریع",
  all: "همه",
  privacyPolicy: "سیاست حفظ حریم خصوصی",
  termsAndConditions: "شرایط و ضوابط",

  newPackSliderBtn: "خرید",
  blog: "اخبار",
};

export default mainFa;
