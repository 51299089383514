import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Header = ({ data = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const changeHandler = (event, newValue) => {
    setSearchParams({ type: newValue });
  };

  useEffect(() => {
    if (!data?.[0]?.id) return;

    if (!searchParams.get("type") || searchParams.get("type") === "undefined")
      setSearchParams({ type: data?.[0]?.id });
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: "10px 20px 0px",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "card.dark" : "card.light",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "transparent" : "#eee",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          {t("competitions")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Tabs value={searchParams.get("type")} onChange={changeHandler}>
          {data?.map?.(({ id, title, color }) => (
            <Tab
              key={id}
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      bgcolor: color,
                    }}
                  ></Box>
                  <Typography variant="body1">{title}</Typography>
                </Box>
              }
              value={id}
              id={id}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default Header;
