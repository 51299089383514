import { calcTotalPages } from "library/helper";
import { useQuery, useQueries } from "react-query";
import ApiCall from "_clients/apiCall";

export const useGetAuctionList = (params) => {
  const queryKey = params
    ? ["cryptowars-auction-list", params]
    : ["cryptowars-auction-list"];

  return useQuery(queryKey, () =>
    ApiCall("GET", "pd/user/auction-list", null, params, (res) => ({
      list: res.data?.data?.data,
      total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
    }))
  );
};
export const useGetBundleAuctionList = (params) => {
  const queryKey = params
    ? ["cryptowars-bundle-list", params]
    : ["cryptowars-bundle-list"];

  return useQuery(queryKey, () =>
    ApiCall("GET", "pd/user/bundle-auction", null, params, (res) => ({
      list: res.data?.data?.data,
      total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
    }))
  );
};

export const useGetCardsBasedOnTypes = (types = []) => {
  return useQueries(
    types.map(({ id, title }) => ({
      queryKey: ["soccer-auction", { id }],
      queryFn: () =>
        ApiCall(
          "GET",
          "pd/user/auction-list",
          null,
          { cardTypeId: id },
          (res) => ({
            type: title,
            typeId: id,
            list: res.data?.data?.data,
            total: calcTotalPages(
              res.data?.data?.total,
              res.data?.data?.pageSize
            ),
          })
        ),
    }))
  );
};

export const useGetAuction = ({ id, onSuccess, onError }) => {
  return useQuery(
    ["single-cryptowars-auction", id],
    () =>
      ApiCall("GET", `pd/user/auction-list/${id}`, null, null, (res) => {
        if (onSuccess) onSuccess(res?.data?.data);
        return res?.data?.data;
      }),
    { onError }
  );
};
export const useGetBundle = ({ id, onSuccess, onError }) => {
  return useQuery(
    ["single-cryptowars-bundle", id],
    () =>
      ApiCall("GET", `pd/user/bundle-auction/${id}`, null, null, (res) => {
        if (onSuccess) onSuccess(res?.data?.data);
        return res?.data?.data;
      }),
    { onError }
  );
};
