import { Route, Routes } from "react-router";
import Feed from "routes/Community/Feed";
import PostDetails from "routes/Community/Feed/PostDetails";
import Self from "routes/Community/Profile/Self";
import User from "routes/Community/Profile";
import FollowList from "routes/Community/Profile/FollowingAndFollowersList/";
import Articles from "routes/Community/Articles";
import AllArticles from "routes/Community/Articles/AllArticles";
import ArticleDetails from "routes/Community/Articles/ArticleDetails";
import NewArticle from "routes/Community/Articles/NewArticle";
import Search from "routes/Community/Search";

export const communityRoutes = [
  {
    path: "/community",
    component: Feed,
    active: "feed",
    exact: true,
  },
  {
    path: "/community/search",
    component: Search,
    exact: true,
  },
  {
    path: "/community/posts/:id",
    component: PostDetails,
    active: "feed",
    exact: true,
  },
  {
    path: "/community/comments/:id",
    component: PostDetails,
    active: "feed",
    isComment: true,
    exact: true,
  },
  {
    path: "/community/articles/",
    component: Articles,
    active: "articles",
    exact: true,
  },
  {
    path: "/community/articles/all",
    component: AllArticles,
    active: "articles",
    exact: true,
  },
  {
    path: "/community/articles/new",
    component: NewArticle,
    active: "articles",
    exact: true,
  },
  {
    path: "/community/articles/:id",
    component: ArticleDetails,
    active: "articles",
    exact: true,
  },
  {
    path: "/community/profile",
    component: Self,
    active: "profile",
    exact: true,
  },
  {
    path: "/community/users/:userID",
    component: User,
    active: "profile",
    exact: true,
  },
  {
    path: "/community/users/:userID/:type",
    component: FollowList,
    active: "profile",
    exact: true,
  },
];

export const CommunityRoutes = () => (
  <Routes>
    {communityRoutes.map(
      ({ exact, path, component: Component, active, isComment }, i) => {
        return (
          <Route
            key={i}
            exact={exact}
            path={path}
            element={<Component active={active} isComment={isComment} />}
          />
        );
      }
    )}
  </Routes>
);
