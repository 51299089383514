const soccerEn = {
  competitions: "Competitions",
  matches: "Matches",
  prize: "Prize",
  gameWeek: "Game Week",
  open: "Open",
  left: "left",
  manageMyTeams: "Manage My Teams",
  select: "Select",
  allMatches: "All Matches",
  myMatches: "My Matches",
  league: "League",
  count: "Count",
  cards: "Cards",
  podium: "Podium",
  participation: "Participation",
  to: "To",
  compete: "Compete",
  managersEntered: "Managers entered",
  minimumCard: "Minimum Card",
  entranceFee: "Entrance Fee",
  participants: "Participants",
  tier: "Tier",
  registered: "Registered",
  noCardFound: "No card found!",
  submitTeam: "Submit team",
  live: "Live",
  leaderboards: "Leaderboards",
  enterATeam: "Enter a team",

  captainModalTitle: "Select your captain",
  captainModalDesc:
    "The player you select as captain will get a 20% to their score. Pick someone you think will perform very well this Game Week!",
  captainModalConfirm: "OK!",
  topPlayers: "Top players",
  yourResult: "Your result",
  viewLiveResults: "View live results",
  viewResults: "View results",
};

export default soccerEn;
