import { Close } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import SoccerPlaySidebar from "../SecondarySidebar";

const MenuModal = ({ menu, closeModal }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", p: "10px" }}>
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
          {t("select")}
        </Typography>
        <ButtonBase
          onClick={closeModal}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
          }}
        >
          <Close />
        </ButtonBase>
      </Box>
      <Box>
        <SoccerPlaySidebar menu={menu} closeModal={closeModal} asMenu />
      </Box>
    </Box>
  );
};

export default MenuModal;
