import PostsTab from "./PostsTab";
import ArticlesTab from "./ArticlesTab";
import LikesTab from "./LikesTab";
import { Tab } from "@mui/material";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import { useState } from "react";

const Tabs = ({ id }) => {
  const [tab, setTab] = useState("posts");

  const handleChange = (tab, newValue) => {
    setTab(newValue);
  };
  return (
    <TabContext value={tab}>
      <TabList variant="fullWidth" onChange={handleChange}>
        <Tab value="posts" label="Posts" />
        <Tab value="articles" label="Articles" />
        <Tab value="likes" label="Likes" />
      </TabList>

      <TabPanel value="posts">
        <PostsTab id={id} />
      </TabPanel>

      <TabPanel value="articles">
        <ArticlesTab id={id} />
      </TabPanel>
      <TabPanel value="likes">
        <LikesTab id={id} />
      </TabPanel>
    </TabContext>
  );
};

export default Tabs;
