const marketEn = {
  auctionTitle: "New Card Auctions",
  auctionDesc:
    "Compete with other managers in auctions of your favorite players",
  deleteAllFilters: "Delete all filters",
  defender: "Defender",
  goalkeeper: "Goalkeeper",
  forward: "Forward",
  midfielder: "Midfielder",
  saleType: "Select Type",
  cards: "Cards",
  bundles: "Bundles",
  limited: "Limited",
  rare: "Rare",
  superRare: "Super Rare",
  unique: "Unique",
  bid: "Bid",
  marketLandingSubHeading: "Create Your Dream Team",
  marketLandingHeading: "NFT Marketplace",
  viewAllCards: "View All Cards",
  viewAllBundles: "View All Bundles",
  makeAnOffer: "Make an offer",
  buy: "Buy",
  level: "Level",
  xp: "XP",
  type: "Type",
  club: "Club",
  serialNumber: "Serial Number",
  season: "Season",
  cardAttributes: "Card attributes",
  connectToVolexWallet: "Connect to volex wallet",
  connectYourWallet: "Connect your wallet",
  connect: "Connect",
  verifyYourWallet: "Verify your wallet",
  swapVlxToPlay: "Swap VLX to play wallet",
  free: "Free",
  inGame: "In Game",
  inAuction: "In Auction",
  price: "Price",
  position: "Position",
  basePrice: "Reserve Price",
  immediatePrice: "Reserve Price",
  bookingPrice: "Minimum Price",
  sell: "Sell",
  withdraw: "Withdraw",
  walletAddress: "Wallet Address",
  contractAddress: "Contract Address",
  withdrawSuccess: "Withdraw Success",
  startTime: "Start date",
  endTime: "End date",
  walletBalance: "Wallet balance",
  enterYourOffer: "Enter your offer",
  bids: "Bids",
  swap: "Swap",
  balance: "Balance",
  from: "From",
  buyImmediately: "Buy Immediately",
  min: "Min",
  max: "Max",
  // maxError: "Insufficient balance",
  maxError: "Please enter a value less than maximum",
  minError: "Please enter a value above minimum",
  noCardFound: "No card found!",
  newCards: "New Cards",
  newBundles: "New Bundles",
  newListed: "New Listed",
  olderListed: "Older Listed",
  endingSoon: "Ending Soon",
  endingLater: "Ending Later",
  loadMore: "Load More",
  bundle: "Bundle",
  buyCardSuccessful: "Your purchase was successful.",
  auctionNotFound: "Auction not found!",
  cardNotFound: "Card not found!",
  showFilters: "Show Filters",

};

export default marketEn;
