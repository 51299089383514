import { io } from "socket.io-client";

class SocketService {
  constructor(socketUrl, accessToken) {
    this._apiUrl = socketUrl;
    this.accessToken = accessToken;

    this.socket = io(`${socketUrl}`, {
      query: {},
      auth: { authorization: "Bearer " + this.accessToken },
    });
  }

  setListener(topic, handler) {
    this.socket?.on?.(topic, handler);
  }

  emitData(topic, data) {
    this.socket?.emit?.(topic, data);
  }

  refreshAccessToken(accessToken) {
    this.socket?.disconnect?.();

    this.accessToken = accessToken;
    this.socket = io(`${this._apiUrl}`, {
      query: {},
      auth: { authorization: "Bearer " + accessToken },
    });
  }

  removeListener(topic, handler) {
    this.socket?.removeAllListeners?.(topic);
    this.socket?.off?.(topic);
  }

  disconnectSocket() {
    this.socket?.disconnect?.();
  }
}

export default SocketService;
