const authFa = {
  verifyDesc:
    "لطفا کد ۴ رقمی ارسالی به (#) را وارد کنید. کد برای ۱ دقیقه معتبر است.",
  email: "ایمیل",
  mobile: "موبایل",
  password: "رمز عبور",
  passwordConfirmation: "تاییدیه رمز عبور",
  name: "نام",
  login: "ورود",
  signin: "ورود",
  signIn: "ورود",
  signup: "ثبت نام",
  signUp: "ثبت نام",
  LOGIN: "ورود",
  SIGNUP: "ثبت نام",
  REGISTER: "ثبت نام",
  signupAgreement: "قوانبن و مقررات را مطالعه کرده ام و قبول دارم.",
  verify: "تایید کنید.",
  verification: "تاییدیه",
  alreadyHaveAnAccount: "در حال حاضر حساب کاربری دارید ؟",
  forgotPassword: "رمز عبور خود را فراموش کرده اید؟",
  freeRegistration: "ثبت نام رایگان",
  back: "قبلی",
  next: "بعدی",
  FORGOT_PASSWORD: "فراموشی رمز عبور",
  resetPassword: "تغییر رمز عبور",
  resend: "ارسال دوباره",
  mobileNotValid: "موبایل معتبر نیست !",
};

export default authFa;
