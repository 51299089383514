import { Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetUserCards } from "_hooks/User/queries";
import Filters from "./Filters";
import List from "./List";

const DashboardCards = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const { data, isLoading: loading } = useGetUserCards({
    page,
    status: searchParams.get("status") || undefined,
    cardTypeId: searchParams.get("type") || undefined,
    limit: 4
  });

  const clearAllFilters = () => setSearchParams({ tab: "cards" });
  const onPageChange = (_, value) => setPage(value);



  return (
    <Box className="container" sx={{ paddingTop: 3, display: "flex", gap: 3 }}>
      <Filters
        onClear={clearAllFilters}
        style={{ display: { xs: "none", md: "flex" } }}
      />
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 4, flexGrow: 1 }}
      >
        <List loading={loading} cards={data?.list}/>

        <Box sx={{ mx: "auto" }}>
          <Pagination
            sx={{ width: "fit-content" }}
            count={data?.total}
            page={page}
            onChange={onPageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCards;
