import {
  AuthRoutes,
  CommonPublicRoutes,
  CommonPrivateRoutes,
} from "Routing/Common";

import {
  CryptoWarsPublicRoutes,
  CryptoWarsPrivateRoutes,
  CryptoWarsPlayRoutes,
} from "Routing/CryptoWars";

import {
  InvestmentPublicRoutes,
  InvestmentPrivateRoutes,
  InvestmentPlayRoutes,
} from "Routing/Investment";

import { MiniGamesRoutes } from "./MiniGames";

import { CryptosRoutes } from "./Cryptos";
import { ExchangesRoutes } from "./Exchanges";
import { CommunityRoutes } from "./Community";

const AppRoutes = () => (
  <>
    <AuthRoutes />
    <CommonPublicRoutes />
    <CommonPrivateRoutes />

    <CryptoWarsPublicRoutes />
    <CryptoWarsPrivateRoutes />
    <CryptoWarsPlayRoutes />

    <InvestmentPublicRoutes />
    <InvestmentPrivateRoutes />
    <InvestmentPlayRoutes />

    <MiniGamesRoutes />

    <CryptosRoutes />
    <ExchangesRoutes />
    <CommunityRoutes />
  </>
);

export default AppRoutes;
