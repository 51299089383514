import { t } from "locales";
import Form from "./Form";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import axios from "library/http";
import { getHumanError } from "library/translateServerErrors";
import List from "./List";
import { getImageUrl } from "library/helper";
import { MAIN_COIN } from "library/constants";

const Index = () => {
  const [type, setType] = useState("BUY");
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [assets, setAssets] = useState({ fiat: [], coin: [] });
  const [selectedAssets, setSelectedAssets] = useState({
    fiat: null,
    coin: null,
  });
  const [list, setList] = useState([]);
  const [inputError, setInputError] = useState();

  const changeType = (type) => setType(type);
  const fiatChangeHandler = (value) =>
    setSelectedAssets((prev) => ({ ...prev, fiat: value }));
  const coinChangeHandler = (value) =>
    setSelectedAssets((prev) => ({ ...prev, coin: value }));
  const amountChangeHandler = (value) => setAmount(value);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get("public/asset");
        const data = response?.data?.data;

        if (!data || data?.length <= 0) return;

        const formattedAssets = { fiat: [], coin: [] };
        data?.forEach?.((item) => {
          const assetType = item?.type === "FIAT" ? "fiat" : "coin";

          // fiat & for coin type just USDT
          if (assetType === "coin") {
            if (item?.coin !== MAIN_COIN) return;

            formattedAssets[assetType] = [
              ...formattedAssets[assetType],
              {
                type: assetType,
                id: item?.id,
                label: item?.coin,
                icon: getImageUrl(item?.icon),
              },
            ];
          } else {
            formattedAssets[assetType] = [
              ...formattedAssets[assetType],
              {
                type: assetType,
                id: item?.id,
                label: item?.coin,
                icon: getImageUrl(item?.icon),
              },
            ];
          }
        });

        setAssets(formattedAssets);
        setSelectedAssets({
          fiat: formattedAssets?.fiat?.[0],
          coin: formattedAssets?.coin?.[0],
        });

        setLoading(false);
      } catch (error) {
        const humanError = getHumanError(error);
        setError(humanError);
        setLoading(false);
      }
    };

    fetchAssets();
  }, []);

  useEffect(() => {
    const fiat = selectedAssets?.fiat?.label;
    const assetId = selectedAssets?.coin?.id;

    if (!fiat || !assetId) return;

    setLoading(true);

    axios
      .get("/asset/payment", {
        params: {
          fiat,
          assetId,
          type,
        },
      })
      .then((res) => {
        setList(res.data?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [selectedAssets, type]);

  return (
    <Box sx={{ pt: "4rem", pb: 5 }}>
      <Box className="container">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", flexDirection: "column", gap: 4 }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                "& > *": {
                  padding: "0.5rem 1.5rem",
                  backgroundColor: "rgba(47, 50, 65, 0.5)",
                  borderRadius: "2rem",
                  color: "#fff",
                  cursor: "pointer",

                  "&.active": { backgroundColor: "#8405d7" },
                },
              }}
            >
              <Box
                onClick={() => changeType("BUY")}
                className={type === "BUY" ? "active" : ""}
              >
                <Typography variant="body1">{t("buy")}</Typography>
              </Box>
              {/* <Box
                onClick={() => changeType("SELL")}
                className={type === "SELL" ? "active" : ""}
              >
                <Typography variant="body1">{t("sell")}</Typography>
              </Box> */}
            </Box>

            <Form
              type={type}
              amount={amount}
              loading={loading}
              assets={assets}
              fiatChangeHandler={fiatChangeHandler}
              coinChangeHandler={coinChangeHandler}
              amountChangeHandler={amountChangeHandler}
              selectedAssets={selectedAssets}
              inputError={inputError}
              setInputError={setInputError}
            />

            <List
              loading={loading}
              data={list}
              selectedAssets={selectedAssets}
              amount={amount}
              inputError={inputError}
              setInputError={setInputError}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <DirectBuySideBar />
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default Index;
