const soccerFa = {
  competitions: "رقابت ها",
  matches: "مسابقات",
  prize: "جایزه",
  gameWeek: "بازی هفته",
  open: "باز",
  left: "تمام شده",
  manageMyTeams: "مدیریت تیم های من",
  select: "انتخاب",
  allMatches: "تمامی مسابقات",
  myMatches: "مسابقه های من",
  league: "لیگ",
  count: "تعداد",
  cards: "کارت ها",
  podium: "لژ اختصاصی",
  participation: "مشارکت",
  to: "تا",
  compete: "رقابت",
  managersEntered: "مدیر های وارد شده",
  minimumCard: "حداقل کارت",
  entranceFee: "هزینه ورود",
  participants: "شرکت کنندگان",
  tier: "ردیف",
  registered: "ثبت شده",
  noCardFound: "کارت یافت نشد!",
  submitTeam: "ثبت تیم",
  live: "زنده",
  leaderboards: "جدول امتیازات",
  enterATeam: "تیم خود را وارد کن.",

  captainModalTitle: "کاپیتان تیم را انتخاب کن",
  captainModalDesc:
    "بازی کنی که به عنوان کاپیتان انتخاب می کنید به امتیاز اش ۲۰٪‌اضافه می شود پس بازیکنی را انتخاب کنید که پیشبینی می کنید بهترین عملکرد را در بازی این هفته داشته باشد.",
  captainModalConfirm: "باشه",
  topPlayers: "برترین بازیکن ها",
  yourResult: "نتیجه شما",
};

export default soccerFa;
