import { Box } from "@mui/system";
import Header from "./Header";
import List from "./List";
import { useParams } from "react-router";
import { useGetLeaderBoard } from "_hooks/CryptoWars/competitions";
import UserResult from "../UserResult";

const Leaderboards = () => {
  const { competitionId } = useParams();
  const { data: typesData } = useGetLeaderBoard({ id: competitionId });
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Header types={typesData?.data?.data?.types} />
      <Box
        sx={{
          padding: "20px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "card.dark" : "card.light",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "transparent" : "#eee",
        }}
      >
        <UserResult
          competitionId={competitionId}
          containerSx={{
            borderBottom: "2px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "#333" : "#eee",
            pb: 1,
            mb: 4,
          }}
        />
        <List
          competitionId={competitionId}
          types={typesData?.data?.data?.types}
        />
      </Box>
    </Box>
  );
};

export default Leaderboards;
