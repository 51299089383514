export const inputAutoFillStyles = {
  "input:-webkit-autofill": {
    borderRadius: "0",
    WebkitBoxShadow: (theme) =>
      theme.palette.mode === "dark"
        ? "0 0 0 1000px #121212 inset"
        : "0 0 0 1000px #fff inset",
    WebkitTextFillColor: (theme) =>
      theme.palette.mode === "dark" ? "#fff!important" : "#000!important",
  },
};

export const accordionStyle = {
  border: "none",
  boxShadow: "none",
  background: "transparent",

  "&.Mui-expanded": {
    margin: "0",
  },
  ".Mui-expanded": {
    margin: "12px 0",
    minHeight: 0,
  },
  ".MuiAccordionSummary-root": {
    padding: 0,
    margin: 0,
    lineHeight: "22px",
  },
  ".MuiAccordionDetails-root": {
    padding: "0 0 8px 0",
  },
};

export const numberInputStyle = {
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    display: "none",
  },
};

export const rtlTextFieldStyle = {
  ".MuiFormHelperText-root": {
    textAlign: "right",
    direction: "rtl",
  },

  "& label": {
    transformOrigin: "top right",
    right: "28px",
    top: "8px",
  },

  "&": {
    padding: "8px 0 !important",
  },
  "& div input": {
    height: "100% !important",
  },

  "& legend": {
    textAlign: "right !important",
  },
};

export const oneLineText = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
