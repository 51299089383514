import * as yup from "yup";
import "yup-phone";

export const loginWithEmailValidation = yup.object({
  email: yup.string().required("emailRequired").email("emailNotValid"),
  password: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .required("passwordRequired"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const loginWithMobileValidation = yup.object({
  mobile: yup.string().min(8, "mobileNotValid").required("mobileRequired"),
  password: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .required("passwordRequired"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const registerWithEmailValidation = yup.object({
  email: yup.string().required("emailRequired").email("emailNotValid"),
  name: yup.string().required("nameRequired"),
  agree: yup.boolean().oneOf([true], "termNotChecked"),
  password: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .required("passwordRequired"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const registerWithMobileValidation = yup.object({
  mobile: yup.string().min(8, "mobileNotValid").required("mobileRequired"),
  name: yup.string().required("nameRequired"),
  agree: yup.boolean().oneOf([true], "termNotChecked"),
  password: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .required("passwordRequired"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const verifValidation = yup.object({
  code: yup
    .string()
    .matches(/^\d{4}$/, "codeMin")
    .required("codeRequired"),
});

export const forgotPasswordWithEmailValidation = yup.object({
  email: yup.string().required("emailRequired").email("emailNotValid"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const forgotPasswordWithMobileValidation = yup.object({
  mobile: yup.string().min(8, "mobileNotValid").required("mobileRequired"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const resetPasswordValidation = yup.object({
  password: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .required("passwordRequired"),
  passwordConfirmation: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .oneOf([yup.ref("password"), null], "passwordConfirmationNotMatch")
    .required("passwordConfirmationRequired"),
});

export const changePasswordValidation = yup.object({
  currentPassword: yup.string().required("requiredField"),
  newPassword: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .required("requiredField"),
  newPasswordConfirm: yup
    .string()
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,64}$/, "passwordNotValid")
    .oneOf([yup.ref("newPassword"), null], "passwordConfirmationNotMatch")
    .required("requiredField"),
});

export const addEmailValidation = yup.object({
  email: yup.string().required("emailRequired").email("emailNotValid"),
});

export const addMobileValidation = yup.object({
  mobile: yup.string().min(8, "mobileNotValid").required("mobileRequired"),
});
