import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export function VerticalTabs({ articles }) {
  const [value, setValue] = useState(0);
  let navigate = useNavigate();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        sx={{ p: 0 }}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openProfile = (id) => {
    navigate("users/" + id);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 224,
        width: "100%",
        height: "400px",
        flexDirection: "row-reverse",
        "& .MuiTabs-indicator": {
          display: "none",
        },
        "& .Mui-selected": {
          backgroundColor: "#F1F1F1",
        },
      }}
    >
      <Box sx={{ display: { xs: "none", md: "contents" } }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            borderRight: 1,
            borderColor: "divider",
            width: { md: "40%" },
            "& .MuiTabs-scrollButtons": { display: "none !important" },
            height: "400px",
            articleTab: {
              height: "120px",
              "& .title": {
                fontWeight: "bold",
                color: "#000",
              },
            },
            "& .MuiButtonBase-root": {
              display: "flex",
              alignItems: "flex-start",
              p: 2,
            },
          }}
        >
          {articles.map((article, index) => (
            <Tab
              key={index}
              label={
                <Box className="articleTab">
                  <Typography sx={{ height: "70px" }}>
                    {article.title}
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() => openProfile(article.profile.id)}
                  >
                    <Avatar
                      alt={article.profile.name}
                      src={
                        article.profile.avatar.length > 0
                          ? article.profile.avatar[0]
                          : "profile.jpg"
                      }
                      sx={{ width: "30px", height: "30px", mr: "5px" }}
                    />
                    <Typography
                      sx={{
                        color: "text.secondary",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {article.profile.name}
                    </Typography>
                  </Box>
                </Box>
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      <Box
        sx={{
          width: { md: "60%", position: "relative" },
          "& img": { width: "100%", height: "100%" },
          "& .container": {
            position: "relative",
          },
        }}
      >
        {articles.map((article, index) => (
          <TabPanel
            value={value}
            index={index}
            key={index}
            children="div"
            className="container"
          >
            <Box
              sx={{
                height: "400px",
                // boxShadow: "-1px -59px 11px -14px rgba(0,0,0,0.75) inset",
              }}
            >
              <img
                src={
                  article.image.length > 0 ? article.image[0].location : null
                }
                alt={article.title}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                color: "#fff",
                width: "100%",
                "& .backgroundoverlay": {
                  background: "#000",
                  content: '""',
                  width: "100%",
                  minHeight: "20px",
                },
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "inherit",
                  backgroundAttachment: "fixed",
                  filter: "blur(12px)",
                  transform: "scale(2) translateY(20px)",
                },
              }}
            >
              <Typography>{article.desciption}</Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  "& span": { fontSize: "14px", fontWeight: "bold" },
                }}
              >
                Published by
                <Typography component="span" sx={{ ml: 0.5 }}>
                  {article.profile.name}
                </Typography>
              </Typography>
            </Box>
          </TabPanel>
        ))}
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            border: "none",
            width: "fit-content",
            position: "absolute",
            right: "30px",
            bottom: "0",
            "& span": {
              width: "6px",
              height: "6px",
              background: "#fff",
              content: '""',
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            "& button": {
              width: "6px",
              height: "6px",
              padding: "0",
              margin: "0",
            },
            "& .MuiButtonBase-root": {
              minHeight: 0,
              minWidth: 0,
            },
            "& .Mui-selected span": {
              backgroundColor: "rgb(171,15,9)",
              height: "12px",
            },
            "& .MuiTabs-flexContainer": {
              gap: "3px",
            },
          }}
        >
          {articles.map((_, index) => (
            <Tab key={index} label={<span></span>} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}
