import ContentLoader from "react-content-loader";

export const SkeletonLoader = ({
  isLight,
  height,
  width,
  marginBottom = 0,
  marginTop = 0,
  borderRadius = 1,
  className = "",
  ...props
}) => {
  let bgColor = {};

  if (isLight) {
    // bgColor = { backgroundColor: "#E9ECEF", foregroundColor: "#ecebe2" };
    bgColor = { backgroundColor: "#d4dae0", foregroundColor: "#eee" };
  } else {
    // bgColor = { backgroundColor: "#22232D", foregroundColor: "#252733" };
    bgColor = { backgroundColor: "#353749", foregroundColor: "#42455b" };
  }

  return (
    <ContentLoader
      className={className}
      width={width}
      height={+height + +marginBottom + +marginTop}
      style={{ marginBottom: marginBottom + "px", marginTop: marginTop + "px" }}
      // viewBox="0 0 100% 830"
      {...bgColor}
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx={borderRadius}
        ry={borderRadius}
        width={width}
        height={height}
      />
    </ContentLoader>
  );
};

export const CircleSkeleton = ({
  isLight,
  radius = "40",
  className = "",
  ...props
}) => {
  let bgColor = {};

  if (isLight) {
    // bgColor = { backgroundColor: "#E9ECEF", foregroundColor: "#ecebe2" };
    bgColor = { backgroundColor: "#d4dae0", foregroundColor: "#eee" };
  } else {
    bgColor = { backgroundColor: "#353749", foregroundColor: "#42455b" };
  }

  return (
    <ContentLoader
      className={className}
      width="40"
      height="40"
      {...bgColor}
      {...props}
    >
      <circle cx={radius} cy={radius} r={radius} />
    </ContentLoader>
  );
};

export const BlogSkeleton = ({ isLight, className = "", ...props }) => {
  let bgColor = {};

  if (isLight) {
    // bgColor = { backgroundColor: "#E9ECEF", foregroundColor: "#ecebe2" };
    bgColor = { backgroundColor: "#d4dae0", foregroundColor: "#eee" };
  } else {
    bgColor = { backgroundColor: "#353749", foregroundColor: "#42455b" };
  }

  return (
    <ContentLoader
      viewBox="0 0 100% 700"
      height={700}
      width="100%"
      {...bgColor}
      className={className}
      {...props}
    >
      <rect x="10" y="0" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="25" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="50" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="75" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="100" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="125" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="150" rx="0" ry="0" width="20%" height="20" />

      <rect x="0" y="200" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="225" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="250" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="275" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="300" rx="0" ry="0" width="45%" height="20" />

      <rect x="0" y="350" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="375" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="400" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="425" rx="0" ry="0" width="80%" height="20" />

      <rect x="0" y="475" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="500" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="525" rx="0" ry="0" width="100%" height="20" />
      <rect x="0" y="550" rx="0" ry="0" width="50%" height="20" />
    </ContentLoader>
  );
};
