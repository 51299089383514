import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { useSelector } from "react-redux";
import SingleCard from "./SingleCard";

const LOADING_LIST = Array(10).fill({});

const MyAuctionsList = ({ list = [], loading = false }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {loading
        ? LOADING_LIST.map((_, i) => (
            <SkeletonLoader
              key={i}
              isLight={isLight}
              width="100%"
              height="120"
              marginBottom="10"
            />
          ))
        : list?.map?.((item) => <SingleCard key={item.id} {...item} />)}
    </Box>
  );
};

export default MyAuctionsList;
