import { Box } from "@mui/system";
import Header from "./Header";
import List from "./List";
import { useState } from "react";
import { useParams } from "react-router";
import DetailPrize from "./DetailPrize";
import { useGetPrize } from "_hooks/Investment/competitions";

const SoccerPlayPrize = () => {
  const { competitionId } = useParams();
  const [prizes, setprizes] = useState(null);
  const { isLoading: loading, data: prizeData } = useGetPrize(competitionId);
  const returnToAllPrize = () => setprizes(null);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {!prizes && <Header data={prizeData} />}
      {prizes ? (
        <DetailPrize returnToAllPrize={returnToAllPrize} data={prizes} />
      ) : (
        <List data={prizeData} setprizes={setprizes} loading={loading} />
      )}
    </Box>
  );
};

export default SoccerPlayPrize;
