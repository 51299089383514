import { Box, Divider, Grid, Link, CircularProgress } from "@mui/material";
import NewPost from "routes/Community/components/NewPost";
import Post from "routes/Community/components/Post";
import LowerSection from "./LowerSection/";
import { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from "library/http";
import { useParams } from "react-router";
import Header from "routes/Community/components/Header";
import SideBar from "routes/Community/Feed/SideBar";
import { useSelector } from "react-redux";

const PostDetails = ({ active, isComment }) => {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [refresh, setRefresh] = useState();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const posts = async () => {
      try {
        let resource = isComment ? "comments" : "posts";
        const result = await axios.get(`/gravity/${resource}/${id}`);

        if (result.data?.status === "success") {
          setPost(result.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    posts();
  }, []);

  return (
    <>
      <Header active={active} />
      <Grid container>
        <Grid item xs={12} md={8} sx={{ px: 2 }}>
          <Box>
            <Link
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                pb: 2,
                textDecoration: "none",
              }}
              href="/community"
              color="text.primary"
              underline="none"
            >
              <ArrowBackIosNewIcon sx={{ fontSize: "12px", m: 2 }} />
              Post Details
            </Link>
            <Divider />
            {loading ? (
              <CircularProgress sx={{ display: "block", m: "0 auto", my: 3 }} />
            ) : (
              <Post post={post} isPostPage="true" isComment={isComment} />
            )}
            {user && <Divider sx={{ m: 1 }} />}
            <NewPost
              isComment={true}
              setRefresh={setRefresh}
              resource={isComment ? "comment" : "post"}
            />
            <Divider sx={{ m: 1 }} />
            <LowerSection
              postID={id}
              refresh={refresh}
              resource={isComment ? "comment" : "post"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <SideBar />
        </Grid>
      </Grid>
    </>
  );
};

export default PostDetails;
