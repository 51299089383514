import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Plus from "assets/icons/Plus";
import TrendDownIcon from "assets/icons/TrendDownIcon";
import TrendUpIcon from "assets/icons/TrendUpIcon";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import Slider from "@mui/material/Slider";
import { AddRounded, RemoveRounded } from "@mui/icons-material";

const TRENDS_BG = {
  buy: { dark: "#373a34", light: "#90bdb4" },
  sell: { dark: "#342726", light: "#e99483" },
};
const TRENDS_COLOR = {
  buy: { dark: "#90bdb4", light: "#373a34" },
  sell: { dark: "#a45747", light: "#342726" },
};
const SingleDeck = ({
  card,
  openCardListModal = null,
  changeCardType,
  changeCardPercent,
  changeActiveCard,
  percent,
}) => {
  const { card: cardData } = card || {};
  const { image } = cardData || {};

  const clickOnDeckHandler = () => openCardListModal?.();

  const onPercentChange = (_, newValue) =>
    changeCardPercent(newValue, card?.id);

  const onIncreasePercent = () =>
    changeCardPercent((prev) => prev + 1, card?.id);
  const onDecreasePercent = () =>
    changeCardPercent((prev) => prev - 1, card?.id);

  const onActiveCardChange = () => {
    changeActiveCard(card?.id);
    clickOnDeckHandler();
  };

  return (
    <Box
      sx={{
        gridColumn: "span 1",
        gridRow: "span 1",
        height: "100%",
        pb: "10px",
      }}
    >
      <Box
        sx={{
          p: "8px",
          pb: !!card ? "0" : "8px",
          position: "relative",
          height: "100%",
        }}
      >
        <Box
          sx={[
            {
              height: "100%",
              width: "200px",
              borderRadius: "10px",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
              position: "relative",
              margin: " 0 auto",
            },
            !!card
              ? {}
              : {
                  background: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(45, 45, 45, 0.4)"
                      : "rgba(45, 45, 45, 0.2)",
                  boxShadow: (theme) =>
                    theme.palette.mode === "dark"
                      ? "0px 3px 8.91px 0.09px rgba(0, 0, 0, 0.63)"
                      : "0px 3px 8.91px 0.09px rgba(0, 0, 0, 0.4)",
                  justifyContent: "center",
                },
          ]}
        >
          {!card ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
                width: "100%",
              }}
              onClick={clickOnDeckHandler}
            >
              <Plus width={30} height={30} color="#fff" />
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                {t("select")}
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                onClick={onActiveCardChange}
                sx={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                  img: {
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                    borderRadius: "20px",
                  },
                }}
              >
                <img src={getImageUrl(image)} alt="" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: 1,
                  "& > div": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    width: "100%",
                    p: "8px",
                    borderRadius: "4px",
                  },
                }}
              >
                <Box
                  onClick={() => changeCardType("ASC", card?.id)}
                  sx={{
                    opacity: card?.type === "ASC" ? "1" : "0.5",
                    filter: (theme) =>
                      theme.palette.mode === "dark" &&
                      card?.type === "ASC" &&
                      "brightness(1.5)",
                    bgcolor: (theme) => TRENDS_BG.buy[theme.palette.mode],
                    color: (theme) => TRENDS_COLOR.buy[theme.palette.mode],
                    cursor: "pointer",
                    svg: {
                      width: "20px",
                      fill: (theme) => TRENDS_COLOR.buy[theme.palette.mode],
                    },
                  }}
                >
                  <Typography variant="body1">{t("buy")}</Typography>
                  <TrendUpIcon />
                </Box>
                <Box
                  onClick={() => changeCardType("DESC", card?.id)}
                  sx={{
                    opacity: card?.type === "DESC" ? "1" : "0.5",
                    filter: card?.type === "DESC" && "brightness(1.5)",
                    bgcolor: (theme) => TRENDS_BG.sell[theme.palette.mode],
                    color: (theme) => TRENDS_COLOR.sell[theme.palette.mode],
                    cursor: "pointer",
                    svg: {
                      width: "20px",
                      fill: (theme) => TRENDS_COLOR.sell[theme.palette.mode],
                    },
                  }}
                >
                  <Typography variant="body1">{t("sell")}</Typography>
                  <TrendDownIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 1,
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Slider
                    size="small"
                    valueLabelDisplay="auto"
                    defaultValue={1}
                    min={1}
                    max={98}
                    step={1}
                    onChange={onPercentChange}
                    value={percent || 1}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 1,
                  "& > div": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: 1,
                    // border: "1px solid #fff",
                    p: "4px 0",
                    borderRadius: "4px",
                    bgcolor: "rgba(45, 45, 45, 0.4)",
                    boxShadow: "0px 3px 12px 2px rgb(0 0 0 / 20%)",
                    cursor: "pointer",
                  },
                }}
              >
                <Box onClick={onDecreasePercent}>
                  <RemoveRounded />
                </Box>
                <Box onClick={onIncreasePercent}>
                  <AddRounded />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SingleDeck;
