import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: null,
  mobileSidebar: false,
  expandedSidebar: true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action?.payload;
    },
    showMobileSidebar: (state) => {
      state.mobileSidebar = true;
    },
    hideMobileSidebar: (state) => {
      state.mobileSidebar = false;
    },
    toggleSidebar: (state) => {
      state.mobileSidebar = !state.mobileSidebar;
    },
    toggleExpandedSidebar: (state) => {
      state.expandedSidebar = !state.expandedSidebar;
    },
    closeExpandedSidebar: (state) => {
      state.expandedSidebar = false;
    },
  },
});

export const {
  setModal,
  showMobileSidebar,
  hideMobileSidebar,
  toggleSidebar,
  toggleExpandedSidebar,
  closeExpandedSidebar,
} = appSlice.actions;
export default appSlice.reducer;
