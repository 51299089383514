import QRCode from "qrcode.react";
import { t } from "locales";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import logoDark from "assets/img/logo-dark.png";
import logoLight from "assets/img/logo-light.png";

const QrcodeModal = ({ code, onClose }) => {
  const theme = useSelector((state) => state.setting.theme);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: "20px", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexGrow: "1",
            gap: 1,
            alignItems: "center",
            img: { height: "40px" },
          }}
        >
          {theme === "dark" ? (
            <img src={logoDark} alt="" />
          ) : (
            <img src={logoLight} alt="" />
          )}
        </Box>
        <Close sx={{ cursor: "pointer" }} size={18} onClick={onClose} />
      </Box>
      <Typography variant="body1" sx={{ my: 1 }}>
        {t("inviteModalDesc")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: "20px",
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        <QRCode className={theme} value={code} level="Q" size={220} />
        <Box sx={{ color: "#222", textAlign: "center", mt: 2 }}>
          <Typography variant="body2" className="label">
            {t("inviteModalCodeTitle")}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {code}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QrcodeModal;
