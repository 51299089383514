import authEn from "./auth";
import mainEn from "./main-en";
import validationEn from "./validation";
import soccerEn from "./soccer";
import cryptoWarsEn from "./crypto-wars";
import gameEn from "./games";
import userEn from "./user";
import marketEn from "./market";
import errorsEn from "./errors";
import buyVlx from "./buy-vlx";
import assetsEn from "./assets";
import howToPlayEn from "./howToPlay";
import privacyPolicyEn from "./privacyPolicy";
import termsAndConditionEn from "./termsAndCondition";
import inviteEn from "./invite";
import contactEn from "./contact";
import ticketEn from "./ticket";

const en = {
  ...mainEn,
  ...authEn,
  ...validationEn,
  ...soccerEn,
  ...gameEn,
  ...cryptoWarsEn,
  ...userEn,
  ...marketEn,
  ...errorsEn,
  ...buyVlx,
  ...assetsEn,
  ...howToPlayEn,
  ...privacyPolicyEn,
  ...termsAndConditionEn,
  ...inviteEn,
  ...contactEn,
  ...cryptoWarsEn,
  ...ticketEn,
};

export default en;
