import { Box } from "@mui/system";
import Sidebar from "./Sidebar";
import MainField from "./MainField";
import { useEffect, useState } from "react";
import { Button, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { t } from "locales";
import MobilePlayersList from "./Sidebar/MobilePlayersList";
import { openToast } from "components/Toast";
import { getHumanError } from "library/translateServerErrors";
import { closeExpandedSidebar } from "redux/app/appSlice";
import { usePostCompetition } from "_hooks/Investment/competitions";

const Index = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [team, setTeam] = useState([null, null, null]);
  const [teamPercentages, setTeamPercentages] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const { expandedSidebar, modal } = useSelector((state) => state.app);
  const showSidebar = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { leagueId_competitionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [leagueId, competitionId] = leagueId_competitionId?.split?.("-") || [];
  const mutationPostCompetition = usePostCompetition();

  useEffect(() => {
    if (showSidebar) setOpenSidebar(false);
  }, [showSidebar]);

  useEffect(() => {
    dispatch(closeExpandedSidebar());
  }, [dispatch]);

  // replace first null value with new card
  const addCardToTeam = (card) => {
    closeMobileSidebar?.();

    let index = team?.findIndex((c) => !c); // find empty place - first null
    // index = index !== -1 ? index : 0;

    const newTeam = [...team];
    newTeam[index] = card;

    // if array is full, add new card to the end
    if (index === -1) {
      newTeam.push(card);
      index = newTeam.length - 1;
    }

    if (index >= 2 && team.length >= 3) {
      newTeam.push(null);
    }

    setTeam(newTeam);
    setTeamPercentages((prev) => {
      const newValue = [...prev];
      newValue[index] = teamPercentages[index] || null;
      return newValue;
    });

    setActiveCard(card?.id);

    modal.hide();
  };

  const changeCardType = (newType, id) => {
    if (isNaN(id)) return;

    const newTeam = [...team];
    const index = team?.findIndex((c) => c?.id === id);

    if (!newTeam?.[index] || index === -1) return;

    newTeam[index].type = newType;
    setTeam(newTeam);
  };

  const changeCardPercent = (newValue, id) => {
    if (isNaN(id)) return;

    let value = newValue;

    if (typeof newValue === "function") {
      const index = team?.findIndex((c) => c?.id === id);
      const prevValue = teamPercentages[index];

      value = newValue(prevValue);
    }

    if (value < 1) return;

    const index = team?.findIndex((c) => c?.id === id);
    // if (!teamPercentages?.[index] || index === -1) return;

    // const sum = teamPercentages.reduce(
    //   (prev, cur, i) => prev + (i === index ? value : cur),
    //   0
    // );

    // Changes other values only if sum is greater than 100
    //   if (sum > 100) {
    //     const otherValue = Math.ceil(Math.abs(100 - value) / 2);
    //     const anotherValue = Math.abs(100 - (value + otherValue));

    //     const newValues =
    //       index === 0
    //         ? [value, otherValue, anotherValue]
    //         : index === 1
    //         ? [otherValue, value, anotherValue]
    //         : [otherValue, anotherValue, value];

    //     newValues[index] = value;

    //     setTeamPercentages(newValues);
    //   } else {
    const newValues = [...teamPercentages];
    newValues[index] = Number(value);
    setTeamPercentages(newValues);

    //   }
  };

  /* Function name is wrong, it should be changeCryptoPrice */
  // const changeCardPercent = (newValue, id) => {
  //   if (isNaN(id) || isNaN(newValue)) return;
  //   if (newValue < 1) return;

  //   let value = newValue;

  // };

  // replace item in team with null
  const removeCardFromTeam = (id) => {
    if (isNaN(id)) return;

    closeMobileSidebar?.();

    const newTeam = [...team];
    const index = team?.findIndex((c) => c?.id === id);

    if (!newTeam?.[index] || index === -1) return;

    // // if index is more than 3 remove the item otherwise replace with null
    // if (index > 2) {
    //   newTeam.splice(index, 1);
    // } else {
    //   newTeam[index] = null;
    // }
    // Remove percentage and card
    const newTeamPercentages = [...teamPercentages];
    newTeam.splice(index, 1);
    newTeamPercentages.splice(index, 1);

    setTeam(newTeam);
    setTeamPercentages(newTeamPercentages);

    if (id === activeCard) {
      const newActive = team?.find?.((c) => !!c)?.id || null;
      setActiveCard(newActive);
    }
  };

  const changeActiveCard = (id) => {
    if (isNaN(id)) return;

    setActiveCard(id);
  };

  const submitTeam = async () => {
    // if all values are null select one at least
    if (team.every((element) => element === null)) {
      openToast("error", "Select one card at least");
      return;
    }

    // remove null values
    let newTeam = team.filter((n) => n);

    // const sum = teamPercentages.reduce((total, cur) => total + cur, 0);
    const sum = teamPercentages.reduce((partialSum, a) => partialSum + a, 0);

    if (sum !== 100000) {
      openToast(
        "error",
        "The sum of the selected items must be equal to 100,000"
      );
      return;
    }

    try {
      let breakmap;
      const formattedCards = newTeam.map((card, index) => {
        if (card?.type == "" || card?.type == null) {
          if (!breakmap)
            openToast("error", "Please select a position for each card");
          breakmap = true;
          return false;
        }
        return {
          id: card?.id,
          amount: teamPercentages[index],
          position: card?.type,
        };
      });

      if (formattedCards.includes(false)) return;

      await mutationPostCompetition.mutateAsync({
        id: leagueId,
        cryptos: formattedCards,
      });
      // await mutationPostCompetition({ id: leagueId, cryptos: formattedCards });

      navigate(`/investment/play/${competitionId}/competitions`);
    } catch {
      const errorMessage = getHumanError(mutationPostCompetition.error);
      openToast("error", errorMessage);
    }
  };

  const openCardListModal = () => {
    if (showSidebar) return;

    setOpenSidebar(true);
  };

  const closeMobileSidebar = () => {
    setOpenSidebar(false);
  };

  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "row",
          height: { xs: "auto", lg: "calc(100vh - 64px)" },
          maxHeight: { xs: "auto", lg: "calc(100vh - 64px)" },
          position: "relative",
        },
        (theme) => ({
          overflow: "hidden",
          maxWidth: expandedSidebar
            ? "calc(100vw - 240px)"
            : "calc(100vw - 40px)",
          [theme.breakpoints.down("lg")]: {
            maxWidth: "100vw",
          },
        }),
      ]}
    >
      {showSidebar && (
        <Sidebar
          onAdd={addCardToTeam}
          onRemove={removeCardFromTeam}
          team={team}
          leagueId={leagueId}
        />
      )}
      <MobilePlayersList
        onAdd={addCardToTeam}
        onRemove={removeCardFromTeam}
        open={!!openSidebar}
        team={team}
        asModal
        leagueId={leagueId}
        onClose={closeMobileSidebar}
      />
      <MainField
        team={team}
        teamPercentages={teamPercentages}
        openCardList={openCardListModal}
        changeCardType={changeCardType}
        changeCardPercent={changeCardPercent}
        changeActiveCard={changeActiveCard}
        activeCard={activeCard}
      />
      <Button
        // disabled={team?.includes(null) || mutationPostCompetition.isLoading}
        disabled={mutationPostCompetition.isLoading}
        onClick={submitTeam}
        sx={(theme) => ({
          backgroundColor: theme.palette.solid.blue,
          color: theme.palette.common.white,
          width: "fit-content",
          padding: "7px 15px",
          position: "fixed",
          top: "80px",
          right: "20px",
          ":disabled": {
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#444" : "#ebebeb",
            border: (theme) =>
              theme.palette.mode === "light" && "1px solid #ccc",
          },
        })}
      >
        {mutationPostCompetition.isLoading
          ? "Submitting..."
          : t("createTeamBtn")}
      </Button>
    </Box>
  );
};

export default Index;
