import {
  Box,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import { SkeletonLoader } from "components/Skeleton";
import axios from "library/http";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import List from "./List";

const Cryptos = ({ embed = true }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [list, setList] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [total, setTotal] = useState(100);
  const [pagesize, setPagesize] = useState("100");

  useEffect(() => {
    const list = async () => {
      try {
        const result = await axios.get("public/cryptos", {
          // Send keyword only if set
          params: { page: page, limit: pagesize, ...(keyword && { keyword }) },
        });
        if (result.data?.status == "success") {
          setList(result.data.data.data);
          setTotal(
            Math.ceil(result.data.data.total / result.data.data.pageSize)
          );
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    list();
  }, [page, pagesize, keyword]);

  const handleChange = (event, value) => {
    setLoading(true);
    setPage(value);
  };

  return (
    <>
      {embed && <Header />}
      <Box m={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <TextField
            id="keyword"
            label="Search"
            variant="standard"
            onChange={(value) => {
              setLoading(true);
              setKeyword(value.target.value);
            }}
          />
          <FormControl variant="standard" size="small">
            <Select
              labelId="row-per-page"
              id="row-per-page"
              defaultValue={pagesize}
              value={pagesize}
              label="Rows"
              onChange={(value) => {
                setLoading(true);
                setPagesize(value.target.value);
              }}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {loading ? (
          Array(pagesize)
            .fill(1)
            .map((_, i) => (
              <SkeletonLoader
                isLight={isLight}
                width="100%"
                height="55"
                children="5"
                key={i}
              />
            ))
        ) : (
          <>
            <List coins={list} page={page} pagesize={pagesize} />
          </>
        )}
        <Pagination
          sx={{ width: "fit-content", mx: "auto", my: "1rem" }}
          count={total}
          page={page}
          color="primary"
          onChange={handleChange}
        />
      </Box>
    </>
  );
};

export default Cryptos;
