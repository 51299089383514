import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { t } from "locales";
import { useSelector } from "react-redux";
import SoccerSingle from "../SingleCard";

const List = ({ cards = [], loading }) => {
  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLight = useSelector((state) => state.setting.theme === "light");

  return (
    <Box>
      <Grid container>
        {loading
          ? Array(10)
              .fill({})
              .map((_, i) => (
                <Grid key={i} item xs={12} sm={4} md={4} lg={3} xl={2.4}>
                  <SkeletonLoader
                    key={i}
                    isLight={isLight}
                    width={smallDevice ? "100%" : "90%"}
                    height={smallDevice ? "120" : "240"}
                    marginBottom="10"
                  />
                </Grid>
              ))
          : cards.map((item) => (
              <Grid key={item.id} item xs={12} sm={4} md={4} lg={3} xl={2.4}>
                <SoccerSingle key={item.id} {...item} />
              </Grid>
            ))}
        {!loading && !cards?.length && (
          <Typography
            variant="h5"
            sx={{ mx: "auto", my: 6, fontWeight: "500" }}
          >
            {t("noCardFound")}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default List;
